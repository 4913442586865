<template>
	<div class="NotFound">

		<div class="wf">
			<div class="w1200 h100 lineh100">
				<div class="logo fl" style="line-height: 50px;">
          <img class="fl mgr5" src="/static/images/common/logo.png?v=1.0" style="height: 50px;margin-top: 25px;">
          <span class="fl mgl10 bold font28 lineh100">{{$http.webConfig.simple}}</span>
        </div>
			</div>
		</div>

		<div class="wf center" style="height: 548px;min-height: 548px;">

			<div style="display: inline-block;width: 700px;margin-top: 100px;">
				<div style="float: left; width: 400px;height: 340px;background-image: url('https:/gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg');"></div>
				<div style="float: left;width: 300px;">
					<div class="font22 bold" style="margin-top: 100px;">抱歉，您访问的页面不存在!</div>
					<router-link to="/m/center"><el-button type="primary" size="large" class="mgtop20 font16">返回首页</el-button></router-link>
				</div>
			</div>

		</div>

		<web-footer></web-footer>

	</div>
</template>

<script>
import WebFooter from '@/components/include/DefaultFooter'
export default {
	name: 'NotFound',
	components:{WebFooter},
	data() {
	    return {
	    }
	},
	methods: {

	},
	mounted() {
		document.title = "您访问的页面不存在"+this.$http.webUrls.manage;
	}
}
</script>

<style>
</style>
