<template>

	<div class="company">
		<web-header></web-header>
		<!-- 标题 -->
		<div class="pdding20 bgffffff overflow">
			<span class="bold font14"><i class="fa fa-bars mgr5" style="color: #00B7EE;"></i>专家信息确认</span>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">

			<div class="pdding20auto mgtop10 radius5" style="background: #FFCC99;">
				<div class="lineh30 font14 mgtop10">【专家申请须知】：</div>
				<p class="wf lineh20 mgbtm10">1、提前准备好一张个人职业形象照片（系统支持上传 jpg、png 格式的照片）。</p>
				<p class="wf lineh20 mgbtm10">2、提前准备好身份证（个人信息完善时需要身份证号码）。</p>
				<p class="wf lineh20 mgbtm10">3、提前准备好一张执业医师证的照片（系统支持上传 jpg、png 格式的照片）。</p>
				<p class="wf lineh20 mgbtm10">4、提前准备好一张职称证的照片（系统支持上传 jpg、png 格式的照片）</p>
				<p class="wf lineh20 mgbtm10">新加入的专家需要填写专家信息，等待平台管理员审核。审核完成后才可以进行评价申请。</p>
			</div>

			<div class="w800 center-block mgtop40">
				<!-- 表单 -->
				<el-form class="wf" ref="doctorForm" :model="doctorForm" label-width="100px" :rules="rules">
					<el-row :gutter="10">
						<el-col :span="12">
							<el-form-item label="专家图像" prop="avater">
								<el-upload class="avatar-uploader" name="mdFile" :action="imgUploadUrl"
									:headers="uploadHeaders" :show-file-list="false" :on-success="handleCoverSuccess"
									:before-upload="beforePicUpload">
									<img v-if="doctorForm.avater" :src="doctorForm.avater" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
								<div class="wf lineh20"> <i
										class="el-icon-star-off"></i>建议使用800*800正方形图片，支持JPG、PNG格式，最大1MB
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="专家姓名" prop="name">
								<el-input v-model="doctorForm.name" placeholder="请填写专家姓名"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="性别" prop="gender">
								<el-select v-model="doctorForm.gender" placeholder="请选择专家性别" style="width:100%;">
									<el-option value="男"></el-option>
									<el-option value="女"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机号" prop="phone">
								<el-input disabled v-model.number="doctorForm.phone" placeholder="请填写专家手机号"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="身份证号" prop="idcard">
								<el-input v-model.number="doctorForm.idcard" placeholder="请填写专家身份证号"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="执业地址" prop="unit">
								<div class="wf h40">
									<el-select v-model="doctorForm.unit" placeholder="请选择专家执业地址" style="width:100%;">
										<el-option v-for="item in unitList" :key="item" :label="item.name"
											:value="item.serial">
										</el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="职称" prop="jobTitle">
								<div class="wf h40">
									<el-select v-model="doctorForm.jobTitle" placeholder="请选择专家职称" style="width:100%;">
										<el-option v-for="item in jobTitleList" :key="item" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="科室" prop="area">
								<div class="wf h40">
									<el-select v-model="doctorForm.area" placeholder="请选择科室" style="width:100%;">
										<el-option v-for="item in areaList" :key="item" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="出诊时间" prop="worktime">
								<div class="wf h40">
									<el-date-picker v-model="doctorForm.worktime" type="datetimerange"
										start-placeholder="开始日期" end-placeholder="结束日期" placeholder="选择日期时间"
										value-format="yyyy-MM-dd HH:mm" style="width: 100%;">
									</el-date-picker>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="擅长领域" prop="summary">
								<el-input type="textarea" :rows="5" v-model="doctorForm.summary"
									placeholder="具体到优势疾病的诊治">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="专业经历" prop="experience">
								<el-input type="textarea" :rows="5" v-model="doctorForm.experience"
									placeholder="请填写专家专业经历">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="职业证书" prop="experience">
								<el-upload class="upload-demo" name="mdFile" list-type="picture-card"
									:action="imgUploadUrl" :headers="uploadHeaders" :on-change="picChange"
									:on-remove="picChange" :on-preview="handlePictureCardPreview" :file-list="picList"
									:before-upload="beforePicUpload">
									<i class="el-icon-plus"></i>
									<div slot="tip" class="el-upload__tip">只能上传JPG、PNG、JPEG文件，且不超过1M</div>
								</el-upload>
								<el-dialog :close-on-click-modal="false" :visible.sync="previewVisible">
									<img width="100%" :src="dialogImageUrl" alt="">
								</el-dialog>
							</el-form-item>
						</el-col>

					</el-row>
				</el-form>

				<div class="wf center">
					<el-button type="primary" @click="publishDoctor">保存</el-button>
				</div>
			</div>

		</div>


		<div class="wf  mgtop40">
			<web-footer></web-footer>
		</div>

	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex'
	import WebHeader from '@/components/include/WebHeader'
	import WebFooter from '@/components/include/WebFooter'
	export default {
		components: {
			WebHeader,
			WebFooter
		},
		name: 'fragementBase',
		data() {
			return {
				previewImageUrl: '',
				previewVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				picList: [],
				doctorForm: {
					avater: "",
					worktime: []
				},
				unitList: [],
				jobTitleList: [],
				positionList: [],
				areaList: [],
				rules: {
					name: [{
							required: true,
							message: '请填写专家姓名',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					gender: [{
						required: true,
						message: '请选择专家性别',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请填写专家电话',
						trigger: 'blur'
					}],
					unit: [{
						required: true,
						message: '请填写专家执业地址',
						trigger: 'blur'
					}],
					jobTitle: [{
						required: true,
						message: '请填写专家职称 ',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '请填写专家所在领域 ',
						trigger: 'blur'
					}],
					avater: [{
						required: true,
						message: '请填写专家图像',
						trigger: 'blur'
					}],
					jobTitle: [{
						required: true,
						message: '请填写专家职称',
						trigger: 'blur'
					}],
					summary: [{
						required: true,
						message: '请填写专家擅长领域',
						trigger: 'blur'
					}],
					experience: [{
						required: true,
						message: '请填写专家专业经历',
						trigger: 'blur'
					}],
					position: [{
						required: true,
						message: '请填写专家职位',
						trigger: 'blur'
					}],
					proportion: [{
						required: true,
						message: '请填写分成比例',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '比例为数字，例如：12.00'
					}],
					mprice: [{
						required: true,
						message: '请填写问诊单价',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '问诊单价为数字，例如：12.00'
					}],
				},
			}
		},
		computed: {
			...mapGetters([
				'principal'
			])
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handleCoverSuccess(res, file) {
				console.log("文件上传", res)
				if (res.files.length > 0)
					this.doctorForm.avater = res.files[0].uploadUrl;
			},
			handlePictureCardPreview(file) {
				this.previewImageUrl = file.url;
				this.previewVisible = true;
			},
			picChange(file, fileList) {
				console.log("文件上传", fileList)
				this.picList = fileList;
			},
			publishDoctor() {
				this.$refs['doctorForm'].validate((valid) => {
					if (valid) {
						var doctorForm = Object.assign({}, this.doctorForm);
						doctorForm.updateTime = "";
						doctorForm.worktime = JSON.stringify(doctorForm.worktime);
						doctorForm.verify = 0;
						var picList = [];
						this.picList.filter(file => {
							if (file.status == "success" && file.response.files.length > 0) {
								let doc = file.response.files[0];
								picList.push({
									uid: file.uid,
									name: doc.orianName,
									url: doc.uploadUrl
								})
							}
						});
						doctorForm.certificate = JSON.stringify(picList);
						this.$http.request(this, this.$apis.user.mineDoctorPublish, doctorForm, true, res => {
							let data = res.data;
							if (data != null) {
								this.doctorList.unshift(data);
							}

							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			showDoctor() {
				this.$http.request(this, this.$apis.user.minedoctorShow, {}, false, res => {
					console.log("获取专家数据成功！", res);
					if (res.code == 0 && res.data != null) {
						let doctorForm = res.data;
						doctorForm.worktime = JSON.parse(doctorForm.worktime);
						this.picList = JSON.parse(doctorForm.certificate);
						if (this.picList != null)
							this.picList.filter(pic => {
								pic.status = "success";
								pic.response = {
									files: [{
										orianName: pic.name,
										uploadUrl: pic.url
									}]
								}
							});

						this.doctorForm = doctorForm;
					}
				});
			},
			showUnit() {
				this.$http.request(this, this.$apis.user.orgList, {
					type: 0
				}, false, res => {
					console.log("获取单位数据成功！");
					if (res.code == 0) {
						this.unitList = res.data;
					}
				});
			},
			showDictionary() {
				this.$http.request(this, this.$apis.tools.dictionaryList, {}, false, res => {
					console.log("获取数据字典");
					if (res.code == 0) {
						this.jobTitleList = res.data["zhicheng"];
						this.positionList = res.data["zhiwei"];
						this.areaList = res.data["zhuangjiakeshi"]
					}
				});
			}
		},
		created() {
			this.doctorForm.phone = this.principal.phone;
			this.showDoctor();
			this.showUnit();
			this.showDictionary();
		}
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
	}

	.avatar {
		width: 148px;
		height: 148px;
		display: block;
	}
</style>
