/**
 * 接口文档列表
 */
import http from '../index';

const baseLogURL = http.baseURL + '/log';

export default {
  logList: "GET:" + baseLogURL + '/v1/api/log/manage/list'
}
