<template>
  <div class="fragementAddress">

    <div class="pdding20 bgffffff radius5 font12">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统设置</el-breadcrumb-item>
        <el-breadcrumb-item>平台设置</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="pdding20 bgffffff overflow mgtop10">
      <el-form class="wf" ref="appConfigForm" :model="appConfigForm" label-width="200px" :rules="rules">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="关于我们" prop="about">
              <el-input v-model="appConfigForm.about" placeholder="请填写关于我们链接"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="隐私政策" prop="law">
              <el-input v-model="appConfigForm.law" placeholder="请填写隐私政策链接"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="服务协议" prop="protocol">
              <el-input v-model="appConfigForm.protocol" placeholder="请填写关于我们链接"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="账号注销申明" prop="destroy">
              <el-input v-model="appConfigForm.destroy" placeholder="请填写账号注销申明链接"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-col class="center" :span="24">
          <el-button type="success" @click="publishAppConfig"><i class="fa fa-save"></i> 保存</el-button>
        </el-col>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        appConfigForm: {
          roles: [],
          checkupPrice: 0,
          vaccinePrice: 0
        },
        rules: {
          rules: [{
            required: true,
            message: '请填写礼券规则',
            trigger: 'blur'
          }],
          vaccinePrice: [{
            required: true,
            message: '请填写价格',
            trigger: 'blur'
          }, {
            pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
            message: '价格为数字，例如：12.00'
          }],
          checkupPrice: [{
            required: true,
            message: '请填写价格',
            trigger: 'blur'
          }, {
            pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
            message: '价格为数字，例如：12.00'
          }]
        },
      }
    },
    methods: {
      publishAppConfig() {
        this.$refs['appConfigForm'].validate((valid) => {
          if (valid) {
            var appConfigForm = Object.assign({}, this.appConfigForm);
            appConfigForm.roles = JSON.stringify(appConfigForm.roles);
            appConfigForm.vaccinePrice = parseFloat(appConfigForm.vaccinePrice * 100).toFixed(0);
            appConfigForm.checkupPrice = parseFloat(appConfigForm.checkupPrice * 100).toFixed(0);
            appConfigForm.subTime = "";
            this.$http.request(this, this.$apis.tools.appConfigPublish, appConfigForm, true, res => {

            });
          }
        });
      },
      showAppConfig() {
        this.$http.request(this, this.$apis.tools.appConfigShow, {}, false, res => {
          if (res.data != null) {
            let data = this.$http.cleanData(res.data);
            if (data.roles != "" && data.roles.indexOf("[") > -1)
              data.roles = JSON.parse(data.roles);

            data.vaccinePrice = parseFloat(data.vaccinePrice * 0.01).toFixed(2);
            data.checkupPrice = parseFloat(data.checkupPrice * 0.01).toFixed(2);
            this.appConfigForm = data;
          }
        });
      }
    },
    created() {
      this.showAppConfig();
    }
  }
</script>

<style>
</style>
