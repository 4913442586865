import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import NotFound from "@/components/NotFound"

import UCModel from '@/components/model/UCModel'
import MemberHomeModel from '@/components/model/MemberHomeModel'
import MemberModel from '@/components/model/MemberModel'
import MemberFrameModel from '@/components/model/MemberFrameModel'

import Login from '@/components/uc/Login'
import FindPwd from '@/components/uc/FindPwd'
import Reg from '@/components/uc/Reg'
import RegSuccess from '@/components/uc/RegSuccess'

import Home from '@/components/Home'
import Frame from '@/components/Frame'

//会员管理
import MemberIndex from '@/components/client/member/Index'
import MemberShow from '@/components/client/member/BaseInfo'
import DoctorCertification from '@/components/user/Certification'
//网点
import OrgIndex from '@/components/client/org/Index'
import OrgShow from '@/components/client/org/BaseInfo'
import OrgLog from '@/components/client/org/Log'
import OrgMapIndex from '@/components/client/org/map/Index'
import OrgAssign from '@/components/client/org/OrgAssign'

//统计
import Hub from '@/components/hub/Index'
//礼券
import Coupon from '@/components/db/tools/Coupon'
import MemberCoupon from '@/components/finance/MemberCoupon'
//积分
import MemberIntegral from '@/components/finance/MemberIntegral'
//推广记录
import PromoteCoupon from '@/components/finance/PromoteCoupon'
//Vip订单
import VipCardOrderIndex from '@/components/finance/order/vipCard/Index'
import VipCardOrderShow from '@/components/finance/order/vipCard/BaseInfo'

//集市管理
import ShopType from '@/components/shop/Type'
import ShopProduct from '@/components/shop/Product'
import ShopOrderIndex from '@/components/shop/order/Index'
import ShopOrderShow from '@/components/shop/order/BaseInfo'
import EvaluationList from '@/components/shop/EvaluationList'

//设置
import Action from '@/components/setting/log/Action'
import Log from '@/components/setting/log/Index'
import Manager from '@/components/setting/manager/Index'
import Role from '@/components/setting/role/Index'
import Menus from '@/components/setting/Menus'

import AppConfig from '@/components/db/tools/AppConfig'
import Banner from '@/components/db/tools/Banner'
import Feedback from '@/components/db/tools/Feedback'
import VipCard from '@/components/db/tools/VipCard'
import Doctor from '@/components/db/tools/Doctor'

import UserInfo from '@/components/user/UserInfo'
import Security from '@/components/user/Security'

//修改密码
import ChangePwd from '@/components/user/ChangePwd'

//建档治疗
import Blindness from '@/components/treatment/Blindness'
import BuildArchives from '@/components/treatment/BuildArchives'
import CureAI from '@/components/treatment/CureAI'
import ExamAI from '@/components/treatment/ExamAI'
import ExamVision from '@/components/treatment/ExamVision'
import CureFace from '@/components/treatment/CureFace'
import CureReferral from '@/components/treatment/CureReferral'
import CureVisit from '@/components/treatment/CureVisit'
import CureNurse from '@/components/treatment/CureNurse'
import Element from '@/components/treatment/archive/Element'
import YoungMyOpia from '@/components/treatment/archive/YoungMyOpia'

import Footprint from '@/components/db/tools/Footprint'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes: [{
			path: '*',
			component: NotFound,
			meta: {
				requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
			}
		},
		{
			path: '/',
			redirect: '/m/dashbord'
		},
		{
			path: '',
			redirect: '/m/dashbord'
		},
		{
			path: '/uc',
			name: 'UCModel',
			component: UCModel,
			children: [{
					path: 'login',
					name: 'Login',
					component: Login,
				},
				{
					path: 'findpwd',
					name: 'FindPwdFindPwd',
					component: FindPwd,
				},
				{
					path: 'reg',
					name: 'reg',
					component: Reg,
				},
				{
					path: 'reg/success',
					name: 'RegSuccess',
					component: RegSuccess,
				}
			]
		},
		{
			path: '/m',
			name: 'MemberFrameModel',
			component: MemberFrameModel,
			meta: {
				requireAuth: true
			},
			children: [{
				path: 'dashbord',
				name: 'Frame',
				component: Frame,
			}]
		},
		{
			path: '/m',
			name: 'MemberHomeModel',
			component: MemberHomeModel,
			meta: {
				requireAuth: true
			},
			children: [{
					path: 'center',
					name: 'Home',
					component: Home
				},
				{
					path: 'hub',
					name: 'Hub',
					component: Hub
				},
				{
					path: 'safety/center',
					name: 'Security',
					component: Security
				}, {
					path: 'changepwd',
					name: 'ChangePwd',
					component: ChangePwd
				}
			]
		},
		{
			path: '/m',
			name: 'MemberModel',
			component: MemberModel,
			meta: {
				requireAuth: true
			},
			children: [{
					path: 'doctor/certification',
					name: 'DoctorCertification',
					component: DoctorCertification
				}, {
					path: 'member/center',
					name: 'MemberIndex',
					component: MemberIndex
				},
				{
					path: 'member/:memberId/show',
					name: 'MemberShow',
					component: MemberShow
				},
				{
					path: 'org/assign',
					name: 'OrgAssign',
					component: OrgAssign
				},
				{
					path: 'org/center',
					name: 'OrgIndex',
					component: OrgIndex
				},
				{
					path: 'org/:orgId/show',
					name: 'OrgShow',
					component: OrgShow
				},
				{
					path: 'org/:orgId/log',
					name: 'OrgLog',
					component: OrgLog
				}, {
					path: 'org/:orgId/polygon',
					name: 'OrgMapIndex',
					component: OrgMapIndex
				},
				{
					path: 'doctor',
					name: 'Doctor',
					component: Doctor,
				}
			]
		},
		{
			path: '/m/doc',
			name: 'MemberModel',
			component: MemberModel,
			meta: {
				requireAuth: true
			},
			children: [{
					path: 'vip/card',
					name: 'VipCard',
					component: VipCard
				},
				{
					path: 'material/list',
					name: 'MaterialsList',
					component: resolve => require(['@/components/material/MaterialList'], resolve),
					meta: {
						auth: 'yygl_sqgl'
					}
				}, {
					path: 'news/list',
					name: 'NewsList',
					component: resolve => require(['@/components/material/NewsList'], resolve)
				},
				{
					path: 'notice/plan/list',
					name: 'NoticePlanList',
					component: resolve => require(['@/components/material/NoticePlanList'], resolve)
				},
				{
					path: 'notice/plan/comment',
					name: 'PlanCommentList',
					component: resolve => require(['@/components/material/PlanCommentList'], resolve)
				},
				{
					path: 'notice/list',
					name: 'NoticeList',
					component: resolve => require(['@/components/material/NoticeList'], resolve)
				},
				{
					path: 'protocol/list',
					name: 'ProtocolList',
					component: resolve => require(['@/components/material/ProtocolList'], resolve)
				},
				{
					path: 'help/list',
					name: 'HelpList',
					component: resolve => require(['@/components/material/HelpList'], resolve)
				},
				{
					path: 'help/option',
					name: 'HelpOption',
					component: resolve => require(['@/components/material/HelpOption'], resolve)
				},
				{
					path: 'feedback/list',
					name: 'Feedback',
					component: Feedback
				},
				{
					path: 'banner',
					name: 'Banner',
					component: Banner
				}, {
					path: 'footprint',
					name: 'Footprint',
					component: Footprint
				}
			]
		},
		{
			path: '/m/shop',
			name: 'MemberModel',
			component: MemberModel,
			meta: {
				requireAuth: true
			},
			children: [{
					path: 'type',
					name: 'ShopType',
					component: ShopType
				}, {
					path: 'product/list',
					name: 'ShopProduct',
					component: ShopProduct
				}, {
					path: 'order/list',
					name: 'ShopOrderIndex',
					component: ShopOrderIndex,
					meta: {
						auth: 'jsgl_ddgl'
					}
				},
				{
					path: 'order/:orderId/show',
					name: 'ShopOrderShow',
					component: ShopOrderShow,
					meta: {
						auth: 'jsgl_ddgl'
					}
				},
				{
					path: 'evaluation',
					name: 'EvaluationList',
					component: EvaluationList,
					meta: {
						auth: 'fwxx_yyjl'
					}
				}
			]
		},
		{
			path: '/m/setting',
			name: 'MemberModel',
			component: MemberModel,
			meta: {
				requireAuth: true
			},
			children: [{
					path: 'news/type',
					name: 'NewsType',
					component: resolve => require(['@/components/db/NewsType'], resolve)
				},
				{
					path: 'news/classification',
					name: 'NewsClassification',
					component: resolve => require(['@/components/db/NewsClassification'], resolve)
				},
				{
					path: 'protocol/type',
					name: 'ProtocolType',
					component: resolve => require(['@/components/db/ProtocolType'], resolve)
				},
				{
					path: 'protocol/classification',
					name: 'ProtocolClassification',
					component: resolve => require(['@/components/db/ProtocolClassification'], resolve)
				},
				{
					path: 'help/type',
					name: 'HelpType',
					component: resolve => require(['@/components/db/HelpType'], resolve)
				},
				{
					path: 'help/classification',
					name: 'HelpClassification',
					component: resolve => require(['@/components/db/HelpClassification'], resolve)
				},
				{
					path: 'dictionary/type',
					name: 'DictionaryType',
					component: resolve => require(['@/components/db/DictionaryType'], resolve)
				},
				{
					path: 'dictionary/classification',
					name: 'DictionaryClassification',
					component: resolve => require(['@/components/db/DictionaryClassification'], resolve)
				},
			]
		},
		{
			path: '/m/hub',
			name: 'MemberModel',
			component: MemberModel,
			meta: {
				requireAuth: true
			},
			children: [{
					path: 'index',
					name: 'Hub',
					component: Hub
				},
				{
					path: 'vip/card/orders',
					name: 'VipCardOrderIndex',
					component: VipCardOrderIndex
				},
				{
					path: 'vip/card/order/:orderId/show',
					name: 'VipCardShow',
					component: VipCardOrderShow
				}, {
					path: 'member/coupons',
					name: 'MemberCoupon',
					component: MemberCoupon
				}, {
					path: 'coupons',
					name: 'Coupon',
					component: Coupon
				}, {
					path: 'member/promote',
					name: 'PromoteCoupon',
					component: PromoteCoupon
				}, {
					path: 'member/integral',
					name: 'MemberIntegral',
					component: MemberIntegral
				}
			]
		},
		{
			path: '/m/hub',
			name: 'MemberModel',
			component: MemberModel,
			meta: {
				requireAuth: true
			},
			children: [{
					path: '/m/color/blindness',
					name: 'Blindness',
					component: Blindness
				},
				{
					path: '/m/cure/nurse',
					name: 'CureNurse',
					component: CureNurse
				},
				{
					path: '/m/build/archives',
					name: 'BuildArchives',
					component: BuildArchives
				},
				{
					path: '/m/build/myopia',
					name: 'YoungMyOpia',
					component: YoungMyOpia
				},
				{
					path: '/m/cure/ai',
					name: 'CureAI',
					component: CureAI
				}, {
					path: '/m/exam/ai',
					name: 'ExamAI',
					component: ExamAI
				}, {
					path: '/m/exam/vision',
					name: 'ExamVision',
					component: ExamVision
				}, {
					path: '/m/cure/face',
					name: 'CureFace',
					component: CureFace
				}, {
					path: '/m/cure/referral',
					name: 'CureReferral',
					component: CureReferral
				},
				{
					path: '/m/cure/visit',
					name: 'CureVisit',
					component: CureVisit
				},
				{
					path: '/m/cure/element',
					name: 'Element',
					component: Element
				}
			]
		},
		{
			path: '/m/setting',
			name: 'MemberModel,',
			component: MemberModel,
			meta: {
				requireAuth: true
			},
			children: [{
					path: 'manager',
					name: 'Manager',
					component: Manager
				},
				{
					path: 'role',
					name: 'Role',
					component: Role
				},
				{
					path: 'menus',
					name: 'Menus',
					component: Menus
				},
				{
					path: 'log',
					name: 'Log',
					component: Log
				},
				{
					path: 'action',
					name: 'Action',
					component: Action
				},
				{
					path: 'app/config',
					name: 'AppConfig',
					component: AppConfig
				}
			]
		}
	]
})

//配置路由拦截
router.beforeEach((to, from, next) => {
	// 判断是否需要登录权限
	if (to.matched.some(item => {
			return item.meta.requireAuth
		})) {
		if (!store.getters.isLogin) {
			if (top == window)
				window.location.href = "/uc/login";
			else
				top.location.href = "/uc/login";
		} else {
			console.log("登录状态", store.getters.principal.verified)
			if (store.getters.principal.verified < 1 && to.path.indexOf("/m/doctor/certification") < 0) { //未实名制
				next('/m/doctor/certification')
			} else {
				next();
			}
		}
	} else {
		next();
	}
});


router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
});


export default router;
