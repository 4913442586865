<template>
	<div class="webFooter">
		<div id="footer" class=" wf center">
			<p class="font14 lineh30 pdding10">
				Copyright &copy; {{this.$http.webConfig.curYear}} {{this.$http.webConfig.copyright}} 网站备案号 {{this.$http.webConfig.recordNumber}}
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'webFooter',
		methods: {
			goTop() {
				window.screenTop(0, 0)
			}
		}
	}
</script>
