<template>
	<div class="fragementManager">
		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>系统设置</el-breadcrumb-item>
				<el-breadcrumb-item>管理员</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<el-tabs class="mgtop10" type="border-card">
			<el-tab-pane label="管理员">
				<div class="wf lineh40">
					<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
					<span class="fl bold mgl10 font14">筛选查询</span>
				</div>

				<div class="wf mgtop10">
					<el-form class="wf" ref="searchForm" :model="searchForm" :inline="true">
						<el-form-item label="管理员名称">
							<el-input v-model="searchForm.app" size="small" placeholder="请填写管理员名称" />
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="doSearch"><i class="fa fa-search"></i>查询
							</el-button>
							<el-button type="success" size="small" @click="newManager"><i class="fa fa-plus"></i>添加管理员
							</el-button>
						</el-form-item>
					</el-form>
				</div>

				<div class="wf lineh40">
					<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
					<span class="fl bold mgl10 font14">数据展示</span>
				</div>


				<el-table :data="managerList" :stripe="true" border class="mgtop10">
					<el-table-column prop="account" label="登录账号" width="200"></el-table-column>
					<el-table-column prop="realName" label="员工姓名"></el-table-column>
					<el-table-column prop="phone" label="手机号" width="120"></el-table-column>
					<el-table-column prop="department" label="部门" width="120"></el-table-column>
					<el-table-column prop="subTime" label="更新时间" width="160"></el-table-column>
					<el-table-column align="center" label="操作" width="200">
						<template slot-scope="scope">
							<el-row :gutter="10">
								<el-col :span="24">
									<el-button style="width:100%" type="success" size="small"
										@click="resetPwdManager(scope.$index)"><i class="fa fa-edit"></i>重置密码
									</el-button>
								</el-col>
								<el-col class="mgtop10" :span="12">
									<el-button style="width:100%" type="primary" size="small"
										@click="showManager(scope.$index)"><i class="fa fa-edit"></i>编辑</el-button>
								</el-col>
								<el-col v-if="principal.level==3" class="mgtop10" :span="12">
									<el-button style="width:100%" type="danger" size="small"
										@click="removeManager(scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
								</el-col>
							</el-row>
						</template>
					</el-table-column>
				</el-table>

				<!-- 分页 -->
				<div class="wf mgtop10">
					<div class="pdding20">
						<el-pagination v-show="managerList.length>0" @size-change="pageSizeChange"
							@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
							:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
							:total="searchForm.total" background class="fr"></el-pagination>
					</div>
				</div>

			</el-tab-pane>
			<el-tab-pane label="部门">
				<department></department>
			</el-tab-pane>
			<el-tab-pane label="角色">
				<role></role>
			</el-tab-pane>
		</el-tabs>

		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="管理员信息" :visible.sync="dialogVisible" width="800px">
			<div class="wf">
				<!-- -->
				<el-form class="wf" ref="managerForm" :model="managerForm" label-width="100px" :rules="rules">

					<el-row :gutter="10">
						<el-col :span="12">
							<el-form-item label="员工姓名" prop="realName">
								<el-input v-model="managerForm.realName" placeholder="请填写员工姓名"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="联系电话" prop="phone">
								<el-input v-model="managerForm.phone" placeholder="请填写联系电话"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="登录账号" prop="account">
								<el-input v-model="managerForm.account" placeholder="请填写登录账号"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="账号密码" prop="password">
								<el-input v-model="managerForm.password" placeholder="请填写账号密码"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="所属部门" prop="department">
								<el-select v-model="managerForm.department" style="width:100%">
									<el-option v-for="(item,index) in departments" :key="index" :value="item.name">
										{{item.name}}
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="角色" prop="role">
								<el-select v-model="managerForm.role" style="width:100%">
									<el-option v-for="(item,index) in roles" :key="index" :value="item.serial"
										:label="item.name"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="微信账号" prop="member">
								<div class="wf h40">
									<el-select clearable v-model="managerForm.member" filterable remote reserve-keyword
										placeholder="请填写微信账号手机号" :remote-method="getMembers" @change="memberChange"
										:loading="loading" style="width: 100%;">
										<el-option v-for="item in memberList" :key="item.serial"
											:label="item.nickname+'('+item.phone+')'" :value="item.serial">
										</el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-col>
					</el-row>

				</el-form>
				<!-- -->
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishManager">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import Department from '@/components/setting/manager/Department'
	import Role from '@/components/setting/manager/Role'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		components: {
			Department,
			Role
		},
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				managerForm: {
					authority: []
				},
				managerList: [],
				departments: [],
				memberList: [],
				roles: [],
				staffs: [],
				rules: {
					realName: [{
							required: true,
							message: '请填写员工姓名',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 100,
							message: '长度在 2 到 100个字符',
							trigger: 'blur'
						}
					],
					phone: [{
						required: true,
						message: '请填写联系电话',
						trigger: 'blur'
					}],
					account: [{
						required: true,
						message: '请填写账号 ',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请填写密码',
						trigger: 'blur'
					}],
					department: [{
						required: true,
						message: '请选择部门',
						trigger: 'blur'
					}],
					role: [{
						required: true,
						message: '请填写角色',
						trigger: 'blur'
					}],
					area: [{
						pattern: /^\d+$|^\d+[.]?\d+$/,
						message: '面积一般为数字'
					}]
				},
			}
		},
		methods: {
			pageChange(page) {
				this.searchForm.page = page;
				this.getManagers();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getManagers();
			},
			newManager() {
				this.managerForm = {
					authority: []
				};
				this.dialogVisible = true;
			},
			getManagers() {
				this.$http.request(this, this.$apis.user.managerList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					this.managerList = res.data;
				});
			},
			publishManager() {
				this.$refs['managerForm'].validate((valid) => {
					if (valid) {
						var managerForm = Object.assign({}, this.managerForm);
						managerForm.updateTime = "";

						this.$http.request(this, this.$apis.user.managerPublish, managerForm, true, (res) => {
							let data = res.data;
							if (data != null) {
								this.managerList.unshift(data);
							}
							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			showManager(index) {
				var managerForm = this.managerList[index];
				this.getMembers(managerForm.memberName)
				this.managerForm = managerForm;
				this.dialogVisible = true;
			},
			removeManager(index) {
				var manager = this.managerList[index];
				this.$confirm('此操作将永久删除[' + manager.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.user.managerRemove.replace("{serial}", manager.serial);
					this.$http.request(this, url, {}, true, data => {
						this.managerList.splice(index, 1);
					});
				});
			},
			resetPwdManager(index) {
				var manager = this.managerList[index];
				this.$confirm('此操作将重置[' + manager.account + ']密账号码, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.user.managerPwdReset.replace("{serial}", manager.serial);
					this.$http.request(this, url, {}, true, res => {
						this.$confirm('请记住新密码[' + res.data + ']', '提示', {
							confirmButtonText: '已经记住密码',
							showCancel: false,
							type: 'success'
						});
					});
				});
			},
			getDeparts() {
				this.$http.request(this, this.$apis.user.departmentList, this.searchForm, false, (res) => {
					this.departments = res.data;
				});
			},
			getRoles() {
				this.$http.request(this, this.$apis.user.roleList, {
					pageSize: 50
				}, false, (res) => {
					this.roles = res.data;
				});
			},
			getMembers(query) {
				this.loading = true;
				this.$http.request(this, this.$apis.user.memberList, {
					phone: query
				}, false, res => {
					this.loading = false;
					this.memberList = res.data;
				});
			}
		},
		created() {
			this.getDeparts();
			this.getRoles();
			this.getManagers();
		}
	}
</script>

<style>
</style>
