<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>客户管理</el-breadcrumb-item>
				<el-breadcrumb-item>服务站点</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<el-tabs type="border-card" class="mgtop10">
			<el-tab-pane label="站点分布">
				<org-tree></org-tree>
			</el-tab-pane>
			<el-tab-pane label="站点管理">
				<org-index></org-index>
			</el-tab-pane>
		</el-tabs>


	</div>
</template>

<script>
	import OrgIndex from '@/components/client/org/OrgIndex'
	import OrgTree from '@/components/client/org/OrgTree'
	export default {
		components: {
			OrgTree,
			OrgIndex
		},
	}
</script>

<style>
</style>
