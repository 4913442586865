<template>
	<div class="fragementBase">
		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">客户管理</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">用户分配</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->
		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form class="wf" ref="searchForm" :model="searchForm" :inline="true">
					<el-form-item label="患者姓名">
						<el-input v-model="searchForm.name" clearable size="small" placeholder="请填写患者姓名/手机号/身份证号" />
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="性别">
						<el-select v-model="searchForm.gender" clearable filterable size="small" placeholder="请选择性别">
							<el-option value="男"></el-option>
							<el-option value="女"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="会员分类">
						<el-select v-model="searchForm.type" clearable filterable size="small" placeholder="请选择会员分类">
							<el-option v-for="item in vipCardList" :value="item.serial" :label="item.name" :key="item">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="来源">
						<el-select v-model="searchForm.origin" clearable filterable remote reserve-keyword
							placeholder="请填写来源" :remote-method="showOrgs" :loading="loading" style="width: 100%;">
							<el-option v-for="item in originList" :value="item.name" :key="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="年龄段">
						<el-select v-model="searchForm.birth" clearable filterable size="small" placeholder="请选择来源">
							<el-option v-for="item in birthList" :key="item" :value="item.value" :label="item.name">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="病种分类">
						<el-select multiple clearable filterable v-model="searchForm.diseases" size="small"
							placeholder="请选择病种分类">
							<el-option v-for="item in diseasesList" :value="item.name" :key="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="文化程度">
						<el-select v-model="searchForm.education" clearable filterable size="small"
							placeholder="请选择文化程度">
							<el-option v-for="item in educationList" :value="item.name" :key="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="民族">
						<el-select v-model="searchForm.ethnic" clearable filterable size="small" placeholder="请选择民族">
							<el-option v-for="item in ethnicList" :value="item.name" :key="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="籍贯">
						<el-cascader v-model="searchForm.mregion" clearable placeholder="试试搜索：广州" :options="gsonOptions"
							filterable :props="{ checkStrictly: true }" style="width: 100%;"></el-cascader>
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="开始时间">
						<el-date-picker v-model="searchForm.startTime" clearable type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="结束日期">
						<el-date-picker v-model="searchForm.endTime" clearable type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="关键词">
						<el-input v-model="searchForm.key" clearable size="small" placeholder="请填写关键词" />
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="doSearch"><i class="fa fa-search"></i>查询
						</el-button>
						<el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i> 重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>


		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<el-button v-if="principal.level==3" type="info" size="small" @click="conSignRecord"><i
						class="fa fa-download"></i> 批量分配</el-button>
			</div>

			<el-table :data="patientList" :stripe="true" border class="mgtop10"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="name" label="患者信息" width="350">
					<template slot-scope="scope">
						<el-row>
							<el-col span="8">
								<img class="border radius5" :src="scope.row.avater" width="100px" height="100px" />
							</el-col>
							<el-col span="16">
								<div class="wf">姓名：{{scope.row.name}}
									<el-tag type="primary" size="small">{{scope.row.gender}}</el-tag>
								</div>
								<div class="wf">年龄：{{scope.row.age}}</div>
								<div class="wf">手机号：{{scope.row.phone}}</div>
								<div class="wf">身份证号：{{scope.row.idcard}}</div>
								<div class="wf">地址：{{scope.row.address}}</div>
								<div class="wf" v-if="scope.row.typeName">会员类型：<el-tag type="success" size="small">
										{{scope.row.typeName}}
									</el-tag>
								</div>
								<div class="wf">标签：<span v-for="label in scope.row.label"
										:key="label">{{label.name}},</span></div>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
				<el-table-column prop="tag" label="用户类型" align="center">
					<template slot-scope="scope">
						<el-tag type="primary" size="small" v-if="scope.row.tag==0">正式档案</el-tag>
						<el-tag type="primary" size="small" v-else>自述档案</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="originName" label="用户来源"></el-table-column>
				<el-table-column prop="hostname" label="眼管家" align="center"></el-table-column>
				<el-table-column prop="record" label="个人眼科档案记录数量" align="center"></el-table-column>
				<el-table-column prop="optionerName" label="创建者" align="center"></el-table-column>
				<el-table-column prop="subTime" label="添加日期" width="160" align="center"></el-table-column>
				<el-table-column align="center" label="操作" width="200">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="12">
								<el-button style="width: 100%;" type="primary" size="small"
									@click="showPatient(scope.$index)"><i class="fa fa-edit"></i>查看</el-button>
							</el-col>
							<el-col :span="12">
								<el-button style="width: 100%;" type="success" size="small"
									@click="showChart(scope.$index)"><i class="fa fa-bar-chart"></i>折线图</el-button>
							</el-col>
							<el-col :span="12" class="mgtop10">
								<el-button style="width: 100%;" type="warning" size="small"
									@click="showRecord(scope.$index)"><i class="fa fa-th-list"></i>变更记录</el-button>
							</el-col>
							<el-col :span="12" class="mgtop10">
								<el-button style="width: 100%;" type="primary" size="small"
									@click="labelPatient(scope.$index)"><i class="fa fa-edit"></i>设置标签</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="patientList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>


		<!-- 弹出层 -->
		<el-dialog id="patientForm" :close-on-click-modal="false" :visible.sync="dialogVisible" width="1000px">
			<div class="pdding20auto mgbtm20" lot="title">
				<div class="fl lineh20 h20 bold font18">患者信息</div>
				<el-switch v-if="principal.serial==patientForm.optioner || principal.level==3" class="fl mgl20"
					v-model="canEdit" active-color="#13ce66" inactive-color="#ff4949" active-text="查看模式"
					inactive-text="编辑模式" :active-value="1" :inactive-value="0">
				</el-switch>
			</div>
			<el-tabs type="border-card" v-model="activeName">
				<el-tab-pane label="基本信息" name="基本信息">
					<base-info :canEdit="canEdit" :patientForm="patientForm" @infoPlus="infoPlus"
						@infoChange="infoChange" @showChange="showChange"></base-info>
				</el-tab-pane>
				<el-tab-pane label="既往病史及家族遗传史" name="既往病史及家族遗传史">
					<sickness :canEdit="canEdit" :patientForm="patientForm" @infoChange="infoChange"
						@showChange="showChange"></sickness>
				</el-tab-pane>
				<el-tab-pane label="眼病及手术史" name="眼病及手术史">
					<eye-surgery :canEdit="canEdit" :patientForm="patientForm" @infoChange="infoChange"
						@showChange="showChange">
					</eye-surgery>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>

		<el-dialog :close-on-click-modal="false" title="变更记录" :visible.sync="recordVisible" width="1200px">
			<history :patientForm="patientForm" @showChange="showChange"></history>
		</el-dialog>

		<el-dialog :close-on-click-modal="false" title="折线图" :visible.sync="chartVisible" width="1200px">
			<chart :patientForm="patientForm"></chart>
		</el-dialog>

		<el-dialog title="设置标签" :close-on-click-modal="false" :visible.sync="labelVisible" width="800px">
			<div class="wf">
				<el-button type="success" @click="patientForm.label.push({name:''})"><i class="fa fa-plus"></i>新增标签
				</el-button>
				<el-table class="mgtop10" border :data="patientForm.label" style="width: 100%">
					<el-table-column prop="name" label="标签">
						<template slot-scope="scope">
							<el-input placeholder="请填写标签" v-model="scope.row.name"></el-input>
						</template>
					</el-table-column>
					<el-table-column prop="act" label="操作" align="center" width="100">
						<template slot-scope="scope">
							<el-button style="width: 100%;" type="danger" size="small"
								@click="patientForm.label.splice(scope.$index,1)"><i class="fa fa-remove"></i>删除
							</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="labelVisible = false">取 消</el-button>
				<el-button type="primary" @click="doLabel">保存标签</el-button>
			</span>
		</el-dialog>

		<el-dialog :close-on-click-modal="false" title="分配眼管家" :visible.sync="consignVisible" width="600px">
			<el-form class="wf" ref="managerForm" :model="managerForm" label-width="100px" :rules="rules">
				<el-form-item label="眼管家">
					<el-row gutter="10">
						<el-col span="18">
							<el-select v-model="patientForm.team" filterable placeholder="请选择眼管家" style="width: 100%;">
								<el-option v-for="(item,index) in hostingList" :key="index" :value="item.serial"
									:label="item.name"></el-option>
							</el-select>
						</el-col>
						<el-col span="4">
							<el-button type="primary" size="small" @click="showhostings"><i
									class="fa fa-refresh mgr5"></i>刷新</el-button>
						</el-col>
					</el-row>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="consignVisible = false">取 消</el-button>
				<el-button type="primary" @click="keepConsign">立即分配</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->


	</div>
</template>

<script>
	import BaseInfo from '@/components/treatment/archive/info/BaseInfo.vue'
	import Sickness from '@/components/treatment/archive/info/Sickness.vue'
	import EyeSurgery from '@/components/treatment/archive/info/EyeSurgery.vue'
	import History from '@/components/treatment/archive/info/History.vue'
	import Chart from '@/components/treatment/archive/info/Chart.vue'
	var gsonData = require('../../../gson/china.json');
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		components: {
			BaseInfo,
			Sickness,
			EyeSurgery,
			History,
			Chart
		},
		data() {
			return {
				consignVisible: false,
				isPlus: false,
				canEdit: 0,
				activeName: "基本信息",
				importUrl: "",
				importVisible: false,
				fileList: [],
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				fileUploadUrl: this.$apis.component.baseFileUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				dialogVisible: false,
				recordVisible: false,
				chartVisible: false,
				labelVisible: false,
				searchForm: {
					scope: "0",
					diseases: [],
					page: 1,
					pageSize: 20,
					total: 0
				},
				gsonOptions: gsonData,
				patientForm: {},
				patientList: [],
				educationList: [],
				ethnicList: [],
				originList: [],
				diseasesList: [],
				vipCardList: [],
				hostingList: [],
				birthList: [],
				serials: [""],
				multipleSelection: [],
				rules: {
					name: [{
							required: true,
							message: '请填写 患者姓名',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					gender: [{
						required: true,
						message: '请选择 患者性别',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请填写 患者电话',
						trigger: 'blur'
					}],
					unit: [{
						required: true,
						message: '请填写 患者所在单位',
						trigger: 'blur'
					}],
					jobTitle: [{
						required: true,
						message: '请填写 患者职称 ',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '请填写 患者所在领域 ',
						trigger: 'blur'
					}],
					avater: [{
						required: true,
						message: '请填写 患者图像',
						trigger: 'blur'
					}],
					jobTitle: [{
						required: true,
						message: '请填写 患者职称',
						trigger: 'blur'
					}],
					summary: [{
						required: true,
						message: '请填写 患者擅长领域',
						trigger: 'blur'
					}],
					experience: [{
						required: true,
						message: '请填写 患者专业经历',
						trigger: 'blur'
					}],
					proportion: [{
						required: true,
						message: '请填写分成比例',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '比例为数字，例如：12.00'
					}],
					mprice: [{
						required: true,
						message: '请填写问诊单价',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '问诊单价为数字，例如：12.00'
					}],
				},
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handleFileSuccess(res, file) {
				console.log("文件上传", res)
				if (res.files.length > 0)
					this.importUrl = res.files[0].uploadUrl;
			},
			dateChange(e) {
				this.searchForm.startTime = e[0];
				this.searchForm.endTime = e[1];
			},
			resetSearch() {
				this.searchForm = {
					scope: "0",
					diseases: [],
					page: 1,
					pageSize: 20,
					total: 0
				}
				this.pageChange(1);
			},
			doSearch() {
				this.pageChange(1);
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getPatients();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getPatients();
			},
			newPatient() {
				this.isPlus = true;
				this.activeName = "基本信息";
				this.patientForm = {
					region: ["", "", ""],
					phone: "",
					avater: "",
					age: "",
					birth: "",
					originName: "铸视眼慢病管理中心",
					origin: "86c979248f104114af0a0fcad2462c81",
					property: {},
					medical: {},
					surgery: {}
				};
				this.canEdit = 1;
				this.dialogVisible = true;
			},
			getPatients() {
				let searchForm = Object.assign({}, this.searchForm);
				searchForm.diseases = searchForm.diseases.join(',');
				searchForm.region = JSON.stringify(this.searchForm.mregion);
				if (searchForm.birth != null && searchForm.birth != undefined) {
					var births = searchForm.birth.split("_");
					if (births.length == 2) {
						searchForm.startBirth = births[0];
						searchForm.endBirth = births[1];
					}
				}
				this.$http.request(this, this.$apis.tools.patientList, searchForm, false, res => {
					this.searchForm.total = res.count;
					res.data.forEach(item => {
						item.label = JSON.parse(item.label);
						item.region = item.region != "" && item.region != null && item.region !=
							undefined ? JSON.parse(
								item.region) : ["", ","];
						item.property = JSON.parse(item.property);
						item.medical = JSON.parse(item.medical);
						item.surgery = JSON.parse(item.surgery);
						item.age = item.age == 0 ? "" : item.age;
					})
					this.patientList = res.data;
					console.log(res.data);
				});
			},
			doExport() {
				var searchForm = Object.assign({}, this.searchForm);
				if (this.serials.length > 1)
					searchForm.serials = this.serials.join(",");
				this.$http.download(this, this.$apis.tools.patientExport, searchForm, false, res => {
					var blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					})
					var url = window.URL.createObjectURL(blob);
					var aLink = document.createElement("a");
					aLink.style.display = "none";
					aLink.href = url;
					aLink.setAttribute("download", "管理端患者档案导出数据.xls");
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); //下载完成移除元素
					window.URL.revokeObjectURL(url); //释放掉blob对象
				});
			},
			doImport() {
				if (this.importUrl == "")
					return;
				this.$http.request(this, this.$apis.tools.patientImport, {
					url: this.importUrl
				}, true, res => {
					if (res.code == 0) {
						this.importVisible = false
						this.importUrl = "";
					}
				});
			},
			doLabel() {
				var patient = this.patientForm;
				this.$http.request(this, this.$apis.tools.patientLabel.replace("{serial}", patient.serial), {
					label: JSON.stringify(patient.label)
				}, true, res => {
					if (res.code == 0) {
						this.labelVisible = false
					}
				});
			},
			batchImport() {
				this.importUrl = "";
				this.importVisible = true;
			},
			showPatient(index) {
				this.isPlus = false;
				this.activeName = "基本信息";
				this.canEdit = 0;
				var patientForm = this.patientList[index];
				this.patientForm = patientForm;
				this.dialogVisible = true;
				console.log("显示患者数据", patientForm)
			},
			labelPatient(index) {
				this.isPlus = false;
				this.activeName = "基本信息";
				this.canEdit = 0;
				var patientForm = this.patientList[index];
				this.patientForm = patientForm;
				this.labelVisible = true;
				console.log("显示患者数据", patientForm)
			},
			removePatient(index) {
				var patient = this.patientList[index];
				this.$confirm('此操作将永久删除[' + patient.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.tools.patientRemove.replace("{serial}", patient.serial);
					this.$http.request(this, url, {}, true, res => {
						this.patientList.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.serials = [""];
				this.multipleSelection.forEach(item => {
					this.serials.push(item.serial);
				});
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的 患者 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.tools.patientBatchRemove, {
						serials: serials
					}, true, res => {
						this.patientList = this.patientList.filter(patient => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == patient.serial) {
									flag = false;
								}
							})
							return flag;
						});
					});
				});
			},
			infoChange(e) {
				console.log("患者信息变更", e)
				this.patientForm = e;
			},
			infoPlus(e) {
				console.log("患者信息变更", e)
				this.patientList.push(e);
			},
			showChange() {
				this.dialogVisible = !this.dialogVisible;
				if (this.isPlus)
					this.getPatients();
			},
			showRecord(index) {
				this.patientForm = this.patientList[index];
				this.recordVisible = true;
			},
			showChart(index) {
				this.patientForm = this.patientList[index];
				this.chartVisible = true;
			},
			showOrgs(query) {
				this.$http.request(this, this.$apis.user.orgList, {
					type: 0,
					name: query
				}, false, res => {
					console.log("获取单位数据成功！");
					if (res.code == 0) {
						this.originList = res.data;
					}
				});
			},
			showDictionary() {
				this.$http.request(this, this.$apis.tools.dictionaryList, {}, false, res => {
					console.log("获取数据字典");
					if (res.code == 0) {
						this.ethnicList = res.data["mingzu"];
						this.educationList = res.data["xueli"];
						this.diseasesList = res.data["bingzhong"]
					}
				});
			},
			getVipCards() {
				this.$http.request(this, this.$apis.user.vipCardList, {
					pageSize: 100
				}, false, res => {
					this.vipCardList = res.data;
				});
			},
			conSignRecord(index) {
				this.consignVisible = true;
			},
			showhostings() {
				this.$http.request(this, this.$apis.user.orgList, {
					pageSize: 100,
					nature: "3"
				}, false, res => {
					if (res.code == 0)
						this.hostingList = res.data;
				});
			},
			keepConsign() {
				var patient = this.patientForm;
				if (this.serials.length <= 1) {
					this.$http.showMessage(this, {
						code: 1,
						message: "请选择要分配的用户！"
					});
					return;
				}

				this.$confirm('确定要分配眼管家进行管理, 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					var searchForm = Object.assign({}, this.searchForm);
					if (this.serials.length > 1)
						searchForm.serials = this.serials.join(",");
					searchForm.hosting = patient.team;
					this.$http.request(this, this.$apis.tools.patientInvite, searchForm, true, res => {
						this.consignVisible = false;
					});
				});
			},
		},
		created() {
			for (var i = 0; i < 9; i++) {
				this.birthList.push({
					name: (i * 10) + "~" + ((i + 1) * 10),
					value: moment().subtract("year", ((i == 8 ? 20 : i) + 1) * 10).format("yyyy") + "-01-01_" +
						moment().subtract(
							"year", i * 10).format("yyyy") + "-12-31"
				})
			}
			this.showhostings();
			this.getVipCards();
			this.showOrgs();
			this.showDictionary();
			this.getPatients();
		}
	}
</script>

<style>
	#patientForm .el-dialog__body {
		padding: 0 !important;
	}
</style>
