<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>系统设置</el-breadcrumb-item>
				<el-breadcrumb-item>系统菜单</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10 lineh40">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="名称">
						<el-input v-model="searchForm.name" clearable placeholder="请填写菜单名称" />
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff overflow radius5 mgtop10">
			<div class="wf lineh40">
				<el-select v-model="searchForm.platform" @change="platformChange">
					<el-option v-for="(item,index) in platforms" :key="index" :value="item.serial" :label="item.name">
					</el-option>
				</el-select>
				<el-button class="mgl10" type="success" size="small" @click="newMenu"><i class="fa fa-plus"></i>新增菜单
				</el-button>
			</div>

			<el-tree class="mgtop40" :data="menuList" show-checkbox node-key="id" default-expand-all
				:expand-on-click-node="false">
				<div class="custom-tree-node" slot-scope="{ node, data }">
					<span class="w400 lineh40" style="display: inline-block;">{{ data.name }}</span>
					<span class="fr">
						<el-button type="primary" size="small" @click="showMenu(data)"><i class="fa fa-edit"></i>编辑
						</el-button>

						<el-button v-if="principal.level==3" type="danger" size="small" @click="removeMenu(node,data)">
							<i class="fa fa-remove"></i>删除</el-button>

						<el-button type="success" size="small" @click="plusMenu(data)"><i class="fa fa-plus"></i>新增子菜单
						</el-button>
					</span>
				</div>
			</el-tree>
		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="菜单信息" :visible.sync="dialogVisible" width="800px">
			<el-form class="wf" ref="menuForm" :model="menuForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="12">
						<el-form-item label="菜单名称" prop="name">
							<el-input v-model="menuForm.name" placeholder="请填写菜单名称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="菜单图标" prop="icon">
							<el-input v-model="menuForm.icon" placeholder="请填写菜单图标"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="排序" prop="sort">
							<el-input v-model="menuForm.sort" placeholder="请填写排序"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="跳转类型" prop="urlType">
							<el-select v-model="menuForm.urlType" placeholder="请填写跳转类型" style="width:100%;">
								<el-option value="web" label="网页"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="跳转链接" prop="url">
							<el-input v-model="menuForm.url" placeholder="请填写跳转链接"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishMenu">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					level: 1,
					platform: "",
					page: 1,
					pageSize: 20,
					total: 0
				},
				parentMenu: {},
				menuForm: {},
				menuList: [],
				menuLevel: 0,
				platforms: [],
				multipleSelection: [],
				rules: {
					name: [{
							required: true,
							message: '请填写菜单名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					]
				},
			}
		},
		methods: {
			load(tree, treeNode, resolve) {
				console.log(tree, treeNode, resolve)
				this.$http.request(this, this.$apis.workflow.menuList, {
					parent: tree.parent,
					level: 2
				}, false, (res) => {
					resolve(res.data);
				});
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getMembers()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getMenus();
			},
			newMenu() {
				this.menuForm = {
					sort: 0,
					params: []
				};
				this.menuLevel = 0;
				this.dialogVisible = true;
			},
			plusMenu(data) {
				console.log("父菜单", data)
				this.parentMenu = data;
				this.menuLevel = 1;
				this.menuForm = {
					sort: 0,
					params: []
				};
				this.dialogVisible = true;
			},
			packageParams(item) {
				item.params = JSON.parse(item.params);
				item.children.forEach(sitem => {
					this.packageParams(sitem);
				});
			},
			getMenus() {
				this.$http.request(this, this.$apis.user.menuList, this.searchForm, false, (res) => {
					res.data.forEach(item => {
						this.packageParams(item);
					});
					this.menuList = res.data;
				});
			},
			publishMenu() {
				this.$refs['menuForm'].validate((valid) => {
					if (valid) {
						var menuForm = Object.assign({}, this.menuForm);
						menuForm.updateTime = "";
						menuForm.children = [];
						menuForm.platform = this.searchForm.platform;
						menuForm.parent = this.menuLevel == 0 ? "" : this.parentMenu.serial;
						menuForm.params = JSON.stringify(menuForm.params);
						this.$http.request(this, this.$apis.user.menuPublish, menuForm, true, res => {
							let data = res.data;
							if (data != null) {
								if (this.menuLevel == 0) {
									data.children = [];
									data.hasChildren = true;
									this.menuList.unshift(data);
								} else {
									this.parentMenu.children.push(data);
								}
							}

							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			showMenu(data) {
				this.menuForm = data;
				console.log("菜单", data)
				this.dialogVisible = true;
			},
			removeMenu(node, data) {
				const parent = node.parent;
				const children = parent.data.children || parent.data;
				const index = children.findIndex(d => d.serial === data.serial);
				var menu = data;
				this.$confirm('此操作将永久删除菜单[' + menu.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					menu: 'warning'
				}).then(() => {
					let url = this.$apis.user.menuRemove.replace("{serial}", menu.serial);
					this.$http.request(this, url, {}, true, data => {
						children.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的菜单 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					menu: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.workflow.menuBatchRemove, {
						serials: serials
					}, true, res => {

						this.menuList = this.menuList.filter(menu => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == menu.serial) {
									flag = false;
								}
							})
							return flag;
						});
					});
				});
			},
			getPlatforms() {
				this.$http.request(this, this.$apis.user.platformList, {}, false, (res) => {
					this.platforms = res.data;
					if (this.platforms.length > 0)
						this.searchForm.platform = this.platforms[0].serial;

					this.getMenus();
				});
			},
			platformChange() {
				this.getMenus();
			}
		},
		created() {
			this.getPlatforms();
		}
	}
</script>

<style>
	.el-tree-node {
		margin-bottom: 10px;
	}

	.el-tree-node__children {
		margin-top: 10px;
	}

	.el-tree-node__content {
		height: auto !important;
	}
</style>
