<template>
	<div class="wf">
		<el-form class="wf" ref="patientForm" :model="patientForm" label-width="150px" :rules="rules">
			<el-row :gutter="10">
				<el-col :span="24">
					<div class="wf bold font16 lineh40">
						<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
						<span class="fl mgl10">患者信息</span>
					</div>
				</el-col>
				<el-col :span="12">
					<el-form-item label="患者图像" prop="avater">
						<el-upload :disabled="canEdit==0" class="avatar-uploader" name="mdFile" :action="imgUploadUrl"
							:headers="uploadHeaders" :show-file-list="false" :on-success="handleCoverSuccess"
							:before-upload="beforePicUpload">
							<img v-if="patientForm.avater" :src="patientForm.avater" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
						<div class="wf lineh20"> <i class="el-icon-star-off"></i>建议使用800*800正方形图片，支持JPG、PNG格式，最大20MB
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="姓名" prop="name">
						<el-autocomplete v-if="canEdit==1" class="inline-input" v-model="patientForm.name"
							style="width: 100%;" :fetch-suggestions="querySearch" placeholder="请填写患者姓名"
							:trigger-on-focus="false">
							<template slot-scope="{ item }">
								<div class="name bold">{{ item.name }}</div>
								<div class="phone">{{ item.phone }}</div>
								<span class="addr">{{ item.address }}</span>
								<div style="width: 100%;border: 1px dashed #dedede;"></div>
							</template>
						</el-autocomplete>
						<!-- <el-input v-if="canEdit==1" clearable v-model="patientForm.name" placeholder="请填写患者姓名">
						</el-input> -->
						<span v-else>{{patientForm.name}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="微信账号" prop="member">
						<div class="wf h40">
							<el-select v-if="canEdit==1" clearable v-model="patientForm.member" filterable remote
								reserve-keyword placeholder="请填写微信账号手机号" :remote-method="getMembers"
								@change="memberChange" :loading="loading" style="width: 100%;">
								<el-option v-for="item in memberList" :key="item.serial"
									:label="item.nickname+'('+item.phone+')'" :value="item.serial">
								</el-option>
							</el-select>
							<span v-else>{{patientForm.nickname}}</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="性别" prop="gender" v-if="canEdit==1||patientForm.gender">
						<div class="wf h40">
							<el-select v-if="canEdit==1" clearable filterable v-model="patientForm.gender"
								placeholder="请选择患者性别" style="width:100%;">
								<el-option value="男"></el-option>
								<el-option value="女"></el-option>
							</el-select>
							<span v-else>{{patientForm.gender}}</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="出生日期" prop="birth" v-if="canEdit==1||patientForm.birth">
						<div class="wf h40">
							<el-date-picker v-if="canEdit==1" @change="birthChange" clearable value-format="yyyy-MM-dd"
								v-model="patientForm.birth" type="date" placeholder="出生日期" style="width:100%;">
							</el-date-picker>
							<span v-else>{{patientForm.birth}}</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="年龄" prop="age" v-if="canEdit==1||patientForm.age">
						<div class="wf h40">
							<el-input v-if="canEdit==1" @change="ageChange" clearable v-model.number="patientForm.age"
								placeholder="请填写患者年龄"></el-input>
							<span v-else>{{patientForm.age}}</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="手机号" prop="phone" v-if="canEdit==1||patientForm.phone">
						<div class="wf h40">
							<el-input v-if="canEdit==1" clearable v-model="patientForm.phone" maxLength="12"
								placeholder="请填写患者手机号"></el-input>
							<span v-else>{{patientForm.phone}}</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="身份证号码" prop="idcard" v-if="canEdit==1||patientForm.idcard">
						<div class="wf h40">
							<el-input v-if="canEdit==1" clearable v-model="patientForm.idcard" placeholder="请填写身份证号码">
							</el-input>
							<span v-else>{{patientForm.idcard}}</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="民族" prop="ethnic" v-if="canEdit==1||patientForm.ethnic">
						<div class="wf h40">
							<el-select v-if="canEdit==1" clearable filterable v-model="patientForm.ethnic"
								placeholder="请选择患者民族" style="width:100%;">
								<el-option v-for="item in ethnicList" :value="item.name" :key="item"></el-option>
							</el-select>
							<span v-else>{{patientForm.ethnic}}</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="建档日期" prop="createDay" v-if="canEdit==1||patientForm.createDay">
						<div class="wf h40">
							<el-date-picker v-if="canEdit==1" clearable value-format="yyyy-MM-dd"
								v-model="patientForm.createDay" type="date" placeholder="选择日期" style="width:100%;">
							</el-date-picker>
							<span v-else>{{patientForm.createDay}}</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="来源" prop="origin" v-if="canEdit==1||patientForm.origin">
						<div class="wf h40">
							<el-select v-if="canEdit==1" v-model="patientForm.origin" clearable filterable remote
								reserve-keyword @change="unitChange" placeholder="请填写来源" :remote-method="showUnit"
								:loading="loading" style="width: 100%;">
								<el-option v-for="item in unitList" :label="item.name" :value="item.serial" :key="item">
								</el-option>
							</el-select>
							<span v-else>{{patientForm.originName}}</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="会员类型" prop="typeName" v-if="canEdit==1||patientForm.type">
						<div class="wf h40">
							<el-select v-if="canEdit==1" clearable filterable v-model="patientForm.type"
								placeholder="请选择会员分类" style="width:100%;" @change="typeChange">
								<el-option v-for="item in vipCardList" :value="item.serial" :label="item.name"
									:key="item">
								</el-option>
							</el-select>
							<span v-else>{{patientForm.typeName}}</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="会员到期" prop="maturity" v-if="canEdit==1||patientForm.maturity">
						<div class="wf h40">
							<el-date-picker v-if="canEdit==1" clearable value-format="yyyy-MM-dd"
								v-model="patientForm.maturity" type="date" placeholder="选择日期" style="width:100%;">
							</el-date-picker>
							<span v-else>{{patientForm.maturity}}</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="国籍" prop="nation" v-if="canEdit==1||patientForm.nation">
						<div class="wf h40">
							<el-select v-if="canEdit==1" clearable filterable v-model="patientForm.nation"
								placeholder="请选择患者国籍" style="width:100%;">
								<el-option v-for="item in nationList" :value="item.name" :key="item"></el-option>
							</el-select>
							<span v-else>{{patientForm.nation}}</span>
						</div>
					</el-form-item>
				</el-col>

				<el-col :span="12">
					<el-form-item label="籍贯" prop="region" v-if="canEdit==1||patientForm.region">
						<div class="wf h40">
							<el-cascader v-if="canEdit==1" v-model="patientForm.region" clearable placeholder="试试搜索：云南"
								:options="gsonOptions" filterable :props="{ checkStrictly: true }" style="width:100%">
							</el-cascader>
							<span v-else>{{patientForm.region.join('')}}</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="详细地址" prop="address" v-if="patientForm.address">
						<el-input v-if="canEdit==1" clearable v-model="patientForm.address" placeholder="请填写详细地址">
						</el-input>
						<span v-else>{{patientForm.address}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<div class="wf bold font16 lineh40">
						<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
						<span class="fl mgl10">其他信息</span>
					</div>
				</el-col>
				<el-col :span="item.cols" v-for="(item,index) in elementList" :key="index">
					<el-form-item :label="item.name" :prop="item.label"
						v-if="patientForm.property[item.serial]||canEdit==1">
						<div v-if="item.type=='text'" class="wf h40">
							<el-input @input="updateEdit" v-if="canEdit==1" clearable
								v-model="patientForm.property[item.serial]" :placeholder="'请填写'+item.name">
								<template v-if="item.tag" slot="append">({{item.tag}})</template>
							</el-input>
							<span v-if="canEdit==0">{{patientForm.property[item.serial]}}</span>
						</div>
						<div v-if="item.type=='select'" class="wf h40">
							<el-select v-if="canEdit==1" clearable filterable
								v-model="patientForm.property[item.serial]" :placeholder="'请选择'+item.name"
								style="width: 100%;">
								<el-option v-for="opt in item.options" :key="opt" :value="opt"></el-option>
							</el-select>
							<span v-if="canEdit==0">{{patientForm.property[item.serial]}}</span>
						</div>
						<div v-if="item.type=='selectMore'" class="wf h40">
							<el-select v-if="canEdit==1" clearable filterable multiple
								v-model="patientForm.property[item.serial]" :placeholder="'请选择'+item.name"
								style="width: 100%;">
								<el-option v-for="opt in item.options" :key="opt" :value="opt"></el-option>
							</el-select>
							<span
								v-if="canEdit==0&&patientForm.property[item.serial]">{{patientForm.property[item.serial].join('')}}</span>
						</div>
						<div v-if="item.type=='textarea'" class="wf">
							<el-input @input="updateEdit" v-if="canEdit==1" type="textarea" :rows="6"
								v-model="patientForm.property[item.serial]" :placeholder="'请填写'+item.name">
							</el-input>
							<span v-if="canEdit==0">{{patientForm.property[item.serial]}}</span>
						</div>
					</el-form-item>
				</el-col>

			</el-row>
		</el-form>

		<div class="wf center">
			<el-button @click="hideDialog">取 消</el-button>
			<el-button v-if="canEdit==1" type="primary" @click="publishPatient">保存</el-button>
		</div>
	</div>
</template>

<script>
	var gsonData = require('../../../../gson/china.json');
	export default {
		data() {
			var validatePhone = (rule, value, callback) => {
				console.log("手机号", (value + "").length)
				if (value === '') {
					callback(new Error('请输入手机号'))
				} else if ((value + "").length == 8 || (value + "").length == 11 || (value + "").length == 12) {
					callback()
				} else {
					callback(new Error('手机号长度为8位，11位，12位!'))
				}
			}
			return {
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				searchForm: {
					phone: ""
				},
				gsonOptions: gsonData,
				elementList: [],
				nationList: [],
				ethnicList: [],
				unitList: [],
				vipCardList: [],
				memberList: [],
				rules: {
					name: [{
							required: true,
							message: '请填写患者姓名',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					gender: [{
						required: true,
						message: '请选择患者性别',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						validator: validatePhone,
						trigger: 'blur'
					}],
					origin: [{
						required: true,
						message: '请选择患者来源',
						trigger: 'blur'
					}],
					createDay: [{
						required: true,
						message: '请选择建档日期',
						trigger: 'blur'
					}],
					ethnic: [{
						required: true,
						message: '请选择民族',
						trigger: 'blur'
					}],
				}
			}
		},
		props: ["patientForm", "canEdit"],
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLtM = file.size / 1024 / 1024 < 20;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLtM) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLtM;
			},
			handleCoverSuccess(res, file) {
				console.log("文件上传", res)
				if (res.files.length > 0)
					this.patientForm.avater = res.files[0].uploadUrl;
			},
			packageParams(item) {
				item.options = JSON.parse(item.options);
				item.children.forEach(sitem => {
					packageParams(sitem);
				})
			},
			getElements() {
				this.$http.request(this, this.$apis.tools.elementList, {
					label: "jbqk",
				}, false, (res) => {
					console.log("什么", res)
					if (res.code == 0) {
						this.elementList = res.data[0].children;
						this.elementList.forEach(item => {
							this.packageParams(item);
						});
					}
				});
			},
			publishPatient() {
				console.log("用户信息", this.patientForm)
				this.$refs['patientForm'].validate((valid) => {
					if (valid) {
						var patientForm = Object.assign({}, this.patientForm);
						patientForm.age = patientForm.age == "" ? 0 : patientForm.age;
						patientForm.province = patientForm.region[0];
						patientForm.city = patientForm.region[1];
						patientForm.county = patientForm.region[2];
						patientForm.region = JSON.stringify(patientForm.region);
						patientForm.updateTime = "";
						patientForm.property = JSON.stringify(patientForm.property);
						patientForm.medical = JSON.stringify(patientForm.medical);
						patientForm.surgery = JSON.stringify(patientForm.surgery);

						console.log("患者数据", patientForm)

						this.$http.request(this, this.$apis.tools.patientPublish, patientForm, true, res => {
							let data = res.data;
							if (res.code == 0) {
								if (data != null) {
									this.patientForm.serial = data.serial;
									this.patientForm.tag = 0;
									this.$emit("infoPlus", this.patientForm)
								}

								this.$emit("infoChange", this.patientForm)
								this.dialogVisible = false;
							}
						});
					}
				});
			},
			hideDialog() {
				this.$emit("showChange")
			},
			birthChange() {
				this.patientForm.age = this.$http.getMyYearAge(this.patientForm.birth);
			},
			ageChange() {
				let year = new Date().getFullYear();
				this.patientForm.birth = (year - this.patientForm.age) + "-" + moment().format("MM-DD")
			},
			showUnit(query) {
				this.$http.request(this, this.$apis.user.orgList, {
					type: 0,
					name: query
				}, false, res => {
					console.log("获取单位数据成功！");
					if (res.code == 0) {
						this.unitList = res.data;
					}
				});
			},
			unitChange() {
				this.unitList.forEach(item => {
					if (item.serial == this.patientForm.origin) {
						this.patientForm.originName = item.name;
					}
				})
			},
			showDictionary() {
				this.$http.request(this, this.$apis.tools.dictionaryList, {}, false, res => {
					console.log("获取数据字典");
					if (res.code == 0) {
						this.ethnicList = res.data["mingzu"];
						this.nationList = res.data["guoji"];
					}
				});
			},
			getMembers(query) {
				this.loading = true;
				this.$http.request(this, this.$apis.user.memberList, {
					phone: query
				}, false, res => {
					this.loading = false;
					this.memberList = res.data;
				});
			},
			memberChange() {
				this.memberList.forEach(item => {
					if (item.serial == this.patientForm.member) {
						this.patientForm.nickname = item.nickname;
					}
				})
			},
			getVipCards() {
				this.$http.request(this, this.$apis.user.vipCardList, {
					pageSize: 100
				}, false, res => {
					this.vipCardList = res.data;
				});
			},
			typeChange(e) {
				this.vipCardList.forEach(item => {
					if (item.serial == e)
						this.patientForm.typeName = item.name;
				})
			},
			updateEdit() {
				console.log("强制更新")
				this.$forceUpdate();
			},
			querySearch(queryString, cb) {
				if (queryString == null || queryString == "")
					return;
				this.$http.request(this, this.$apis.tools.patientList, {
					name: queryString
				}, false, res => {
					var results = [];
					res.data.forEach(item => {
						results.push({
							name: item.name,
							phone: item.phone,
							address: item.address
						});
					});
					cb(results);
				});

			},
		},
		created() {
			this.getVipCards();
			this.getMembers(this.patientForm.phone);
			this.showUnit(this.patientForm.originName);
			this.showDictionary();
			this.getElements();
		},
		watch: {
			canEdit(newValue, oldValue) {
				if (this.canEdit == 0)
					this.$refs['patientForm'].clearValidate();
				this.getVipCards();
				this.getMembers(this.patientForm.phone);
				this.showUnit(this.patientForm.originName);
				this.showDictionary();
				this.getElements();
			}
		},
	}
</script>

<style>
</style>
