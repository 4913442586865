import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import VueCookies from 'vue-cookie'

const production = true; //是否是生产环境
const baseURL = production ? 'http://api.sjymbgl.com' : 'http://127.0.0.1:9000/gateway';
let shake = false;

//时间格式化
Date.prototype.Format = function(fmt) { //author: meizz
	var o = {
		"M+": this.getMonth() + 1, //月份
		"d+": this.getDate(), //日
		"h+": this.getHours(), //小时
		"m+": this.getMinutes(), //分
		"s+": this.getSeconds(), //秒
		"q+": Math.floor((this.getMonth() + 3) / 3), //季度
		"S": this.getMilliseconds() //毫秒
	};
	if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (var k in o)
		if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : ((
			"00" +
			o[k]).substr(("" + o[k]).length)));
	return fmt;
}

//获取出生年龄
export const getMyAge = (birth) => {

	let year = 0,
		month = 0,
		ageDay = 0;

	let birthDay = new Date(birth);
	let nowDay = new Date();

	year = nowDay.getFullYear() - birthDay.getFullYear();

	if (birthDay.getMonth() <= nowDay.getMonth()) { //已经过了出生的月份
		month = nowDay.getMonth() - birthDay.getMonth();
	} else {
		year--;
		month = 12 - (birthDay.getMonth() - nowDay.getMonth());
	}

	if (birthDay.getDate() <= nowDay.getDate()) { //已经过了出生的天数
		ageDay = nowDay.getDate() - birthDay.getDate();
	} else {
		month--;
		ageDay = 31 - (birthDay.getDate() - nowDay.getDate());
	}

	return (year == 0 ? "" : year + "年") + (month == 0 ? "" : month + "个月") + (ageDay == 0 ? "" : ageDay + "天");
}

//获取出生年龄
export const getMyYearAge = (birth) => {

	let year = 0,
		month = 0,
		ageDay = 0;

	let birthDay = new Date(birth);
	let nowDay = new Date();

	year = nowDay.getFullYear() - birthDay.getFullYear();

	if (birthDay.getMonth() <= nowDay.getMonth()) { //已经过了出生的月份
		month = nowDay.getMonth() - birthDay.getMonth();
	} else {
		year--;
		month = 12 - (birthDay.getMonth() - nowDay.getMonth());
	}

	if (birthDay.getDate() <= nowDay.getDate()) { //已经过了出生的天数
		ageDay = nowDay.getDate() - birthDay.getDate();
	} else {
		month--;
		ageDay = 31 - (birthDay.getDate() - nowDay.getDate());
	}

	return year;
}

//系统配置
export const webConfig = {
	abbreviation: "铸视眼慢病管理中心",
	simple: '铸视眼慢病管理中心',
	copyright: '云南视记健康信息咨询有限公司',
	technology: '云南追溯科技有限公司',
	curYear: new Date().Format("yyyy"),
	recordNumber: "滇ICP备19011634号",
	address: "云南省昆明市五华区西坝路8号迎春巷4号院办公楼103号",
	tel: "0871-6000000",
	webUrls: {
		domain: production ? "sjymbgl.com" : "http://localhost:8080",
		app: production ? "https://app.sjymbgl.com" : "http://localhost:8080"
	}
}

//数据整理
export const cleanDatas = (data) => {
	data.filter(item => {
		for (var key in item) {
			if (item[key] == null || item[key] == undefined || item[key] == "null" || item[key] ==
				"undefined")
				item[key] = "";
		}
	});

	return data;
}

export const cleanData = (item) => {
	for (var key in item) {
		if (item[key] == null || item[key] == undefined || item[key] == "null" || item[key] == "undefined")
			item[key] = "";
	}

	return item;
}

//日志打印
export const log = (name, ...args) => {
	console.log(name, args);
}

//消息提示
export const showMessage = (page, result) => {
	try {
		if (result.code == 0) {
			page.$message.success(result.message, 3);
		} else {
			page.$message.error(result.message, 3);
		}
	} catch (e) {
		console.log("错误", result)
	}
}

// 把axios这个方法赋给一个叫做ajax的变量
const ajax = axios.create()

// 拦截请求参数，在这里全局提示加载中
ajax.interceptors.request.use(config => {
	console.log(config.data)
	var headers = {
		'Authorization': sessionStorage.getItem("token")
	}
	if (config.headers["Content-Type"] != undefined)
		headers["Content-Type"] = config.headers["Content-Type"];
	// headers 是设置即将被发送的自定义请求头。
	config.headers = headers;
	console.log("config", config)

	return config
})

//接受数据拦截 接受拦截响应
ajax.interceptors.response.use(resp => {
	//console.log("请求结果",resp)
	switch (resp) {
		case 200:
			return resp.data
			break;
		case 401:
			return {
				data: 'Unauthorized',
					code: 1,
					message: "请先登录"
			}
			case 403:
				return {
					data: 'Forbidden',
						code: 1,
						message: "语法错误"
				}
				case 404:
					return {
						data: 'Not Found',
							code: 1,
							message: 'Not Found'
					}
					case 504:
						return {
							data: 'GateWayTimeOut',
								code: 1,
								message: '连接超时'
						}
						case 500:
							return {
								data: 'server Error',
									code: 1,
									message: '服务器错误'
							}
							default:
								break;
	}
	return resp.data;
}, error => {
	console.log("一般的", "你好");

	switch (error.response.status) {
		case 401:
			VueCookies.set("return_url", window.location.href, {
				expires: 1000000,
				domain: webConfig.webUrls.domain,
				path: "/"
			});
			store.dispatch("logout");
			VueCookies.delete("passport");
			VueCookies.set('logout', true, {
				expires: 1000000
			});
			if (top == window)
				window.location.href = "/uc/login";
			else
				top.location.href = "/uc/login";

			return {
				data: 'Unauthorized',
					code: 1,
					message: "请先登录"
			}
			case 403:
				return {
					data: 'Forbidden',
						code: 1,
						message: "语法错误"
				}
				case 404:
					return {
						data: 'Not Found',
							code: 1,
							message: '请求不存在'
					}
					case 405:
						return {
							data: 'Not Found 405',
								code: 1,
								message: '请求方法错误GET/POST'
						}
						case 504:
							return {
								data: 'GateWayTimeOut',
									code: 1,
									message: '连接超时'
							}
							case 500:
								return {
									data: 'server Error',
										code: 1,
										message: '服务器错误'
								}
								default:
									break;
	}

	return Promise.reject(error);
});


///////////////////////////////////通用请求接口//////////////////////////////////////////
/**
 * 下载请求
 */
export const download = (page, url, params, showMsg, callback) => {
	url = url.replace("GET:", "");
	if (!shake) {
		shake = true;
		ajax.get(url + "?" + qs.stringify(params), {
			responseType: 'blob'
		}).then(res => {
			log("数据", url, res)
			if (showMsg) //提示消息
				showMessage(page, res);

			callback(res);
			//加延时操作
			setTimeout(function() {
				shake = false;
			}, 300);
		}, error => {
			shake = false;
		});
	} else {
		showMessage(page, {
			code: 1,
			message: "请勿重复数据提交。如长时间未返回结果，请刷新页面再继续操作！"
		});
	}
}

/**
 * get请求
 */
export const http_get = (page, url, params, showMsg, callback) => {
	ajax.get(url + "?" + qs.stringify(params)).then(res => {
		log("数据", url, res)
		if (showMsg) //提示消息
			showMessage(page, res);

		if (res.code == 0)
			callback(res);
	});
}

//获取图片验证码
export const http_img_get = (url, callback, databack) => {
	axios.get(url, {
		responseType: "arraybuffer"
	}).then(res => {
		callback(res.headers["content-type"].replace("=", "").split(";")[1].trim());
		return 'data:image/png;base64,' + btoa(
			new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
		);
	}).then(data => {
		databack(data)
	});
}

/**
 * post请求
 */
export const http_post = (page, url, params, showMsg, callback) => {
	console.log("抖动前", shake);

	if (!shake) {
		shake = true;
		ajax.post(url, qs.stringify(params), {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		}).then(res => {
			log("数据", url, res)
			if (showMsg) //提示消息
				showMessage(page, res);
			callback(res);
			//加延时操作
			setTimeout(function() {
				shake = false;
			}, 300);
			console.log("抖动结束", shake);
		}, error => {
			console.log("抖动结束", shake);
			shake = false;
		});
	} else {
		showMessage(page, {
			code: 1,
			message: "请勿重复数据提交。如长时间未返回结果，请刷新页面再继续操作！"
		});
	}
}

export const http_post_noshake = (page, url, params, showMsg, callback) => {
	ajax.post(url, qs.stringify(params), {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	}).then(res => {
		log("数据", url, res)
		if (showMsg) //提示消息
			showMessage(page, res);
		callback(res);
	});
}
/**
 * 封装请求
 */
export const request = (page, url, params, showMsg, callback) => {
	if (url.indexOf("POST") > -1) {
		url = url.replace("POST:", "");
		http_post(page, url, params, showMsg, callback)
	} else {
		url = url.replace("GET:", "");
		http_get(page, url, params, showMsg, callback)
	}
}


export const request_noshake = (page, url, params, showMsg, callback) => {
	if (url.indexOf("POST") > -1) {
		url = url.replace("POST:", "");
		http_post_noshake(page, url, params, showMsg, callback)
	} else {
		url = url.replace("GET:", "");
		http_get(page, url, params, showMsg, callback)
	}
}

//使用统一域名配置
export default {
	baseURL,
	showMessage
}
