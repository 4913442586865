<template>
	<div class="wf">
		<div :id="id" :style="'width: 100%;height:'+height+'px;'"></div>
	</div>
</template>

<script>
	export default {
		name: "barChart",
		props: ["data", "height", "title", "unit", "id"],
		data() {
			return {
				myChart: null,
				barData: {
					legend: [],
					xAxis: [],
					datas: []
				},
				option: {
					title: {
						text: ''
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						}
					},
					legend: {
						data: []
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: []
					}],
					yAxis: [{
						type: 'value'
					}],
					series: []
				}
			}
		},
		methods: {
			installChart() {
				this.myChart = echarts.init(document.getElementById(this.id));
				this.packOption();
			},
			packOption() {
				let xAxisData = this.barData.xAxis;
				let legendData = this.barData.legend;
				let seriesData = [];
				this.barData.datas.forEach(item => {
					seriesData.push({
						name: item.label,
						type: 'bar',
						stack: item.tag,
						emphasis: {
							focus: 'series'
						},
						data: item.data
					});
				});
				this.option.xAxis[0].data = xAxisData;
				this.option.legend.data = legendData;
				this.option.series = seriesData;
				this.option.title.text = this.title;

				console.log(this.option)
				this.myChart.clear();
				this.myChart.setOption(this.option);
			}
		},
		mounted() {
			this.installChart();
		},
		watch: {
			data(newValue, oldValue) {
				this.barData = newValue;
				this.packOption();
			}
		},
	}
</script>

<style>
</style>
