<template>
	<div class="fragementBase">
		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">检查治疗</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">转诊</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->
		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form class="wf" ref="searchForm" :model="searchForm" :inline="true">
					<el-form-item label="患者信息">
						<el-input v-model="searchForm.name" size="small" placeholder="请填写患者手机号/身份证号/姓名" />
					</el-form-item>
					<el-form-item label="发布时间">
						<el-date-picker v-model="searchForm.daterange" type="daterange" @change="dateChange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="doSearch"><i class="fa fa-search"></i>查询
						</el-button>
						<el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i> 重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>


		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<el-button type="success" size="small" @click="newNursing"><i class="fa fa-plus"></i>新增记录</el-button>
				<el-button v-if="principal.level==3" type="success" size="small" @click="doExport"><i
						class="fa fa-download"></i> 批量导出</el-button>
			</div>


			<el-table :data="nursingList" :stripe="true" border class="mgtop10"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="name" label="患者信息" width="350">
					<template slot-scope="scope">
						<el-row>
							<el-col span="8">
								<img class="border radius5" :src="scope.row.avater" width="100px" height="100px" />
							</el-col>
							<el-col span="16">
								<div class="wf">姓名：{{scope.row.name}}
									<el-tag type="primary" size="small">{{scope.row.gender}}</el-tag>
								</div>
								<div class="wf">手机号：{{scope.row.phone}}</div>
								<div class="wf">身份证号：{{scope.row.idcard}}</div>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="转诊信息" width="350">
					<template slot-scope="scope">
						<div class="wf">医院名称：{{scope.row.hospitalName}}</div>
						<div class="wf">责任专家：{{scope.row.expertName}}</div>
						<div class="wf">预约时间：{{scope.row.appointment}}</div>
						<div class="wf" v-if="scope.row.agree==1">
							<el-tag type="success" size="small">已同意{{scope.row.agreeTime}}</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="detail" label="备注"></el-table-column>
				<el-table-column prop="optionerName" label="操作者" align="center"></el-table-column>
				<el-table-column prop="updateTime" label="更新日期" width="160" align="center"></el-table-column>
				<el-table-column align="center" label="操作" width="200">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="12">
								<el-button style="width: 100%;" type="primary" size="small"
									@click="showNursing(scope.$index)"><i class="fa fa-edit"></i>详情</el-button>
							</el-col>
							<el-col v-if="principal.level==3" :span="12">
								<el-button style="width: 100%;" type="danger" size="small"
									@click="removeNursing(scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
							</el-col>
						</el-row>

					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="nursingList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" width="1000px">
			<div class="pdding20auto mgbtm20" lot="title">
				<div class="fl lineh20 h20 bold font18">转诊信息</div>
				<el-switch v-if="principal.serial==nursingForm.optioner || principal.level==3" class="fl mgl20"
					v-model="canEdit" active-color="#13ce66" inactive-color="#ff4949" active-text="查看模式"
					inactive-text="编辑模式" :active-value="1" :inactive-value="0">
				</el-switch>
			</div>
			<el-form class="wf" ref="nursingForm" :model="nursingForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="12">
						<el-form-item label="患者图像" prop="avater">
							<el-upload :disabled="canEdit==0" class="avatar-uploader" name="mdFile"
								:action="imgUploadUrl" :headers="uploadHeaders" :show-file-list="false"
								:on-success="handleCoverSuccess" :before-upload="beforePicUpload">
								<img v-if="nursingForm.avater" :src="nursingForm.avater" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<div class="wf lineh20"> <i class="el-icon-star-off"></i>建议使用800*800正方形图片，支持JPG、PNG格式，最大20MB
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="患者姓名" prop="patient">
							<el-select v-if="canEdit==1" v-model="nursingForm.patient" filterable remote reserve-keyword
								placeholder="请选择记录姓名" :remote-method="remoteMethod" @change="patientChange"
								:loading="loading" style="width: 100%;">
								<el-option v-for="item in patientList" :key="item.serial"
									:label="item.name+'('+item.phone+')'" :value="item.serial">
								</el-option>
							</el-select>
							<span v-else>{{nursingForm.name}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="性别" prop="gender">
							<el-input v-if="canEdit==1" disabled v-model="nursingForm.gender" placeholder="请填写记录姓名">
							</el-input>
							<span v-else>{{nursingForm.gender}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="手机号" prop="phone">
							<el-input v-if="canEdit==1" disabled v-model.number="nursingForm.phone"
								placeholder="请填写记录手机号"></el-input>
							<span v-else>{{nursingForm.phone}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="身份证号码" prop="idcard">
							<el-input v-if="canEdit==1" disabled v-model="nursingForm.idcard" placeholder="请填写身份证号码">
							</el-input>
							<span v-else>{{nursingForm.idcard}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="医院" prop="hospital">
							<div class="wf h40">
								<el-select v-if="canEdit==1" v-model="nursingForm.hospital" filterable remote
									reserve-keyword placeholder="请选择记录姓名" :remote-method="remoteHospital"
									@change="hospitalChange" :loading="loading" style="width: 100%;">
									<el-option v-for="item in hospitalList" :key="item.serial" :label="item.name"
										:value="item.serial">
									</el-option>
								</el-select>
								<span v-else>{{nursingForm.hospitalName}}</span>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="责任专家" prop="expert">
							<div class="wf h40">
								<el-select v-if="canEdit==1" v-model="nursingForm.expert" filterable remote
									reserve-keyword placeholder="请选择专家姓名" @change="expertChange" :loading="loading"
									style="width: 100%;">
									<el-option v-for="item in expertList" :key="item.serial" :label="item.name"
										:value="item.serial">
									</el-option>
								</el-select>
								<span v-else>{{nursingForm.expertName}}</span>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="预约时间" prop="appointment">
							<div class="wf h40">
								<el-date-picker v-if="canEdit==1" v-model="nursingForm.appointment" type="datetime"
									placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%;">
								</el-date-picker>
								<span v-else>{{nursingForm.appointment}}</span>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="转诊备注" prop="detail">
							<el-input v-if="canEdit==1" type="textarea" :rows="5" v-model="nursingForm.detail"
								placeholder="请填写转诊备注">
							</el-input>
							<span v-else>{{nursingForm.detail}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="协议图片">
							<el-upload :disabled="canEdit==0" class="upload-demo" name="mdFile" list-type="picture-card"
								:action="imgUploadUrl" :headers="uploadHeaders" :on-change="picChange"
								:on-remove="picChange" :on-preview="handlePictureCardPreview" :file-list="picList"
								:before-upload="beforePicUpload" :limit="5">
								<i class="el-icon-plus"></i>
								<div slot="tip" class="el-upload__tip">只能上传JPG、PNG、JPEG文件，且不超过1M</div>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button v-if="canEdit==1" type="primary" @click="publishNursing">保存</el-button>
			</span>
		</el-dialog>
		<el-dialog :close-on-click-modal="false" :visible.sync="previewVisible" append-to-body>
			<img width="100%" :src="previewImageUrl" alt="">
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				canEdit: 0,
				loading: false,
				previewImageUrl: '',
				previewVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				dialogVisible: false,
				searchForm: {
					type: "referral",
					page: 1,
					pageSize: 20,
					total: 0
				},
				nursingForm: {},
				nursingList: [],
				patientList: [],
				expertList: [],
				picList: [],
				serials: [],
				multipleSelection: [],
				rules: {
					patient: [{
							required: true,
							message: '请填写患者姓名',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 100,
							message: '长度在 2 到 100个字符',
							trigger: 'blur'
						}
					],
					gender: [{
						required: true,
						message: '请选择患者性别',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请填写患者电话',
						trigger: 'blur'
					}],
					unit: [{
						required: true,
						message: '请填写患者所在单位',
						trigger: 'blur'
					}],
					jobTitle: [{
						required: true,
						message: '请填写患者职称 ',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '请填写患者所在领域 ',
						trigger: 'blur'
					}],
					avater: [{
						required: true,
						message: '请填写患者图像',
						trigger: 'blur'
					}],
					detail: [{
						required: true,
						message: '请填写转诊信息',
						trigger: 'blur'
					}],
					result: [{
						required: true,
						message: '请填写转诊结果',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLtM = file.size / 1024 / 1024 < 20;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLtM) {
					this.$message.error('上传图片大小不能超过 20MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLtM;
			},
			handlePictureCardPreview(file) {
				this.previewImageUrl = file.url;
				this.previewVisible = true;
			},
			picChange(file, fileList) {
				console.log("文件上传", fileList)
				this.picList = fileList;
			},
			handleCoverSuccess(res, file) {
				console.log("文件上传", res)
				if (res.files.length > 0)
					this.nursingForm.avater = res.files[0].uploadUrl;
			},
			doSearch() {
				this.pageChange(1);
			},
			dateChange(e) {
				this.searchForm.startTime = e == null ? "" : e[0];
				this.searchForm.endTime = e == null ? "" : e[1];
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getNursings();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getNursings();
			},
			newNursing() {
				this.canEdit = 1;
				this.nursingForm = {
					avater: "",
					type: "referral"
				};
				this.dialogVisible = true;
			},
			getNursings() {
				this.$http.request(this, this.$apis.tools.nursingList, this.searchForm, false, res => {
					this.searchForm.total = res.count;
					res.data.forEach(item => {
						item.mprice = parseFloat(item.price * 0.01).toFixed(2);
					})
					this.nursingList = res.data;
					console.log(res.data);
				});
			},
			doExport() {
				var searchForm = Object.assign({}, this.searchForm);
				if (this.serials.length > 1)
					searchForm.serials = this.serials.join(",");
				this.$http.download(this, this.$apis.tools.nursingExport, searchForm, false, res => {
					var blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					})
					var url = window.URL.createObjectURL(blob);
					var aLink = document.createElement("a");
					aLink.style.display = "none";
					aLink.href = url;
					aLink.setAttribute("download", "管理端患者档案导出数据.xls");
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); //下载完成移除元素
					window.URL.revokeObjectURL(url); //释放掉blob对象
				});
			},
			publishNursing() {

				this.$refs['nursingForm'].validate((valid) => {
					if (valid) {
						var nursingForm = Object.assign({}, this.nursingForm);
						nursingForm.updateTime = "";
						var picList = [];
						this.picList.filter(file => {
							if (file.status == "success" && file.response.files.length > 0) {
								let doc = file.response.files[0];
								picList.push({
									uid: file.uid,
									name: doc.orianName,
									url: doc.uploadUrl
								})
							}
						});
						nursingForm.pics = JSON.stringify(picList);

						this.$http.request(this, this.$apis.tools.nursingPublish, nursingForm, true, res => {
							let data = res.data;
							if (data != null) {
								data.mprice = parseFloat(data.price * 0.01).toFixed(2);
								this.nursingList.unshift(data);
							}

							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			showNursing(index) {
				this.canEdit = 0;
				var nursingForm = this.nursingList[index];
				this.nursingForm = nursingForm;
				this.remoteMethod(nursingForm.name);
				this.remoteHospital(nursingForm.hospitalName);
				this.dialogVisible = true;

			},
			removeNursing(index) {
				var nursing = this.nursingList[index];
				this.$confirm('此操作将永久删除[' + nursing.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.tools.nursingRemove.replace("{serial}", nursing.serial);
					this.$http.request(this, url, {}, true, res => {
						this.nursingList.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的记录 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.tools.nursingBatchRemove, {
						serials: serials
					}, true, res => {
						this.nursingList = this.nursingList.filter(nursing => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == nursing.serial) {
									flag = false;
								}
							})
							return flag;
						});
					});
				});
			},
			remoteMethod(query) {
				this.loading = true;
				this.$http.request(this, this.$apis.tools.patientList, {
					name: query,
					phone: query
				}, false, res => {
					this.loading = false;
					this.patientList = res.data;
				});
			},
			patientChange() {
				console.log("阿斯顿撒的", this.nursingForm.patient)
				this.patientList.forEach(item => {
					if (item.serial == this.nursingForm.patient) {
						this.nursingForm.phone = item.phone;
						this.nursingForm.gender = item.gender;
						this.nursingForm.avater = item.avater;
						this.nursingForm.idcard = item.idcard;
						this.nursingForm.name = item.name;
					}
				})
			},
			remoteHospital(query) {
				this.loading = true;
				this.$http.request(this, this.$apis.user.orgList, {
					name: query
				}, false, res => {
					this.loading = false;
					this.hospitalList = res.data;
					if (res.data.length > 0)
						this.remoteExpert(res.data[0].serial)
				});
			},
			hospitalChange() {
				this.hospitalList.forEach(item => {
					if (item.serial == this.nursingForm.hospital) {
						this.nursingForm.hospitalName = item.name;
						this.remoteExpert(item.serial);
					}
				})
			},
			remoteExpert(query) {
				this.loading = true;
				this.$http.request(this, this.$apis.user.doctorList, {
					unit: query
				}, false, res => {
					this.loading = false;
					this.expertList = res.data;
				});
			},
			expertChange() {
				this.expertList.forEach(item => {
					if (item.serial == this.nursingForm.expert) {
						this.nursingForm.expertName = item.name;
					}
				})
			}
		},
		created() {
			this.remoteHospital("")
			this.remoteMethod("");
			this.getNursings();
		},
		watch: {
			canEdit(newValue, oldValue) {
				this.remoteHospital(this.nursingForm.hospitalName)
			}
		},
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
	}

	.avatar {
		width: 148px;
		height: 148px;
		display: block;
	}
</style>
