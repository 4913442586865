<template>
	<div class="fragementBase">
		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">运营中心</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">会员卡</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->

		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<el-button type="success" size="small" @click="newVipCard"><i class="fa fa-plus"></i>新增会员卡</el-button>
			</div>

			<el-table :data="vipCardList" :stripe="true" border class="mgtop10">
				<el-table-column prop="name" label="卡名称" width="200">
					<template slot-scope="scope">
						{{scope.row.name}}
						<el-tag size="small" type="danger" v-if="scope.row.recommend==1">推荐</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="duration" label="服务明目">
					<template slot-scope="scope">
						<div class="wf lineh20">有效时长：{{scope.row.duration}}月</div>
						<div class="wf lineh20">可用次数：{{scope.row.amount}}次</div>
						<div class="wf lineh20">赠送次数：{{scope.row.give}}次</div>
					</template>
				</el-table-column>
				<el-table-column prop="duration" label="使用额度" align="center">
					<template slot-scope="scope">
						<div class="wf lineh20">当日使用:{{scope.row.coupon}}次</div>
						<div class="wf lineh20">单次可用额:{{parseFloat(scope.row.valent).toFixed(2)}}元</div>
					</template>
				</el-table-column>
				<el-table-column prop="price" label="价格" align="center" width="200">
					<template slot-scope="scope">
						<div class="wf">
							<el-tag type="warning" size="small">原价：{{parseFloat(scope.row.originalPrice).toFixed(2)}}
							</el-tag>
						</div>
						<div class="wf">
							<el-tag type="success" size="small">现价：{{parseFloat(scope.row.price).toFixed(2)}}</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="buy" label="销量" align="right"></el-table-column>
				<el-table-column prop="updateTime" label="更新日期" width="160" align="center"></el-table-column>
				<el-table-column align="center" label="操作" width="200">
					<template slot-scope="scope">
						<el-button type="primary" size="small" @click="showVipCard(scope.$index)"><i
								class="fa fa-edit"></i>编辑</el-button>
						<el-button v-if="principal.level==3" type="danger" size="small" @click="removeVipCard(scope.$index)"><i
								class="fa fa-remove"></i>删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="vipCardList.length>0" background layout="prev, pager, next"
						@current-change="pageChange" :page-size="searchForm.pageSize" :total="searchForm.total"
						class="fr"> </el-pagination>
				</div>
			</div>
		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="会员卡信息" :visible.sync="dialogVisible" width="1000px">
			<div class="wf">
				<!-- -->
				<el-form class="wf" ref="vipCardForm" :model="vipCardForm" label-width="100px" :rules="rules">

					<el-row :gutter="10">
						<el-col :span="24">
							<el-form-item label="卡名称" prop="name">
								<el-input v-model="vipCardForm.name" placeholder="请填写会员卡名称"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="是否推荐" prop="recommend">
								<div class="wf h40">
									<el-select v-model="vipCardForm.recommend" placeholder="请填写会员卡名称"
										style="width: 100%;">
										<el-option label="是" :value="1"></el-option>
										<el-option label="否" :value="0"></el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="有效时长" prop="duration">
								<el-input v-model="vipCardForm.duration" placeholder="请填写会员卡名称">
									<span slot="append">月</span>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="可用次数" prop="amount">
								<el-input v-model.number="vipCardForm.amount" placeholder="请填写可用次数">
									<span slot="append">次</span>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="赠送次数" prop="give">
								<el-input v-model.number="vipCardForm.give" placeholder="请填写赠送次数">
									<span slot="append">次</span>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="单次额度" prop="valent">
								<el-input v-model="vipCardForm.valent" placeholder="请填写单次可用额度">
									<span slot="append">元</span>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="每日次数" prop="coupon">
								<el-input v-model.number="vipCardForm.coupon" placeholder="请填写每日使用次数">
									<span slot="append">次</span>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="原价" prop="originalPrice">
								<el-input v-model="vipCardForm.originalPrice" placeholder="请填写会员卡原价">
									<span slot="append">元</span>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="现价" prop="price">
								<el-input v-model="vipCardForm.price" placeholder="请填写会员卡现价">
									<span slot="append">元</span>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="主要服务" prop="service">
								<el-input type="textarea" :rows="5" v-model="vipCardForm.service"
									placeholder="请填写会员套主要服务"></el-input>
							</el-form-item>
						</el-col>
					</el-row>

				</el-form>
				<!-- -->
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishVipCard">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				vipCardForm: {
					coupon: 0
				},
				vipCardList: [],
				rules: {
					name: [{
							required: true,
							message: '请填写会员卡名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					duration: [{
						required: true,
						message: '请填写有效时长',
						trigger: 'blur'
					}],
					amount: [{
						required: true,
						message: '请填写可用次数',
						trigger: 'blur'
					}],
					give: [{
						required: true,
						message: '请填写赠送次数',
						trigger: 'blur'
					}],
					coupon: [{
						required: true,
						message: '请填写每日使用次数',
						trigger: 'blur'
					}],
					price: [{
						required: true,
						message: '请填写价格',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '价格为数字，例如：12.00'
					}],
					originalPrice: [{
						required: true,
						message: '请填写价格',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '价格为数字，例如：12.00'
					}],
					valent: [{
						required: true,
						message: '请填写价格',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '价格为数字，例如：12.00'
					}]
				},
			}
		},
		methods: {
			doSearch() {
				this.pageChange(1);
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getVipCards();
			},
			newVipCard() {
				this.vipCardForm = {
					coupon: 0
				};
				this.dialogVisible = true;
			},
			getVipCards() {
				this.$http.request(this, this.$apis.user.vipCardList, this.searchForm, false, res => {
					this.searchForm.total = res.count;
					res.data.forEach(item => {
						item.price = parseInt(item.price * 0.01).toFixed(2);
						item.originalPrice = parseInt(item.originalPrice * 0.01).toFixed(2);
						item.valent = parseInt(item.valent * 0.01).toFixed(2);
					})
					this.vipCardList = res.data;

				});
			},
			publishVipCard() {

				this.$refs['vipCardForm'].validate((valid) => {
					if (valid) {
						var vipCardForm = Object.assign({}, this.vipCardForm);
						vipCardForm.updateTime = "";
						vipCardForm.price = parseInt(vipCardForm.price * 100);
						vipCardForm.originalPrice = parseInt(vipCardForm.originalPrice * 100);
						vipCardForm.valent = parseInt(vipCardForm.valent * 100);

						this.$http.request(this, this.$apis.user.vipCardPublish, vipCardForm, true, res => {
							let data = res.data;
							if (data != null) {
								data.price = parseInt(data.price * 0.01).toFixed(2);
								data.originalPrice = parseInt(data.originalPrice * 0.01).toFixed(2);
								data.valent = parseInt(data.valent * 0.01).toFixed(2);
								this.vipCardList.unshift(data);
							}
							this.dialogVisible = false;
						});
					}
				});
			},
			showVipCard(index) {
				var vipCardForm = this.vipCardList[index];
				this.vipCardForm = vipCardForm;
				this.dialogVisible = true;

			},
			removeVipCard(index) {
				var vipCard = this.vipCardList[index];
				this.$confirm('此操作将永久删除[' + vipCard.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.user.vipCardRemove.replace("{serial}", vipCard.serial);
					this.$http.request(this, url, {}, true, data => {
						this.vipCardList.splice(index, 1);
					});
				});
			}
		},
		mounted() {

		},
		created() {
			this.getVipCards();
		}
	}
</script>
