<template>
	<div class="wf">
		<el-menu :default-active="activeIndex" class="el-menu-demo" background-color="#222" text-color="#fff"
			active-text-color="#4DC6BF" @select="menuSelect" :collapse="isCollapse">
			<template v-for="(item,index) in authoritys">
				<el-menu-item v-if="item.children.length==0" :index="item.serial">
					<i class="fa fa-th mgr5"></i>
					<span>{{item.name}}</span>
				</el-menu-item>
				<el-submenu v-else :index="item.serial">
					<template slot="title">
						<i class="fa fa-th mgr5"></i>
						<span>{{item.name}}</span>
					</template>
					<el-menu-item :index="sitem.serial" v-for="(sitem,sindex) in item.children" :key="sindex">
						{{sitem.name}}
					</el-menu-item>
				</el-submenu>
			</template>
		</el-menu>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'

	export default {
		name: 'webNav',
		props: ["isCollapse"],
		data() {
			return {
				authoritys: [],
			}
		},
		computed: {
			...mapGetters([
				'principal'
			])
		},
		methods: {
			menuSelect(serial) {
				console.log("菜单下标", serial)
				this.$store.dispatch("selectMenu", serial);
				// let url = this.authoritys[index].url;
				//sessionStorage.setItem("subNavs", JSON.stringify(this.authoritys[index].children));
				this.authoritys.forEach(item => {
					if (item.serial == serial) {
						this.$emit("menuSelected", item);
					} else {
						item.children.forEach(sitem => {
							if (sitem.serial == serial)
								this.$emit("menuSelected", sitem);
						});
					}
				})
				//window.location.href = url != null && url != "" ? url : this.authoritys[index].children[0].url;
			}
		},
		created() {
			this.authoritys = this.principal.authority;
			console.log("数据", this.authoritys);
		}
	}
</script>

<style>
	.el-menu {
		border-right: 0;
	}
</style>
