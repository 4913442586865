<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="手机号">
						<el-input v-model="searchForm.phone" placeholder="请填写用户手机号" />
					</el-form-item>
					<el-form-item label="设备商">
						<el-select v-model="searchForm.vendorName" placeholder="请选择设备商">
							<el-option value="车便捷"></el-option>
							<el-option value="鲸洗"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="设备编号">
						<el-input v-model="searchForm.device" placeholder="请填写设备名称" />
					</el-form-item>
					<el-form-item label="服务网点">
						<el-input v-model="searchForm.stationName" placeholder="请填写服务网点" />
					</el-form-item>
					<el-form-item label="订单状态">
						<el-select v-model="searchForm.status" placeholder="请选择订单状态">
							<el-option :value="-1" label="设备故障"></el-option>
							<el-option :value="0" label="进行中"></el-option>
							<el-option :value="9" label="已完成"></el-option>
							<el-option :value="11" label="工作异常"></el-option>
							<el-option :value="12" label="已取消"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="服务日期">
						<el-date-picker v-model="searchForm.daterange" type="daterange" @change="dateChange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
						<el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i>重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>


		<div class="pdding20 bgffffff overflow mgtop10">

			<div class="wf lineh40">
				<el-button type="success" size="small" @click="doExport"><i class="fa fa-download"></i> 批量导出</el-button>
			</div>

			<!-- 最新激活记录 -->
			<el-table class="mgtop10" :data="orderList" :stripe="true" border @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="childName" label="用户信息" width="180">
					<template slot-scope="scope">
						<div class="wf lineh30">{{scope.row.memberName}}</div>
						<div class="wf lineh30">手机号：{{scope.row.memberPhone}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="deviceName" label="设备名称" width="200">
					<template slot-scope="scope">
						<div class="wf">设备号：{{scope.row.number}}</div>
						<div class="wf">{{scope.row.name}}</div>
						<div class="wf">机器号：{{scope.row.machine}}</div>
						<div class="wf">设备商：<el-tag size="small" type="primary">{{scope.row.vendorName}}</el-tag>
						</div>
						<div class="wf">泊位点：{{scope.row.stationName}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="sortName" label="消费明细" align="center">
					<template slot-scope="scope">
						<div class="wf" v-for="(item,index) in scope.row.detail" :key="index">
							<el-tag v-if="item.channel==1" type="primary" size="small">
								清水：{{parseFloat(item.time / 60).toFixed(2)}}分钟</el-tag>
							<el-tag v-if="item.channel==2" type="primary" size="small">
								泡沫：{{parseFloat(item.time / 60).toFixed(2)}}分钟</el-tag>
							<el-tag v-if="item.channel==3" type="primary" size="small">
								洗手：{{parseFloat(item.time / 60).toFixed(2)}}分钟</el-tag>
							<el-tag v-if="item.channel==4" type="primary" size="small">
								吸尘：{{parseFloat(item.time / 60).toFixed(2)}}分钟</el-tag>
						</div>
						<div v-if="scope.row.detail.length==0">
							<el-tag type="warning" size="small">没有消费明细</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="mprice" label="费用明细" align="right" width="160">
					<template slot-scope="scope">
						<div class="wf lineh30">用券：{{scope.row.coupon}}元</div>
						<div class="wf lineh30">用卡：{{scope.row.card}}次</div>
						<div class="wf lineh30">余额：{{scope.row.cash}}元</div>
						<div class="wf lineh30">赠送：{{scope.row.give}}元</div>
					</template>
				</el-table-column>
				<el-table-column prop="updateTime" label="服务时间" align="left" width="200">
					<template slot-scope="scope">
						<div class="wf lineh20">开启：{{scope.row.subTime}}</div>
						<div class="wf lineh20">结束：{{scope.row.finishTime}}</div>
						<div class="wf lineh20">时长：{{scope.row.time}}分钟</div>
					</template>
				</el-table-column>
				<el-table-column prop="status" label="订单状态" width="100" align="center">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.status == 0" type="primary" size="small">进行中</el-tag>
						<el-tag v-if="scope.row.status == 12" type="info" size="small">已取消</el-tag>
						<el-tag v-if="scope.row.status == 9" type="success" size="small">已完成</el-tag>
						<el-tag v-if="scope.row.status == -1" type="danger" size="small">设备故障</el-tag>
						<el-tag v-if="scope.row.status == 11" type="danger" size="small">工作异常</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="140">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="24" class="mgtop10">
								<el-button @click="removeOrder(scope.$index)" type="danger" size="small">
									<i class="fa fa-remove"></i>取消订单
								</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="orderList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'fragementAddress',
		props: ["org"],
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					state: 4,
					page: 1,
					pageSize: 20,
					total: 0,
					sortServiceTime: 1
				},
				orderData: {
					total: 0,
					new: 0,
					future: 0,
					state: {
						1: 0,
						2: 0,
						3: 0,
						4: 0
					}
				},
				accountForm: {},
				orderForm: {},
				orderList: [],
				multipleSelection: [],
				orgs: [],
				systems: [],
				loading: false,
				rules: {
					name: [{
						required: true,
						message: '请输入订单名称',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入订单代码',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择订单类型',
						trigger: 'blur'
					}],
					state: [{
						required: true,
						message: '请选择订单状态',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			resetSearch() {
				this.searchForm = {
					state: 4,
					page: 1,
					pageSize: 20,
					total: 0
				}
			},
			dateChange(e) {
				if (e == null) {
					this.searchForm.startTime = "";
					this.searchForm.endTime = "";
				} else {
					this.searchForm.startTime = e[0];
					this.searchForm.endTime = e[1];
				}
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getOrders(this.org.serial);
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getOrders(this.org.serial);
			},
			getOrders(org) {
				var searchForm = Object.assign({}, this.searchForm);
				searchForm.station = org;
				this.$http.request(this, this.$apis.device.deviceOrderList, searchForm, false, res => {
					this.searchForm.total = res.count;
					res.data = this.$http.cleanDatas(res.data);
					res.data.forEach(item => {
						item.coupon = parseFloat(item.coupon * 0.01).toFixed(2);
						item.cash = parseFloat(item.cash * 0.01).toFixed(2);
						item.give = parseFloat(item.give * 0.01).toFixed(2);
						item.time = parseFloat(item.time * 0.001 / 60).toFixed(2);
						item.detail = JSON.parse(item.detail);
					});
					this.orderList = res.data;
				});
			},
			doExport() {
				this.$http.download(this, this.$apis.device.orderExport, this.searchForm, false, res => {
					var blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					})
					var url = window.URL.createObjectURL(blob);
					var aLink = document.createElement("a");
					aLink.style.display = "none";
					aLink.href = url;
					aLink.setAttribute("download", "运营端设备接种导出数据.xls");
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); //下载完成移除元素
					window.URL.revokeObjectURL(url); //释放掉blob对象
				});
			},
			showOrder(index) {
				var orderForm = this.orderList[index];
				this.orderForm = orderForm;
				this.dialogVisible = true;
			},
			removeOrder(index) {
				var orderForm = this.orderList[index];
				this.orderForm = orderForm;
				this.$confirm('此操作将永久取消服务订单 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.device.deviceOrderCancel.replace("{serial}", orderForm.serial);
					this.$http.request(this, url, {}, true, res => {
						if (res.code == 0) {
							this.orderForm.status = 9;
						}
					});
				});
			},
			remoteMethod(query) {
				if (query !== '') {
					this.loading = true;
					setTimeout(() => {
						this.loading = false;
						this.$http.request(this, this.$apis.user.orgList, {
							name: query
						}, false, res => {
							this.orgs = res.data;
						});
					}, 200);
				} else {
					this.orgs = [];
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的接种记录 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.device.orderBatchRemove, {
						serials: serials
					}, true, res => {
						this.orderList = this.orderList.filter(order => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == order.serial) {
									flag = false;
								}
							})
							return flag;
						});
					});
				});
			}
		},
		watch: {
			org(newValue, oldValue) {
				this.getOrders(this.org.serial);
			}
		}
	}
</script>

<style>
</style>
