<template>
	<div class="wf">
		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>检查治疗</el-breadcrumb-item>
				<el-breadcrumb-item>青少年近视档案</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form class="wf" ref="searchForm" :model="searchForm" :inline="true">
					<el-form-item label="患者姓名">
						<el-input v-model="searchForm.name" clearable size="small" placeholder="请填写患者姓名/手机号/身份证号" />
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="开始时间">
						<el-date-picker v-model="searchForm.startDay" clearable type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="结束日期">
						<el-date-picker v-model="searchForm.endDay" clearable type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item v-if="principal.type<=1" label="关键词">
						<el-input v-model="searchForm.key" clearable size="small" placeholder="请填写关键词" />
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="doSearch"><i class="fa fa-search"></i>查询
						</el-button>
						<el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i> 重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<el-button type="success" size="small" @click="newAchives"><i class="fa fa-plus"></i>新增档案</el-button>
			</div>

			<el-table :data="archivesList" :stripe="true" border class="mgtop10"
				@selection-change="handleSelectionChange">
				<el-table-column label="序号" type="index" width="55"></el-table-column>
				<el-table-column prop="number" label="档案信息" width="240px">
					<template slot-scope="scope">
						<div class="wf">档案编号：{{scope.row.patient}}</div>
						<div class="wf">患者信息：{{scope.row.name}}</div>
						<div class="wf">建档日期：{{scope.row.day}}</div>
						<div class="wf">更新日期：{{scope.row.updateTime}} </div>
					</template>
				</el-table-column>
				<el-table-column prop="appointment" label="验配史">
					<template slot-scope="scope">
						{{scope.row.evaluation["1feb6e6986f64490af6cff9615a29cfc"]}}
					</template>
				</el-table-column>
				<el-table-column prop="detail" label="医生建议">
					<template slot-scope="scope">
						{{scope.row.evaluation["722bec1836774c9cab340aabe50bf2b0)"]}}
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="200">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="12">
								<el-button style="width: 100%;" type="primary" size="small"
									@click="showAchives(scope.$index)"><i class="fa fa-edit"></i>查看</el-button>
							</el-col>
							<el-col v-if="principal.level==3" :span="12">
								<el-button style="width: 100%;" type="danger" size="small"
									@click="removeAchives(scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
							</el-col>
						</el-row>

					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="archivesList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>

		<!-- 弹出层 -->
		<el-dialog id="archivesForm" :close-on-click-modal="false" :visible.sync="dialogVisible" width="1000px"
			append-to-body>
			<div class="pdding20auto mgbtm20" lot="title">
				<div class="fl lineh20 h20 bold font18">档案信息</div>
				<el-switch v-if="principal.serial==archivesForm.optioner || principal.level==3" class="fl mgl20"
					v-model="canEdit" active-color="#13ce66" inactive-color="#ff4949" active-text="查看模式"
					inactive-text="编辑模式" :active-value="1" :inactive-value="0">
				</el-switch>
			</div>
			<div class="pdding20auto lineh40">
				<span class="fl mgr10">患者用户:</span>
				<el-select class="fl" v-model="archivesForm.patient" filterable remote reserve-keyword
					placeholder="请选择记录姓名" :remote-method="remoteMethod" @change="patientChange" :loading="loading"
					style="width: 300px;">
					<el-option v-for="item in patientList" :key="item.serial" :label="item.name+'('+item.phone+')'"
						:value="item.serial">
					</el-option>
				</el-select>
			</div>
			<div class="wf mgtop10">
				<el-tabs type="border-card" v-model="activeName">
					<el-tab-pane label="视力档案" name="视力档案">
						<evaluate :canEdit="canEdit" :patientForm="patientForm" :archivesForm="archivesForm"
							@infoChange="infoChange" @showChange="showChange">
						</evaluate>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import Evaluate from '@/components/treatment/archive/young/Evaluate.vue'

	import VueUEditor from 'vue-ueditor'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				canEdit: 0,
				activeName: "视力档案",
				dialogVisible: false,
				searchForm: {
					tag: 2,
					page: 1,
					pageSize: 20,
					total: 0
				},
				archivesForm: {},
				archivesList: [],
				patientForm: {},
				patientList: []
			}
		},
		props: ["patientForm"],
		components: {
			Evaluate
		},
		methods: {
			doSearch() {
				this.searchForm.page = 1;
				this.getArchivesList();
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getArchivesList();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getArchivesList();
			},
			getArchivesList() {
				this.$http.request(this, this.$apis.tools.archivesList, this.searchForm, false, (res) => {
					if (res.code == 0) {
						res.data.forEach(item => {
							item.evaluation = JSON.parse(item.evaluation);
							item.eyefile = JSON.parse(item.eyefile);
							item.nurse = JSON.parse(item.nurse);
						});
						this.archivesList = res.data;
						this.searchForm.total = res.count;
					}
				});
			},
			newAchives() {
				this.archivesForm = {
					evaluation: {},
					eyefile: {},
					nurse: {}
				};
				this.canEdit = 1;
				this.dialogVisible = true;
			},
			showAchives(index) {
				this.activeName = "视力档案";
				this.archivesForm = this.archivesList[index];
				this.remoteMethod(this.archivesForm.name);
				this.dialogVisible = true;
				console.log("档案记录", this.archivesForm);
			},
			removeAchives(index) {
				var archives = this.archivesList[index];
				this.$confirm('此操作将永久删除[' + archives.number + ']档案变更记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.tools.archivesRemove.replace("{serial}", archives.serial);
					this.$http.request(this, url, {}, true, res => {
						this.archivesList.splice(index, 1);
					});
				});
			},
			infoChange(e) {
				console.log("档案信息变更", e)
				this.archivesForm = e;
			},
			showChange() {
				this.dialogVisible = !this.dialogVisible;
				this.getArchivesList();
			},
			remoteMethod(query) {
				this.loading = true;
				this.$http.request(this, this.$apis.tools.patientList, {
					name: query,
					phone: query
				}, false, res => {
					this.loading = false;
					this.patientList = res.data;
				});
			},
			patientChange() {
				this.patientList.forEach(item => {
					if (item.serial == this.archivesForm.patient) {
						this.patientForm = item;
					}
				})
			},
		},
		created() {
			this.getArchivesList();
		}
	}
</script>

<style>
	#archivesForm .el-dialog__body {
		padding: 0 !important;
	}
</style>
