<template>
	<div id="uc">
		<router-view/>
		<web-footer></web-footer>
	</div>
</template>
<script>
import WebHeader from '@/components/include/DefaultHeader'
import WebFooter from '@/components/include/DefaultFooter'

 export default {
	 name: 'App',
	 components:{WebHeader,WebFooter},
    data() {
      return {

      }
    },
    methods: {

    }
  }
</script>
