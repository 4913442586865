<template>
	<div class="security">
		<!-- 标题 -->
		<div class="pdding20 bgffffff overflow">
			<span class="bold font14">用户安全</span>
		</div>
		
		<div class="pdding20 bgffffff mgtop10">
			
			<div class="wf h50 lineh50">基本信息</div>
			<div class="wf font14">用户安全级别：
				<label v-if="pstrength<=3" class="bold" style="color: #ea544a">低级</label>
				<label v-if="pstrength<=8||pstrength>3" class="bold" style="color: #ff9966">中级</label>
				<label v-else class="bold" style="color: #009900">高级</label>
			</div>
			
			<div class="wf mgtop20" style="border-top: 1px dashed #d2d2d2;margin-bottom: 30px">
				<div class="wf h60 lineh60 mgtop10" style="border-bottom: 1px solid #d2d2d2;">
					<div class="fl w100 h60" style="background: url(../../../static/images/icons-white.png) no-repeat center 0px;"></div>
					<div class="fl w100">登陆密码</div>
					<div class="fl w500">互联网账号存在被盗风险，建议您定期更改密码以保护用户安全。</div>
					<div class="fr w100"><router-link to="/m/changepwd">修改</router-link></div>
				</div>
				<div class="wf h60 lineh60 mgtop10 mhide" style="border-bottom: 1px solid #d2d2d2;">
					<div class="fl w100 h60" style="background: url('../../../static/images/icons-white.png') no-repeat center 0px;"></div>
					<div class="fl w100">邮箱验证</div>
					<div class="fl w500">您的验证的邮箱：d****j@kmmdkj.com</div>
					<div class="fr w100"><a href="/mall/m/safety/validate/verifyMail">修改</a></div>
				</div>
				<div class="wf h60 lineh60 mgtop10 mhide" style="border-bottom: 1px solid #d2d2d2;">
					<div class="fl w100 h60" style="background: url('../../../static/images/icons-white.png') no-repeat center -60px;"></div>
					<div class="fl w100">手机验证</div>
					<div class="fl w500">未验证</div>
					<div class="fr w100"><a href="/mall/m/safety/validate/verifyMobile">修改</a></div>
				</div>
			</div>
			
		</div>
		
	</div>
</template>

<script>
	export default{
		data(){
			return {
				pstrength:4
			}
		}
	}
</script>

<style>
</style>
