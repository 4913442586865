<template>
	<div class="FindPwd bgffffff">
		<div class="wf" style="border-bottom: 1px solid #EDEDED;">
			<div class="w1200 h100 lineh100">
				<a href="/">
					<div class="logo fl" style="line-height: 50px;"><img src="../../assets/logo.png?v=1.0"
							style="height: 50px;margin-top: 25px;"></div>
					<div class="fl mgl10 font18 bold">{{$http.webConfig.simple}}</div>
				</a>
				<span class="fr">
					<router-link to="/uc/login">
						<el-button type="primary" round icon="el-icon-sort">登录</el-button>
					</router-link>
				</span>
			</div>
		</div>

		<div class="wf" style="margin-top: 100px;">
			<div class="w800 center-block ">
				<el-steps :active="curStep" align-center process-status="finish">
					<el-step title="填写用户名/手机号"></el-step>
					<el-step title="验证身份"></el-step>
					<el-step title="设置新密码"></el-step>
					<el-step title="完成"></el-step>
				</el-steps>

				<div class="wf mgtop40" style="min-height: 500px;">
					<div class="w300 center-block">
						<form v-if="curStep==0" ref="userForm" name="login_form_box" :model="userForm">
							<div class="h30 lineh30 center" id="emsg" style="color:#ea544a;"></div>
							<div class="wf">
								<el-input placeholder="用户名/手机号" v-model="userForm.account">
									<template slot="prepend"><i class="fa fa-user"></i></template>
								</el-input>
							</div>
							<div class="wf mgtop20">
								<el-input placeholder="验证码" v-model="userForm.code" style="float: left;width:180px;">
								</el-input>
								<img :src="captcha" @click="getCaptcha" class="center pointer"
									style="width: 100px;height: 40px;line-height: 40px;" />
							</div>
						</form>
						<form v-if="curStep==1" ref="userForm" name="login_form_box" :model="userForm">
							<div class="h30 lineh30 center" id="emsg" style="color:#ea544a;"></div>
							<div class="wf">
								<div class="h30 lineh30 font14">尊敬的用户：{{userForm.nickname}}</div>
								<div class="h30 lineh30 font14">手机号：{{userForm.phoneStr}}</div>
							</div>
							<div class="wf mgtop20">
								<el-input placeholder="验证码" v-model="userForm.ecode">
									<el-button slot="append" v-if="!showTime" @click="getSms">获取短信验证码</el-button>
									<el-button slot="append" v-if="showTime">{{time}}(s)</el-button>
								</el-input>
							</div>
						</form>
						<el-form v-if="curStep==2" ref="pwdForm" name="login_form_box" :model="userForm" :rules="rules">
							<div class="h30 lineh30 font14">尊敬的用户：{{userForm.nickname}} ，您正在设置新密码!</div>
							<div class="h30 lineh30 center" id="emsg" style="color:#ea544a;"></div>
							<el-form-item prop="newpwd">
								<el-input placeholder="新密码" v-model="userForm.newpwd">
									<template slot="prepend"><i class="fa fa-lock"></i></template>
								</el-input>
							</el-form-item>
							<el-form-item class="mgtop40" prop="pwdconfirm">
								<el-input placeholder="确认新密码" v-model="userForm.pwdconfirm">
									<template slot="prepend"><i class="fa fa-lock"></i></template>
								</el-input>
							</el-form-item>
						</el-form>

						<form v-if="curStep==3" name="login_form_box">
							<p class="center lineh100 font18" style="color: #44B134;">
								<i class="el-icon-success mgr10"></i>密码设置成功！</br>
								<a href="/uc/login">
									<el-button type="primary" plain icon="el-icon-arrow-left">5s中跳转到登录页面</el-button>
								</a>
							</p>

						</form>

						<div class="wf mgtop20" v-if="curStep<3">
							<el-button class="wf" type="primary" @click="next">下一步</el-button>
						</div>

					</div>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'FindPwd',
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'))
				} else if (value !== this.userForm.newpwd) {
					callback(new Error('两次输入密码不一致!'))
				} else {
					callback()
				}
			}
			return {
				curStep: 0,
				captcha: "",
				showTime: false,
				time: 60,
				loginForm: {},
				userForm: {},
				rules: {
					newpwd: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{6,20}$/,
							message: '密码为数字，小写字母，大写字母，特殊符号 至少包含三种，长度为 6 - 20位，密码不能包含 用户名，公司名称(lidian), 公司域名(rekoon) （判断的时候不区分大小写)'
						}
					],
					pwdconfirm: [{
						required: true,
						validator: validatePass,
						trigger: 'blur'
					}],
				}
			}
		},
		methods: {
			next() { //下一步
				if (this.curStep == 0) { //获取用户
					this.userForm.captchaId = this.loginForm.captchaId;
					this.$http.request(this, this.$apis.user.managerReceptCheck, this.userForm, true, (res) => {
						if (res.code == 0) {
							let data = res.data;
							this.curStep++;
							this.userForm.k = data.key;
							this.userForm.phone = data.phone;
							this.userForm.phoneStr = data.phoneStr;
							this.userForm.nickname = data.nickname;
						}
					});

				} else if (this.curStep == 1) { //验证用户
					this.$http.request(this, this.$apis.user.managerReceptVerify, this.userForm, true, res => {
						if (res.code == 0) {
							let data = res.data;
							this.curStep++;
							this.userForm.k = data;
						}
					});
				} else if (this.curStep == 2) { //修改密码
					this.$refs['pwdForm'].validate((valid) => {
						if (valid) {
							this.$http.request(this, this.$apis.user.managerReceptChangePwd, this.userForm, true, (
								res) => {
								if (res.code == 0) {
									let data = res.data;
									this.curStep++;
									this.userForm.k = "";
								}
							});
						} else {
							return false;
						}
					});

				}
			},
			getCaptcha() {
				this.$http.http_img_get(this.$apis.component.captchaImg + '?v=' + new Date().getTime(), res => {
					this.loginForm.captchaId = res;
				}, data => {
					this.captcha = data;
				});
			},
			getSms() {
				if (this.userForm.phone != undefined && this.userForm.phone.length == 11) {
					if (!this.showTime) {
						this.showTime = true;
						let myInterval = null;
						this.userForm.smsType = "dynamic";
						this.$http.request(this, this.$apis.component.sms, this.userForm, true, res => {
							var page = this;
							myInterval = setInterval(rs => {
								page.time--;
								if (page.time == 1) {
									clearInterval(myInterval);
									page.showTime = false;
									page.time = 60;
								}
							}, 1000)
						});
					}
				}
			}
		},
		mounted() {
			document.title = "找回密码-供深食品";
			this.getCaptcha();
		}
	}
</script>

<style>
</style>
