<template>
  <div class="security">
    <!-- 标题 -->
    <div class="pdding20 bgffffff overflow">
      <span class="bold font14">修改用户密码</span>
      <div class="warning lineh30">
        <p>
          1.用户修改密码需要获取短信验证码进行身份验证，这样可以规避不法分子通过密码修改来窃取您的用户。</br>
          2.定期修改密码可以降低用户安全风险。</br>
          3.请使用比较复杂的密码，切忌使用123，12345，abc这样的纯数字/纯字母密码
        </p>
      </div>
    </div>



    <div class="pdding20 bgffffff mgtop10">
      <div class="w600 center-block">
        <el-form :model="pwdFrom" ref="pwdFrom" label-width="100px" :rules="rules">
          <el-form-item label="新密码" class="mgtop30" prop="newpwd">
            <el-input type="password" class="w300" v-model="pwdFrom.newpwd" placeholder="输入新密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" class="mgtop30" prop="pwdconfirm">
            <el-input type="password" class="w300" v-model="pwdFrom.pwdconfirm" placeholder="确认新密码"></el-input>
          </el-form-item>
          <el-form-item label="验证码" class="mgtop30" prop="code">
            <el-input type="text" v-model="pwdFrom.code" placeholder="输入短信验证码" style="width:150px;"></el-input>
            <el-button style="width: 140px;margin-left: 10px;" type="success" v-if="!showTime" @click="getSms"><i class="fa fa-send mgr5"></i> 获取验证码</el-button>
            <el-button style="width: 140px;margin-left: 10px;" type="warning" v-if="showTime">{{time}}(s)</el-button>
          </el-form-item>
          <el-form-item class="wf mgtop40">
            <el-button type="primary" style="margin-left: 60px;" @click="changePwd"><i class="fa fa-save mgr5"></i> 确认修改密码</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 其他操作 -->
    <div class="pdding20 bgffffff mgtop10">
      <div class="bold font16">问答小贴士</div>
      <div style="padding-left: 60px;">
        <p class="wf mgtop20">
          <el-tag type="primary">Q</el-tag><span class="mgl10 bold">收不到短信验证码？</span>
        </p>
        <p class="mgtop10">
          <el-tag type="success">A</el-tag><span class="mgl10">如果您注册账号的手机号无法接受短信，您可以拨打人工客服找回密码。</span>
        </p>
        <p class="wf mgtop30">
          <el-tag type="primary">Q</el-tag><span class="mgl10 bold">密码修改完成，但是没有记住？</span>
        </p>
        <p class="mgtop10">
          <el-tag type="success">A</el-tag><span class="mgl10">建议您在修改密码的时候，记录下自己的密码。也可以通过登录页面的“忘记密码”操作，直接找回密码。</span>
        </p>
      </div>
    </div>
    <!-- 其他操作 end -->

    <!-- 密码修改完成 -->
    <el-dialog :close-on-click-modal="false" title="温馨提示" :visible.sync="dialogVisible" width="600px" :show-close="false"
      ::close-on-click-modal="false" :close-on-press-escape="false">
      <div class="wf h60">
        <p class="mgtop10" style="padding-left: 100px;">
          您的密码修改成功！为了您的用户安全，您需要在<span style="color:#ff9966;">{{ctime}}s</span>后重新登录。</br>
          <span @click="logout" style="color:#00b7ee">立即登录</span>
        </p>
        <img class="" width="60" src="/static/images/reg_success.png" style="position: absolute;left: 0;top:0" />
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'
  export default {
    data() {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.pwdFrom.newpwd) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        dialogVisible: false,
        pwdFrom: {},
        showTime: false,
        time: 60,
        ctime: 5,
        smsForm: {
          phone: '',
          smsType: 'changePwd'
        },
        rules: {
          newpwd: [{
              required: true,
              message: '请输入密码',
              trigger: 'blur'
            },
            {
              min: 6,
              message: '密码至少6位',
              trigger: 'blur'
            },
            {
              max: 16,
              message: '密码最长16位',
              trigger: 'blur'
            }
          ],
          pwdconfirm: [{
            required: true,
            validator: validatePass,
            trigger: 'blur'
          }],
          code: [{
            required: true,
            message: '请填写短信验证码',
            trigger: 'blur'
          }]
        }
      };
    },
    computed: {
      ...mapGetters([
        'principal'
      ])
    },
    mounted() {},
    methods: {
      logout() {
        this.$store.dispatch("logout");
        this.$cookie.delete("passport");
        window.location.href = "/uc/login"
      },
      changePwd() {
        let _that = this;
        this.$refs["pwdFrom"].validate((valid) => {
          if (valid) {
            _that.$http.request(this, this.$apis.user.managerChangePwd, this.pwdFrom, true, res => {
              console.log("会员密码修改成功！");
              _that.pwdFrom = {};
              _that.dialogVisible = true;
              _that.ctime = 6;
              let timeInterval = setInterval(rs => {
                _that.ctime--;
                if (_that.ctime == 0) {
                  clearInterval(timeInterval);
                  _that.showTime = false;
                  _that.logout()
                  _that.ctime = 5;
                }
              }, 1000)
            });
          } else {
            return false;
          }
        });
      },
      getSms() {
        if (!this.showTime) {
          this.showTime = true;
          this.smsForm.phone = this.principal.phone;
          let myInterval = null;
          this.$http.request(this, this.$apis.component.sms, this.smsForm, true, res => {
            var page = this;
            myInterval = setInterval(rs => {
              page.time--;
              if (page.time == 1) {
                clearInterval(myInterval);
                page.showTime = false;
                page.time = 60;
              }
            }, 1000)
          });
        }
      }
    },
    created() {

    }
  };
</script>
<style scoped>
  .memberContent .warning {
    padding: 8px 16px;
    background-color: #fff6f7;
    border-radius: 4px;
    border-left: 5px solid #fe6c6f;
    margin: 20px 0;
  }
</style>
