<template>
	<div class="showSystem">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>客户管理</el-breadcrumb-item>
				<el-breadcrumb-item>用户管理</el-breadcrumb-item>
				<el-breadcrumb-item>会员信息</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="pdding10 mgtop10 bgffffff">
			<el-row class="wf lineh40">
				<el-col :span="14">
					<el-col :span="12">会员昵称：{{member.nickname}}</el-col>
					<el-col :span="12">会员手机号：{{member.phone}}</el-col>
					<el-col :span="12">会员类型：
						<el-tag v-if="member.vip==0" type="success" size="small">普通会员</el-tag>
						<el-tag v-else type="success" size="small">vip</el-tag>
					</el-col>
					<el-col :span="12">会员有效期：{{member.vipStart}} - {{member.vipEnd}}</el-col>
					<el-col :span="12">优惠券数量：{{memberFund.coupon}}</el-col>
					<el-col :span="12">注册时间：{{member.subTime}}</el-col>
				</el-col>
				<el-col class="center" :span="10">
					<el-col :span="8">
						<div class="wf bold font30 mgtop30">{{memberFund.funds }}</div>
						<div class="wf">充值余额</div>
					</el-col>
					<el-col :span="8">
						<div class="wf bold font30 mgtop30">{{memberFund.give}}</div>
						<div class="wf">赠送余额</div>
					</el-col>
					<el-col :span="8">
						<div class="wf bold font30 mgtop30">{{memberFund.apply}}</div>
						<div class="wf">退款申请</div>
					</el-col>
					<el-col :span="24" class="center">
						<el-button @click="fundVisible=true" type="primary" size="small"><i class="fa fa-plus"></i> 修改余额
						</el-button>
					</el-col>
				</el-col>
			</el-row>
		</div>

		<el-tabs type="border-card" class="mgtop10">
			<el-tab-pane label="服务记录">
				<fragment-device-order :member="member.serial"></fragment-device-order>
			</el-tab-pane>
			<el-tab-pane label="优惠券">
				<fragment-coupon :member="member.serial"></fragment-coupon>
			</el-tab-pane>
			<el-tab-pane label="充值记录">
				<fragment-vip-order :member="member.serial"></fragment-vip-order>
			</el-tab-pane>
		</el-tabs>


		<!-- 账户余额设置 -->
		<el-dialog :close-on-click-modal="false" title="账户余额" :visible.sync="fundVisible" width="400px">
			<el-form class="wf" ref="memberFund" :model="memberFund" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="24">
						<el-form-item label="现金余额" prop="funds">
							<el-input v-model="memberFund.funds" placeholder="请填写现金余额">
								<span slot="append">元</span>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="赠送余额" prop="give">
							<el-input v-model="memberFund.give" placeholder="请填写赠送余额">
								<span slot="append">元</span>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="fundVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishFund">保存</el-button>
			</span>
		</el-dialog>
		<!-- 账户余额设置 end -->
	</div>
</template>

<script>
	import FragmentDeviceOrder from '@/components/fragment/member/FragmentDeviceOrder'
	import FragmentCoupon from '@/components/fragment/member/FragmentCoupon'
	import FragmentVipOrder from '@/components/fragment/member/FragmentVipOrder'


	export default {
		name: 'showSystem',
		components: {
			FragmentDeviceOrder,
			FragmentCoupon,
			FragmentVipOrder
		},
		data() {
			return {
				fundVisible: false,
				dialogVisible: false,
				orderVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				member: {
					integal: 0,
					sumCost: 0,
					recharg: 0
				},
				memberFund: {
					give: 0,
					funds: 0,
					coupon: 0
				},
				orderForm: {},
				orderList: []
			}
		},
		methods: {
			pageChange(page) {
				this.searchForm.page = page;
				this.getOrders();
			},
			getMember() {
				let url = this.$apis.user.memberShow.replace("{serial}", this.$route.params.memberId)
				this.$http.request(this, url, {}, false, (res) => {
					console.log("会员信息", res)
					this.member = res.data;
				});
			},
			getMemberFund() {
				let url = this.$apis.user.memberFund.replace("{serial}", this.$route.params.memberId)
				this.$http.request(this, url, {}, false, (res) => {
					console.log("会员信息", res)
					if (res.code == 0 && res.data != null) {
						let data = res.data;
						data.funds = parseFloat(data.funds * 0.01).toFixed(2);
						data.give = parseFloat(data.give * 0.01).toFixed(2);
						data.apply = parseFloat(data.apply * 0.01).toFixed(2);
						this.memberFund = data;
					}
				});
			},
			publishFund() {
				let url = this.$apis.user.memberFundModify.replace("{serial}", this.$route.params.memberId)
				let memberFund = Object.assign({}, this.memberFund);
				memberFund.funds = parseFloat(memberFund.funds * 100).toFixed(0);
				memberFund.give = parseFloat(memberFund.give * 100).toFixed(0);
				memberFund.apply = 0;
				this.$http.request(this, url, memberFund, true, (res) => {

				});
			}
		},
		created() {
			this.getMember();
			this.getMemberFund();
		}
	}
</script>

<style>
	.el-table__expanded-cell {
		background: #F7F7F7;
	}
</style>
