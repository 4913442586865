<template>
	<div id="memberModel">
		<router-view />
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import WebHeader from '@/components/include/WebHeader'
	import WebFooter from '@/components/include/WebFooter'
	import MyNav from '@/components/include/Nav'

	export default {
		name: 'App',
		components: {
			WebHeader,
			WebFooter,
			MyNav
		},
		data() {
			return {
				frameHeight: 0,
				editableTabsValue: '2',
				editableTabs: [{
					title: 'Tab 1',
					name: '1',
					content: 'Tab 1 content'
				}, {
					title: 'Tab 2',
					name: '2',
					content: 'Tab 2 content'
				}],
				tabIndex: 2
			}
		},
		methods: {
			removeTab(targetName) {
				let tabs = this.editableTabs;
				let activeName = this.editableTabsValue;
				if (activeName === targetName) {
					tabs.forEach((tab, index) => {
						if (tab.name === targetName) {
							let nextTab = tabs[index + 1] || tabs[index - 1];
							if (nextTab) {
								activeName = nextTab.name;
							}
						}
					});
				}

				this.editableTabsValue = activeName;
				this.editableTabs = tabs.filter(tab => tab.name !== targetName);
			}
		},
		created() {
			this.frameHeight = window.innerHeight - 160;
		},
		mounted() {
			document.title = "客户管理-" + this.$http.webConfig.simple;
			console.log("页面高度", window.innerHeight - 80);
		}
	}
</script>
<style>
	body,
	html,
	#app {
		width: 100%;
		height: 100%;
		;
	}
</style>
