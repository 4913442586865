<template>
	<div class="tools" v-if="showVisible">
		<div class="wf h60 lineh60 center white font16 bold" style="background: #4DC6BF;">区域绘制</div>
		<el-form class="pdding10 mgtop20" ref="areaForm" :model="areaForm" label-width="100px" :rules="rules">
			<el-form-item label="区域名称" prop="name">
				<el-input v-model="areaForm.name" placeholder="请填写区域名称" />
			</el-form-item>
			<el-form-item label="边框颜色" prop="config">
				<el-input v-model="areaForm.config.strokeColor" placeholder="请填写边框颜色" />
			</el-form-item>
			<el-form-item label="边框宽度" prop="config">
				<el-input v-model="areaForm.config.strokeWeight" placeholder="请填写边框宽度" />
			</el-form-item>
			<el-form-item label="边框透明度" prop="config">
				<el-input v-model="areaForm.config.strokeOpacity" placeholder="请填写边框透明度" />
			</el-form-item>
			<el-form-item label="填充色" prop="config">
				<el-input v-model="areaForm.config.fillColor" placeholder="请填写填充色" />
			</el-form-item>
			<el-form-item label="填充色透明度" prop="config">
				<el-input v-model="areaForm.config.fillOpacity" placeholder="请填写填充色透明度" />
			</el-form-item>

			<el-form-item label="备注" prop="remark">
				<el-input type="textarea" v-model="areaForm.remark" rows="5" placeholder="请填写备注"></el-input>
			</el-form-item>


		</el-form>
		<div class="wf center">
			<el-button @click="publish" type="primary" size="small"><i class="fa fa-edit"></i>保存</el-button>
			<el-button v-if="areaForm.serial" @click="remove" type="danger" size="small"><i class="fa fa-remove"></i>删除
			</el-button>
			<el-button @click="showVisible=false" type="info" size="small"><i class="fa fa-remove"></i>取消</el-button>
		</div>
		<div class="wf h100"></div>
	</div>
</template>

<script>
	export default {
		props: ["map"],
		data() {
			return {
				previewImageUrl: '',
				previewVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				picList: [],
				loading: false,
				showVisible: false,
				areaForm: {
					path: [],
					config: {}
				},
				rules: {
					name: [{
						required: true,
						message: '请填写覆盖物名称',
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handlePictureCardPreview(file) {
				this.previewImageUrl = file.url;
				this.previewVisible = true;
			},
			picChange(file, fileList) {
				console.log("文件上传", fileList)
				this.picList = fileList;
			},
			picDone(res, file) {
				console.log("文件上传完成", file)
				if (file.response.files.length > 0)
					this.sceneForm.pic = file.response.files[0].uploadUrl;
			},
			publish() {
				this.$refs['areaForm'].validate((valid) => {
					if (valid) {
						let areaForm = Object.assign({}, this.areaForm);
						areaForm.config = JSON.stringify(areaForm.config);
						areaForm.path = JSON.stringify(areaForm.path);
						areaForm.project = this.$route.params.orgId;
						var picList = [];
						this.picList.filter(file => {
							if (file.status == "success" && file.response.files.length > 0) {
								let doc = file.response.files[0];
								picList.push({
									uid: file.uid,
									name: doc.orianName,
									url: doc.uploadUrl
								})
							}
						});
						areaForm.pics = JSON.stringify(picList);

						this.$http.request(this, this.$apis.user.overlayPublish, areaForm, true, (res) => {
							if (res.code == 0) {
								if (res.data != null) {
									areaForm.serial = res.data.serial;
									areaForm.pics = this.picList;
									areaForm.path = JSON.parse(areaForm.path);
									areaForm.config = JSON.parse(areaForm.config);
									this.$emit("polygonChange", areaForm);
								}
							}
						});
						this.showVisible = false;
					}
				});
			},
			remove() {
				var areaForm = this.areaForm;
				this.$confirm('此操作将删除[' + areaForm.name + ']的区域, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.user.overlayRemove.replace("{serial}", areaForm.serial);
					this.$http.request(this, url, {}, true, res => {
						this.showVisible = false;
						this.$emit("polygonRemove", areaForm.serial);
					});
				});
			}
		},
	}
</script>

<style>
	.tools {
		position: fixed;
		right: 0;
		top: 0;
		width: 360px;
		z-index: 9;
		height: 100%;
		background: #FFFFFF;
		overflow-y: scroll;
	}
</style>
