<template>
	<div class="fragementBase">
		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">客户管理</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">专家管理</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->
		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form class="wf" ref="searchForm" :model="searchForm" :inline="true">
					<el-form-item label="专家姓名">
						<el-input v-model="searchForm.name" size="small" placeholder="请填写专家姓名" />
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="doSearch"><i class="fa fa-search"></i>查询
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>


		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<el-button type="danger" size="small" @click="batchRemove"><i class="fa fa-trash"></i> 批量删除</el-button>
				<el-button type="success" size="small" @click="newDoctor"><i class="fa fa-plus"></i>新增专家</el-button>
				<el-button type="success" size="small"><i class="fa fa-download"></i> 批量导出</el-button>
			</div>


			<el-table :data="doctorList" :stripe="true" border class="mgtop10"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="userName" label="专家信息" width="400">
					<template slot-scope="scope">
						<el-row>
							<el-col span="8">
								<img class="border radius5" :src="scope.row.avater" width="100px" height="100px" />
							</el-col>
							<el-col span="16">
								<div class="wf">姓名：{{scope.row.name}}
									<el-tag type="primary" size="small">{{scope.row.gender}}</el-tag>
								</div>
								<div class="wf">手机号：{{scope.row.phone}}</div>
								<div class="wf">身份证号：{{scope.row.idcard}}</div>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
				<el-table-column prop="area" label="所在科室"></el-table-column>
				<el-table-column prop="jobTitle" label="职称"></el-table-column>
				<el-table-column prop="unitName" label="所在单位"></el-table-column>
				<el-table-column prop="updateTime" label="更新日期" width="160"></el-table-column>
				<el-table-column align="center" label="操作" width="200">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="12">
								<el-button style="width: 100%;" type="primary" size="small"
									@click="showDoctor(scope.$index)"><i class="fa fa-edit"></i>编辑</el-button>
							</el-col>
							<el-col v-if="principal.level==3" :span="12">
								<el-button style="width: 100%;" type="danger" size="small"
									@click="removeDoctor(scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
							</el-col>
							<el-col span="24" class="mgtop10">
								<el-switch v-model="scope.row.verify" @change="switchState" active-text="已审核"
									inactive-text="待审核" :active-value="scope.$index+'-1'"
									:inactive-value="scope.$index+'-0'">
								</el-switch>
							</el-col>
						</el-row>

					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="doctorList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="专家信息" :visible.sync="dialogVisible" width="1000px">
			<el-form class="wf" ref="doctorForm" :model="doctorForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="12">
						<el-form-item label="专家图像" prop="avater">
							<el-upload class="avatar-uploader" name="mdFile" :action="imgUploadUrl"
								:headers="uploadHeaders" :show-file-list="false" :on-success="handleCoverSuccess"
								:before-upload="beforePicUpload">
								<img v-if="doctorForm.avater" :src="doctorForm.avater" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<div class="wf lineh20"> <i class="el-icon-star-off"></i>建议使用800*800正方形图片，支持JPG、PNG格式，最大1MB
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="专家姓名" prop="name">
							<el-input v-model="doctorForm.name" placeholder="请填写专家姓名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="性别" prop="gender">
							<el-select v-model="doctorForm.gender" placeholder="请选择专家性别" style="width:100%;">
								<el-option value="男"></el-option>
								<el-option value="女"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="手机号" prop="phone">
							<el-input v-model.number="doctorForm.phone" placeholder="请填写专家手机号"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="身份证号" prop="idcard">
							<el-input v-model.number="doctorForm.idcard" placeholder="请填写专家身份证号"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="所在单位" prop="unit">
							<div class="wf h40">
								<el-select v-model="doctorForm.unit" filterable remote reserve-keyword
									@change="unitChange" placeholder="请填写所在单位" :remote-method="showUnit"
									:loading="loading" style="width: 100%;">
									<el-option v-for="item in unitList" :value="item.serial" :label="item.name"
										:key="item"></el-option>
								</el-select>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="职称" prop="jobTitle">
							<div class="wf h40">
								<el-select v-model="doctorForm.jobTitle" placeholder="请选择专家职称" style="width:100%;">
									<el-option v-for="item in jobTitleList" :key="item" :label="item.name"
										:value="item.name">
									</el-option>
								</el-select>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="科室" prop="area">
							<div class="wf h40">
								<el-select v-model="doctorForm.area" placeholder="请选择专家所在科室" style="width:100%;">
									<el-option v-for="item in areaList" :key="item" :label="item.name"
										:value="item.name">
									</el-option>
								</el-select>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="出诊时间" prop="worktime">
							<div class="wf h40">
								<el-date-picker v-model="doctorForm.worktime" type="datetimerange" placeholder="选择日期时间"
									start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm"
									style="width: 100%;">
								</el-date-picker>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="擅长领域" prop="summary">
							<el-input type="textarea" :rows="5" v-model="doctorForm.summary" placeholder="具体到优势疾病的诊治">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="专业经历" prop="experience">
							<el-input type="textarea" :rows="5" v-model="doctorForm.experience" placeholder="请填写专家专业经历">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="职业证书" prop="experience">
							<el-upload class="upload-demo" name="mdFile" list-type="picture-card" :action="imgUploadUrl"
								:headers="uploadHeaders" :on-change="picChange" :on-remove="picChange"
								:on-preview="handlePictureCardPreview" :file-list="picList"
								:before-upload="beforePicUpload">
								<i class="el-icon-plus"></i>
								<div slot="tip" class="el-upload__tip">只能上传JPG、PNG、JPEG文件，且不超过1M</div>
							</el-upload>
							<el-dialog :close-on-click-modal="false" :visible.sync="previewVisible">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</el-form-item>
					</el-col>

				</el-row>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishDoctor">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				loading: false,
				previewImageUrl: '',
				previewVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				picList: [],
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				doctorForm: {},
				doctorList: [],
				unitList: [],
				jobTitleList: [],
				positionList: [],
				areaList: [],
				multipleSelection: [],
				rules: {
					name: [{
							required: true,
							message: '请填写专家姓名',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					gender: [{
						required: true,
						message: '请选择专家性别',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请填写专家电话',
						trigger: 'blur'
					}],
					unit: [{
						required: true,
						message: '请填写专家所在单位',
						trigger: 'blur'
					}],
					jobTitle: [{
						required: true,
						message: '请填写专家职称 ',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '请填写专家所在领域 ',
						trigger: 'blur'
					}],
					avater: [{
						required: true,
						message: '请填写专家图像',
						trigger: 'blur'
					}],
					jobTitle: [{
						required: true,
						message: '请填写专家职称',
						trigger: 'blur'
					}],
					summary: [{
						required: true,
						message: '请填写专家擅长领域',
						trigger: 'blur'
					}],
					experience: [{
						required: true,
						message: '请填写专家专业经历',
						trigger: 'blur'
					}],
					position: [{
						required: true,
						message: '请填写专家职位',
						trigger: 'blur'
					}],
					proportion: [{
						required: true,
						message: '请填写分成比例',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '比例为数字，例如：12.00'
					}],
					mprice: [{
						required: true,
						message: '请填写问诊单价',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '问诊单价为数字，例如：12.00'
					}],
				},
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handleCoverSuccess(res, file) {
				console.log("文件上传", res)
				if (res.files.length > 0)
					this.doctorForm.avater = res.files[0].uploadUrl;
			},
			handlePictureCardPreview(file) {
				this.previewImageUrl = file.url;
				this.previewVisible = true;
			},
			picChange(file, fileList) {
				console.log("文件上传", fileList)
				this.picList = fileList;
			},
			doSearch() {
				this.pageChange(1);
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getDoctors();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getDoctors();
			},
			newDoctor() {
				this.doctorForm = {
					avater: ""
				};
				this.dialogVisible = true;
			},
			getDoctors() {
				this.$http.request(this, this.$apis.user.doctorList, this.searchForm, false, res => {
					this.searchForm.total = res.count;
					res.data.forEach((item, index) => {
						item.verify = index + "-" + item.verify;
						item.worktime = JSON.parse(item.worktime);
					})
					this.doctorList = res.data;
					console.log(res.data);
				});
			},
			publishDoctor() {
				this.$refs['doctorForm'].validate((valid) => {
					if (valid) {
						var doctorForm = Object.assign({}, this.doctorForm);
						doctorForm.updateTime = "";
						doctorForm.worktime = JSON.stringify(doctorForm.worktime);
						doctorForm.verify = 0;
						var picList = [];
						this.picList.filter(file => {
							if (file.status == "success" && file.response.files.length > 0) {
								let doc = file.response.files[0];
								picList.push({
									uid: file.uid,
									name: doc.orianName,
									url: doc.uploadUrl
								})
							}
						});
						doctorForm.certificate = JSON.stringify(picList);
						this.$http.request(this, this.$apis.user.doctorPublish, doctorForm, true, res => {
							let data = res.data;
							if (data != null) {
								this.doctorList.unshift(data);
							}

							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			showDoctor(index) {
				var doctorForm = this.doctorList[index];
				this.showUnit(doctorForm.unitName)
				this.picList = JSON.parse(doctorForm.certificate);
				if (this.picList != null)
					this.picList.filter(pic => {
						pic.status = "success";
						pic.response = {
							files: [{
								orianName: pic.name,
								uploadUrl: pic.url
							}]
						}
					});

				this.doctorForm = doctorForm;
				this.dialogVisible = true;

			},
			removeDoctor(index) {
				var doctor = this.doctorList[index];
				this.$confirm('此操作将永久删除[' + doctor.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.user.doctorRemove.replace("{serial}", doctor.serial);
					this.$http.request(this, url, {}, true, res => {
						this.doctorList.splice(index, 1);
					});
				});
			},
			switchState(e) {
				var index = e.split("-")[0];
				var doctor = this.doctorList[index];
				let url = this.$apis.user.doctorVerify.replace("{serial}", doctor.serial)
				this.$http.request(this, url, {}, true, (res) => {});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的专家 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.user.doctorBatchRemove, {
						serials: serials
					}, true, res => {
						this.doctorList = this.doctorList.filter(doctor => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == doctor.serial) {
									flag = false;
								}
							})
							return flag;
						});
					});
				});
			},
			showUnit(query) {
				this.$http.request(this, this.$apis.user.orgList, {
					type: 0,
					name: query
				}, false, res => {
					console.log("获取单位数据成功！");
					if (res.code == 0) {
						this.unitList = res.data;
					}
				});
			},
			unitChange() {
				this.unitList.forEach(item => {
					if (item.serial == this.doctorForm.unit) {
						this.doctorForm.unitName = item.name;
					}
				})
			},
			showDictionary() {
				this.$http.request(this, this.$apis.tools.dictionaryList, {}, false, res => {
					console.log("获取数据字典");
					if (res.code == 0) {
						this.jobTitleList = res.data["zhicheng"];
						this.positionList = res.data["zhiwei"];
						this.areaList = res.data["zhuangjiakeshi"]
					}
				});
			}
		},
		created() {
			this.showUnit("");
			this.showDictionary();
			this.getDoctors();
		}
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
	}

	.avatar {
		width: 148px;
		height: 148px;
		display: block;
	}
</style>
