/**
 * 接口文档列表
 */
import http from '../index';

const baseToolsURL = http.baseURL + '/tools';

export default {
	bannerCenterTotalData: "GET:" + baseToolsURL + '/v1/api/manage/data/banner',
	bannerTopIncomeData: "GET:" + baseToolsURL + '/v1/api/manage/data/banner/income',

	appVersionList: "GET:" + baseToolsURL + '/v1/api/manage/app/version/list',
	appVersionPublish: "POST:" + baseToolsURL + '/v1/api/manage/app/version/publish',
	appVersionRemove: "GET:" + baseToolsURL + '/v1/api/manage/app/version/{serial}/remove',

	partnerList: "GET:" + baseToolsURL + '/v1/api/manage/partner/list',
	partnerPublish: "POST:" + baseToolsURL + '/v1/api/manage/partner/publish',
	partnerRemove: "GET:" + baseToolsURL + '/v1/api/manage/partner/{serial}/remove',

	feedbackList: "GET:" + baseToolsURL + '/v1/api/manage/feedback/list',
	feedbackPublish: "POST:" + baseToolsURL + '/v1/api/manage/feedback/publish',
	feedbackRemove: "GET:" + baseToolsURL + '/v1/api/manage/feedback/{serial}/remove',

	launchList: "GET:" + baseToolsURL + '/v1/api/manage/launch/list',
	launchPublish: "POST:" + baseToolsURL + '/v1/api/manage/launch/publish',
	launchRemove: "GET:" + baseToolsURL + '/v1/api/manage/launch/{serial}/remove',

	bannerList: "GET:" + baseToolsURL + '/v1/api/manage/banner/list',
	bannerPublish: "POST:" + baseToolsURL + '/v1/api/manage/banner/publish',
	bannerRemove: "GET:" + baseToolsURL + '/v1/api/manage/banner/{serial}/remove',

	appConfigShow: "GET:" + baseToolsURL + '/v1/api/manage/app/config/show',
	appConfigPublish: "POST:" + baseToolsURL + '/v1/api/manage/app/config/publish',

	achromatopsiaList: "GET:" + baseToolsURL + '/v1/api/manage/achromatopsia/list',
	achromatopsiaPublish: "POST:" + baseToolsURL + '/v1/api/manage/achromatopsia/publish',
	achromatopsiaRemove: "GET:" + baseToolsURL + '/v1/api/manage/achromatopsia/{serial}/remove',

	archivesList: "GET:" + baseToolsURL + '/v1/api/manage/archives/list',
	archivesPublish: "POST:" + baseToolsURL + '/v1/api/manage/archives/publish',
	archivesRemove: "GET:" + baseToolsURL + '/v1/api/manage/archives/{serial}/remove',

	nursingList: "GET:" + baseToolsURL + '/v1/api/manage/nursing/list',
	nursingExport: "GET:" + baseToolsURL + '/v1/api/manage/nursing/batch/export',
	nursingPublish: "POST:" + baseToolsURL + '/v1/api/manage/nursing/publish',
	nursingRemove: "GET:" + baseToolsURL + '/v1/api/manage/nursing/{serial}/remove',
	nursingVisit: "POST:" + baseToolsURL + '/v1/api/manage/nursing/{serial}/visit',

	patientList: "GET:" + baseToolsURL + '/v1/api/manage/patient/list',
	patientPublish: "POST:" + baseToolsURL + '/v1/api/manage/patient/publish',
	patientRemove: "GET:" + baseToolsURL + '/v1/api/manage/patient/{serial}/remove',
	patientLabel: "POST:" + baseToolsURL + '/v1/api/manage/patient/{serial}/label',
	patientExport: "GET:" + baseToolsURL + '/v1/api/manage/patient/batch/export',
	patientImport: "POST:" + baseToolsURL + '/v1/api/manage/patient/batch/import',
	patientInvite: "POST:" + baseToolsURL + '/v1/api/manage/patient/invite',

	elementList: "GET:" + baseToolsURL + '/v1/api/manage/element/list',
	elementPublish: "POST:" + baseToolsURL + '/v1/api/manage/element/publish',
	elementRemove: "GET:" + baseToolsURL + '/v1/api/manage/element/{serial}/remove',

	elementValueList: "GET:" + baseToolsURL + '/v1/api/manage/element/value/list',

	achromatopsiaList: "GET:" + baseToolsURL + '/v1/api/manage/achromatopsia/list',
	achromatopsiaPublish: "POST:" + baseToolsURL + '/v1/api/manage/achromatopsia/publish',
	achromatopsiaRemove: "GET:" + baseToolsURL + '/v1/api/manage/achromatopsia/{serial}/remove',

	applyExamData: "GET:" + baseToolsURL + '/v1/api/manage/exam/order/data/total',
	applyExamList: "GET:" + baseToolsURL + '/v1/api/manage/exam/order/list',
	applyExamDone: "POST:" + baseToolsURL + '/v1/api/manage/exam/order/{serial}/done',
	applyExamCancel: "GET:" + baseToolsURL + '/v1/api/manage/exam/order/{serial}/cancel',
	applyExamSend: "POST:" + baseToolsURL + '/v1/api/manage/exam/order/{serial}/send/task',
	applyExamPublish: "POST:" + baseToolsURL + '/v1/api/manage/exam/order/publish',
	applyExamExport: "GET:" + baseToolsURL + '/v1/api/manage/exam/order/batch/export',

	examList: "GET:" + baseToolsURL + '/v1/api/manage/exam/list',
	examPublish: "POST:" + baseToolsURL + '/v1/api/manage/exam/publish',
	examRemove: "GET:" + baseToolsURL + '/v1/api/manage/exam/{serial}/remove',

	visionExamList: "GET:" + baseToolsURL + '/v1/api/manage/vision/exam/list',
	visionExamPublish: "POST:" + baseToolsURL + '/v1/api/manage/vision/exam/publish',
	visionExamRemove: "GET:" + baseToolsURL + '/v1/api/manage/vision/exam/{serial}/remove',

	nursingList: "GET:" + baseToolsURL + '/v1/api/manage/nursing/list',
	nursingPublish: "POST:" + baseToolsURL + '/v1/api/manage/nursing/publish',
	nursingRemove: "GET:" + baseToolsURL + '/v1/api/manage/nursing/{serial}/remove',

	patientList: "GET:" + baseToolsURL + '/v1/api/manage/patient/list',
	patientPublish: "POST:" + baseToolsURL + '/v1/api/manage/patient/publish',
	patientRemove: "GET:" + baseToolsURL + '/v1/api/manage/patient/{serial}/remove',

	archivesList: "GET:" + baseToolsURL + '/v1/api/manage/archives/list',
	archivesPublish: "POST:" + baseToolsURL + '/v1/api/manage/archives/publish',
	archivesRemove: "GET:" + baseToolsURL + '/v1/api/manage/archives/{serial}/remove',

	dictionaryTypeList: "GET:" + baseToolsURL + '/v1/api/dictionary/type/list',
	dictionaryTypeCacheAllList: "GET:" + baseToolsURL + '/v1/api/dictionary/type/list/all/show',
	dictionaryTypeCacheList: "GET:" + baseToolsURL + '/v1/api/dictionary/type/list/show',
	dictionaryTypePublish: "POST:" + baseToolsURL + '/v1/api/dictionary/type/publish',
	dictionaryTypeRemove: "GET:" + baseToolsURL + '/v1/api/dictionary/type/remove/{serial}',
	dictionaryClassificationList: "GET:" + baseToolsURL + '/v1/api/dictionary/sort/list',
	dictionaryClassificationCacheList: "GET:" + baseToolsURL + '/v1/api/dictionary/sort/list',
	dictionaryClassificationPublish: "POST:" + baseToolsURL + '/v1/api/dictionary/sort/publish',
	dictionaryClassificationRemove: "GET:" + baseToolsURL + '/v1/api/dictionary/sort/remove/{serial}',

	dictionaryList: "GET:" + baseToolsURL + '/v1/api/customer/dictionary/types',

	dataPatientTotal: "GET:" + baseToolsURL + '/v1/api/manage/data/patient/total',
	dataExpertPatientTotal: "GET:" + baseToolsURL + '/v1/api/manage/data/expert/patient/total',
	dataPatientOrigin: "GET:" + baseToolsURL + '/v1/api/manage/data/patient/origin',
	dataPatientAge: "GET:" + baseToolsURL + '/v1/api/manage/data/patient/age',
	dataPatientType: "GET:" + baseToolsURL + '/v1/api/manage/data/patient/type',
	dataPatientEthnic: "GET:" + baseToolsURL + '/v1/api/manage/data/patient/ethnic',
	dataPatientEducation: "GET:" + baseToolsURL + '/v1/api/manage/data/patient/education',
	dataPatientGender: "GET:" + baseToolsURL + '/v1/api/manage/data/patient/gender',
	dataPatientCity: "GET:" + baseToolsURL + '/v1/api/manage/data/patient/city',
	dataPatientProvince: "GET:" + baseToolsURL + '/v1/api/manage/data/patient/province',
	dataPatientElement: "GET:" + baseToolsURL + '/v1/api/manage/data/patient/element',
	dataPatientNursingMonth: "GET:" + baseToolsURL + '/v1/api/manage/data/nursing/month/data',
	dataPatientElementValue: "GET:" + baseToolsURL + '/v1/api/manage/data/element/value',

	dataPatientDisease: "GET:" + baseToolsURL + '/v1/api/manage/data/disease/data',
	dataPatientAgeDisease: "GET:" + baseToolsURL + '/v1/api/manage/data/disease/age/data',

	footprintList: "GET:" + baseToolsURL + '/v1/api/manage/footprint/list',
	footprintGroup: "GET:" + baseToolsURL + '/v1/api/manage/footprint/group',
}
