
<template>
	<div class="DefaultFooter" style="position: fixed;bottom: 0;left: 0;width: 100%;">
		<div id="footer" class=" wf center">

				<div class="w1200">
					<p class="font14 lineh40 pdding10">
					Copyright &copy; {{this.$http.webConfig.curYear}} {{this.$http.webConfig.copyright}}  网站备案号 {{this.$http.webConfig.recordNumber}}
					</p>
          <a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="/static/images/wa.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">滇公网安备 53010202001064号</p ></a >
          
				</div>

		</div>
	</div>

</template>

<script>
	export default{
		 name: 'DefaultFooter',
	}
</script>

<style scoped="scoped">
.fas>li{
	padding: 0 10px;
}

</style>
