<template>
	<div class="Login " style="background: #FFFFFF;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">

		<div class="wf">
			<div class="w1200 h60 mgtop20">
				<img class="fl" style="height: 100%;" src="/static/images/common/logo.png" />
				<span class="font24 bold lineh60">{{$http.webConfig.simple}}</span>
				<div class="fr">
					<ul>
						<li class="fl lineh60">
							<a href="/uc/reg">
								<el-button type="primary" round icon="el-icon-user">专家申请</el-button>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<el-dialog title="微信小程序" :visible.sync="appletVisible" width="340px">
			<img class="center-block" style="height: 200px;width: 200px;"
				src="/static/images/gh_ba27c58699f9_430.jpg" />
			<div class="center color999 mgtop20">微信扫一扫，关注“爱眼一生”小程序</div>
		</el-dialog>


		<div class="wf" style="height: 548px;min-height: 548px;">

			<div class="w1200">
				<div class="w360 center-block bgffffff radius15 border"
					style="box-shadow: 0 0 10px #BBBBBB;position: fixed;top: 50%;left: 50%;margin-left: -180px;margin-top: -200px;">
					<div class="pdding20 overflow" style="padding: 20px 40px;">
						<div class="loginBox" style="width: 280px;">
							<div class="wf font16 lineh40 bold center">管理员登录</div>
							<div class="wf center color9a font14 mgbtm10 mgtop10">
								<span :class="'mgr30 pointer '+(dynamicLogin==false?'active':'')"
									@click="dynamicLogin=false">密码登录</span>
								<span class="mgr30" style="color: #DEDEDE;">|</span>
								<span :class="'pointer '+(dynamicLogin==true?'active':'')"
									@click="dynamicLogin=true">短信登录</span>
							</div>
							<el-form v-if="!dynamicLogin" ref="loginForm" name="login_form_box" :model="loginForm">
								<el-form-item class="wf">
									<el-input placeholder="用户名/已验证手机号" v-model="loginForm.js_account"
										@keyup.enter.native="subLogin">
										<template slot="prepend"><i class="fa fa-user"></i></template>
									</el-input>
								</el-form-item>
								<el-form-item class="wf mgtop20">
									<el-input placeholder="密码" v-model="loginForm.js_password" type="password"
										@keyup.enter.native="subLogin">
										<template slot="prepend"><i class="fa fa-lock"></i></template>
									</el-input>
								</el-form-item>

								<el-form-item class="wf mgtop20 lastMg">
									<el-input placeholder="验证码" v-model="loginForm.code"
										style="float: left;width:180px;" @keyup.enter.native="subLogin"></el-input>
									<img :src="captcha" @click="getCaptcha" class="center pointer"
										style="width: 100px;height: 40px;line-height: 40px;background: #F7F7F7;" />
								</el-form-item>

								<p class="login_tip" style="line-height: 30px;">
									<span class="fl">
										<el-checkbox v-model="autoLogin">自动登录</el-checkbox>
									</span>
									<span class="fr">
										<a href="/uc/findpwd">忘记密码？</a>
									</span>
								</p>

								<div class="wf mgtop20">
									<div class="subtn"
										style="background: #4DC6BF;height: 40px;line-height: 40px;font-size:14px;"
										@click="subLogin">登
										录</div>
								</div>

							</el-form>
							<el-form v-if="dynamicLogin" ref="dynamicLoginForm" name="login_form_box"
								:model="loginForm">
								<el-form-item class="wf">
									<el-input placeholder="已验证手机号" v-model="dynamicLoginForm.js_account"
										@keyup.enter.native="subDynamicLogin">
										<template slot="prepend"><i class="fa fa-phone"></i></template>
									</el-input>
								</el-form-item>
								<el-form-item class="wf mgtop20 lastMg">
									<el-input placeholder="验证码" v-model="dynamicLoginForm.js_password"
										@keyup.enter.native="subDynamicLogin">
										<el-button slot="append" v-if="!showTime" @click="getSms">获取短信验证码</el-button>
										<el-button slot="append" v-if="showTime">{{time}}(s)</el-button>
									</el-input>
								</el-form-item>

								<p class="lineh20 mgtop10 font12">
									提示： 未注册{{this.$http.webConfig.simple}}账号的手机号，登录时将自动注册，且代表您已同意<a
										href="/protocol/reg_protocol.html" style="color: #00b7ee;;">《网站注册协议》</a>
								</p>

								<div class="wf mgtop20">
									<div class="subtn"
										style="background: #4DC6BF; height: 40px;line-height: 40px;font-size:14px;"
										@click="subDynamicLogin">登 录</div>
								</div>

							</el-form>
						</div>

					</div>
					<div class="pdding20auto h40">
						<div class="fr pointer lineh30" @click="appletVisible=!appletVisible">
							<span class="fl">小程序：</span>
							<img class="fl" style="height: 30px;width: 30px;"
								src="/static/images/gh_ba27c58699f9_430.jpg" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Login',
		data() {
			return {
				appletVisible: false,
				random: "",
				captcha: "",
				autoLogin: false,
				dynamicLogin: false,
				showTime: false,
				time: 60,
				loginForm: {
					loginType: "manager",
					js_account: "",
					js_password: "",
					code: "",
					captchaId: "",
					autoLogin: false,

				},
				dynamicLoginForm: {
					loginType: "manager",
					js_account: "",
					code: "",
					dynamic: true
				},
			}
		},
		methods: {
			getRandomKey() {
				this.$http.request(this, this.$apis.sso.showLoginKey + '?v=' + new Date().getTime(), {}, false, res => {
					this.random = res.data;
				});
			},
			getCaptcha() {
				this.getRandomKey();
				this.$http.http_img_get(this.$apis.component.captchaImg + '?v=' + new Date().getTime(), res => {
					this.loginForm.captchaId = res;
				}, data => {
					this.captcha = data;
				});
			},
			refreshToken(userData) { //刷新token
				this.$http.request(this, this.$apis.sso.refreshToken, {
					refeshToken: userData.refresh_token
				}, false, data => {
					console.log("处理刷新", data)
					userData.access_token = data.access_token;
					userData.refresh_token = data.refresh_token;
					this.$store.dispatch("setUser", userData);
					let passportData = this.$utils.encrypt(JSON.stringify(userData));
					window.localStorage.setItem("autoPassport", passportData);
					this.$cookie.set('passport', passportData, {
						expires: 1000000,
						domain: this.$http.webUrls.domain,
						path: "/"
					});
					let returnUrl = this.$cookie.get("return_url");

					if (returnUrl != null && returnUrl.indexOf(this.$http.webUrls.pay) > -1) {
						window.location.href = "/m/dashbord";
					} else {
						this.$store.dispatch("selectMenu", 0);
						window.location.href = returnUrl == null ? "/m/dashbord" : returnUrl;
					}
					this.$cookie.delete("return_url");
				});
			},
			subLogin() { //登录
				var loginForm = Object.assign({}, this.loginForm);
				loginForm.js_login = this.$utils.encryptLogin(JSON.stringify(loginForm), this.random);
				loginForm.random = this.random;
				loginForm.js_account = "";
				loginForm.js_password = "";

				this.$http.request(this, this.$apis.sso.postLogin, loginForm, true, res => {
					this.getCaptcha();

					if (res.code == 1)
						return;

					var data = res.data;
					console.log("什么鬼", data)
					this.$store.dispatch("setUser", data);
					window.localStorage.setItem("autoLogin", this.autoLogin);
					let passportData = this.$utils.encrypt(JSON.stringify(data));
					window.localStorage.setItem("autoPassport", passportData);
					this.$cookie.set('passport', passportData, {
						expires: 1000000,
						domain: this.$http.webConfig.webUrls.domain,
						path: "/"
					});
					let returnUrl = this.$cookie.get("return_url");

					if (returnUrl != null) {
						window.location.href = "/m/dashbord";
					} else {
						this.$store.dispatch("selectMenu", 0);
						window.location.href = returnUrl == null ? "/m/dashbord" : returnUrl;
					}

					this.$cookie.delete("return_url");
				});

			},
			subDynamicLogin() { //动态密码登录
				this.dynamicLoginForm.phone = this.dynamicLoginForm.js_account;
				if (this.dynamicLoginForm.phone != undefined && this.dynamicLoginForm.phone.length == 11) {
					var dynamicLoginForm = Object.assign({}, this.dynamicLoginForm);
					dynamicLoginForm.js_login = this.$utils.encryptLogin(JSON.stringify(dynamicLoginForm), this.random);
					dynamicLoginForm.random = this.random;
					dynamicLoginForm.js_account = "";
					dynamicLoginForm.js_password = "";

					this.$http.request(this, this.$apis.sso.postLogin, dynamicLoginForm, true, res => {
						let data = res.data;
						this.$store.dispatch("setUser", data);
						let passportData = this.$utils.encrypt(JSON.stringify(data));
						this.$cookie.set('passport', passportData, {
							expires: 1000000,
							domain: this.$http.webConfig.webUrls.domain,
							path: "/"
						});
						let returnUrl = this.$cookie.get("return_url");

						if (returnUrl != null) {
							window.location.href = "/m/dashbord";

						} else {
							this.$store.dispatch("selectMenu", 0);
							window.location.href = returnUrl == null ? "/m/dashbord" : returnUrl;
						}

						this.$cookie.delete("return_url");
					});
				}
			},
			getSms() {
				this.getRandomKey();
				this.dynamicLoginForm.phone = this.dynamicLoginForm.js_account;
				if (this.dynamicLoginForm.phone != undefined && this.dynamicLoginForm.phone.length == 11) {
					if (!this.showTime) {
						this.showTime = true;
						let myInterval = null;
						this.dynamicLoginForm.smsType = "dynamic";
						this.$http.request(this, this.$apis.component.sms, this.dynamicLoginForm, true, res => {
							var page = this;
							myInterval = setInterval(rs => {
								page.time--;
								if (page.time == 1) {
									clearInterval(myInterval);
									page.showTime = false;
									page.time = 60;
								}
							}, 1000)
						});
					}
				}
			}
		},
		mounted() {
			document.title = "登录-" + this.$http.webConfig.simple;
			this.getCaptcha();
		},
		created() {
			this.getRandomKey();
			let isLogout = this.$cookie.get("logout");
			if (isLogout == "true") {
				this.$cookie.delete("logout");
				this.$store.dispatch("logout");
				window.localStorage.setItem("autoLogin", false);
			}

			//自动登录
			this.autoLogin = JSON.parse(window.localStorage.getItem("autoLogin"));
			var autoPassport = window.localStorage.getItem("autoPassport");
			if (this.autoLogin && autoPassport != null) {
				var userData = JSON.parse(this.$utils.decrypt(autoPassport));
				this.refreshToken(userData);
			}
		}
	}
</script>

<style>
	.lastMg {
		margin-bottom: 0;
	}

	.loginBox .active {
		font-weight: bold;
		color: #3F85E9;
	}
</style>
