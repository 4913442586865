<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>客户管理</el-breadcrumb-item>
				<el-breadcrumb-item>用户管理</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">数据首页</span>
			</div>

			<el-row class="white" :gutter="10">
				<el-col :span="12">
					<div class="wf center radius5" style="background: #3F85E9;height:100px">
						<i class="fa fa-file-text-o font20" style="position:absolute;left:5px;top:5px"></i>
						<div class="wf lineh30 mgtop20 font24 bold">{{totalData.sumAccount}}</div>
						<div class="wf lineh20 font14">总计用户</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="wf center radius5" style="background: #3F85E9;height:100px">
						<i class="fa fa-file-text-o font20" style="position:absolute;left:5px;top:5px"></i>
						<div class="wf lineh30 mgtop20 font24 bold">{{totalData.sumVip}}</div>
						<div class="wf lineh20 font14">会员用户</div>
					</div>
				</el-col>
			</el-row>

		</div>


		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="名称">
						<el-input v-model="searchForm.name" placeholder="请填写会员名称" />
					</el-form-item>
					<el-form-item label="手机号">
						<el-input v-model="searchForm.phone" placeholder="请填写会员手机号" />
					</el-form-item>
					<el-form-item label="状态">
						<el-select v-model="searchForm.state" placeholder="请选择推荐状态">
							<el-option :value="1" label="正常"></el-option>
							<el-option :value="0" label="封禁"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="类型">
						<el-select v-model="searchForm.vip" placeholder="请选择会员类型">
							<el-option :value="9" label="会员"></el-option>
							<el-option :value="0" label="普通"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="注册时间">
						<el-date-picker v-model="searchForm.daterange" type="daterange" @change="dateChange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
						<el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i>重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>


		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff overflow radius5 mgtop10">
			<div class="wf lineh40">
				<el-button type="success" size="small" @click="doExport"><i class="fa fa-download"></i> 批量导出</el-button>
			</div>

			<el-table class="mgtop10" :data="memberList" :stripe="true" border
				@selection-change="handleSelectionChange">
				<el-table-column label="序号" type="index" width="55"></el-table-column>
				<el-table-column prop="member" label="会员信息" width="300">
					<template slot-scope="scope">
						<div class="wf lineh30">{{scope.row.nickname}}
							<el-tag v-if="scope.row.vip>0" type="success" size="small">优质</el-tag>
							<el-tag v-else type="info" size="small">普通</el-tag>
						</div>
						<div class="wf lineh30">账号：{{scope.row.account}}</div>
						<div class="wf lineh30">手机号：{{scope.row.phone}}</div>
						<div class="wf lineh30">注册日期：{{scope.row.regTime}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="level" label="会员期限" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.vipName" class="wf lineh30">
							<el-tag type="success" size="small">{{scope.row.vipName}}</el-tag>
						</div>
						{{scope.row.vipStart}} - {{scope.row.vipEnd}}
					</template>
				</el-table-column>
				<el-table-column prop="muser" label="账户余额" align="center" width="200">
					<template slot-scope="scope">
						<div class="wf lineh30">
							<el-tag type="warning" size="small">优惠券：{{scope.row.coupon}}</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="enable" label="账号状态" width="160">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.state" @change="switchState" active-text="正常" inactive-text="封禁"
							:active-value="scope.$index+'-1'" :inactive-value="scope.$index+'-0'">
						</el-switch>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="memberList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
	export default {
		name: 'fragementAddress',
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					type: 0,
					page: 1,
					pageSize: 20,
					total: 0
				},
				totalData: {
					sumAccount: 0,
					sumVip: 0,
					sumFunds: 0,
					sumGive: 0,
				},
				memberForm: {},
				memberList: [],
				orgs: [],
				multipleSelection: [],
				loading: false,
				rules: {
					name: [{
						required: true,
						message: '请输入会员名称',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入会员代码',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择会员类型',
						trigger: 'blur'
					}],
					state: [{
						required: true,
						message: '请选择会员状态',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			resetSearch() {
				this.searchForm = {
					type: 0,
					page: 1,
					pageSize: 20,
					total: 0
				}
				this.pageChange(1);
			},
			dateChange(e) {
				this.searchForm.startTime = e[0];
				this.searchForm.endTime = e[1];
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getMembers();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getMembers();
			},
			getMembers() {
				this.$http.request(this, this.$apis.user.memberList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					res.data.filter((item, index) => {
						item.state = index + "-" + item.state;
						item.funds = parseFloat(item.funds * 0.01).toFixed(2);
						item.give = parseFloat(item.give * 0.01).toFixed(2);
					});
					console.log("用户数据", res.data)
					this.memberList = res.data;
				});
			},
			doExport() {
				this.$http.download(this, this.$apis.user.memberExport, this.searchForm, false, res => {
					var blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					})
					var url = window.URL.createObjectURL(blob);
					var aLink = document.createElement("a");
					aLink.style.display = "none";
					aLink.href = url;
					aLink.setAttribute("download", "运营端账号管理导出数据.xls");
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); //下载完成移除元素
					window.URL.revokeObjectURL(url); //释放掉blob对象
				});
			},
			showMember(index) {
				var memberForm = this.memberList[index];
				this.memberForm = memberForm;
				this.dialogVisible = true;
			},
			recommend(index) {
				var member = this.memberList[index];
				this.$confirm('温馨提示,确定要设会员【' + member.nickname + '】的推荐状态吗, 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.user.memberRecommend.replace("{serial}", member.serial)
					this.$http.request(this, url, {}, true, (res) => {
						this.memberList[index].recommend = res.data;
					});
				})
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的用户用户 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.user.memberBatchRemove, {
						serials: serials
					}, true, res => {
						this.memberList = this.memberList.filter(member => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == member.serial) {
									flag = false;
								}
							})
							return flag;
						});
					});
				});
			},
			switchState(e) {
				var index = e.split("-")[0];
				var member = this.memberList[index];
				let url = this.$apis.user.memberEnable.replace("{serial}", member.serial)
				this.$http.request(this, url, {}, true, (res) => {});
			},
			getUserData() {
				this.$http.request(this, this.$apis.user.memberSumTotalData, {}, false, (res) => {
					console.log("用户数据", res.data)
					this.totalData = res.data;
				});
			}
		},
		created() {
			this.getUserData();
			this.getMembers();
		}
	}
</script>

<style>
</style>
