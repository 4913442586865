<template>
	<div class="wf">
		<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
			<el-tab-pane v-for="item in tabs" :key="item" :label="item.name" :name="item.name"></el-tab-pane>
		</el-tabs>
		<more-line-chart id="valueChart" :data="dataList" :height="400" :title="activeName" unit="">
		</more-line-chart>
	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	import MoreLineChart from '@/components/fragment/chart/MoreLineChart'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		components: {
			MoreLineChart
		},
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				dataList: [],
				activeName: "视力曲线",
				tabs: [{
						name: "视力曲线",
						elements: "20210904163806GC,20210904163825RA,20210904163837OS,20210904163850BU,20210904163903HP,20210904163917IT"
					},
					{
						name: "眼压曲线",
						elements: "20210904164321LJ,20210904164334WS,20210904164347JP"
					},
					{
						name: "血糖曲线",
						elements: "20210904164435TB,20210904164457YI,20210904164536TK,20210904164517SR"
					},
					{
						name: "血压曲线",
						elements: "20210904164106YO,20210904164123MF"
					},
					{
						name: "裸眼视力",
						elements: "58eb2b1ecc8a4d26b0f4f1e06c627c64,73abcd3208ef493f96a1a8b6987d075f"
					},
					{
						name: "屈光度数",
						elements: "dfb6ee80a8484f1e81fa919a0ce3cf55,4d4de01b0f8c42c093d912fef07c0b8e"
					}
				]
			}
		},
		props: ["patientForm"],
		methods: {
			getDataList(elements) {
				this.$http.request(this, this.$apis.tools.dataPatientElementValue, {
					patient: this.patientForm.serial,
					elements: elements
				}, false, (res) => {
					console.log("历史数据")
					if (res.code == 0) {
						let datas = []
						res.data.forEach(item => {
							item.label = item.day;
							let data = {};
							for (var key in item) {
								if (key != 'day') {
									data[key] = item[key];
								}
							}
							datas.push(data);
						});
						this.dataList = datas;
					}
				});
			},
			handleClick(e) {
				console.log(e.label);
				this.tabs.forEach(item => {
					if (item.name == e.label)
						this.getDataList(item.elements);
				})
			}
		},
		created() {
			this.tabs.forEach(item => {
				if (item.name == this.activeName)
					this.getDataList(item.elements);
			})
		},
		watch: {
			patientForm(newValue, oldValue) {
				console.log("什么编号")
				this.tabs.forEach(item => {
					if (item.name == this.activeName)
						this.getDataList(item.elements);
				})
			}
		},
	}
</script>

<style>
	#archivesForm .el-dialog__body {
		padding: 0 !important;
	}
</style>
