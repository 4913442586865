/**
 * 接口文档列表
 */
import http from '../index';

const baseMaterialURL = http.baseURL + '/material';

export default {
	newsTypeList: "GET:" + baseMaterialURL + '/v1/api/news/type/list',
	newsTypeCacheAllList: "GET:" + baseMaterialURL + '/v1/api/news/type/list/all/show',
	newsTypeCacheList: "GET:" + baseMaterialURL + '/v1/api/news/type/list/show',
	newsTypePublish: "POST:" + baseMaterialURL + '/v1/api/news/type/publish',
	newsTypeRemove: "GET:" + baseMaterialURL + '/v1/api/news/type/remove/{serial}',
	newsClassificationList: "GET:" + baseMaterialURL + '/v1/api/news/sort/list',
	newsClassificationCacheList: "GET:" + baseMaterialURL + '/v1/api/news/sort/list',
	newsClassificationPublish: "POST:" + baseMaterialURL + '/v1/api/news/sort/publish',
	newsClassificationRemove: "GET:" + baseMaterialURL + '/v1/api/news/sort/remove/{serial}',
	newsExport: "GET:" + baseMaterialURL + '/v1/api/news/batch/export',
	newsList: "GET:" + baseMaterialURL + '/v1/api/news/list',
	noticeList: "GET:" + baseMaterialURL + '/v1/api/news/notice/list',
	newsPublish: "POST:" + baseMaterialURL + '/v1/api/news/publish',
	noticePublish: "POST:" + baseMaterialURL + '/v1/api/news/notice/publish',
	newsRemove: "GET:" + baseMaterialURL + '/v1/api/news/{serial}/remove',
	newsBatchRemove: "GET:" + baseMaterialURL + '/v1/api/news/batch/remove',
	newsNotice: "GET:" + baseMaterialURL + '/v1/api/news/{serial}/notice',
	newsNoticeCopy: "GET:" + baseMaterialURL + '/v1/api/news/{serial}/notice/copy',
	newsRecommend: "GET:" + baseMaterialURL + '/v1/api/news/{serial}/recommend',
	newsBanner: "GET:" + baseMaterialURL + '/v1/api/news/{serial}/banner',

	postList: "GET:" + baseMaterialURL + '/v1/api/material/post/list',
	postExport: "GET:" + baseMaterialURL + '/v1/api/material/post/batch/export',
	postPublish: "POST:" + baseMaterialURL + '/v1/api/material/post/publish',
	postVisit: "GET:" + baseMaterialURL + '/v1/api/material/post/{serial}/visit',
	postRemove: "GET:" + baseMaterialURL + '/v1/api/material/post/{serial}/remove',
	postBatchRemove: "GET:" + baseMaterialURL + '/v1/api/material/post/batch/remove',
	postRecommend: "GET:" + baseMaterialURL + '/v1/api/material/post/{serial}/recommend',
	postConsign: "GET:" + baseMaterialURL + '/v1/api/material/post/{serial}/consign',
	postBanner: "GET:" + baseMaterialURL + '/v1/api/material/post/{serial}/banner',
	postReplyData: "GET:" + baseMaterialURL + '/v1/api/material/post/reply/data',

	protocolTypeList: "GET:" + baseMaterialURL + '/v1/api/protocol/type/list',
	protocolTypeCacheAllList: "GET:" + baseMaterialURL + '/v1/api/protocol/type/list/all/show',
	protocolTypeCacheList: "GET:" + baseMaterialURL + '/v1/api/protocol/type/list/show',
	protocolTypePublish: "POST:" + baseMaterialURL + '/v1/api/protocol/type/publish',
	protocolTypeRemove: "GET:" + baseMaterialURL + '/v1/api/protocol/type/remove/{serial}',
	protocolClassificationList: "GET:" + baseMaterialURL + '/v1/api/protocol/sort/list',
	protocolClassificationCacheList: "GET:" + baseMaterialURL + '/v1/api/protocol/sort/list',
	protocolClassificationPublish: "POST:" + baseMaterialURL + '/v1/api/protocol/sort/publish',
	protocolClassificationRemove: "GET:" + baseMaterialURL + '/v1/api/protocol/sort/remove/{serial}',
	protocolList: "GET:" + baseMaterialURL + '/v1/api/protocol/list',
	protocolPublish: "POST:" + baseMaterialURL + '/v1/api/protocol/publish',
	protocolRemove: "GET:" + baseMaterialURL + '/v1/api/protocol/{serial}/remove',
	protocolBatchRemove: "GET:" + baseMaterialURL + '/v1/api/protocol/batch/remove',

	helpTypeList: "GET:" + baseMaterialURL + '/v1/api/help/type/list',
	helpTypeCacheAllList: "GET:" + baseMaterialURL + '/v1/api/help/type/list/all/show',
	helpTypeCacheList: "GET:" + baseMaterialURL + '/v1/api/help/type/list/show',
	helpTypePublish: "POST:" + baseMaterialURL + '/v1/api/help/type/publish',
	helpTypeRemove: "GET:" + baseMaterialURL + '/v1/api/help/type/remove/{serial}',
	helpClassificationList: "GET:" + baseMaterialURL + '/v1/api/help/sort/list',
	helpClassificationCacheList: "GET:" + baseMaterialURL + '/v1/api/help/sort/list',
	helpClassificationPublish: "POST:" + baseMaterialURL + '/v1/api/help/sort/publish',
	helpClassificationRemove: "GET:" + baseMaterialURL + '/v1/api/help/sort/remove/{serial}',
	helpList: "GET:" + baseMaterialURL + '/v1/api/help/list',
	helpPublish: "POST:" + baseMaterialURL + '/v1/api/help/publish',
	helpRecommend: "GET:" + baseMaterialURL + '/v1/api/help/{serial}/recommend',
	helpRemove: "GET:" + baseMaterialURL + '/v1/api/help/{serial}/remove',
	helpBatchRemove: "GET:" + baseMaterialURL + '/v1/api/help/batch/remove',

	topicExport: "GET:" + baseMaterialURL + '/v1/api/topic/batch/export',
	topicList: "GET:" + baseMaterialURL + '/v1/api/topic/list',
	topicPublish: "POST:" + baseMaterialURL + '/v1/api/topic/publish',
	topicRemove: "GET:" + baseMaterialURL + '/v1/api/topic/{serial}/remove',
	topicBatchRemove: "GET:" + baseMaterialURL + '/v1/api/topic/batch/remove',
	topicArticleList: "GET:" + baseMaterialURL + '/v1/api/topic/news/list',
	topicAllowArticle: "GET:" + baseMaterialURL + '/v1/api/topic/news/{serial}/option',
	topicListData: "GET:" + baseMaterialURL + '/v1/api/manage/topic/data/list/total',

	manageDealComment: "POST:" + baseMaterialURL + '/v1/api/material/deal/comment/publish',
	commentList: "GET:" + baseMaterialURL + '/v1/api/common/material/comment/search'
}
