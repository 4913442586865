<template>
  <div class="fragementAddress">

    <div class="wf bold font14 h40 lineh40 mgtop20" v-if="showHeader" style="border-top: 1px dashed #DEDEDE;padding-top: 20px;">
      <span><i class="fa fa-bars mgr5" style="color: #00B7EE;"></i>预约记录</span>
    </div>

    <div class="wf mgtop10" style="border-bottom: 1px dashed #DEDEDE;">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="预约编号">
          <el-input v-model="searchForm.name" placeholder="请填写预约名称" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询</el-button>
        </el-form-item>
      </el-form>


      <!-- 最新激活记录 -->
      <div class="wf bgffffff overflow radius5 mgtop20">

        <el-table :data="orderList" :stripe="true" border>
          <el-table-column prop="valid" label="订单状态" width="80">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.valid==1" type="success">启用</el-tag>
              <el-tag v-else type="warning">停用</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="bookCode" label="订单号"></el-table-column>
          <el-table-column prop="goods" label="订单信息"></el-table-column>
          <el-table-column prop="type" label="会员信息">
            <template slot-scope="scope">
              <div class="wf lineh20">账号：{{scope.row.contacts}}</div>
              <div class="wf lineh20">联系方式：{{scope.row.telphone}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="cost" label="金额" align="right">
            <template slot-scope="scope">
              <div class="wf lineh20" style="text-align: right;">实付：{{parseFloat(scope.row.actualCost * 0.01).toFixed(2)}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="subTime" label="创建时间" width="160"></el-table-column>
          <el-table-column align="center" label="操作" width="300">
            <template slot-scope="scope">
              <el-button type="success" size="small">
                <router-link :to="'/m/order/'+scope.row.serial+'/show'" style="color:#fff">
                  <i class="fa fa-navicon"></i>订单详情
                </router-link>
              </el-button>
              <el-button type="primary" size="small" @click="showOrder(scope.$index)"><i class="fa fa-edit"></i>退款</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="wf mgtop10">
          <div class="pdding20">
            <el-pagination v-show="orderList.length>0" background layout="prev, pager, next" @current-change="pageChange"
              :page-size="searchForm.pageSize" :total="searchForm.total" class="fr"> </el-pagination>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'fragementAddress',
    props: [ "member"],
    data() {
      return {
        dialogVisible: false,
        searchForm: {
          status:"0,1,2,3,4,5,6,7,8,9,10",
          page: 1,
          pageSize: 20,
          total: 0,
          type: "recharge"
        },
        accountForm: {},
        orderForm: {},
        orderList: [],
        orgs: [],
        systems: [],
        loading: false
      }
    },
    methods: {
      dateChange(e) {
        this.searchForm.startTime = e[0];
        this.searchForm.endTime = e[1];
      },
      pageChange(page) {
        this.searchForm.page = page;
        this.getOrders();
      },
      getOrders() {
        var searchForm = Object.assign({}, this.searchForm);
        searchForm.member = this.member;
        this.$http.request(this, this.$apis.pay.orderList, searchForm, false, (res) => {
          this.searchForm.total = res.count;
          this.orderList = res.data;
        });
      },
      showOrder(index) {
        var orderForm = this.orderList[index];
        this.orderForm = orderForm;
        this.dialogVisible = true;
      },
      remoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.$http.org_getOrgs({
              name: query
            }, res => {
              this.orgs = res.data;
            });
          }, 200);
        } else {
          this.orgs = [];
        }
      }
    },
    created() {},
    watch: {
      member(newValue, oldValue) {
        if (newValue != null) {
          this.getOrders();
        }
      }
    }
  }
</script>

<style>
</style>
