<template>
	<div class="wf">
		<home-manage v-if="principal.type!=99"></home-manage>
		<home-cooperate v-if="principal.type==99&&principal.level==2"></home-cooperate>
		<home-expert v-if="principal.type==99&&principal.level==0"></home-expert>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex'
	import HomeExpert from '@/components/HomeExpert'
	import HomeCooperate from '@/components/HomeCooperate'
	import HomeManage from '@/components/HomeManage'
	export default {
		components: {
			HomeManage,
			HomeExpert,
			HomeCooperate
		},
		computed: {
			...mapGetters([
				'principal'
			])
		},
	}
</script>
