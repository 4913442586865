<template>
	<div class="wf">
		<el-form class="wf" ref="archivesForm" :model="archivesForm" label-width="150px" :rules="rules">
			<el-row :gutter="10">
				<el-col :span="item.cols" v-for="(item,index) in elementList" :key="index">
					<el-form-item :prop="item.label">
						<template slot="label">
							<span class="pointer" @click="showHistoryValue(item.serial)">{{item.name}}</span>
						</template>
						<div v-if="item.type=='text'" class="wf h40">
							<el-input @input="updateEdit" v-if="canEdit==1" clearable
								v-model="archivesForm.nurse[item.serial]" :placeholder="'请填写'+item.name">
								<template v-if="item.tag" slot="append">({{item.tag}})</template>
							</el-input>
							<span v-if="canEdit==0">{{archivesForm.nurse[item.serial]}}<label class="color999"
									v-if="item.tag">({{item.tag}})</label></span>
						</div>
						<div v-if="item.type=='select'" class="wf h40">
							<el-select v-if="canEdit==1" clearable filterable v-model="archivesForm.nurse[item.serial]"
								:placeholder="'请选择'+item.name" style="width: 100%;">
								<el-option v-for="opt in item.options" :key="opt" :value="opt"></el-option>
							</el-select>
							<span v-if="canEdit==0">{{archivesForm.nurse[item.serial]}}</span>
						</div>
						<div v-if="item.type=='selectMore'" class="wf h40">
							<el-select v-if="canEdit==1" multiple clearable filterable
								v-model="archivesForm.nurse[item.serial]" :placeholder="'请选择'+item.name"
								style="width: 100%;">
								<el-option v-for="opt in item.options" :key="opt" :value="opt"></el-option>
							</el-select>
							<span
								v-if="canEdit==0&&archivesForm.nurse[item.serial]">{{archivesForm.nurse[item.serial].join('')}}</span>
						</div>
						<div v-if="item.type=='textarea'" class="wf">
							<el-input @input="updateEdit" v-if="canEdit==1" type="textarea" :rows="6"
								v-model="archivesForm.nurse[item.serial]" :placeholder="'请填写'+item.name">
							</el-input>
							<span v-if="canEdit==0">{{archivesForm.nurse[item.serial]}}</span>
						</div>
						<div v-if="item.type=='image'" class="wf">
							<upload-element :disabled="canEdit==0" @updatePicData="updatePicData"
								:pics="archivesForm.evaluation[item.serial]" :label="item.serial"></upload-element>
						</div>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>


		<div class="wf center">
			<el-button @click="hideDialog">取 消</el-button>
			<el-button type="primary" @click="publisharchives">保存</el-button>
		</div>

		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="历史记录" :visible.sync="historyVisible" width="800px"
			append-to-body>
			<el-row gutter="10" v-for="(item,index) in elementValues" :key="index">
				<el-col span="6">{{item.day}}</el-col>
				<el-col span="18">
					<span v-if="item.type=='moreSelect'">{{item.value.join(',')}}</span>
					<span v-if="item.type=='select'||item.type=='textarea'||item.type=='text'">{{item.value}}</span>
					<span v-if="item.type=='image'">
						<div v-for="pic in item.value" :key="pic" class="fl" style="width: 140px;height: 180px;">
							<img :src="pic.url" style="width: 140px;height: 140px;" />
							<div class="center h20 lineh20 wf font12">{{pic.time}}</div>
						</div>
					</span>
				</el-col>
			</el-row>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import UploadElement from '@/components/treatment/archive/UploadElement.vue'
	export default {
		data() {
			return {
				historyVisible: false,
				elementList: [],
				elementValues: [],
			}
		},
		props: ["archivesForm", "patientForm", "canEdit"],
		components: {
			UploadElement
		},
		methods: {
			packageParams(item) {
				// if (this.archivesForm.nurse[item.serial] == null || this.archivesForm.nurse[item.serial] ==
				// 	undefined) {
				// 	if (item.type == "image") {
				// 		this.archivesForm.nurse[item.serial] = [];
				// 	} else if (item.type == "select" || item.type == "textarea" || item.type == "text") {
				// 		this.archivesForm.nurse[item.serial] = "";
				// 	}
				// }
				item.options = JSON.parse(item.options);
				item.children.forEach(sitem => {
					packageParams(sitem);
				})
			},
			getElements() {
				this.$http.request(this, this.$apis.tools.elementList, {
					label: "zljhjkfjl",
				}, false, (res) => {
					console.log("什么", res)
					if (res.code == 0) {
						this.elementList = res.data[0].children;
						this.elementList.forEach(item => {
							this.packageParams(item);
						});
					}
				});
			},
			publisharchives() {
				console.log("用户信息", this.archivesForm)
				this.$refs['archivesForm'].validate((valid) => {
					if (valid) {
						var archivesForm = Object.assign({}, this.archivesForm);
						archivesForm.updateTime = "";
						archivesForm.patient = this.patientForm.serial;
						archivesForm.evaluation = JSON.stringify(archivesForm.evaluation);
						archivesForm.eyefile = JSON.stringify(archivesForm.eyefile);
						archivesForm.nurse = JSON.stringify(archivesForm.nurse);
						if (archivesForm.serial.indexOf("TEST") > -1)
							archivesForm.serial = ""	
						this.$http.request(this, this.$apis.tools.archivesPublish, archivesForm, true, res => {
							let data = res.data;
							if (res.code == 0) {
								if (data != null) {
									this.archivesForm.serial = data.serial;
								}

								this.$emit("infoChange", this.archivesForm)
								this.dialogVisible = false;
							}
						});
					}
				});
			},
			hideDialog() {
				this.$emit("showChange")
			},
			showHistoryValue(element) {
				this.$http.request(this, this.$apis.tools.elementValueList, {
					element: element,
					member: this.patientForm.serial,
					pageSize: 20
				}, false, (res) => {
					if (res.code == 0) {
						res.data.forEach(item => {
							if (item.type == "selectMore" || item.type == "image")
								item.value = JSON.parse(item.value)
						});
						this.elementValues = res.data;
						this.historyVisible = true;
					}
				});
			},
			updatePicData(label, datas) {
				console.log("上传的图片数据", label, datas);
				this.archivesForm.evaluation[label] = datas;
			},
			updateEdit() {
				console.log("强制更新")
				this.$forceUpdate();
			}
		},
		created() {
			this.getElements();
		}
	}
</script>

<style>
</style>
