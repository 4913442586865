<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>系统设置</el-breadcrumb-item>
				<el-breadcrumb-item>视力测试</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="名称">
						<el-input v-model="searchForm.name" clearable placeholder="请填写问题名称" />
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff overflow radius5 mgtop10">
			<div class="wf lineh40">
				<el-button type="success" size="small" @click="newMenu"><i class="fa fa-plus"></i>新增问题</el-button>
			</div>

			<el-tree class="mgtop40" :data="menuList" show-checkbox node-key="id" default-expand-all
				:expand-on-click-node="false">
				<div class="custom-tree-node" slot-scope="{ node, data }">
					<span class="w400 lineh40" style="display: inline-block;">{{ data.name }}</span>
					<span class="fr">
						<el-button type="primary" size="small" @click="showMenu(data)"><i class="fa fa-edit"></i>编辑
						</el-button>

						<el-button v-if="principal.level==3" type="danger" size="small" @click="removeMenu(node,data)">
							<i class="fa fa-remove"></i>删除</el-button>

						<el-button type="success" size="small" @click="plusMenu(data)"><i class="fa fa-plus"></i>新增子问题
						</el-button>
					</span>
				</div>
			</el-tree>
		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="问题信息" :visible.sync="dialogVisible" width="800px">
			<el-form class="wf" ref="menuForm" :model="menuForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="12">
						<el-form-item label="问题名称" prop="name">
							<el-input v-model="menuForm.name" placeholder="请填写问题名称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="问题标签" prop="label">
							<el-input v-model="menuForm.label" placeholder="请填写问题标签"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="问题图片" prop="icon">
							<el-upload class="upload-demo" name="mdFile" list-type="picture-card" :action="imgUploadUrl"
								:headers="uploadHeaders" :on-change="picChange" :on-remove="picChange"
								:on-preview="handlePictureCardPreview" :file-list="picList"
								:before-upload="beforePicUpload">
								<i class="el-icon-plus"></i>
								<div slot="tip" class="el-upload__tip">只能上传JPG、PNG、JPEG文件，且不超过1M</div>
							</el-upload>
							<el-dialog :close-on-click-modal="false" :visible.sync="previewVisible" append-to-body>
								<img width="100%" :src="previewImageUrl" alt="">
							</el-dialog>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishMenu">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				previewImageUrl: '',
				previewVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				picList: [],
				dialogVisible: false,
				searchForm: {
					level: 1,
					page: 1,
					pageSize: 20,
					total: 0
				},
				parentMenu: {},
				menuForm: {},
				menuList: [],
				menuLevel: 0,
				multipleSelection: [],
				rules: {
					name: [{
							required: true,
							message: '请填写问题名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					label: [{
						required: true,
						message: '请填写问题标签',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handlePictureCardPreview(file) {
				this.previewImageUrl = file.url;
				this.previewVisible = true;
			},
			picChange(file, fileList) {
				console.log("文件上传", fileList)
				this.picList = fileList;
			},
			resetSearch() {
				this.searchForm = {
					page: 1,
					pageSize: 20,
					total: 0
				}
			},
			load(tree, treeNode, resolve) {
				console.log(tree, treeNode, resolve)
				this.$http.request(this, this.$apis.tools.visionExamList, {
					parent: tree.parent,
					level: 2
				}, false, (res) => {
					resolve(res.data);
				});
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getMembers()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getMenus();
			},
			newMenu() {
				this.menuForm = {
					sort: 0,
					params: []
				};
				this.menuLevel = 0;
				this.dialogVisible = true;
			},
			plusMenu(data) {
				console.log("父问题", data)
				this.parentMenu = data;
				this.menuLevel = 1;
				this.menuForm = {
					sort: 0,
					params: []
				};
				this.dialogVisible = true;
			},
			packageParams(item) {
				item.params = JSON.parse(item.params);
				item.children.forEach(sitem => {
					this.packageParams(sitem);
				});
			},
			getMenus() {
				this.$http.request(this, this.$apis.tools.visionExamList, this.searchForm, false, (res) => {
					res.data.forEach(item => {
						this.packageParams(item);
					});
					this.menuList = res.data;
				});
			},
			publishMenu() {
				this.$refs['menuForm'].validate((valid) => {
					if (valid) {
						var menuForm = Object.assign({}, this.menuForm);
						menuForm.updateTime = "";
						menuForm.parent = this.menuLevel == 0 ? "" : this.parentMenu.serial;
						menuForm.params = JSON.stringify(menuForm.params);
						var picList = [];
						this.picList.filter(file => {
							if (file.status == "success" && file.response.files.length > 0) {
								let doc = file.response.files[0];
								picList.push({
									uid: file.uid,
									name: doc.orianName,
									url: doc.uploadUrl
								})
							}
						});
						menuForm.icon = JSON.stringify(picList);
						this.$http.request(this, this.$apis.tools.visionExamPublish, menuForm, true, res => {
							let data = res.data;
							if (data != null) {
								if (this.menuLevel == 0) {
									data.children = [];
									data.hasChildren = true;
									this.menuList.push(data);
								} else {
									this.parentMenu.children.push(data);
								}
							}

							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			showMenu(data) {
				this.menuForm = data;
				this.picList = JSON.parse(data.icon);
				if (this.picList != null)
					this.picList.filter(pic => {
						pic.status = "success";
						pic.response = {
							files: [{
								orianName: pic.name,
								uploadUrl: pic.url
							}]
						}
					});

				console.log("问题", data)
				this.dialogVisible = true;
			},
			removeMenu(node, data) {
				const parent = node.parent;
				const children = parent.data.children || parent.data;
				const index = children.findIndex(d => d.serial === data.serial);
				var menu = data;
				this.$confirm('此操作将永久删除问题[' + menu.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					menu: 'warning'
				}).then(() => {
					let url = this.$apis.tools.visionExamRemove.replace("{serial}", menu.serial);
					this.$http.request(this, url, {}, true, data => {
						children.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的问题 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					menu: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.serving.menuBatchRemove, {
						serials: serials
					}, true, res => {

						this.menuList = this.menuList.filter(menu => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == menu.serial) {
									flag = false;
								}
							})
							return flag;
						});

					});
				});
			},
		},
		created() {
			this.getMenus();
		}
	}
</script>

<style>
	.el-tree-node {
		margin-bottom: 10px;
	}

	.el-tree-node__children {
		margin-top: 10px;
	}

	.el-tree-node__content {
		height: auto !important;
	}
</style>
