import sso from './sso';
import user from './user';
import material from './material';
import tools from './tools';
import component from './component';
import pay from './pay';
import log from './log';

export default {
  sso,
  user,
  material,
  tools,
  component,
  pay,
  log
}
