<template>
	<div class="wf">
		<el-form class="wf" ref="patientForm" :model="patientForm" label-width="150px" :rules="rules">
			<el-row :gutter="10">
				<el-col :span="item.cols" v-for="(item,index) in elementList" :key="index">
					<el-form-item :label="item.name" :prop="item.label" v-if="patientForm.medical[item.serial]||canEdit==1">
						<div v-if="item.type=='text'" class="wf h40">
							<el-input @input="updateEdit" v-if="canEdit==1" v-model="patientForm.medical[item.serial]"
								clearable :placeholder="'请填写'+item.name">
								<template v-if="item.tag" slot="append">({{item.tag}})</template>
							</el-input>
							<span v-if="canEdit==0">{{patientForm.medical[item.serial]}}<label class="color999"
									v-if="item.tag">({{item.tag}})</label></span>
						</div>
						<div v-if="item.type=='select'" class="wf h40">
							<el-select v-if="canEdit==1" v-model="patientForm.medical[item.serial]" clearable filterable
								:placeholder="'请选择'+item.name" style="width: 100%;">
								<el-option v-for="opt in item.options" :key="opt" :value="opt"></el-option>
							</el-select>
							<span v-if="canEdit==0">{{patientForm.medical[item.serial]}}</span>
						</div>
						<div v-if="item.type=='selectMore'" class="wf h40">
							<el-select v-if="canEdit==1" multiple clearable filterable
								v-model="patientForm.medical[item.serial]" :placeholder="'请选择'+item.name"
								style="width: 100%;">
								<el-option v-for="opt in item.options" :key="opt" :value="opt"></el-option>
							</el-select>
							<span
								v-if="canEdit==0&&patientForm.medical[item.serial]">{{patientForm.medical[item.serial].join('')}}</span>
						</div>
						<div v-if="item.type=='textarea'" class="wf">
							<el-input @input="updateEdit" v-if="canEdit==1" type="textarea" :rows="6"
								v-model="patientForm.medical[item.serial]" :placeholder="'请填写'+item.name">
							</el-input>
							<span v-if="canEdit==0">{{patientForm.medical[item.serial]}}</span>
						</div>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>


		<div class="wf center">
			<el-button @click="hideDialog">取 消</el-button>
			<el-button v-if="canEdit==1" type="primary" @click="publishPatient">保存</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				elementList: []
			}
		},
		props: ["patientForm", "canEdit"],
		methods: {
			packageParams(item) {
				item.value = "";
				item.options = JSON.parse(item.options);
				item.children.forEach(sitem => {
					packageParams(sitem);
				})
			},
			getElements() {
				this.$http.request(this, this.$apis.tools.elementList, {
					label: "jwbsjjzycs",
				}, false, (res) => {
					console.log("什么", res)
					if (res.code == 0) {
						this.elementList = res.data[0].children;
						this.elementList.forEach(item => {
							this.packageParams(item);
						});
					}
				});
			},
			publishPatient() {
				console.log("用户信息", this.patientForm)
				this.$refs['patientForm'].validate((valid) => {
					if (valid) {
						var patientForm = Object.assign({}, this.patientForm);
						patientForm.updateTime = "";
						patientForm.province = patientForm.region[0];
						patientForm.city = patientForm.region[1];
						patientForm.county = patientForm.region[2];
						patientForm.region = JSON.stringify(patientForm.region);
						patientForm.medical = JSON.stringify(patientForm.medical);
						patientForm.property = JSON.stringify(patientForm.property);
						patientForm.surgery = JSON.stringify(patientForm.surgery);

						this.$http.request(this, this.$apis.tools.patientPublish, patientForm, true, res => {
							let data = res.data;
							if (res.code == 0) {
								if (data != null) {
									this.patientForm.serial = data.serial;
								}

								this.$emit("infoChange", this.patientForm)
								this.dialogVisible = false;
							}
						});
					}
				});
			},
			hideDialog() {
				this.$emit("showChange")
			},
			updateEdit() {
				this.$forceUpdate();
			}
		},
		created() {
			this.getElements();
		}
	}
</script>

<style>
</style>
