<template>
	<div class="fragementAddress">

		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">卡券服务</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">卡券分类</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="名称">
						<el-input v-model="searchForm.name" clearable placeholder="请填写分类名称" />
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff overflow radius5 mgtop10">
			<div class="wf lineh40">
				<el-button v-if="principal.level==3" type="danger" size="small" @click="batchRemove"><i class="fa fa-trash"></i> 批量删除</el-button>
				<el-button type="success" size="small" @click="newType"><i class="fa fa-plus"></i>新增分类</el-button>
			</div>

			<el-table :data="typeList" :stripe="true" border class="mgtop10" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="name" label="分类名称"></el-table-column>
				<el-table-column prop="updateTime" label="更新时间" width="160" align="center"></el-table-column>
				<el-table-column align="center" label="操作" width="240">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="12">
								<el-button style="width: 100%;" type="primary" size="small"
									@click="showType(0,scope.$index)"><i class="fa fa-edit"></i>编辑</el-button>
							</el-col>
							<el-col v-if="principal.level==3" :span="12">
								<el-button style="width: 100%;" type="danger" size="small"
									@click="removeType(0, scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="typeList.length>0" @size-change="pageSizeChange" @current-change="pageChange"
						:current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]" :page-size="searchForm.pageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="searchForm.total" background
						class="fr"></el-pagination>
				</div>
			</div>

		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="分类信息" :visible.sync="dialogVisible" width="600px">
			<el-form class="wf" ref="typeForm" :model="typeForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="24">
						<el-form-item label="分类名称" prop="name">
							<el-input v-model="typeForm.name" placeholder="请填写分类名称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="分类图标" prop="name">
							<el-upload class="upload-demo" name="mdFile" list-type="picture-card" :action="imgUploadUrl"
								:headers="uploadHeaders" :on-success="picDone" :on-preview="handlePictureCardPreview"
								:show-file-list="false" :before-upload="beforePicUpload">
								<img v-if="typeForm.icon" :src="typeForm.icon" class="avatar"
									style="width: 100%;height: 100%;">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishType">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->
		<el-dialog :close-on-click-modal="false" :visible.sync="previewVisible">
			<img width="100%" :src="previewImageUrl" alt="">
		</el-dialog>

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				previewImageUrl: '',
				previewVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				typeForm: {},
				typeList: [],
				multipleSelection: [],
				rules: {
					name: [{
							required: true,
							message: '请填写分类名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					]
				},
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handlePictureCardPreview(file) {
				this.previewImageUrl = file.url;
				this.previewVisible = true;
			},
			picChange(file, fileList) {
				console.log("文件上传", fileList)
				this.picList = fileList;
			},
			picDone(res, file) {
				console.log("文件上传完成", file)
				if (file.response.files.length > 0)
					this.typeForm.icon = file.response.files[0].uploadUrl;
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getMembers()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getTypes();
			},
			newType() {
				this.typeForm = {
					icon: ""
				};
				this.dialogVisible = true;
			},
			getTypes() {
				this.$http.request(this, this.$apis.pay.shopTypeList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					this.typeList = res.data;
				});
			},
			publishType() {
				this.$refs['typeForm'].validate((valid) => {
					if (valid) {
						var typeForm = Object.assign({}, this.typeForm);
						typeForm.updateTime = "";

						this.$http.request(this, this.$apis.pay.shopTypePublish, typeForm, true, res => {
							let data = res.data;
							if (data != null) {
								this.typeList.unshift(data);
							}

							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			showType(type, index) {
				var typeForm = this.typeList[index];
				this.typeForm = typeForm;
				this.dialogVisible = true;
			},
			removeType(type, index) {
				var type = this.typeList[index];
				this.$confirm('此操作将永久删除分类[' + type.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.pay.shopTypeRemove.replace("{serial}", type.serial);
					this.$http.request(this, url, {}, true, data => {
						this.typeList.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的服务类型 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.pay.shopTypeBatchRemove, {
						serials: serials
					}, true, res => {

						this.typeList = this.typeList.filter(type => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == type.serial) {
									flag = false;
								}
							})
							return flag;
						});

					});
				});
			},
		},
		created() {
			this.getTypes();
		}
	}
</script>

<style>
</style>
