<template>
	<div class="pdding20 bgffffff">
		<el-table class="mgtop20" :data="dataList" :stripe="true" border>
			<el-table-column label="序号" type="index" width="55" align="right"></el-table-column>
			<el-table-column label="来源" prop="name" />
			<el-table-column label="数量" prop="member" align="right" />
			<el-table-column label="占比" prop="rate" align="right" />
		</el-table>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				dataList: []
			}
		},
		methods: {
			getOrgs() {
				this.$http.request(this, this.$apis.user.orgMemberTopData, {
					type: 0,
					pageSize: 100
				}, false, res => {
					if (res.code == 0) {
						let sum = 0;
						res.data.forEach(item => {
							sum += item.member;
							item.rate = "0.00%"
						});
						if (sum > 0)
							res.data.forEach(item => {
								item.rate = parseFloat(item.member / sum * 100).toFixed(2) + "%";
							});
							
						this.dataList = res.data;
					}
				});
			}
		},
		created() {
			this.getOrgs();
		}
	}
</script>
