const state = {
	isLogin: sessionStorage.getItem('isLogin'),
	userData: JSON.parse(sessionStorage.getItem('userData')),
	principal: JSON.parse(sessionStorage.getItem('principal')),
	token: sessionStorage.getItem('token'),
	selectMenu: sessionStorage.getItem('selectMenu'),
	confirmInfo:JSON.parse(sessionStorage.getItem('confirmInfo'))==null?{tips:"",show:false,event:function(){}}:JSON.parse(sessionStorage.getItem('confirmInfo'))
}

const getters = {
  principal: state => state.principal,
	isLogin(state){
		if(!state.isLogin){
      state.userInfo = JSON.parse(sessionStorage.getItem('isLogin'))
    }
		return state.isLogin
	},
	token: state => state.token,
	selectMenu: state => state.selectMenu,
	confirmInfo: state => state.confirmInfo
}

const mutations = {
  // 更改用户的状态信息
  userStatus(state,user){
    if(user){
			state.userData = user
			state.principal = user.principal
			state.isLogin = true
			state.token = user.access_token
			//用户凭证  
			sessionStorage.setItem("isLogin",true);
			sessionStorage.setItem("principal",JSON.stringify(user.principal));
			sessionStorage.setItem("userData",JSON.stringify(user));
			sessionStorage.setItem("token", user.token_type+user.access_token)
    }else{
      state.principal = null
			state.userData = null 
      state.isLogin = false
			state.token = null
    }
  },
	principalStatus(state,principal){
		state.principal = principal;
		sessionStorage.setItem("principal",JSON.stringify(principal));
	},
	logout(state){
		state.principal = null
		state.userData = null 
		state.isLogin = false
		state.token = null
		sessionStorage.removeItem("isLogin");
		sessionStorage.removeItem("principal");
		sessionStorage.removeItem("userData");
		sessionStorage.removeItem("token");
	},
	setConfirmInfo(state,confirmInfo){
		state.confirmInfo=confirmInfo;
	},
	selectMenu(state,menu){
		state.selectMenu=menu;
		sessionStorage.setItem("selectMenu",menu);
	}
}

const actions = {
	// 应用mutations
	setUser({commit},user){
		commit("userStatus",user)
	},
	changePrincipal({commit},principal){
		commit("principalStatus",principal)
	},
	logout({commit}){
		commit("logout");
	},
	setConfirmInfo({commit},confirmInfo){
		commit("setConfirmInfo",confirmInfo)
	},
	selectMenu({commit}, menu){
		commit("selectMenu",menu);
	}
}

export default{
  state,
  getters,
  mutations,
  actions
}