<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>数据中心</el-breadcrumb-item>
				<el-breadcrumb-item>会员卡购买</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="名称">
						<el-input v-model="searchForm.contacts" placeholder="请填写会员名称" />
					</el-form-item>
					<el-form-item label="手机号">
						<el-input v-model="searchForm.telphone" placeholder="请填写会员手机号" />
					</el-form-item>
					<el-form-item label="支付时间">
						<el-date-picker v-model="searchForm.daterange" type="daterange" @change="dateChange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
						<el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i>重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">

			<div class="wf lineh40">
				<el-button type="success" size="small" @click="doExport"><i class="fa fa-download"></i> 批量导出</el-button>
			</div>

			<el-table :data="orderList" :stripe="true" border>
				<el-table-column prop="type" label="用户信息" width="200">
					<template slot-scope="scope">
						<div class="wf lineh20">昵称：{{scope.row.contacts}}</div>
						<div class="wf lineh20">电话：{{scope.row.telphone}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="goods" label="订单信息"></el-table-column>
				<el-table-column prop="bookCode" label="订单编号"></el-table-column>
				<el-table-column prop="valid" label="订单状态" width="80">
					<template slot-scope="scope">
						<el-tag size="small" v-if="scope.row.status==1" type="danger">待支付</el-tag>
						<el-tag size="small" v-if="scope.row.status==11" type="info">已取消</el-tag>
						<el-tag size="small" v-if="scope.row.status==2" type="success">已支付</el-tag>
						<el-tag size="small" v-if="scope.row.status==8" type="waring">已退款</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="payTime" label="支付时间" width="160"></el-table-column>
				<el-table-column prop="cost" label="支付金额" align="right" width="120">
					<template slot-scope="scope">
						<el-tag type="danger" size="small">¥{{parseFloat(scope.row.actualCost * 0.01).toFixed(2)}}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="subTime" label="创建时间" width="160"></el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="orderList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'fragementAddress',
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					type: "vip",
					status: "2",
					page: 1,
					pageSize: 20,
					total: 0
				},
				accountForm: {},
				orderData: {
					total: 0,
					new: 0,
					sumcost: 0,
					goods: {
						"20200704001011UN": 0,
						"20200704000451LJ": 0,
						"20200704001115ND": 0
					}
				},
				orderForm: {},
				orderList: [],
				orgs: [],
				systems: [],
				loading: false,
				rules: {
					name: [{
						required: true,
						message: '请输入订单名称',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入订单代码',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择订单类型',
						trigger: 'blur'
					}],
					state: [{
						required: true,
						message: '请选择订单状态',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			dateChange(e) {
				if (e == null) {
					this.searchForm.startTime = "";
					this.searchForm.endTime = "";
				} else {
					this.searchForm.startTime = e[0];
					this.searchForm.endTime = e[1];
				}
			},
			resetSearch() {
				this.searchForm = {
					type: "vip",
					status: "2",
					page: 1,
					pageSize: 20,
					total: 0
				}
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getOrders();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getOrders();
			},
			getOrders() {
				this.$http.request(this, this.$apis.pay.orderList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					this.orderList = res.data;
				});
			},
			doExport() {
				this.$http.download(this, this.$apis.pay.orderExport, this.searchForm, false, res => {
					var blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					})
					var url = window.URL.createObjectURL(blob);
					var aLink = document.createElement("a");
					aLink.style.display = "none";
					aLink.href = url;
					aLink.setAttribute("download", "运营端会员充值订单管理导出数据.xls");
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); //下载完成移除元素
					window.URL.revokeObjectURL(url); //释放掉blob对象
				});
			},
			showOrder(index) {
				var orderForm = this.orderList[index];
				this.orderForm = orderForm;
				this.dialogVisible = true;
			},
			refundOrder(index) {
				var orderForm = this.orderList[index];
				this.$confirm('此操作将发起退款, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.pay.orderRefund.replace("{payCode}", orderForm.payCode);
					this.$http.request(this, url, {}, true, data => {
						if (res.code == 0)
							orderForm.status = 8;
					});
				});
			},
			getOrderData() {
				this.$http.request(this, this.$apis.pay.orderListData, this.searchForm, false, (res) => {
					console.log("数据统计", res.data)
					let data = res.data;
					data.total.filter(item => {
						this.orderData.total += item.data;
						this.orderData.sumcost += item.cost;
						this.orderData.goods[item.label] = item.data;
					});

					data.new.filter(item => {
						this.orderData.new += item.data;
					});

				});
			}
		},
		created() {
			this.getOrders();
			this.getOrderData();
		}
	}
</script>

<style>
</style>
