<template>
	<div class="webHeader">

		<!-- 顶部菜单 -->
		<div id="topArea" class="pdding10auto" style="background: #4DC6BF;width: auto;">
			<div class="fl font20 lineh60 white pointer" @click="changeCollapse">
				<i class="el-icon-s-fold" v-if="isCollapse"></i>
				<i class="el-icon-menu" v-else></i>
			</div>
			<div class="fl font18 bold white lineh60 mgl20">{{this.$http.webConfig.simple}}</div>
			
			<ul class="fr lineh60 pointer">
				<li class="fl mgl10">
					<router-link class=" white bold" to="/m/safety/center">忘记密码</router-link>
				</li>
				<li class="fl mgl10">
					<span class=" white bold" @click="logout">退出登录</span>
				</li>
				<li class="fl mgl20">
					<img class="radius50p overflow" style="float:left;width: 20px;height: 20px;margin-top: 20px;"
						src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
					<span class="fl mgl10 white bold">{{principal.account}}</span>
				</li>
			</ul>
		</div>

	</div>
	<!-- 顶部菜单 end-->


	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'webHeader',
		props: ["isCollapse"],
		data() {
			return {
				authoritys: [],
				activeIndex: "1",
				searchForm: {},
				msgCount: 0
			}
		},
		computed: {
			...mapGetters([
				'principal', 'isLogin', 'selectMenu'
			])
		},
		methods: {
			changeCollapse() {
				this.$emit("changeCollapse");
			},
			logout() {
				this.$store.dispatch("logout");
				this.$cookie.delete("passport");
				this.$cookie.set('logout', true, {
					expires: 1000000
				});
				window.location.href = "/uc/login";
			}
		},
		created() {
			// this.authoritys = this.principal.authority;
			// this.activeIndex = this.selectMenu == null ? "0" : this.selectMenu + '';
		}
	}
</script>
<style>
	#topArea .el-menu-item.is-active {
		background: #FFFFFF !important;
	}

	#topArea .el-menu-item>a {
		display: block;
		width: 100%;
		height: 100%;
	}

	#topArea {
		border-bottom: 0;
	}
</style>
