/**
 * 接口文档列表
 */
import http from '../index';

const baseComponentURL = http.baseURL + '/component';

export default {
	captchaImg: baseComponentURL + "/captcha",
	sms: baseComponentURL + "/v1/api/sms/public/send",
	baseUploadUrl: baseComponentURL + "/v1/api/upload/image",
	baseFileUploadUrl: baseComponentURL + "/v1/api/upload/file"
}
