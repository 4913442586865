/**
 * 接口文档列表
 */
import http from '../index';

const basePayURL = http.baseURL + '/pay';

export default {
	shopTypeList: "GET:" + basePayURL + '/v1/api/manage/product/type/list',
	shopTypePublish: "POST:" + basePayURL + '/v1/api/manage/product/type/publish',
	shopTypeRemove: "GET:" + basePayURL + '/v1/api/manage/product/type/{serial}/remove',

	shopProductList: "GET:" + basePayURL + '/v1/api/manage/shop/product/list',
	shopProductPublish: "POST:" + basePayURL + '/v1/api/manage/shop/product/publish',
	shopProductRemove: "GET:" + basePayURL + '/v1/api/manage/shop/product/{serial}/remove',
	shopProductStop: "GET:" + basePayURL + '/v1/api/manage/shop/product/{serial}/shelf',
	shopProductRecommend: "GET:" + basePayURL + '/v1/api/manage/shop/product/{serial}/recommend',
	shopProductBatchRemove: "GET:" + basePayURL + '/v1/api/manage/shop/product/batch/remove',

	shopProductStandardList: "GET:" + basePayURL + '/v1/api/manage/shop/product/{serial}/standard/list',
	shopProductStandardPublish: "POST:" + basePayURL + '/v1/api/manage/shop/product/standard/publish',
	shopProductStandardRemove: "GET:" + basePayURL + '/v1/api/manage/shop/product/standard/{serial}/remove',

	shopEvaluationType: "GET:" + basePayURL + '/v1/api/manage/product/evaluation/type',
	shopEvaluationList: "GET:" + basePayURL + '/v1/api/manage/product/evaluation/list',

	orderExport: "GET:" + basePayURL + '/v1/api/manage/order/batch/export',
	orderList: "GET:" + basePayURL + '/v1/api/manage/order/list',
	orderListData: "GET:" + basePayURL + '/v1/api/manage/order/data/list/total',
	orderMonthData: "GET:" + basePayURL + '/v1/api/manage/order/data/month/total',
	orderRefund: "POST:" + basePayURL + '/v1/api/manage/order/{payCode}/auto/refund',
	refundList: "GET:" + basePayURL + '/v1/api/manage/order/refund/list',
	refundDone: "POST:" + basePayURL + '/v1/api/manage/order/{serial}/auto/refund',
	refundCancel: "POST:" + basePayURL + '/v1/api/manage/order/{serial}/auto/cancel',
	invoiceList: "GET:" + basePayURL + '/v1/api/manage/order/invoice/list',
	invoiceDone: "GET:" + basePayURL + '/v1/api/manage/order/invoice/{invoice}/done',
}
