<template>
	<div class="fragementAddress">

		<div class="wf mgtop10" style="border-bottom: 1px dashed #DEDEDE;">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="设备商">
					<el-select v-model="searchForm.vendorName" placeholder="请选择设备商">
						<el-option value="车便捷"></el-option>
						<el-option value="鲸洗"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="设备编号">
					<el-input v-model="searchForm.device" placeholder="请填写设备名称" />
				</el-form-item>
				<el-form-item label="服务网点">
					<el-input v-model="searchForm.stationName" placeholder="请填写服务网点" />
				</el-form-item>
				<el-form-item label="订单状态">
					<el-select v-model="searchForm.status" placeholder="请选择订单状态">
						<el-option :value="-1" label="设备故障"></el-option>
						<el-option :value="0" label="进行中"></el-option>
						<el-option :value="9" label="已完成"></el-option>
						<el-option :value="11" label="工作异常"></el-option>
						<el-option :value="12" label="已取消"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="服务日期">
					<el-date-picker v-model="searchForm.daterange" type="daterange" @change="dateChange"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
						value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
					</el-button>
				</el-form-item>
			</el-form>


			<!-- 最新激活记录 -->
			<div class="wf bgffffff overflow radius5 mgtop20">

				<el-table :data="orderList" :stripe="true" border>
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="childName" label="用户信息" width="180">
						<template slot-scope="scope">
							<div class="wf lineh30">{{scope.row.memberName}}</div>
							<div class="wf lineh30">手机号：{{scope.row.memberPhone}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="deviceName" label="设备名称" width="200">
						<template slot-scope="scope">
							<div class="wf">设备号：{{scope.row.number}}</div>
							<div class="wf">{{scope.row.name}}</div>
							<div class="wf">机器号：{{scope.row.machine}}</div>
							<div class="wf">设备商：<el-tag size="small" type="primary">{{scope.row.vendorName}}</el-tag>
							</div>
							<div class="wf">泊位点：{{scope.row.stationName}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="sortName" label="消费明细" align="center">
						<template slot-scope="scope">
							<div class="wf" v-for="(item,index) in scope.row.detail" :key="index">
								<el-tag v-if="item.channel==1" type="primary" size="small">
									清水：{{parseFloat(item.time / 60).toFixed(2)}}分钟</el-tag>
								<el-tag v-if="item.channel==2" type="primary" size="small">
									泡沫：{{parseFloat(item.time / 60).toFixed(2)}}分钟</el-tag>
								<el-tag v-if="item.channel==3" type="primary" size="small">
									洗手：{{parseFloat(item.time / 60).toFixed(2)}}分钟</el-tag>
								<el-tag v-if="item.channel==4" type="primary" size="small">
									吸尘：{{parseFloat(item.time / 60).toFixed(2)}}分钟</el-tag>
							</div>
							<div v-if="scope.row.detail.length==0">
								<el-tag type="warning" size="small">没有消费明细</el-tag>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="mprice" label="费用明细" align="right" width="160">
						<template slot-scope="scope">
							<div class="wf lineh30">用券：{{scope.row.coupon}}元</div>
							<div class="wf lineh30">用卡：{{scope.row.card}}次</div>
							<div class="wf lineh30">余额：{{scope.row.cash}}元</div>
							<div class="wf lineh30">赠送：{{scope.row.give}}元</div>
						</template>
					</el-table-column>
					<el-table-column prop="updateTime" label="服务时间" align="left" width="200">
						<template slot-scope="scope">
							<div class="wf lineh20">开启：{{scope.row.subTime}}</div>
							<div class="wf lineh20">结束：{{scope.row.finishTime}}</div>
							<div class="wf lineh20">时长：{{scope.row.time}}分钟</div>
						</template>
					</el-table-column>
					<el-table-column prop="status" label="订单状态" width="100" align="center">
						<template slot-scope="scope">
							<el-tag v-if="scope.row.status == 0" type="primary" size="small">进行中</el-tag>
							<el-tag v-if="scope.row.status == 12" type="info" size="small">已取消</el-tag>
							<el-tag v-if="scope.row.status == 9" type="success" size="small">已完成</el-tag>
							<el-tag v-if="scope.row.status == -1" type="danger" size="small">设备故障</el-tag>
							<el-tag v-if="scope.row.status == 11" type="danger" size="small">工作异常</el-tag>
						</template>
					</el-table-column>
					<el-table-column align="center" label="操作" width="140">
						<template slot-scope="scope">
							<el-row :gutter="10">
								<el-col :span="24" class="mgtop10">
									<el-button @click="removeOrder(scope.$index)" type="danger" size="small">
										<i class="fa fa-remove"></i>取消订单
									</el-button>
								</el-col>
							</el-row>
						</template>
					</el-table-column>
				</el-table>

				<!-- 分页 -->
				<div class="wf mgtop10">
					<div class="pdding20">
						<el-pagination v-show="orderList.length>0" background layout="prev, pager, next"
							@current-change="pageChange" :page-size="searchForm.pageSize" :total="searchForm.total"
							class="fr"> </el-pagination>
					</div>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'fragementAddress',
		props: ["member"],
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					state: [1, 2, 3, 4],
					page: 1,
					pageSize: 20,
					total: 0,
				},
				accountForm: {},
				orderForm: {},
				orderList: [],
				orgs: [],
				systems: [],
				loading: false
			}
		},
		methods: {
			dateChange(e) {
				this.searchForm.startTime = e[0];
				this.searchForm.endTime = e[1];
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getOrders();
			},
			getOrders() {
				var searchForm = Object.assign({}, this.searchForm);
				searchForm.state = JSON.stringify(searchForm.state).replace("[", "");
				searchForm.state = searchForm.state.replace("]", "");
				searchForm.member = this.member;
				searchForm.type = 0;
				this.$http.request(this, this.$apis.device.deviceOrderList, searchForm, false, (res) => {
					this.searchForm.total = res.count;
					res.data = this.$http.cleanDatas(res.data);
					res.data.forEach(item => {
						item.coupon = parseFloat(item.coupon * 0.01).toFixed(2);
						item.cash = parseFloat(item.cash * 0.01).toFixed(2);
						item.give = parseFloat(item.give * 0.01).toFixed(2);
						item.time = parseFloat(item.time * 0.001 / 60).toFixed(2);
						item.detail = JSON.parse(item.detail);
					});
					this.orderList = res.data;
				});
			},
			showOrder(index) {
				var orderForm = this.orderList[index];
				this.orderForm = orderForm;
				this.dialogVisible = true;
			},
			remoteMethod(query) {
				if (query !== '') {
					this.loading = true;
					setTimeout(() => {
						this.loading = false;
						this.$http.org_getOrgs({
							name: query
						}, res => {
							this.orgs = res.data;
						});
					}, 200);
				} else {
					this.orgs = [];
				}
			}
		},
		created() {},
		watch: {
			member(newValue, oldValue) {
				if (newValue != null) {
					this.getOrders();
				}
			}
		}
	}
</script>

<style>
</style>
