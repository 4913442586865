<template>
	<div class="showSystem">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>客户管理</el-breadcrumb-item>
				<el-breadcrumb-item>网点管理</el-breadcrumb-item>
				<el-breadcrumb-item>网点详情</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<el-tabs type="border-card" class="mgtop10" value="基本信息">
			<el-tab-pane label="基本信息" name="基本信息">

				<el-row class="wf lineh40 font14">
					<el-col :span="20">
						<el-col :span="12">网点名称：{{org.name}}</el-col>
						<el-col :span="12">网点状态：
							<el-tag type="success">在营</el-tag>
						</el-col>
						<el-col :span="12">网点地址：{{org.province}} {{org.city}} {{org.county}} {{org.address}}</el-col>
						<el-col :span="12">联系人：{{org.contact}}</el-col>
						<el-col :span="12">联系电话：{{org.contactNumber}}</el-col>
						<el-col :span="12">所属分公司：{{org.name}}</el-col>
						<el-col :span="12">创建时间：{{org.subTime}}</el-col>
						<el-col :span="12">营业时间：{{org.subTime}}</el-col>
						<el-col :span="24">网点描述：{{org.remark}}</el-col>
					</el-col>
					<el-col :span="4" class="center">
						<el-col :span="12">
							<div class="wf bold font30 mgtop30">{{memberFund.funds}}</div>
							<div class="wf">充值余额</div>
						</el-col>
						<el-col :span="12">
							<div class="wf bold font30 mgtop30">{{memberFund.give}}</div>
							<div class="wf">赠送余额</div>
						</el-col>
						<el-col :span="24" class="center">
							<el-button @click="fundVisible=true" type="primary" size="small"><i class="fa fa-plus"></i>
								修改余额</el-button>
						</el-col>
					</el-col>
				</el-row>

				<fragment-device-product :org="org"></fragment-device-product>

			</el-tab-pane>
			<el-tab-pane label="服务记录" name="record">
				<fragment-device-order :org="org" :showHeader="false"></fragment-device-order>
			</el-tab-pane>
		</el-tabs>


		<!-- 账户余额设置 -->
		<el-dialog :close-on-click-modal="false" title="账户余额" :visible.sync="fundVisible" width="400px">
			<el-form class="wf" ref="memberFund" :model="memberFund" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="24">
						<el-form-item label="现金余额" prop="funds">
							<el-input v-model="memberFund.funds" placeholder="请填写现金余额">
								<span slot="append">元</span>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="赠送余额" prop="give">
							<el-input v-model="memberFund.give" placeholder="请填写赠送余额">
								<span slot="append">元</span>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="fundVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishFund">保存</el-button>
			</span>
		</el-dialog>
		<!-- 账户余额设置 end -->


	</div>
</template>

<script>
	import FragmentDeviceProduct from '@/components/fragment/org/FragmentDeviceProduct'
	import FragmentDeviceOrder from '@/components/fragment/org/FragmentDeviceOrder'

	export default {
		name: 'showSystem',
		components: {
			FragmentDeviceProduct,
			FragmentDeviceOrder
		},
		data() {
			return {
				fundVisible: false,
				spaceOwner: {
					org: "org"
				},
				orderOwner: {
					beBusiness: "beBusiness",
					orderType: "parking"
				},
				org: {},
				memberFund: {
					give: 0,
					funds: 0,
					coupon: 0
				}
			}
		},
		methods: {
			getOrg() { //获取网点信息
				let url = this.$apis.user.orgShow.replace("{serial}", this.$route.params.orgId);
				this.$http.request(this, url, {}, false, res => {
					let data = res.data;
					this.org = data;
					this.spaceOwner = {
						org: data.serial
					};
					this.orderOwner = {
						beBusiness: data.serial + "-",
						orderType: "parking"
					};
				});
			},
			getMemberFund() {
				let url = this.$apis.user.memberFund.replace("{serial}", this.$route.params.orgId)
				this.$http.request(this, url, {}, false, (res) => {
					console.log("会员信息", res)
					if (res.code == 0 && res.data != null) {
						let data = res.data;
						data.funds = parseFloat(data.funds * 0.01).toFixed(2);
						data.give = parseFloat(data.give * 0.01).toFixed(2);
						this.memberFund = data;
					}
				});
			},
			publishFund() {
				let url = this.$apis.user.memberFundModify.replace("{serial}", this.$route.params.orgId)
				let memberFund = Object.assign({}, this.memberFund);
				memberFund.funds = parseFloat(memberFund.funds * 100).toFixed(0);
				memberFund.give = parseFloat(memberFund.give * 100).toFixed(0);
				this.$http.request(this, url, memberFund, true, (res) => {

				});
			}
		},
		created() {
			this.getOrg();
			this.getMemberFund();
		}
	}
</script>

<style>
</style>
