/**
 * 接口文档列表
 */
import http from '../index';

const baseUserURL = http.baseURL + '/user';

export default {

	memberCenterTotalData: "GET:" + baseUserURL + '/v1/api/sys/member/data/center/total',
	memberCenterTypeData: "GET:" + baseUserURL + '/v1/api/sys/member/data/type',
	memberCenterCouponTagData: "GET:" + baseUserURL + '/v1/api/sys/member/data/coupon/tag',
	memberCenterVipData: "GET:" + baseUserURL + '/v1/api/sys/member/data/vip',
	memberCenterServiceData: "GET:" + baseUserURL + '/v1/api/sys/member/data/service',
	memberCenterWeekData: "GET:" + baseUserURL + '/v1/api/sys/member/data/week/recent',
	orgCenterTotalData: "GET:" + baseUserURL + '/v1/api/manage/org/data/center/total',

	memberRegData: "GET:" + baseUserURL + '/v1/api/sys/member/data/month/recent',
	memberTotalData: "GET:" + baseUserURL + '/v1/api/sys/member/data/total',
	memberFundsData: "GET:" + baseUserURL + '/v1/api/sys/member/data/funds',
	memberSumTotalData: "GET:" + baseUserURL + '/v1/api/manage/member/data/sum/total',

	orgProvinceData: "GET:" + baseUserURL + '/v1/api/manage/org/data/city',
	orgProvinceIncomeData: "GET:" + baseUserURL + '/v1/api/manage/org/data/city/income',
	orgMemberTopData: "GET:" + baseUserURL + '/v1/api/manage/org/data/top/member',
	orgIncomeTopData: "GET:" + baseUserURL + '/v1/api/manage/org/data/top/income',
	orgNatureData: "GET:" + baseUserURL + '/v1/api/manage/org/data/nature',

	storeMemberShow: "GET:" + baseUserURL + '/v1/api/manage/store/member/{serial}/show',

	memberExport: "GET:" + baseUserURL + '/v1/api/sys/member/batch/export',
	memberList: "GET:" + baseUserURL + '/v1/api/sys/member/list',
	memberShow: "GET:" + baseUserURL + '/v1/api/sys/member/{serial}/show',
	memberFund: "GET:" + baseUserURL + '/v1/api/sys/member/{serial}/fund',
	memberFundModify: "POST:" + baseUserURL + '/v1/api/sys/member/{serial}/fund/modify',
	memberRecommend: "POST:" + baseUserURL + '/v1/api/sys/member/{serial}/recommend',
	memberEnable: "POST:" + baseUserURL + '/v1/api/sys/member/{serial}/enable',
	memberBatchRemove: "GET:" + baseUserURL + '/v1/api/sys/member/batch/remove',

	storeMemberList: "GET:" + baseUserURL + '/v1/api/manage/store/member/list',
	storeMemberBatchRemove: "GET:" + baseUserURL + '/v1/api/manage/store/batch/remove',

	vipCardList: "GET:" + baseUserURL + '/v1/api/vip/list',
	vipCardPublish: "POST:" + baseUserURL + '/v1/api/vip/publish',
	vipCardRemove: "POST:" + baseUserURL + '/v1/api/vip/{serial}/remove',

	vipLevelList: "GET:" + baseUserURL + '/v1/api/vip/level/list',
	vipLevelPublish: "POST:" + baseUserURL + '/v1/api/vip/level/publish',
	vipLevelRemove: "POST:" + baseUserURL + '/v1/api/vip/level/{serial}/remove',

	vipPriceList: "GET:" + baseUserURL + '/v1/api/vip/price/list',
	vipPricePublish: "POST:" + baseUserURL + '/v1/api/vip/price/publish',
	vipPriceRemove: "POST:" + baseUserURL + '/v1/api/vip/price/{serial}/remove',

	qrCouponList: "GET:" + baseUserURL + '/v1/api/vip/coupon/qr/list',
	qrCouponPublish: "POST:" + baseUserURL + '/v1/api/vip/coupon/qr/publish',
	qrCouponRemove: "POST:" + baseUserURL + '/v1/api/vip/coupon/qr/{serial}/remove',

	giveCouponList: "GET:" + baseUserURL + '/v1/api/vip/coupon/give/list',
	giveCouponPublish: "POST:" + baseUserURL + '/v1/api/vip/coupon/give/publish',
	giveCouponRemove: "POST:" + baseUserURL + '/v1/api/vip/coupon/give/{serial}/remove',

	washRecordList: "GET:" + baseUserURL + '/v1/api/wash/record/list',
	washRecordPublish: "POST:" + baseUserURL + '/v1/api/wash/record/publish',
	washRecordRemove: "POST:" + baseUserURL + '/v1/api/wash/record/{serial}/remove',
	washRecordMoney: "GET:" + baseUserURL + '/v1/api/wash/record/{serial}/money',
	washRecordSend: "POST:" + baseUserURL + '/v1/api/wash/record/{serial}/send',
	washRecordItemList: "GET:" + baseUserURL + '/v1/api/wash/record/items',

	memberCouponList: "GET:" + baseUserURL + '/v1/api/manage/coupon/list',

	orgExport: "GET:" + baseUserURL + '/v1/api/organization/batch/export',
	orgList: "GET:" + baseUserURL + '/v1/api/organization/list',
	orgPublish: "POST:" + baseUserURL + '/v1/api/organization/publish',
	orgRemove: "GET:" + baseUserURL + '/v1/api/organization/{serial}/remove',
	orgBatchRemove: "GET:" + baseUserURL + '/v1/api/organization/batch/remove',
	orgShow: "GET:" + baseUserURL + '/v1/api/organization/{serial}/show',
	orgRecommend: "POST:" + baseUserURL + '/v1/api/organization/{serial}/recommend',
	orgTop: "POST:" + baseUserURL + '/v1/api/organization/{serial}/top',
	orgStop: "GET:" + baseUserURL + '/v1/api/organization/{serial}/stop',
	orgLevelList: "GET:" + baseUserURL + '/v1/api/organization/level/list',

	contractList: "GET:" + baseUserURL + '/v1/api/manage/contract/list',
	contractPublish: "POST:" + baseUserURL + '/v1/api/manage/contract/publish',
	contractRemove: "GET:" + baseUserURL + '/v1/api/manage/contract/{serial}/remove',

	managerList: "GET:" + baseUserURL + '/v1/api/manager/list',
	managerPublish: "POST:" + baseUserURL + '/v1/api/manager/publish',
	managerRemove: "GET:" + baseUserURL + '/v1/api/manager/remove/{serial}',
	managerPwdReset: "GET:" + baseUserURL + '/v1/api/manager/{serial}/reset/pwd',
	managerChangePwd: "POST:" + baseUserURL + '/v1/api/manager/change/pwd',
	departmentList: "GET:" + baseUserURL + '/v1/api/manage/department/list',
	departmentPublish: "POST:" + baseUserURL + '/v1/api/manage/department/publish',
	departmentRemove: "GET:" + baseUserURL + '/v1/api/manage/department/{serial}/remove',
	roleList: "GET:" + baseUserURL + '/v1/api/manage/role/list',
	rolePublish: "POST:" + baseUserURL + '/v1/api/manage/role/publish',
	roleRemove: "POST:" + baseUserURL + '/v1/api/manage/role/item/{serial}/remove',
	logList: "GET:" + baseUserURL + '/v1/api/log/list',

	menuList: "GET:" + baseUserURL + '/v1/api/menu/list',
	menuPublish: "POST:" + baseUserURL + '/v1/api/menu/publish',
	menuRemove: "GET:" + baseUserURL + '/v1/api/menu/{serial}/remove',

	platformList: "GET:" + baseUserURL + '/v1/api/manage/platform/list',

	overlayList: "GET:" + baseUserURL + '/v1/api/overlay/list',
	overlayPublish: "POST:" + baseUserURL + '/v1/api/overlay/publish',
	overlayRemove: "GET:" + baseUserURL + '/v1/api/overlay/{serial}/remove',

	doctorExport: "GET:" + baseUserURL + '/v1/api/manage/doctor/batch/export',
	doctorList: "GET:" + baseUserURL + '/v1/api/manage/doctor/list',
	doctorPublish: "POST:" + baseUserURL + '/v1/api/manage/doctor/publish',
	doctorRemove: "GET:" + baseUserURL + '/v1/api/manage/doctor/{serial}/remove',
	doctorBatchRemove: "GET:" + baseUserURL + '/v1/api/manage/doctor/batch/remove',
	doctorShow: "GET:" + baseUserURL + '/v1/api/manage/doctor/{serial}/show',
	doctorVerify: "GET:" + baseUserURL + '/v1/api/manage/doctor/{serial}/verify',
	doctorResetPwd: "GET:" + baseUserURL + '/v1/api/manage/doctor/{serial}/reset/pwd',
	
	managerReceptReg: "POST:" + baseUserURL + '/v1/api/customer/register',
	managerReceptCheck: "POST:" + baseUserURL + '/v1/api/customer/check/member',
	managerReceptVerify: "POST:" + baseUserURL + '/v1/api/customer/verify/member',
	managerReceptChangePwd: "POST:" + baseUserURL + '/v1/api/customer/change/pwd',

	minedoctorShow: "GET:" + baseUserURL + '/v1/api/mine/doctor/show',
	mineDoctorPublish: "POST:" + baseUserURL + '/v1/api/mine/doctor/publish',
	
	actionList: "GET:" + baseUserURL + '/v1/api/sys/footprint/list',
}
