<template>
   <div class="showSystem">

    <div class="pdding20 bgffffff radius5 font12">
       <el-breadcrumb separator="/">
         <el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
         <el-breadcrumb-item>早教管理</el-breadcrumb-item>
         <el-breadcrumb-item>订单</el-breadcrumb-item>
       </el-breadcrumb>
    </div>

    <el-tabs type="border-card" class="mgtop10">
      <el-tab-pane label="订单信息">

        <el-row class="wf lineh40">
          <el-col :span="12">订单代码：{{order.code}}</el-col>
          <el-col :span="12">订单名称：{{order.name}}</el-col>
          <el-col :span="12">所属机构：{{order.orgName}}</el-col>
          <el-col :span="12">订单类型：{{order.type}}</el-col>
          <el-col :span="12">创建时间：{{order.subTime}}</el-col>
          <el-col :span="12">订单描述：{{order.remark}}</el-col>
        </el-row>

      </el-tab-pane>
    </el-tabs>


   </div>
</template>

<script>
export default {
  name: 'showSystem',
  data () {
    return {
      dialogVisible: false,
      apiVisible:false,
      searchForm:{
        page:1,
        pageSize:20,
        total:0
      },
      order:{},
    }
  },
  methods:{
      pageChange(page){
        this.searchForm.page=page;
        this.getOrders();
      },
      getOrder(){
        this.$order.order_getOrder(this.$route.params.orderId,data=>{
          this.order = data;
        });
      }
    },
    created() {
      this.getOrder();
    }
}
</script>

<style>
  .el-table__expanded-cell{
    background: #F7F7F7;
  }
</style>
