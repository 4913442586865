<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i>首页</el-breadcrumb-item>
				<el-breadcrumb-item>汽车服务</el-breadcrumb-item>
				<el-breadcrumb-item>评价管理</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wfc mgtop10">
				<div class="fl">
					<el-badge :value="evalautionTotal.fine" class="item mgl20">
						<el-button size="small">好评</el-button>
					</el-badge>
					<el-badge :value="evalautionTotal.good" class="item mgl20">
						<el-button size="small">中评</el-button>
					</el-badge>
					<el-badge :value="evalautionTotal.bad" class="item mgl20" type="primary">
						<el-button size="small">差评</el-button>
					</el-badge>
				</div>
				<div class="fr">
					<span>好评度</span>
					<label style="font-size: 20px;font-weight: bold;color: #E4393C;">
						<span
							style="font-size: 40px;margin-right: 5px;">{{parseFloat(evalautionTotal.total).toFixed(1)}}</span>%
					</label>
				</div>
			</div>
		</div>


		<div class="pdding20 bgffffff overflow mgtop10">

			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">数据展示</span>
			</div>

			<ul class="wfc mgtop10">
				<li class="wf mgbtm10" v-for="(item,index) in evaluationList" :key="index"
					style="bevaluation-bottom: 1px dashed #d2d2d2;">
					<div class="pdding10 overflow">
						<div class="fl w120">
							<img :src="item.memberAvater" class="w60 h60 radius50p" />
							<div>{{item.memberName}}</div>
						</div>
						<div class="fl w600 mgl20" style="width: 730px;">
							<div class="wf">
								<el-rate v-model="item.fraction" disabled show-score text-color="#ff9900"
									score-template="{value}"></el-rate>
							</div>
							<div class="wf lineh20 mgtop20" style="color: #000000;">
								{{item.evaluate}}
							</div>
							<div class="wf lineh20 color9a mgtop10">
								<span>{{item.subTime}}</span>
								<label class="fr">
									<el-button type="danger" size="small" icon="el-icon-more" @click="reply(index)">回复
									</el-button>
								</label>
							</div>
						</div>

					</div>
				</li>
				<li class="wf bevaluation radius5 mgbtm20" style="bevaluation:1px solid #F3F3F3"
					v-show="evaluationList.length==0">
					<div class="wf h300 center lineh200">
						<div class="mgtop10">尚未有卡券评价哦！</div>
					</div>
				</li>
			</ul>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination background layout="prev, pager, next" :total="searchForm.total" class="fr"
						:page-size="evaluationList.pageSize" @current-change="pageChange">
					</el-pagination>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
	export default {
		name: 'fragementAddress',
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				evalautionTotal: {
					bad: 0,
					fine: 0,
					good: 0,
					pic: 0,
					sum: 0,
					total: 100
				},
				accountForm: {},
				evaluationForm: {},
				evaluationList: [],
				orgs: [],
				systems: [],
				loading: false,
				rules: {
					name: [{
						required: true,
						message: '请输入订单名称',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入订单代码',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择订单类型',
						trigger: 'blur'
					}],
					state: [{
						required: true,
						message: '请选择订单状态',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			pageChange(page) {
				this.searchForm.page = page;
				this.getEvaluations();
			},
			getEvaluationType() {
				this.$http.request(this, this.$apis.pay.shopEvaluationType, this.searchForm, false, (res) => {
					this.evalautionTotal = res.data;
				});
			},
			getEvaluations() {
				this.$http.request(this, this.$apis.pay.shopEvaluationList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					this.evaluationList = res.data;
				});
			},
			showEvaluation(index) {
				var evaluationForm = this.evaluationList[index];
				this.evaluationForm = evaluationForm;
				this.dialogVisible = true;
			}
		},
		created() {
			this.getEvaluationType();
			this.getEvaluations();
		}
	}
</script>

<style>
</style>
