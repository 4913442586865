<template>
	<div class="home pdding10">
		<el-row :gutter="10">
			<!-- 数据统计 -->
			<el-col :span="24">
				<el-row :gutter="10" class="white">
					<el-col :span="12">
						<div class="wf center radius5 bgffffff relative" style="height:120px;">
							<div class="mgtop30 overflow h60" style="padding-left: 80px;position: relative">
								<img src="/static/images/data/mdata_jrxzkh.png"
									style="width:60px;height:60px;position: absolute;left: 20px;" />
								<div class="wf lineh20 font12 mgtop5" style="color: #ea6372;">建档总数</div>
								<div class="wf lineh30 font24 bold" style="color: #ea6372;">
									{{totalData.sum}}<span class="font16">人</span>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="wf center radius5 bgffffff relative" style="height:120px;">
							<div class="mgtop30 overflow h60" style="padding-left: 80px;position: relative">
								<img src="/static/images/data/mdata_jrxzdp.png"
									style="width:60px;height:60px;position: absolute;left: 20px;" />
								<div class="wf lineh20 font12 mgtop5" style="color: #feb643;">社区待回</div>
								<div class="wf lineh30 font24 bold" style="color: #feb643;">{{replyData["0"]}}
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="8" class="mgtop10">
						<div class="wf center radius5 bgffffff relative" style="height:120px;">
							<div class="mgtop30 overflow h60" style="padding-left: 80px;position: relative">
								<img src="/static/images/data/mdata_jrxzmz.png"
									style="width:60px;height:60px;position: absolute;left: 20px;" />
								<div class="wf lineh20 font12 mgtop5" style="color: #3F85E9;">转诊记录</div>
								<div class="wf lineh30 font24 bold" style="color: #3F85E9;">
									{{totalData.referral}}
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="8" class="mgtop10">
						<div class="wf center radius5 bgffffff relative" style="height:120px;">
							<div class="mgtop30 overflow h60" style="padding-left: 80px;position: relative">
								<img src="/static/images/data/mdata_jrxzhy.png"
									style="width:60px;height:60px;position: absolute;left: 20px;" />
								<div class="wf lineh20 font12 mgtop5" style="color: #3da5f6;">面诊记录</div>
								<div class="wf lineh30 font24 bold" style="color: #3da5f6;">
									{{totalData.face}}
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="8" class="mgtop10">
						<div class="wf center radius5 bgffffff relative" style="height:120px;">
							<div class="mgtop30 overflow h60" style="padding-left: 80px;position: relative">
								<img src="/static/images/data/mdata_ljkh.png"
									style="width:60px;height:60px;position: absolute;left: 20px;" />
								<div class="wf lineh20 font12 mgtop5" style="color: #009966;">干眼护理记录</div>
								<div class="wf lineh30 font24 bold" style="color: #009966;">
									{{totalData.nurse}}
								</div>
							</div>
						</div>
					</el-col>
					
				</el-row>
			</el-col>
			<!-- 数据统计 end -->

			<!-- 用户信息 数据 -->
			<el-col :span="6">
				<div class="pdding10 radius5 bgffffff mgtop10">
					<div class="wf" style="height:100px;position:relative">
						<div class="wf lineh20 bold font14">
							<span style="color: #3F85E9;">欢迎您，{{principal.account}}</span>
						</div>
						<div class="wf lineh20 bold font12">
							<span class="color9a">上次登录时间：{{principal.lastLogin}}</span>
						</div>
						<div class="wf mgtop10">
							<div class="w40 h40 radius50p overflow" style="position: absolute;left: 0;top: 0;">
								<img src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
									width="100%" height="100%" />
							</div>
							<div class="font12 mgl10" style="padding-left: 50px;">
								<div class="wf lineh20">姓名：{{principal.realName}}</div>
								<div class="wf lineh20">单位：{{principal.identityName}}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="pdding10 radius5 bgffffff mgtop10">
					<el-calendar v-model="toDay"> </el-calendar>
				</div>
			</el-col>
			<!-- 用户信息 数据 end -->

			<el-col :span="18">
				<div class="pdding10 radius5 bgffffff mgtop10">
					<div class="h400">
						<div class="wf lineh40">
							<div class="h15 mgtop13 fl" style="background: #3ac87e;width: 4px;"></div>
							<span class="fl bold mgl10 font16">文章资讯</span>
						</div>

						<ul class="wf lineh30">
							<li v-for="(item,index) in news" :key="index" class="relative"
								style="border-bottom: 1px solid #F1F1F1;">
								<div class="radius50p"
									style="position: absolute;width:4px;height:4px;background: #AA5500;left: 0;top:13px;">
								</div>
								<div class="ellipsis h30 lineh30 overflow"
									style="padding-right: 180px;padding-left: 10px;">
									<a :href="$http.webConfig.webUrls.app +item.url"
										target="_blank"><span>{{item.title}}</span></a>
								</div>
								<span class="color9a"
									style="position: absolute;right: 0;top: 0px;line-height: 30px;">{{item.subTime}}
								</span>
							</li>
							<li v-if="news.length==0" class="wf lineh200 center">暂无数据</li>
						</ul>

					</div>
				</div>

			</el-col>

		</el-row>

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'Home',
		computed: {
			...mapGetters([
				'principal', 'isLogin'
			])
		},
		data() {
			return {
				toDay: new Date(),
				timeName: new Date().getHours() < 12 ? "上午" : "下午",
				replyData: {
					"0": 0,
					"1": 0
				},
				totalData: {
					face: 0,
					nurse: 0,
					sum: 0,
					referral: 0
				},
				news: []
			}
		},
		methods: {
			getTotalData() {
				this.$http.request(this, this.$apis.tools.dataExpertPatientTotal, {}, false, res => {
					if (res.code == 0)
						for (var key in res.data)
							this.totalData[key] = res.data[key];
				});
			},
			getReplyData() {
				this.$http.request(this, this.$apis.material.postReplyData, {}, false, res => {
					if (res.code == 0)
						res.data.forEach(item => {
							this.replyData[item.label] = item.value;
						});
				});
			},
			getNews() {
				this.$http.request(this, this.$apis.material.newsList, {
					pageSize: 12
				}, false, res => {
					console.log("帖子数据！", res)
					this.news = res.data;
				});
			},

		},
		created() {
			this.getTotalData();
			this.getReplyData();
			this.getNews();
		}

	}
</script>

<style>
	.el-calendar-table .el-calendar-day {
		height: 30px;
		text-align: center;
	}

	.el-calendar-table .el-calendar-day span {
		display: block;
		margin: 0px auto;
		width: 30px;
		height: 30px;
		line-height: 30px;
	}

	.el-calendar-table,
	.el-calendar-table tr td {
		border: 0px !important;
	}

	.el-calendar-table td.is-today {
		background: transparent;
	}

	.el-calendar-table td.is-today span {
		background: #3F85E9;
		border-radius: 50%;
		color: white;
	}

	.el-calendar__body {
		padding: 0;
	}

	.el-calendar__header {
		border-bottom: 0px;
	}

	.el-calendar__header .el-calendar__button-group {
		display: none;
	}

	.el-calendar__title {
		width: 100%;
		text-align: center;
	}
</style>
