import Vue from 'vue'
import Vuex from 'vuex'

import users from './module/users';
import apply from './module/apply';

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    users,
    apply
  }
})
