<template>
	<div class="wf">
		<div :id="id" :style="'width: 100%;height:'+height+'px;'"></div>
	</div>
</template>

<script>
	export default {
		name: "lineChart",
		props: ["data", "height", "title", "legend", "unit", "id"],
		data() {
			return {
				sourceData: [],
				option: {
					title: {
						text: ''
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: []
					},
					toolbox: {
						show: true,
						feature: {
							magicType: {
								type: ['line', 'bar']
							},
							restore: {},
							saveAsImage: {}
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: []
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value}'
						}
					},
					series: []
				}
			};
		},
		methods: {
			installChart() {
				this.myChart = echarts.init(document.getElementById(this.id));
				this.packOption();
			},
			packOption() {
				var legend = [];
				this.sourceData.forEach((item, index) => {
					if (index == 0)
						for (var key in item) {
							if (key != 'label')
								legend.push(key);
						}
				});
				this.legend = legend;
				this.option.title.text = this.title;
				this.option.yAxis.axisLabel.formatter = "{value} " + this.unit;
				this.option.legend.data = this.legend;
				this.option.xAxis.data = []
				this.sourceData.forEach(item => {
					this.option.xAxis.data.push(item.label);
				});

				this.option.series = [];
				this.legend.forEach(name => {
					let datas = [];
					this.sourceData.forEach(item => {
						datas.push(item[name]);
					});
					this.option.series.push({
						name: name,
						type: 'line',
						smooth: true,
						label: {
							show: true
						},
						data: datas,
						markPoint: {
							data: [{
									type: 'max',
									name: '最大值'
								},
								{
									type: 'min',
									name: '最小值'
								}
							]
						},
						markLine: {
							data: [{
								type: 'average',
								name: '平均值'
							}]
						}
					});
				});
				this.myChart.clear();
				this.myChart.setOption(this.option);
			}
		},
		mounted() {
			this.installChart();
		},
		watch: {
			data(newValue, oldValue) {
				this.sourceData = newValue;
				this.packOption();
			}
		}
	};
</script>
