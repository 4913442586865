<template>
	<div class="fragementBase">

		<!-- 最新激活记录 -->
		<div class="wf bgffffff overflow">
			<el-row gutter="10">
				<el-tree class="wf" ref="tree" :data="stationList" node-key="id" :props="props" lazy :load="loadNode"
					highlight-current :default-expand-all="false" :expand-on-click-node="false">
					<div class="custom-tree-node" style="width: 80%;" slot-scope="{ node, data }">
						<el-col span="24">
							<div class="wf">
								<span class="bold font14">{{data.name}}</span>
								<el-tag v-if="data.nature==0" type="warning" size="small">平台运营中心</el-tag>
								<el-tag v-if="data.nature==1" ttype="success" size="small">区域运营中心</el-tag>
								<el-tag v-if="data.nature==2" ttype="primary" size="small">医疗机构/组织/服务中心</el-tag>
								<el-tag v-if="data.nature==3" type="info" size="small">眼管家</el-tag>
							</div>
						</el-col>
						<el-col span="12">
							<div class="wf">站点编码：{{data.orgCode}}</div>
							<div class="wf">联系人：{{data.contact}}</div>
							<div class="wf">联系电话：{{data.contactNumber}}</div>
						</el-col>
						<el-col span="12">
							<div class="wf lineh30">用户数量：{{data.member}}</div>
						</el-col>
					</div>
				</el-tree>
			</el-row>
		</div>


	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		data() {
			return {
				dialogVisible: false,
				stationList: [],
				props: {
					label: 'name',
					value: 'serial'
				}
			}
		},
		methods: {
			loadNode(node, resolve) {
				this.$http.request(this, this.$apis.user.orgLevelList, {
					level: node.level,
					parent: node.data.serial
				}, false, res => {
					console.log("获取泊位点", res)
					res.data.filter((item, index) => {
						item.isLeaf = true;
						item.children = [];
					});
					resolve(res.data);
				});
			}
		},
		created() {

		}
	}
</script>
