<template>
	<div class="userInfo">

		<!-- 标题 -->
		<div class="pdding20 bgffffff overflow">
			<span class="bold font14">个人信息</span>
		</div>

		<div class="pdding20 bgffffff overflow mgtop20">

			<el-tabs v-model="activeName" type="card">
				<el-tab-pane label="基本信息" name="first">
					<div class="wf mgtop40">
						<el-form ref="userForm" :inline="true" :model="userForm" label-width="80px" :rules="rules">
							<div class="wf">
								<el-form-item label="昵称:" prop="nickname">
									<el-input v-model="userForm.nickname"></el-input>
								</el-form-item>
							</div>

							<div class="wf">
								<el-form-item label="姓名:" prop="realName">
									<el-input v-model="userForm.realName"></el-input>
								</el-form-item>
							</div>

							<div class="wf">
								<el-form-item label="生日:">
									 <el-date-picker
										v-model="userForm.birth"
										type="date"
										placeholder="选择日期" value-format="yyyy-MM-dd">
									</el-date-picker>
								</el-form-item>
							</div>

							<div class="wf">
								<el-form-item label="性别:">
									<el-radio-group v-model="userForm.gender">
										<el-radio label="男" value="男">男</el-radio>
										<el-radio label="女" value="女">女</el-radio>
										<el-radio label="保密" value="保密">保密</el-radio>
									</el-radio-group>
								</el-form-item>
							</div>

							<div class="wf">
								<el-form-item label="邮箱:" prop="mail">
									<el-input v-model="userForm.mail"></el-input>
								</el-form-item>
							</div>

						</el-form>
						<div class="wf mgtop20 center">
							<el-button type="primary" @click="onSubmit">立即提交</el-button>
						</div>
					</div>
					<div class="mgtop30 wf">
						<p>1.个人资料不修改项请勿填写</p>
						<p>2.充值完成后，您可以进入用户充值记录页面进行查看余额充值状态。邮箱作为密码找回条件，请正确填写</p>
					</div>
				</el-tab-pane>
				<el-tab-pane label="图像照片" name="second">

					<div class="wf mgtop40">
						<div class="wf">
							<el-upload
								class="upload-demo"
								name="mdFile"
								:action="imgUploadUrl"
								:headers="uploadHeaders"
								:on-success="handleAvaterSuccess"
								:show-file-list="false"
								:before-upload="beforeAvatarUpload">
								<el-button size="small" type="primary">选择您要上传的图片</el-button>
							</el-upload>
							<span style="color: #6b6b6b;">仅支持JPG、PNG、JPEG格式，文件小于1M</span>
							<div class="wf h300 mgtop10 overflow center" style="background: #f7f7f7;">
								<img alt="" id="avaterCover" class="center-block w150 h150 border" :src="userForm.accountPic" style="margin-top: 75px;background: #fff;">
							</div>
						</div>

						<div class="wf lineh30 bold mgtop20">为您推荐</div>
						<ul class="avater_icon wf pointer w500" id="sysIcon">
							<li @click="chooiceAvater('http://i.cyszx.com/defaultImgs/1.jpg')"><img src="http://i.cyszx.com/defaultImgs/1.jpg"></li>
							<li @click="chooiceAvater('http://i.cyszx.com/defaultImgs/2.jpg')"><img src="http://i.cyszx.com/defaultImgs/2.jpg"></li>
							<li @click="chooiceAvater('http://i.cyszx.com/defaultImgs/3.jpg')"><img src="http://i.cyszx.com/defaultImgs/3.jpg"></li>
              <li @click="chooiceAvater('http://i.cyszx.com/defaultImgs/4.jpg')"><img src="http://i.cyszx.com/defaultImgs/4.jpg"></li>
              <li @click="chooiceAvater('http://i.cyszx.com/defaultImgs/5.jpg')"><img src="http://i.cyszx.com/defaultImgs/5.jpg"></li>
              <li @click="chooiceAvater('http://i.cyszx.com/defaultImgs/6.jpg')"><img src="http://i.cyszx.com/defaultImgs/6.jpg"></li>
              <li @click="chooiceAvater('http://i.cyszx.com/defaultImgs/7.jpg')"><img src="http://i.cyszx.com/defaultImgs/7.jpg"></li>
              <li @click="chooiceAvater('http://i.cyszx.com/defaultImgs/8.jpg')"><img src="http://i.cyszx.com/defaultImgs/8.jpg"></li>
              <li @click="chooiceAvater('http://i.cyszx.com/defaultImgs/9.jpg')"><img src="http://i.cyszx.com/defaultImgs/9.jpg"></li>
              <li @click="chooiceAvater('http://i.cyszx.com/defaultImgs/10.jpg')"><img src="http://i.cyszx.com/defaultImgs/10.jpg"></li>
              <li @click="chooiceAvater('http://i.cyszx.com/defaultImgs/11.jpg')"><img src="http://i.cyszx.com/defaultImgs/11.jpg"></li>
              <li @click="chooiceAvater('http://i.cyszx.com/defaultImgs/12.jpg')"><img src="http://i.cyszx.com/defaultImgs/12.jpg"></li>
						</ul>

						<div class="wf mgtop20 center">
							<el-button type="primary" @click="onSubmitAvater">立即提交</el-button>
						</div>

					</div>

				</el-tab-pane>
			</el-tabs>

		</div>

	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
  export default {
    data() {
      return {
				imgUploadUrl:this.$http.baseUploadUrl,
				uploadHeaders:{'Authorization':sessionStorage.getItem("token")},
				activeName:'first',
        userForm: {
					gender:'男'
				},
				birth:{
					year:[2019],
					month:[1,2,3,4,5,6,7,8,9,10,11,12],
					day:[1,2,3,4]
				},
        rules:{
        	nickname: [
        		{ required: true, message: '请输入您的昵称', trigger: 'blur' },
        		{ min: 2, max: 20, message: '长度在 2 到 20个字符', trigger: 'blur' }
        	],
        	realName: [
        		{ required: true, message: '请输入真实姓名', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在 2 到 20个字符', trigger: 'blur' }
        	],
        	mail: [
        		{ required: true, message: '请输入您的工作邮箱', trigger: 'blur' },
            { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
            { pattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/, message: '请输入正确的邮箱地址' }
        	]
        }
      }
    },
		computed: {
			...mapGetters([
			  'principal'
			])
		},
    methods: {
				beforeAvatarUpload(file) {

          console.log("图片类型",file.type)

					const isJPG = file.type === 'image/jpeg';
          const isJPEG = file.type === 'image/jpeg';
          const isPNG = file.type === 'image/png';

					const isLt2M = file.size / 1024 / 1024 < 1;

					if (!(isJPG || isJPEG || isPNG)) {
						this.$message.error('上传头像图片只能是 JPG、PNG、JPEG 格式!');
					}

					if (!isLt2M) {
						this.$message.error('上传头像图片大小不能超过 1MB!');
					}
					return (isJPG || isJPEG || isPNG) && isLt2M;
				},
				handleAvaterSuccess(res,file) {
					console.log("文件上传",res)
					if(res.files.length>0)
						this.userForm.accountPic=res.files[0].uploadUrl;
				},
				chooiceAvater(avater){
					this.userForm.accountPic=avater;
				},
				onSubmit() {
          	this.$refs['userForm'].validate((valid) => {
          	if (valid) {
              this.$http.user_changeUserInfo(this,this.userForm,(page,data)=>{
                page.principal.birth = page.userForm.birth;
                page.principal.mail = page.userForm.mail;
                page.principal.gender = page.userForm.gender;
                page.principal.nickname = page.userForm.nickname;
                page.principal.realName = page.userForm.realName;
                page.$store.dispatch("changePrincipal",page.principal);
              });
            }
          });
				},
				onSubmitAvater(){//保存图像
					this.$http.user_changeUserAvater(this,this.userForm.accountPic,(page,data)=>{
						page.principal.accountPic = page.userForm.accountPic;
						page.$store.dispatch("changePrincipal",page.principal);
					});
				}
			},
			created() {
				 this.userForm=this.principal;
				 console.log("用户信息",this.userForm)
			}
  }
</script>

<style >
	.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
