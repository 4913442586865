/**
 * 接口文档列表
 */
import http from '../index';

const baseSsoURL = http.baseURL + '/sso';

export default {
	showLoginKey: "GET:" + baseSsoURL + '/v1/api/sso/random/key',
	postLogin: "POST:" + baseSsoURL + '/v1/api/sso/sign',
	refreshToken: "POST:" + baseSsoURL + '/v1/api/sso/refresh/token'
}
