<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff overflow mgtop10">

			<el-table class="mgtop10" :data="deviceList" :stripe="true" border
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="name" label="设备名称">
					<template slot-scope="scope">
						<div class="wf">{{scope.row.number}}
							<el-tag v-if="scope.row.status==-1" size="small" type="danger">已停用</el-tag>
							<el-tag v-if="scope.row.status==0" size="small" type="success">空闲</el-tag>
							<el-tag v-if="scope.row.status==1" size="small" type="primary">投币泊位</el-tag>
							<el-tag v-if="scope.row.status==2" size="small" type="primary">刷卡泊位</el-tag>
							<el-tag v-if="scope.row.status==3" size="small" type="primary">微信泊位</el-tag>
							<el-tag v-if="scope.row.status==4" size="small" type="primary">停售</el-tag>
							<el-tag v-if="scope.row.status==5" size="small" type="primary">手机号泊位</el-tag>
							<el-tag v-if="scope.row.status==6" size="small" type="primary">会员扫码泊位</el-tag>
							<el-tag v-if="scope.row.status==7" size="small" type="warning">缺泡沫</el-tag>
							<el-tag v-if="scope.row.status==9" size="small" type="danger">设备异常</el-tag>
						</div>
						<div class="wf">{{scope.row.name}}</div>
						<div class="wf">设备号：{{scope.row.machine}}</div>
						<div class="wf">投运时间：{{scope.row.worktime}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="business" label="设备商">
					<template slot-scope="scope">{{scope.row.vendorName}}</template>
				</el-table-column>
				<el-table-column prop="mprice" label="使用" align="right">
					<template slot-scope="scope">{{scope.row.used}}次</template>
				</el-table-column>
				<el-table-column prop="mprice" label="费用明细" align="right">
					<template slot-scope="scope">
						<div class="wf lineh30">用券：{{scope.row.coupon}}元</div>
						<div class="wf lineh30">用卡：{{scope.row.card}}次</div>
						<div class="wf lineh30">余额：{{scope.row.cash}}元</div>
						<div class="wf lineh30">赠送：{{scope.row.give}}元</div>
					</template>
				</el-table-column>
				<el-table-column prop="method" label="所在网点" align="center">
					<template slot-scope="scope">{{scope.row.stationName}}</template>
				</el-table-column>
				<el-table-column prop="updateTime" label="更新时间" width="120" align="center">
					<template slot-scope="scope">
						<div class="wf lineh20 center">{{scope.row.updateTime.substring(0,10)}}</div>
						<div class="wf lineh20 center">{{scope.row.updateTime.substring(11,19)}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="200">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="24">
								<el-button style="width:100%" :type="scope.row.status==0?'info':'success'" size="small"
									@click="stopDevice(scope.$index)"><i class="fa fa-stop"></i>
									<span v-if="scope.row.status==0">停用</span>
									<span v-else>启用</span>
								</el-button>
							</el-col>
							<el-col class="mgtop10" :span="12">
								<el-button style="width:100%" type="primary" size="small"
									@click="showDevice(scope.$index)"><i class="fa fa-edit"></i>编辑</el-button>
							</el-col>
							<el-col class="mgtop10" :span="12">
								<el-button style="width:100%" type="danger" size="small"
									@click="removeDevice(scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
							</el-col>
							<el-col class="mgtop10" :span="24">
								<el-button style="width:100%" type="warning" size="small"
									@click="showQrcode(scope.$index)"><i class="fa fa-qrcode"></i>二维码</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="deviceList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>

		</div>



		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="设备信息" :visible.sync="dialogVisible" width="800px">

			<el-form class="wf" ref="deviceForm" :model="deviceForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="12">
						<el-form-item label="设备名称" prop="name">
							<el-input v-model="deviceForm.name" placeholder="请填写设备名称" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="设备型号" prop="model">
							<el-input v-model="deviceForm.model" placeholder="请填写设备规格" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="设备编号" prop="number">
							<el-input v-model="deviceForm.number" placeholder="请填写设备编号" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="机器号" prop="machine">
							<el-input v-model="deviceForm.machine" placeholder="请填写机器号" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="设备类型" prop="type">
							<el-select v-model="deviceForm.type" placeholder="请选择设备类型" style="width:100%">
								<el-option label="全自动" :value="0"></el-option>
								<el-option label="全自助" :value="1"></el-option>
								<el-option label="吸尘器" :value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="设备状态" prop="status">
							<el-select v-model="deviceForm.status" placeholder="请选择设备类型" style="width:100%">
								<el-option label="已停用" :value="-1"></el-option>
								<el-option label="空闲" :value="0"></el-option>
								<el-option label="投币泊位" :value="1"></el-option>
								<el-option label="刷卡泊位" :value="2"></el-option>
								<el-option label="微信泊位" :value="3"></el-option>
								<el-option label="停售" :value="4"></el-option>
								<el-option label="手机号泊位" :value="5"></el-option>
								<el-option label="会员扫码泊位" :value="6"></el-option>
								<el-option label="缺泡沫" :value="7"></el-option>
								<el-option label="设备异常" :value="9"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="所在网点" prop="station">
							<el-select v-model="deviceForm.station" filterable remote reserve-keyword
								placeholder="请输入关键词" :remote-method="getStation" :loading="loading"
								@change="stationChange" style="width: 100%;">
								<el-option v-for="(item,index) in stationList" :value="item.serial" :label="item.name"
									:key="index"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="设备商" prop="vendor">
							<el-select v-model="deviceForm.vendor" @change="vendorChange" style="width: 100%;">
								<el-option v-for="(item,index) in vendorList" :value="item.serial" :label="item.name"
									:key="index"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="投运时间" prop="worktime">
							<el-date-picker style="width: 100%;" v-model="deviceForm.worktime" type="date"
								placeholder="选择日期" value-format="yyyy-MM-dd">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="备注" prop="remark">
							<el-input type="textarea" v-model="deviceForm.remark" rows="5" placeholder="请填写注意事项">
							</el-input>
						</el-form-item>
					</el-col>

				</el-row>

			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishDevice">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

		<fragement-code ref="qrcode"></fragement-code>

	</div>
</template>

<script>
	import FragementCode from '@/components/fragment/FragementCode'
	export default {
		name: 'fragementAddress',
		props: ["org"],
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				totalData: {
					sum: 0,
					bad: 0,
					cleaner: 0,
					device: 0
				},
				deviceNames: [],
				deviceForm: {
					station: ""
				},
				deviceList: [],
				multipleSelection: [],
				vendorList: [],
				stationList: [],
				loading: false,
				rules: {
					device: [{
						required: true,
						message: '请选择设备名称',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请填写设备包装全称',
						trigger: 'blur'
					}],
					model: [{
						required: true,
						message: '请填写设备规格',
						trigger: 'blur'
					}],
					number: [{
						required: true,
						message: '请填写机器号',
						trigger: 'blur'
					}],
					machine: [{
						required: true,
						message: '请填写设备编码',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请填写设备类型',
						trigger: 'blur'
					}],
					status: [{
						required: true,
						message: '请填写设备状态',
						trigger: 'blur'
					}],
					station: [{
						required: true,
						message: '请选择网点',
						trigger: 'blur'
					}],
					vendor: [{
						required: true,
						message: '请填写设备商',
						trigger: 'blur'
					}],
					worktime: [{
						required: true,
						message: '请填写设备投用时间',
						trigger: 'blur'
					}]
				},
			}
		},
		components: {
			FragementCode
		},
		methods: {
			resetSearch() {
				this.searchForm = {
					page: 1,
					pageSize: 20,
					total: 0
				}
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getDevices(this.org.serial);
			},
			getDevices(org) {
				this.searchForm.station = org;
				this.$http.request(this, this.$apis.device.deviceList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					res.data.forEach(item => {
						item.station = item.station.split(":")[0];
					});
					this.deviceList = res.data;
				});
			},
			publishDevice() {
				this.$refs['deviceForm'].validate((valid) => {
					if (valid) {
						var deviceForm = Object.assign({}, this.deviceForm);
						deviceForm.updateTime = "";

						this.$http.request(this, this.$apis.device.devicePublish, deviceForm, true, res => {
							let data = res.data;
							if (data != null) {
								this.deviceList.unshift(data);
							}

							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			showDevice(index) {
				var deviceForm = this.deviceList[index];
				this.getStation(deviceForm.stationName);
				this.deviceForm = deviceForm;
				this.dialogVisible = true;
			},
			removeDevice(index) {
				var device = this.deviceList[index];
				this.$confirm('此操作将删除[' + device.name + ']的厂家, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.device.deviceRemove.replace("{serial}", device.serial);
					this.$http.request(this, url, {}, true, res => {
						this.deviceList.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);
				this.$confirm('此操作将永久删除选中的设备信息 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.device.deviceBatchRemove, {
						serials: serials
					}, true, res => {
						var devices = Object.assign([], this.deviceList);
						devices.filter((device, index) => {
							this.multipleSelection.filter(select => {
								if (select.serial == device.serial) {
									this.deviceList.splice(index, 1);
								}
							})
						});
					});
				});
			},
			stopDevice(index) {
				var device = this.deviceList[index];
				this.$confirm('此操作将停用[' + device.name + ']的厂家,如需删除，请点击删除。 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.device.deviceStop.replace("{serial}", device.serial);
					this.$http.request(this, url, {}, true, res => {
						if (res.code == 0)
							device.status = res.data;
					});
				});
			},
			showQrcode(index) {
				var device = this.deviceList[index];
				this.$refs["qrcode"].dialogVisible = true;
				this.$refs["qrcode"].batch = {
					serial: this.$http.deviceUrls[device.vendorName] + "?m=" + device.serial
				};
			},
			getVendors() {
				this.$http.request(this, this.$apis.user.orgList, {
					type: 1
				}, false, (res) => {
					this.vendorList = res.data;
				});
			},
			vendorChange() {
				this.vendorList.forEach(item => {
					if (this.deviceForm.vendor == item.serial)
						this.deviceForm.vendorName = item.name;
				});
			},
			getStation(query) {
				this.loading = true;
				this.$http.request(this, this.$apis.user.orgList, {
					name: query,
					nature: "泊位点"
				}, false, res => {
					this.loading = false;
					this.stationList = res.data;
				});
			}
		},
		watch: {
			org(newValue, oldValue) {
				this.getDevices(this.org.serial);
			}
		},
	}
</script>

<style>
</style>


<style>
</style>
