<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>系统设置</el-breadcrumb-item>
				<el-breadcrumb-item>系统属性</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="名称">
						<el-input v-model="searchForm.name" clearable placeholder="请填写属性名称" />
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff overflow radius5 mgtop10">
			<div class="wf lineh40">
				<el-button type="success" size="small" @click="newElement"><i class="fa fa-plus"></i>新增属性</el-button>
			</div>

			<el-tree class="mgtop40" :data="elementList" show-checkbox node-key="id" default-expand-all
				:expand-on-click-node="false">
				<div class="custom-tree-node" slot-scope="{ node, data }">
					<div class="w400 lineh40" style="display: inline-block;">{{data.name}} <span class="color999"
							style="color: #999;">({{data.serial}})</span></div>

					<span class="fr">
						<el-button type="primary" size="small" @click="showElement(data)"><i class="fa fa-edit"></i>编辑
						</el-button>

						<el-button v-if="principal.level==3" type="danger" size="small"
							@click="removeElement(node,data)"><i class="fa fa-remove"></i>删除</el-button>

						<el-button type="success" size="small" @click="plusElement(data)"><i
								class="fa fa-plus"></i>新增子属性
						</el-button>
					</span>
				</div>
			</el-tree>
		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="属性信息" :visible.sync="dialogVisible" width="600px">
			<el-form class="wf" ref="elementForm" :model="elementForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="24">
						<el-form-item label="属性名称" prop="name">
							<el-input v-model="elementForm.name" placeholder="请填写属性名称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="属性标签" prop="label">
							<el-input v-model="elementForm.label" placeholder="请填写属性标签"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="默认值" prop="value">
							<el-input v-model="elementForm.value" placeholder="请填写属性默认值"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="属性备注" prop="tag">
							<el-input v-model="elementForm.tag" placeholder="请填写属性备注"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="属性类型" prop="type">
							<el-select type="select" v-model="elementForm.type" placeholder="请选择属性类型"
								style="width:100%;">
								<el-option value="text" label="文本数据"></el-option>
								<el-option value="select" label="下拉单选"></el-option>
								<el-option value="selectMore" label="下拉多选"></el-option>
								<el-option value="textarea" label="文本框"></el-option>
								<el-option value="image" label="图片上传"></el-option>
								<el-option value="datetime" label="日期时间"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24" v-if="elementForm.type=='select' || elementForm.type=='selectMore'">
						<el-form-item label="候选值" prop="options">
							<div class="wf lineh40">
								<el-button size="mini" @click="newOption" type="success">新增选项</el-button>
							</div>
							<div class="wf mgtop10">
								<el-row class="mgbtm10" v-for="(opt,index) in elementForm.options" :key="index">
									<el-col span="4">候选值{{index+1}}:</el-col>
									<el-col span="20">
										<el-input v-model="elementForm.options[index]" placeholder="请填写候选值"></el-input>
									</el-col>
								</el-row>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="显示列宽" prop="cols">
							<el-input v-model.number="elementForm.cols" placeholder="请填写显示列宽"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="排序" prop="sort">
							<el-input v-model.number="elementForm.sort" placeholder="请填写排序"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="是否必填" prop="required">
							<el-select type="select" v-model="elementForm.required" placeholder="请选择是否必填"
								style="width:100%;">
								<el-option :value="1" label="必填"></el-option>
								<el-option :value="0" label="选填"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="提示图片" prop="pics">
							<el-upload class="upload-demo" name="mdFile" list-type="picture-card" :action="imgUploadUrl"
								:headers="uploadHeaders" :on-change="picChange" :on-remove="picChange"
								:on-preview="handlePictureCardPreview" :file-list="picList"
								:before-upload="beforePicUpload">
								<i class="el-icon-plus"></i>
								<div slot="tip" class="el-upload__tip">只能上传JPG、PNG、JPEG文件，且不超过1M</div>
							</el-upload>
							<el-dialog :close-on-click-modal="false" :visible.sync="previewVisible">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishElement">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				previewImageUrl: '',
				previewVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				picList: [],
				dialogVisible: false,
				searchForm: {
					level: 1,
					page: 1,
					pageSize: 20,
					total: 0
				},
				parentElement: {},
				elementForm: {},
				elementList: [],
				elementLevel: 0,
				multipleSelection: [],
				rules: {
					name: [{
							required: true,
							message: '请填写属性名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					label: [{
						required: true,
						message: '请填写属性标签',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请填写属性输入类型',
						trigger: 'blur'
					}],
					required: [{
						required: true,
						message: '请填写选择是否必填',
						trigger: 'blur'
					}],
					cols: [{
						required: true,
						message: '请填写列宽',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handlePictureCardPreview(file) {
				this.previewImageUrl = file.url;
				this.previewVisible = true;
			},
			picChange(file, fileList) {
				console.log("文件上传", fileList)
				this.picList = fileList;
			},
			resetSearch() {
				this.searchForm = {
					page: 1,
					pageSize: 20,
					total: 0
				}
			},
			load(tree, treeNode, resolve) {
				console.log(tree, treeNode, resolve)
				this.$http.request(this, this.$apis.tools.elementList, {
					fold: tree.parent,
					level: 2
				}, false, (res) => {
					resolve(res.data);
				});
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getMembers()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getElements();
			},
			newElement() {
				this.elementForm = {
					sort: 0,
					cols: 12,
					required: 0,
					options: []
				};
				this.elementLevel = 0;
				this.dialogVisible = true;
			},
			plusElement(data) {
				console.log("父属性", data)
				this.parentElement = data;
				this.elementLevel = 1;
				this.elementForm = {
					sort: 0,
					options: []
				};
				this.dialogVisible = true;
			},
			packageParams(item) {
				item.options = JSON.parse(item.options);
				item.children.forEach(sitem => {
					this.packageParams(sitem);
				})
			},
			getElements() {
				this.$http.request(this, this.$apis.tools.elementList, this.searchForm, false, (res) => {
					res.data.forEach(item => {
						this.packageParams(item);
					});
					this.elementList = res.data;
				});
			},
			publishElement() {
				this.$refs['elementForm'].validate((valid) => {
					if (valid) {
						var elementForm = Object.assign({}, this.elementForm);
						elementForm.updateTime = "";
						elementForm.fold = this.elementLevel == 0 ? "" : this.parentElement.serial;
						elementForm.options = JSON.stringify(elementForm.options);
						var picList = [];
						this.picList.filter(file => {
							if (file.status == "success" && file.response.files.length > 0) {
								let doc = file.response.files[0];
								picList.push({
									uid: file.uid,
									name: doc.orianName,
									url: doc.uploadUrl
								})
							}
						});
						elementForm.pics = JSON.stringify(picList);
						this.$http.request(this, this.$apis.tools.elementPublish, elementForm, true, res => {
							let data = res.data;
							if (data != null) {
								if (this.elementLevel == 0) {
									data.children = [];
									data.hasChildren = true;
									this.elementList.push(data);
								} else {
									this.parentElement.children.push(data);
								}
							}

							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			showElement(data) {
				this.elementForm = data;
				this.picList = JSON.parse(data.pics);
				if (this.picList != null)
					this.picList.filter(pic => {
						pic.status = "success";
						pic.response = {
							files: [{
								orianName: pic.name,
								uploadUrl: pic.url
							}]
						}
					});

				this.dialogVisible = true;
			},
			removeElement(node, data) {
				const parent = node.parent;
				const children = parent.data.children || parent.data;
				const index = children.findIndex(d => d.serial === data.serial);
				var element = data;
				this.$confirm('此操作将永久删除属性[' + element.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					element: 'warning'
				}).then(() => {
					let url = this.$apis.tools.elementRemove.replace("{serial}", element.serial);
					this.$http.request(this, url, {}, true, data => {
						children.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的属性 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					element: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.serving.elementBatchRemove, {
						serials: serials
					}, true, res => {

						this.elementList = this.elementList.filter(element => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == element.serial) {
									flag = false;
								}
							})
							return flag;
						});

					});
				});
			},
			newOption() {
				this.elementForm.options.push("");
			}
		},
		created() {
			this.getElements();
		}
	}
</script>

<style>
	.el-tree-node {
		margin-bottom: 10px;
	}

	.el-tree-node__children {
		margin-top: 10px;
	}

	.el-tree-node__content {
		height: auto !important;
	}
</style>
