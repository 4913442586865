<template>
	<div class="fragementBase">
		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">检查治疗</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">色盲检测图</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->
		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form class="wf" ref="searchForm" :model="searchForm" :inline="true">
					<el-form-item label="关键词">
						<el-input v-model="searchForm.answer" size="small" placeholder="请填写关键词" />
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="doSearch"><i class="fa fa-search"></i>查询
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>


		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<el-button type="success" size="small" @click="newAchromatopsia"><i class="fa fa-plus"></i>新增图片
				</el-button>
				<el-button type="success" size="small"><i class="fa fa-download"></i> 批量导出</el-button>
			</div>

			<div class="wf mgtop20">
				<el-row gutter="10">
					<el-col span="6" v-for="(item,index) in achromatopsiaList" :key="index">
						<el-card class="box-card">
							<div class="wf h200">
								<img :src="item.pic" height="100%" class="center-block" />
							</div>
							<div class="wf bold lineh30 font14">正确答案：{{item.answer}}</div>
							<div class="wf">
								<el-row gutter="10">
									<el-col span="12" v-for="(opt,i) in item.options" :key="i">
										<div class="wf h40">错误答案{{i+1}}：{{opt}}</div>
									</el-col>
								</el-row>
							</div>
							<div class="wf mgtop10">
								<el-row :gutter="10">
									<el-col :span="12">
										<el-button style="width: 100%;" type="primary" size="small"
											@click="showAchromatopsia(index)"><i class="fa fa-edit"></i>编辑</el-button>
									</el-col>
									<el-col v-if="principal.level==3" :span="12">
										<el-button style="width: 100%;" type="danger" size="small"
											@click="removeAchromatopsia(index)"><i class="fa fa-remove"></i>删除
										</el-button>
									</el-col>
								</el-row>
							</div>
						</el-card>
					</el-col>
				</el-row>
			</div>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="achromatopsiaList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="图片信息" :visible.sync="dialogVisible" width="600px">
			<el-form class="wf" ref="achromatopsiaForm" :model="achromatopsiaForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="24">
						<el-form-item label="上传图片" prop="pic">
							<el-upload class="avatar-uploader" name="mdFile" :action="imgUploadUrl"
								:headers="uploadHeaders" :show-file-list="false" :on-success="handleCoverSuccess"
								:before-upload="beforePicUpload">
								<img v-if="achromatopsiaForm.pic" :src="achromatopsiaForm.pic" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<div class="wf lineh20"> <i class="el-icon-star-off"></i>建议使用800*800正方形图片，支持JPG、PNG格式，最大1MB
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="正确答案" prop="answer">
							<el-input v-model="achromatopsiaForm.answer" placeholder="请填写正确答案"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12" v-for="(opt,index) in achromatopsiaForm.options">
						<el-form-item :label="'错误答案'+(index+1)" prop="options">
							<el-input v-model="achromatopsiaForm.options[index]" placeholder="请填写错误答案"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishAchromatopsia">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				achromatopsiaForm: {},
				achromatopsiaList: [],
				multipleSelection: [],
				rules: {
					answer: [{
						required: true,
						message: '请设置正确答案',
						trigger: 'blur'
					}],
					options: [{
						required: true,
						message: '请设置错误答案',
						trigger: 'blur'
					}],
					pic: [{
						required: true,
						message: '请上传图片',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handleCoverSuccess(res, file) {
				console.log("文件上传", res)
				if (res.files.length > 0)
					this.achromatopsiaForm.pic = res.files[0].uploadUrl;
			},
			doSearch() {
				this.pageChange(1);
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getAchromatopsias();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getAchromatopsias();
			},
			newAchromatopsia() {
				this.achromatopsiaForm = {
					pic: "",
					options: ["", "", "", ""]

				};
				this.dialogVisible = true;
			},
			getAchromatopsias() {
				this.$http.request(this, this.$apis.tools.achromatopsiaList, this.searchForm, false, res => {
					this.searchForm.total = res.count;
					res.data.forEach(item => {
						item.options = JSON.parse(item.options);
					})
					this.achromatopsiaList = res.data;
					console.log(res.data);
				});
			},
			publishAchromatopsia() {
				this.$refs['achromatopsiaForm'].validate((valid) => {
					if (valid) {
						var achromatopsiaForm = Object.assign({}, this.achromatopsiaForm);
						achromatopsiaForm.updateTime = "";
						achromatopsiaForm.options = JSON.stringify(achromatopsiaForm.options);

						this.$http.request(this, this.$apis.tools.achromatopsiaPublish, achromatopsiaForm, true,
							res => {
								let data = res.data;
								if (data != null) {
									data.options = JSON.parse(data.options);
									this.achromatopsiaList.unshift(data);
								}

								if (res.code == 0)
									this.dialogVisible = false;
							});
					}
				});
			},
			showAchromatopsia(index) {
				var achromatopsiaForm = this.achromatopsiaList[index];
				this.achromatopsiaForm = achromatopsiaForm;
				this.dialogVisible = true;

			},
			removeAchromatopsia(index) {
				var achromatopsia = this.achromatopsiaList[index];
				this.$confirm('此操作将永久删除[' + achromatopsia.answer + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.tools.achromatopsiaRemove.replace("{serial}", achromatopsia.serial);
					this.$http.request(this, url, {}, true, res => {
						this.achromatopsiaList.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的图片 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.tools.achromatopsiaBatchRemove, {
						serials: serials
					}, true, res => {
						this.achromatopsiaList = this.achromatopsiaList.filter(achromatopsia => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == achromatopsia.serial) {
									flag = false;
								}
							})
							return flag;
						});
					});
				});
			},
		},
		created() {
			this.getAchromatopsias();
		}
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
	}

	.avatar {
		width: 148px;
		height: 148px;
		display: block;
	}
</style>
