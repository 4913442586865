<template>
	<div class="pdding20 bgffffff">
		<el-table class="mgtop20" :data="dataList" :stripe="true" border>
			<el-table-column label="序号" type="index" width="55"></el-table-column>
			<el-table-column label="名称" prop="label" />
			<el-table-column label="数量" prop="value" />
			<el-table-column label="占比" prop="rate" />
		</el-table>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				dataList: []
			}
		},
		methods: {
			getProducts() {
				this.$http.request(this, this.$apis.tools.dataPatientDisease, {
					tag: "ztpg"
				}, false, res => {
					if (res.code == 0) {
						let sum = 0;
						res.data.forEach(item => {
							sum += item.value;
							item.rate = "0.00%"
						});
						if (sum > 0)
							res.data.forEach(item => {
								item.rate = parseFloat(item.value / sum * 100).toFixed(2) + "%";
							});

						this.dataList = res.data;
					}
				});
			}
		},
		created() {
			this.getProducts();
		}
	}
</script>
