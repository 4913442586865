<template>
	<div class="fragementBase">

		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">卡券管理</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">卡券发放</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->

		<div class="pdding20auto bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form class="wf" ref="searchForm" :model="searchForm" :inline="true">
					<el-form-item label="券名称">
						<el-input v-model="searchForm.name" size="small" placeholder="请填写消费券名称" />
					</el-form-item>
					<el-form-item label="客户">
						<el-input v-model="searchForm.object" size="small" placeholder="请填写姓名或手机号" />
					</el-form-item>
					<el-form-item label="发放日期">
						<el-date-picker v-model="searchForm.datetimerange" type="daterange" @change="dateChange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							value-format="yyyy-MM-dd HH:mm:ss">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" size="small" @click="doSearch">查询</el-button>
						<el-button type="success" size="small" @click="newCoupon"><i class="fa fa-plus"></i>新增消费券
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<!-- 最新激活记录 -->
		<div class="pdding20auto bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">数据展示</span>
			</div>

			<el-table :data="couponList" :stripe="true" border class="mgtop10">
				<el-table-column prop="subTime" label="发布日期" width="100">
					<template slot-scope="scope">
						{{scope.row.subTime.substring(0,10)}}
					</template>
				</el-table-column>
				<el-table-column prop="name" label="优惠券名称" width="300">
					<template slot-scope="scope">
						{{scope.row.name}}
						<div>
							<el-tag type="primary" size="small">{{scope.row.tag}}</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="发送对象">
					<template slot-scope="scope">
						{{scope.row.remark}}
					</template>
				</el-table-column>
				<el-table-column prop="version" label="券额" align="center" width="200">
					<template slot-scope="scope">
						{{scope.row.discount}}元 共{{scope.row.amount}}张
					</template>
				</el-table-column>
				<el-table-column prop="name" label="领取数量" width="200" align="center">
					<template slot-scope="scope">{{scope.row.received}}/{{scope.row.send}}张</template>
				</el-table-column>
				<el-table-column prop="used" label="剩余" align="center" width="100">
					<template slot-scope="scope">
						<el-tag :type="scope.row.received-scope.row.used==0?'info':'success'">
							{{scope.row.received-scope.row.used}}张
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="200">
					<template slot-scope="scope">
						<el-button type="danger" size="small" @click="removeCoupon(scope.$index)"><i
								class="fa fa-remove"></i>删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="couponList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="优惠券信息" :visible.sync="dialogVisible" width="1000px">
			<el-form class="wf" ref="couponForm" :model="couponForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="12">
						<el-form-item label="优惠券名称" prop="name">
							<div class="wf h40">
								<el-select v-model="couponForm.product" @change="productChange" placeholder="请选择优惠券名称"
									style="width:100%;">
									<el-option v-for="product in productList" :key="product" :value="product.serial"
										:label="product.name"></el-option>
								</el-select>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="优惠券标签" prop="tag">
							<div class="wf h40">
								<el-select v-model="couponForm.tag" placeholder="请选择优惠券标签" style="width:100%;">
									<el-option v-for="standard in productStandardList" :key="standard"
										:value="standard.combination" :label="standard.combination"></el-option>
								</el-select>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="券额" prop="discount">
							<div class="wf h40">
								<el-input v-model="couponForm.discount" placeholder="请填写券额">
									<span slot="append">元</span>
								</el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="购券数量" prop="amount">
							<div class="wf h40">
								<el-input v-model.number="couponForm.amount" placeholder="请填写消费券数量">
									<span slot="append">张</span>
								</el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="有效时长" prop="duration">
							<div class="wf h40">
								<el-input v-model.number="couponForm.duration" placeholder="请填写消费券有效时长">
									<span slot="append">天</span>
								</el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="到期日" prop="validPeriod">
							<div class="wf h40">
								<el-date-picker v-model="couponForm.validPeriod" style="width:100%;" type="datetime"
									placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss">
								</el-date-picker>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="患者" prop="object">
							<el-select v-model="couponForm.object" filterable remote reserve-keyword
								placeholder="请选择记录姓名" :remote-method="remoteMethod" @change="patientChange"
								:loading="loading" style="width: 100%;">
								<el-option v-for="item in patientList" :key="item.serial"
									:label="item.name+'('+item.phone+')'" :value="item.serial">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button v-if="!couponForm.serial" type="primary" @click="publishCoupon">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		data() {
			return {
				previewImageUrl: '',
				previewVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				excelList: [],
				dialogVisible: false,
				searchForm: {
					convertType: 1,
					page: 1,
					pageSize: 20,
					total: 0
				},
				couponForm: {
					patient: ""
				},
				couponList: [],
				patientList: [],
				productList: [],
				productStandardList: [],
				vipCardList: [],
				vipLevelList: [],
				rules: {
					name: [{
							required: true,
							message: '请填写消费券名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					tag: [{
							required: true,
							message: '请填写优惠券标签',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					discount: [{
						required: true,
						message: '请填写优惠额',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '价格为数字，例如：12.00'
					}],
					amount: [{
						required: true,
						message: '请填写券量',
						trigger: 'blur'
					}],
					give: [{
						required: true,
						message: '请选择是否赠送',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择消费券类型',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			beforeExcelUpload(file) {

				console.log("文件类型", file.type)

				const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

				const isLt5M = file.size / 1024 / 1024 < 5;

				if (!isExcel) {
					this.$message.error('上传图片只能是xlsx格式!');
				}

				if (!isLt5M) {
					this.$message.error('上传文件大小不能超过 5MB!');
				}
				return isExcel && isLt5M;
			},
			excelChange(file, fileList) {
				console.log("文件上传", fileList)
				this.excelList = fileList;
			},
			doSearch() {
				this.pageChange(1);
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getCoupons();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getCoupons();
			},
			newCoupon() {
				this.couponForm = {
					serial: "",
					converter: "",
					excelList: [],
					duration: 90
				};
				this.excelList = [];
				this.dialogVisible = true;
			},
			getCoupons() {
				this.$http.request(this, this.$apis.user.giveCouponList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					res.data.forEach(item => {
						item.discount = parseInt(item.discount * 0.01).toFixed(2);
					})
					this.couponList = res.data;
				});
			},
			publishCoupon() {

				this.$refs['couponForm'].validate((valid) => {
					if (valid) {
						var couponForm = Object.assign({}, this.couponForm);
						couponForm.convertType = 1;
						couponForm.discount = parseInt(couponForm.discount * 100);
						couponForm.object = "";
						this.vipCardList.forEach(item => {
							if (item.serial == couponForm.vipCard)
								couponForm.object += item.name + "、";
						});
						this.vipLevelList.forEach(item => {
							if (item.serial == couponForm.vipLevel)
								couponForm.object += item.name;
						});
						if (couponForm.converter != "") {
							couponForm.object = couponForm.converter;
						}
						couponForm.updateTime = "";
						var excelList = [];
						this.excelList.filter(file => {
							if (file.status == "success" && file.response.files.length > 0) {
								let doc = file.response.files[0];
								excelList.push({
									uid: file.uid,
									name: doc.orianName,
									url: doc.uploadUrl
								})
							}
						});
						couponForm.excel = JSON.stringify(excelList);

						this.$http.request(this, this.$apis.user.giveCouponPublish, couponForm, true, res => {
							let data = res.data;
							if (data != null) {
								data.discount = parseInt(data.discount * 0.01).toFixed(2);
								this.couponList.unshift(data);
							}

							this.dialogVisible = false;
						});
					}
				});
			},
			showCoupon(index) {
				var couponForm = this.couponList[index];
				this.remoteMethod(couponForm.remark.split("-")[1]);
				this.excelList = JSON.parse(couponForm.excel);
				if (this.excelList != null)
					this.excelList.filter(file => {
						file.status = "success";
						file.response = {
							files: [{
								orianName: file.name,
								uploadUrl: file.url
							}]
						}
					});

				this.couponForm = couponForm;
				this.dialogVisible = true;
			},
			removeCoupon(index) {
				var that = this;
				var coupon = this.couponList[index];
				this.$confirm('此操作将永久删除[' + coupon.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.user.giveCouponRemove.replace("{serial}", coupon.serial)
					this.$http.request(this, url, {}, true, data => {
						this.couponList.splice(index, 1);
					});
				});
			},
			getVipCards() {
				this.$http.request(this, this.$apis.user.vipCardList, {}, false, (res) => {
					this.vipCardList = res.data;
				});
			},
			getVipLevels() {
				this.$http.request(this, this.$apis.user.vipLevelList, {}, false, (res) => {
					this.vipLevelList = res.data;
				});
			},
			getProducts() {
				this.$http.request(this, this.$apis.pay.shopProductList, {
					pageSize: 500
				}, false, (res) => {
					res.data.filter(item => {
						item.price = parseFloat(item.price * 0.01).toFixed(2);
						item.marketPrice = parseFloat(item.marketPrice * 0.01).toFixed(2);
						item.patientPrice = parseFloat(item.patientPrice * 0.01).toFixed(2);
						item.freight = parseFloat(item.freight).toFixed(2);
						item.weight = parseFloat(item.weight).toFixed(2);
					});
					this.productList = res.data;
				});
			},
			productChange(e) {
				this.productList.forEach(item => {
					if (item.serial == e)
						this.couponForm.name = item.name;
				});
				let url = this.$apis.pay.shopProductStandardList.replace("{serial}", e);
				this.$http.request(this, url, {}, false, (res) => {
					res.data.filter(item => {
						item.price = parseFloat(item.price * 0.01).toFixed(2);
						item.patientPrice = parseFloat(item.patientPrice * 0.01).toFixed(2);
					});
					if (res.data.length == 0) {
						res.data.push({
							combination: this.couponForm.name
						});
					}
					this.productStandardList = res.data;
				});
			},
			remoteMethod(query) {
				this.loading = true;
				this.$http.request(this, this.$apis.tools.patientList, {
					name: query,
					phone: query
				}, false, res => {
					this.loading = false;
					this.patientList = res.data;
				});
			},
			patientChange(e) {
				this.patientList.forEach(item => {
					if (item.serial == e) {
						this.couponForm.converter = item.member;
						this.couponForm.remark = item.name + "-" + item.phone;
					}
				});
			}
		},
		created() {
			this.getCoupons();
			this.getProducts();
		}
	}
</script>
