<template>
	<div class="wf">

		<!-- 最新激活记录 -->
		<div class="wf">
			<div class="wf lineh40">
				<el-button type="success" size="small" @click="newAchives"><i class="fa fa-plus"></i>新增档案</el-button>
			</div>

			<el-table :data="archivesList" :stripe="true" border class="mgtop10"
				@selection-change="handleSelectionChange">
				<el-table-column label="序号" type="index" width="55"></el-table-column>
				<el-table-column prop="number" label="档案信息" width="240px">
					<template slot-scope="scope">
						<div class="wf">档案编号：{{scope.row.patient}}</div>
						<div class="wf">患者信息：{{scope.row.name}}</div>
						<div class="wf">建档日期：{{scope.row.day}}</div>
						<div class="wf">更新日期：{{scope.row.updateTime}} </div>
					</template>
				</el-table-column>
				<el-table-column prop="tag" label="档案类型" align="center">
					<template slot-scope="scope">
						<el-tag type="primary" size="small" v-if="scope.row.tag==0">正式档案</el-tag>
						<el-tag type="primary" size="small" v-else>自述档案</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="appointment" label="病种分类">
					<template slot-scope="scope">
						<span
							v-if="scope.row.evaluation['20210904165442PR']">{{scope.row.evaluation["20210904165442PR"].join('')}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="detail" label="主诉症状">
					<template slot-scope="scope">
						{{scope.row.nurse["20210904165628VY"]}}
					</template>
				</el-table-column>
				<el-table-column prop="detail" label="血压">
					<template slot-scope="scope">
						<div>血压收缩压：{{scope.row.eyefile["20210904164106YO"]}} (mmHg)</div>
						<div>血压舒张压：{{scope.row.eyefile["20210904164123MF"]}} (mmHg)</div>
					</template>
				</el-table-column>
				<el-table-column prop="detail" label="血糖">
					<template slot-scope="scope">
						<div>餐前血糖：{{scope.row.eyefile["20210904164435TB"]}} (mmHg)</div>
						<div>餐后2小时血糖：{{scope.row.eyefile["20210904164457YI"]}} (mmHg)</div>
						<div>空腹血糖：{{scope.row.eyefile["20210904164536TK"]}} (mmHg)</div>
						<div>随机血糖：{{scope.row.eyefile["20210904164517SR"]}} (mmHg)</div>
					</template>
				</el-table-column>
				<el-table-column prop="expertName" label="责任人">
					<template slot-scope="scope">
						<div class="wf">责任专家：{{scope.row.expertName}}</div>
						<div class="wf">责任客服：{{scope.row.customerName}}</div>
						<div class="wf">创建人：{{scope.row.optionerName}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="120">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="24">
								<el-button style="width: 100%;" type="primary" size="small"
									@click="showAchives(scope.$index)"><i class="fa fa-edit"></i>查看</el-button>
							</el-col>
							<el-col v-if="principal.level==3" :span="24" class="mgtop10">
								<el-button style="width: 100%;" type="danger" size="small"
									@click="removeAchives(scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
							</el-col>
						</el-row>

					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="archivesList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>

		<!-- 弹出层 -->
		<el-dialog id="archivesForm" :close-on-click-modal="false" :visible.sync="dialogVisible" width="1000px"
			append-to-body>
			<div class="pdding20auto mgbtm20" lot="title">
				<div class="fl lineh20 h20 bold font18">档案信息</div>
				<el-switch v-if="principal.serial==archivesForm.optioner || principal.level==3" class="fl mgl20"
					v-model="canEdit" active-color="#13ce66" inactive-color="#ff4949" active-text="查看模式"
					inactive-text="编辑模式" :active-value="1" :inactive-value="0">
				</el-switch>
			</div>
			<el-tabs type="border-card" v-model="activeName">
				<el-tab-pane label="个人眼科档案" name="个人眼科档案">
					<eye-file :canEdit="canEdit" :patientForm="patientForm" :archivesForm="archivesForm"
						@infoChange="infoChange" @showChange="showChange">
					</eye-file>
				</el-tab-pane>
				<el-tab-pane label="眼健康评估" name="眼健康评估">
					<evaluate :canEdit="canEdit" :patientForm="patientForm" :archivesForm="archivesForm"
						@infoChange="infoChange" @showChange="showChange">
					</evaluate>
				</el-tab-pane>
				<el-tab-pane label="治疗计划及康复记录" name="治疗计划及康复记录">
					<nurse :canEdit="canEdit" :patientForm="patientForm" :archivesForm="archivesForm"
						@infoChange="infoChange" @showChange="showChange"></nurse>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import EyeFile from '@/components/treatment/archive/record/EyeFile.vue'
	import Nurse from '@/components/treatment/archive/record/Nurse.vue'
	import Evaluate from '@/components/treatment/archive/record/Evaluate.vue'

	import VueUEditor from 'vue-ueditor'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				canEdit: 0,
				activeName: "个人眼科档案",
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				archivesForm: {},
				archivesList: []
			}
		},
		props: ["patientForm"],
		components: {
			EyeFile,
			Nurse,
			Evaluate
		},
		methods: {
			doSearch() {
				this.searchForm.page = 1;
				this.getArchivesList();
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getArchivesList();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getArchivesList();
			},
			getArchivesList() {
				this.searchForm.patient = this.patientForm.serial;
				this.$http.request(this, this.$apis.tools.archivesList, this.searchForm, false, (res) => {
					if (res.code == 0) {
						res.data.forEach(item => {
							item.evaluation = JSON.parse(item.evaluation);
							item.eyefile = JSON.parse(item.eyefile);
							item.nurse = JSON.parse(item.nurse);
						});
						this.archivesList = res.data;
						this.searchForm.total = res.count;
					}
				});
			},
			newAchives() {
				this.archivesForm = {
					serial: "TEST" + new Date().getTime(),
					evaluation: {},
					eyefile: {},
					nurse: {}
				};
				this.canEdit = 1;
				this.dialogVisible = true;
			},
			showAchives(index) {
				this.activeName = "个人眼科档案";
				this.archivesForm = this.archivesList[index];
				this.dialogVisible = true;
				console.log("档案记录", this.archivesForm);
			},
			removeAchives(index) {
				var archives = this.archivesList[index];
				this.$confirm('此操作将永久删除[' + archives.number + ']档案变更记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.tools.archivesRemove.replace("{serial}", archives.serial);
					this.$http.request(this, url, {}, true, res => {
						this.archivesList.splice(index, 1);
					});
				});
			},
			infoChange(e) {
				console.log("档案信息变更", e)
				this.archivesForm = e;
			},
			showChange() {
				this.dialogVisible = !this.dialogVisible;
				this.getArchivesList();
			}
		},
		created() {
			this.getArchivesList();
		},
		watch: {
			patientForm(newValue, oldValue) {
				this.searchForm.page = 1;
				this.getArchivesList();
			}
		},
	}
</script>

<style>
	#archivesForm .el-dialog__body {
		padding: 0 !important;
	}
</style>
