<template>
  <div class="FragementCode">

  <!-- 弹出层 -->
      <el-dialog :close-on-click-modal="false"  title="电子码" :visible.sync="dialogVisible" width="600px" z-index="99">
           <div class="wf">
            <div class="w200 h200 center-block mgtop20" style="background: #F7F7F7;">
               <div id="qrImg" ref="qrImg"></div>
            </div>

            <div class="wf center font14 mgtop20" >{{qr}}</div>
           </div>

           <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">关 闭</el-button>
           </span>
      </el-dialog>
     <!-- 弹出层 end-->
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  name: 'FragementCode',
  data () {
    return {
       batch:{},
       qr:"",
       qrcode:null,
       dialogVisible:false
    }
  },
  methods:{

  },
  created() {

  },
  watch: {
    batch(newValue, oldValue) {
      this.qr = this.batch.serial;

      setTimeout(()=>{
        if(this.qrcode==null)
        this.qrcode = new QRCode('qrImg', {
            width: 200, //图像宽度
            height: 200, //图像高度
            colorDark : "#000000", //前景色
            colorLight : "#ffffff", //背景色
            typeNumber:4,
            correctLevel : QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
        });
        this.qrcode.clear();
        this.qrcode.makeCode(this.qr) //生成另一个新的二维码
      },1000);
    }
  },
 }
</script>

<style>
</style>
