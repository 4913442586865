<template>
	<div class="home pdding10">
		<el-row :gutter="10">
			<!-- 用户信息 数据 -->
			<el-col :span="5">
				<div class="pdding10 radius5 bgffffff">
					<div class="wf" style="height:100px;position:relative">
						<div class="wf lineh20 bold font14">
							<span style="color: #3F85E9;">欢迎您，{{principal.account}}</span>
						</div>
						<div class="wf lineh20 bold font12">
							<span class="color9a">上次登录时间：{{principal.lastLogin}}</span>
						</div>
						<div class="wf mgtop10">
							<div class="fl w40 h40 radius50p overflow">
								<img src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
									width="100%" height="100%" />
							</div>
							<div class="fl font12 mgl10">
								<div class="wf lineh20">姓名：{{principal.realName}}</div>
								<div class="wf lineh20">部门：{{principal.department}}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="pdding10 radius5 bgffffff mgtop10">
					<el-calendar v-model="toDay"> </el-calendar>
				</div>

				<div class="pdding10 radius5 bgffffff mgtop10">
					<div class="h280" style="height: 290px;">
						<div class="wf lineh40">
							<div class="h15 mgtop13 fl" style="background: #3F85E9;width: 4px;"></div>
							<span class="fl bold mgl10 font16">会员分类</span>
						</div>
						<car-chart id="cardChart" :data="vipData" :height="300" title="" legend="会员分类" unit="人">
						</car-chart>
					</div>
				</div>

				<div class="pdding10 radius5 bgffffff mgtop10">
					<div class="h280" style="height: 290px;">
						<div class="wf lineh40">
							<div class="h15 mgtop13 fl" style="background: #3F85E9;width: 4px;"></div>
							<span class="fl bold mgl10 font16">用户省份分布</span>
						</div>

						<org-chart id="originChart" :data="provinceData" :height="300" title="" legend="省份分布" unit="人">
						</org-chart>

					</div>
				</div>
			</el-col>
			<!-- 用户信息 数据 end -->


			<!-- 数据统计 -->
			<el-col :span="14">
				<el-row :gutter="10" class="white">
					<el-col :span="8">
						<div class="wf center radius5 bgffffff relative" style="height:115px;">
							<div class="mgtop30 overflow h60" style="padding-left: 80px;position: relative">
								<img src="/static/images/data/mdata_jrxzkh.png"
									style="width:60px;height:60px;position: absolute;left: 20px;" />
								<div class="wf lineh20 font12 mgtop5" style="color: #ea6372;">今日新增用户</div>
								<div class="wf lineh30 font24 bold" style="color: #ea6372;">
									{{totalData.todayMember}}<span class="font16">人</span>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="wf center radius5 bgffffff relative" style="height:115px;">
							<div class="mgtop30 overflow h60" style="padding-left: 80px;position: relative">
								<img src="/static/images/data/mdata_jrxzhy.png"
									style="width:60px;height:60px;position: absolute;left: 20px;" />
								<div class="wf lineh20 font12 mgtop5" style="color: #3da5f6;">今日新增档案</div>
								<div class="wf lineh30 font24 bold" style="color: #3da5f6;">
									{{patientData.today}}
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="wf center radius5 bgffffff relative" style="height:115px;">
							<div class="mgtop30 overflow h60" style="padding-left: 80px;position: relative">
								<img src="/static/images/data/mdata_jrxzdp.png"
									style="width:60px;height:60px;position: absolute;left: 20px;" />
								<div class="wf lineh20 font12 mgtop5" style="color: #feb643;">社区待回</div>
								<div class="wf lineh30 font24 bold" style="color: #feb643;">{{totalData.unreply}}
								</div>
							</div>
						</div>
					</el-col>
					<el-col class="mgtop10" :span="8">
						<div class="wf center radius5 bgffffff relative" style="height:115px;">
							<div class="mgtop30 overflow h60" style="padding-left: 80px;position: relative">
								<img src="/static/images/data/mdata_ljkh.png"
									style="width:60px;height:60px;position: absolute;left: 20px;" />
								<div class="wf lineh20 font12 mgtop5" style="color: #3F85E9">累计用户总数</div>
								<div class="wf lineh30 font24 bold" style="color: #3F85E9">{{totalData.sumMember}}<span
										class="font16">人</span></div>
							</div>
						</div>
					</el-col>
					<el-col class="mgtop10" :span="8">
						<div class="wf center radius5 bgffffff relative" style="height:115px;">
							<div class="mgtop30 overflow h60" style="padding-left: 80px;position: relative">
								<img src="/static/images/data/mdata_ljhy.png"
									style="width:60px;height:60px;position: absolute;left: 20px;" />
								<div class="wf lineh20 font12 mgtop5" style="color: #feb643;">累计档案人数</div>
								<div class="wf lineh30 font24 bold" style="color: #feb643;">
									{{patientData.sum}}
								</div>
							</div>
						</div>
					</el-col>
					<el-col class="mgtop10" :span="8">
						<div class="wf center radius5 bgffffff relative" style="height:115px;">
							<div class="mgtop30 overflow h60" style="padding-left: 80px;position: relative">
								<img src="/static/images/data/mdata_ljdp.png"
									style="width:60px;height:60px;position: absolute;left: 20px;" />
								<div class="wf lineh20 font12 mgtop5" style="color: #3da5f6;">会员占比</div>
								<div class="wf lineh30 font24 bold" style="color: #3da5f6;">
									{{parseFloat(totalData.sumCard / patientData.sum * 100).toFixed(2)}}<span
										class="font16">%</span>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>

				<div class="pdding10 radius5 bgffffff mgtop10">
					<div style="height: 380px;">
						<div class="wf lineh40">
							<div class="h15 mgtop13 fl" style="background: #3F85E9;width: 4px;"></div>
							<span class="fl bold mgl10 font16">检查治疗情况</span>
						</div>

						<div class="wf mgtop10">
							<more-line-chart id="nurseChart" :data="nurseData" :height="360" title="" unit="人">
							</more-line-chart>
						</div>

					</div>
				</div>


				<div class="pdding10 radius5 bgffffff mgtop10">
					<div style="height: 380px;">
						<div class="wf lineh40">
							<div class="h15 mgtop13 fl" style="background: #3F85E9;width: 4px;"></div>
							<span class="fl bold mgl10 font16">消费券销售情况</span>
						</div>

						<div class="wf mgtop10">
							<stacked-bar-chart id="couponChart" :data="buyCouponData" :height="340" title="" unit="元">
							</stacked-bar-chart>
						</div>

					</div>
				</div>

			</el-col>
			<!-- 数据统计 end -->

			<!-- 片区排名 -->
			<el-col :span="5">
				<div class="pdding10 radius5 bgffffff">
					<div class="h300" style="height: 505px;">
						<div class="wf lineh40">
							<div class="h15 mgtop13 fl" style="background: #3F85E9;width: 4px;"></div>
							<span class="fl bold mgl10 font16">用户来源排行</span>
						</div>
						<el-row class="mgtop20" :gutter="10">
							<el-col v-if="index<10" class="mgbtm10" :span="24" v-for="(item,index) in originData"
								:key="item">
								<div class="h30 lineh30 relative"
									style="border-bottom: 1px dashed #DEDEDE;padding-right: 40px;padding-left: 20px;">
									<img v-if="index<3" :src="'/static/images/win_'+index+'.png'"
										class="red bold font14 w15" style="position: absolute;left: -5px;top: 0;" />
									<span v-else class="red bold font14"
										style="position: absolute;left: 0;top: 0;">{{index + 1}}</span>
									<span class="bold font14"
										style="color: #feb643;position: absolute;right: 0;">{{item.member}}</span>
									<p class="ellipsis"
										style=" white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;">
										<span class="mgl5">{{item.name}}</span>
									</p>
								</div>
							</el-col>
							<el-col v-if="originData.length==0" class="wf lineh200 center">暂无数据</el-col>
						</el-row>
					</div>
				</div>

				<div class="pdding10 radius5 bgffffff mgtop10">
					<div class="h300" style="height: 505px;">
						<div class="wf lineh40">
							<div class="h15 mgtop13 fl" style="background: #3F85E9;width: 4px;"></div>
							<span class="fl bold mgl10 font16">消费订单排行</span>
						</div>

						<el-row class="mgtop20" :gutter="10">
							<el-col class="mgbtm10" :span="24" v-for="(item,index) in couponData" :key="item">
								<div class="h30 lineh30 relative"
									style="border-bottom: 1px dashed #DEDEDE;padding-right: 40px;padding-left: 20px;">
									<img v-if="index<3" :src="'/static/images/win_'+index+'.png'"
										class="red bold font14 w15" style="position: absolute;left: -5px;top: 0;" />
									<span v-else class="red bold font14"
										style="position: absolute;left: 0;top: 0;">{{index + 1}}</span>
									<span class="bold font14"
										style="color: #feb643;position: absolute;right: 0;">{{item.value}}</span>
									<p class="ellipsis"
										style=" white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;">
										<span class="mgl5">{{item.label}}</span>
									</p>
								</div>
							</el-col>
							<el-col v-if="couponData.length==0" class="wf lineh200 center">暂无数据</el-col>
						</el-row>
					</div>
				</div>

			</el-col>
			<!-- 片区排名 end -->

			<el-col span="24">
				<div class="pdding10 radius5 bgffffff mgtop10">
					<div style="height: 350px;">
						<div class="wf lineh40">
							<div class="h15 mgtop13 fl" style="background: #3F85E9;width: 4px;"></div>
							<span class="fl bold mgl10 font16">各年龄段病种</span>
						</div>

						<div class="wf mgtop10">
							<stacked-bar-chart id="diseaseChart" :data="diseasesData" :height="300" title="" unit="元">
							</stacked-bar-chart>
						</div>

					</div>
				</div>
			</el-col>
			<!-- 其他数据统计 -->
			<el-col span="24">
				<el-col span="6">
					<div class="pdding10 radius5 bgffffff mgtop10">
						<div class="h300">
							<div class="wf lineh40">
								<div class="h15 mgtop13 fl" style="background: #3F85E9;width: 4px;"></div>
								<span class="fl bold mgl10 font16">教育程度比例</span>
							</div>
							<car-chart id="educationChart" :data="educationData" :height="300" title="" legend="教育程度"
								unit="人"></car-chart>
						</div>
					</div>
				</el-col>
				<el-col span="6">
					<div class="pdding10 radius5 bgffffff mgtop10">
						<div class="h300">
							<div class="wf lineh40">
								<div class="h15 mgtop13 fl" style="background: #3F85E9;width: 4px;"></div>
								<span class="fl bold mgl10 font16">年龄段比例</span>
							</div>
							<car-chart id="ageChart" :data="ageData" :height="300" title="" legend="年龄段" unit="人">
							</car-chart>
						</div>
					</div>
				</el-col>
				<el-col span="6">
					<div class="pdding10 radius5 bgffffff mgtop10">
						<div class="h300">
							<div class="wf lineh40">
								<div class="h15 mgtop13 fl" style="background: #3F85E9;width: 4px;"></div>
								<span class="fl bold mgl10 font16">男女比例</span>
							</div>
							<car-chart id="genderChart" :data="genderData" :height="300" title="" legend="男女比例"
								unit="人"></car-chart>
						</div>
					</div>
				</el-col>
				<el-col span="6">
					<div class="pdding10 radius5 bgffffff mgtop10">
						<div class="h300">
							<div class="wf lineh40">
								<div class="h15 mgtop13 fl" style="background: #3F85E9;width: 4px;"></div>
								<span class="fl bold mgl10 font16">民族比例</span>
							</div>
							<car-chart id="ethnicChart" :data="ethnicData" :height="300" title="" legend="民族" unit="人">
							</car-chart>
						</div>
					</div>
				</el-col>
			</el-col>
			<!-- 其他数据统计 -->
		</el-row>

	</div>
</template>

<script>
	import MoreLineChart from '@/components/fragment/chart/MoreLineChart.vue'
	import OrgChart from '@/components/fragment/chart/RingChart.vue'
	import CarChart from '@/components/fragment/chart/RingChart.vue'
	import StackedBarChart from '@/components/fragment/chart/StackedBarChart.vue'

	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'Home',
		components: {
			MoreLineChart,
			OrgChart,
			CarChart,
			StackedBarChart
		},
		computed: {
			...mapGetters([
				'principal', 'isLogin'
			])
		},
		data() {
			return {
				toDay: new Date(),
				timeName: new Date().getHours() < 12 ? "上午" : "下午",
				totalData: {
					todayMember: 0,
					sumMember: 0,
					todayVip: 0,
					sumVip: 0,
					todayGive: 0,
					sumGive: 0,
					todayCoupon: 0,
					sumCoupon: 0,
					unreply: 0,
					sumCard: 0
				},
				patientData: {
					today: 0,
					sum: 0
				},
				provinceData: [],
				vipData: [],
				ageData: [],
				genderData: [],
				ethnicData: [],
				educationData: [],
				diseasesData: [],
				nurseData: [],
				couponData: [],
				originData: [],
				buyCouponData: []
			}
		},
		methods: {
			getTotalData() {
				this.$http.request(this, this.$apis.user.memberTotalData, {}, false, res => {
					if (res.code == 0)
						for (var key in res.data)
							this.totalData[key] = res.data[key];
				});
			},
			getProvinceData() {
				this.$http.request(this, this.$apis.tools.dataPatientProvince, {}, false, res => {
					if (res.code == 0)
						this.provinceData = res.data;
				});
			},
			getVipData() {
				this.$http.request(this, this.$apis.tools.dataPatientType, {}, false, res => {
					if (res.code == 0) {
						let sumVip = 0;
						res.data.forEach(item => {
							console.log(item);
							if (item.label != '一般用户')
								sumVip += item.value;
						});
						this.totalData.sumCard = sumVip;
						this.vipData = res.data;
					}
				});
			},
			getGenderData() {
				this.$http.request(this, this.$apis.tools.dataPatientGender, {}, false, res => {
					if (res.code == 0)
						this.genderData = res.data;
				});
			},
			getEducationData() {
				this.$http.request(this, this.$apis.tools.dataPatientEducation, {}, false, res => {
					if (res.code == 0)
						this.educationData = res.data;
				});
			},
			getAgeData() {
				let ages = ["~20", "20~30", "30~40", "40~50", "50~60", "60~70", "70~80", "80~90", "90~"]
				this.$http.request(this, this.$apis.tools.dataPatientAge, {}, false, res => {
					if (res.code == 0) {
						let ageDatas = [];
						for (var key in res.data) {
							ageDatas.push({
								label: ages[key.replace("d", "")],
								value: res.data[key]
							});
						}
						this.ageData = ageDatas;
					}
				});
			},
			getEthnicData() {
				this.$http.request(this, this.$apis.tools.dataPatientEthnic, {}, false, res => {
					if (res.code == 0)
						this.ethnicData = res.data;
				});
			},
			getOriginData() {
				this.$http.request(this, this.$apis.user.orgMemberTopData, {
					type: 0
				}, false, res => {
					if (res.code == 0)
						this.originData = res.data;
				});
			},
			getCouponData() {
				this.$http.request(this, this.$apis.user.memberCenterCouponTagData, {}, false, res => {
					console.log("优惠券", res);
					if (res.code == 0)
						this.couponData = res.data;
				});
			},
			getAgeDisease(type) {
				let ages = ["~10", "10~20", "20~30", "30~40", "40~50", "50~60", "60~70", "70~80", "80~"];
				this.$http.request(this, this.$apis.tools.dataPatientAgeDisease, {
					tag: type
				}, false, res => {
					console.log("整体评估数据", res.data)
					if (res.code == 0) {
						this.diseasesData = res.data;
						this.diseasesData.xAxis = ages;
					}
				});
			},
			getBuyOrderData() {
				this.$http.request(this, this.$apis.pay.orderMonthData, {}, false, res => {
					console.log("订单明细折线数据", res)
					this.buyCouponData = res.data;
				});
			},
			getNurseData() {
				this.$http.request(this, this.$apis.tools.dataPatientNursingMonth, {}, false, res => {
					console.log("检查记录数据", res)
					let datas = [];
					res.data.forEach(item => {
						let data = {
							label: item.label
						};
						data["检查人数"] = item["archives"];
						data["回访人数"] = item["visit"];
						data["护理人数"] = item["nurse"];
						data["面诊人数"] = item["face"];
						data["转诊人数"] = item["referral"];
						datas.push(data);
					});
					this.nurseData = datas;
				});
			},
			getReplyData() {
				this.$http.request(this, this.$apis.material.postReplyData, {}, false, res => {
					console.log("消息", res.data);
					if (res.code == 0)
						this.totalData.unreply = res.data[0].value;
				});
			},
			getPatientTotal() {
				this.$http.request(this, this.$apis.tools.dataPatientTotal, {}, false, res => {
					console.log("消息>>>", res.data);
					for (var key in res.data)
						this.patientData[key] = res.data[key];
				});
			}
		},
		created() {
			this.getPatientTotal();
			this.getTotalData();
			this.getProvinceData();
			this.getVipData();
			this.getAgeData();
			this.getGenderData();
			this.getEthnicData();
			this.getEducationData();
			this.getCouponData();
			this.getOriginData();
			this.getAgeDisease("ztpg");
			this.getBuyOrderData();
			this.getNurseData();
			this.getReplyData();
		}
	}
</script>

<style>
	.el-calendar-table .el-calendar-day {
		height: 30px;
		text-align: center;
	}

	.el-calendar-table .el-calendar-day span {
		display: block;
		margin: 0px auto;
		width: 30px;
		height: 30px;
		line-height: 30px;
	}

	.el-calendar-table,
	.el-calendar-table tr td {
		border: 0px !important;
	}

	.el-calendar-table td.is-today {
		background: transparent;
	}

	.el-calendar-table td.is-today span {
		background: #3F85E9;
		border-radius: 50%;
		color: white;
	}

	.el-calendar__body {
		padding: 0;
	}

	.el-calendar__header {
		border-bottom: 0px;
	}

	.el-calendar__header .el-calendar__button-group {
		display: none;
	}

	.el-calendar__title {
		width: 100%;
		text-align: center;
	}
</style>
