<template>
	<div class="DefaultHeader" >
		
	

	</div>
</template>

<script>
	export default {
	  name: 'DefaultHeader',
	  data () {
	    return {
	      
	    }
	  }
	}
</script>

<style scoped>

</style>
