<template>
	<div id="memberModel">
		<web-header @changeCollapse="changeCollapse" :isCollapse="isCollapse"></web-header>
		<div style="position: fixed;top:60px;left:0;bottom:0;right:0;">
			<div :style="'position:absolute;width: '+navWidth+'px;background:#333;top:0;left:0;bottom:0;'">
				<div :style="'height:'+(navHeight)+'px;overflow-y:scroll;'">
					<my-nav @menuSelected="menuSelected" :isCollapse="isCollapse" />
				</div>
			</div>
			<div :style="'padding-left: '+navWidth+'px;overflow: hidden;'">
				<div id="frame" class="wf">
					<span class="pointer center" @click="closeAll"
						style="position: absolute;width:80px;height:38px;line-height: 38px;right:100px;background: #F5F7FA;z-index:9;"><i
							class="fa fa-remove"></i>全部关闭</span>
					<span class="pointer center" @click="refresh"
						style="position: absolute;width:80px;height:38px;line-height: 38px;right:20px;background: #F5F7FA;z-index:9;"><i
							class="fa fa-refresh"></i>刷新</span>
					<el-tabs v-model="editableTabsValue" type="border-card" @tab-remove="removeTab"
						style="width: 100%;">
						<el-tab-pane :closable="index>0" :key="item.name" v-for="(item, index) in editableTabs"
							:label="item.title" :name="item.name">
							<iframe :id="'frame_'+item.name" :src="item.url" width="100%" :height="frameHeight"
								frameborder="0"></iframe>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
		<div
			style="display: none;margin-left: 180px;position: absolute;width: auto;bottom: 0px;left: 0px;right: 0px;overflow: hidden;overflow-y: auto;">
			<web-footer></web-footer>
		</div>
	</div>

</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import WebHeader from '@/components/include/WebHeader'
	import WebFooter from '@/components/include/WebFooter'
	import MyNav from '@/components/include/Nav'
	export default {
		components: {
			WebHeader,
			WebFooter,
			MyNav
		},
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				navWidth: 200,
				isCollapse: false,
				navHeight: 0,
				frameHeight: 0,
				editableTabsValue: '首页',
				editableTabs: [],
				tabIndex: 2
			}
		},
		methods: {
			menuSelected(e) {
				console.log("点击的菜单", e);
				var tabs = this.editableTabs.filter(item => {
					return item.url == e.url;
				});
				if (tabs.length == 0) {
					this.editableTabs.push({
						title: e.name,
						name: e.name,
						url: e.url
					});
				}
				this.editableTabsValue = e.name;
				console.log("标签", this.editableTabs)
			},
			changeCollapse() {
				this.isCollapse = !this.isCollapse;
				console.log("", this.isCollapse);
				this.navWidth = this.isCollapse ? 64 : 200;
			},
			removeTab(targetName) {
				let tabs = this.editableTabs;
				let activeName = this.editableTabsValue;
				if (activeName === targetName) {
					tabs.forEach((tab, index) => {
						if (tab.name === targetName) {
							let nextTab = tabs[index + 1] || tabs[index - 1];
							if (nextTab) {
								activeName = nextTab.name;
							}
						}
					});
				}

				this.editableTabsValue = activeName;
				this.editableTabs = tabs.filter(tab => tab.name !== targetName);
			},
			closeAll() {
				this.editableTabsValue = "首页";
				this.editableTabs = this.editableTabs.filter((tab, index) => {
					return index == 0;
				});
			},
			refresh() {
				document.getElementById('frame_' + this.editableTabsValue).contentWindow.location.reload();
			}
		},
		created() {
			this.navHeight = window.innerHeight - 60;
			this.frameHeight = window.innerHeight - 108;
			let auth = this.principal.authority[0];
			if (auth.url == null || auth.url == "" || auth.url == undefined)
				auth = auth.children[0];

			this.editableTabsValue = auth.name;
			this.editableTabs.push({
				title: auth.name,
				name: auth.name,
				url: auth.url
			})
		},
		mounted() {
			document.title = this.$http.webConfig.simple;
			console.log("页面高度");
		}
	}
</script>

<style>
	#frame .el-tabs__content {
		background: #F5F5F5;
		padding: 0;
	}
</style>
