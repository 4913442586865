const state = {
	applyData: JSON.parse(sessionStorage.getItem('applyData')) ,
}

const getters = {
  applyData: state => state.applyData
}

const mutations = {
  // 更改申请信息
  applyData(state,applyData){
    state.applyData = applyData
    console.log("也许执行的了")
    sessionStorage.setItem("applyData",JSON.stringify(applyData));
  }
}

const actions = {
	// 应用mutations
	applyData({commit},applyData){
		commit("applyData",applyData)
	}
}

export default{
  state,
  getters,
  mutations,
  actions
}
