<template>
	<div class="Reg bgffffff">
		<div class="wf" style="border-bottom: 1px solid #EDEDED;">
			<div class="w1200 h100 lineh100">
				<a href="/">
					<div class="logo fl" style="line-height: 50px;"><img src="../../assets/logo.png?v=1.0"
							style="height: 50px;margin-top: 25px;"></div>
					<div class="fl mgl10 font18 bold">{{$http.webConfig.simple}}</div>
				</a>
				<span class="fr">
					<a href="/uc/login">
						<el-button type="primary" round icon="el-icon-sort">登录</el-button>
					</a>
				</span>
			</div>
		</div>

		<div class="wf" style="margin-top: 100px;">
			<div class="w400 center-block " style="min-height: 660px;">
				<div class="wf lineh40 center font26">注册专家账号</div>
				<el-form ref="regForm" name="login_form_box" :model="regForm" :rules="rules">
					<div class="h30 lineh30 center" id="emsg" style="color:#ea544a;"></div>
					<el-form-item class="mgtop20" prop="nickname">
						<el-input placeholder="请输入用户昵称" v-model="regForm.nickname">
							<template slot="prepend"><i class="fa fa-user"></i></template>
						</el-input>
					</el-form-item>
					<el-form-item class="mgtop30" prop="phone">
						<el-input placeholder="请输入手机号码" v-model="regForm.phone">
							<template slot="prepend"><i class="fa fa-phone"></i></template>
						</el-input>
					</el-form-item>
					<el-form-item class="mgtop30" prop="password">
						<el-input placeholder="请输入6~20位登录密码" v-model="regForm.password">
							<template slot="prepend"><i class="fa fa-lock"></i></template>
						</el-input>
					</el-form-item>
					<el-form-item class="mgtop30" prop="code">
						<el-input placeholder="请输入短信验证码" v-model="regForm.code">
							<el-button slot="append" v-if="!showTime" @click="getSms">获取短信验证码</el-button>
							<el-button slot="append" v-if="showTime">{{time}}(s)</el-button>
						</el-input>
					</el-form-item>
					<p class="lineh20 mgtop30 font12">
						<el-checkbox v-model="checked">注册代表您已同意<a href="/protocol/reg_protocol.html"
								style="color: #00b7ee;">《网站注册协议》</a></el-checkbox>
					</p>
				</el-form>

				<div class="wf mgtop20">
					<el-button class="wf" type="primary" :disabled="!checked" @click="subReg">立即注册</el-button>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'Reg',
		data() {
			return {
				checked: false,
				showTime: false,
				time: 60,
				regForm: {},
				rules: {
					nickname: [{
							required: true,
							message: '请输入用户昵称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 20,
							message: '长度在 2 到 20个字符',
							trigger: 'blur'
						}
					],
					phone: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'blur'
						},
						{
							len: 11,
							message: '手机号码11为',
							trigger: 'blur'
						}
					],
					password: [{
							required: true,
							message: '请输入6~20位登录密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 20,
							message: '长度在 6 到 20个字符',
							trigger: 'blur'
						}
					],
					code: [{
						required: true,
						message: '请输入短信验证码',
						trigger: 'blur'
					}, ]
				}
			}
		},
		methods: {
			subReg() { //跳转到实名认证页面
				this.$refs['regForm'].validate((valid) => {
					if (valid) {
						this.$http.request(this, this.$apis.user.managerReceptReg, this.regForm, true, (res) => {
							if (res.code == 0) {
								window.localStorage.setItem("nickname", res.data)
								window.location.href = "/uc/reg/success";
							}
						});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			getSms() {
				if (this.regForm.phone != undefined && this.regForm.phone.length == 11) {
					if (!this.showTime) {
						this.showTime = true;
						let myInterval = null;
						this.regForm.smsType = "memberReg";
						this.$http.request(this, this.$apis.component.sms, this.regForm, true, res => {
							var page = this;
							myInterval = setInterval(rs => {
								page.time--;
								if (page.time == 1) {
									clearInterval(myInterval);
									page.showTime = false;
									page.time = 60;
								}
							}, 1000)
						});
					}
				}
			}
		},
		mounted() {
			document.title = "用户注册-" + this.$http.webConfig.simple;
		}
	}
</script>

<style>
	.Reg .el-input__inner {
		height: 50px !important;
		line-height: 50px !important;
	}
</style>
