<template>
	<div class="fragementBase">
		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">运营中心</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">意见反馈</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form class="wf" ref="searchForm" :model="searchForm" :inline="true">
					<el-form-item label="意见反馈名称">
						<el-input v-model="searchForm.name" clearable size="small" placeholder="请填写意见反馈名称" />
					</el-form-item>
					<el-form-item>
						<el-button type="primary" size="small" @click="doSearch"><i class="fa fa-search"></i>查询</el-button>
						<el-button type="success" size="small" @click="newFeedback"><i class="fa fa-plus"></i>新增意见反馈</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff radius5 overflow mgtop10">

			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">数据展示</span>
			</div>

			<el-table :data="feedbackList" :stripe="true" border class="mgtop10">
				<el-table-column prop="subTime" label="发布日期" width="100">
					<template slot-scope="scope">
						{{scope.row.subTime.substring(0,10)}}
					</template>
				</el-table-column>
				<el-table-column prop="opinion" label="意见/问题" width="300">
					<template slot-scope="scope">
						{{scope.row.opinion}}
						<el-tag type="primary" size="small" v-if="scope.row.recommend==1">推荐</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="reply" label="回复"></el-table-column>
				<el-table-column prop="tag" label="标签"></el-table-column>
				<el-table-column align="center" label="操作" width="200">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="12">
								<el-button style="width: 100%;" type="primary" size="small" @click="showFeedback(scope.$index)"><i class="fa fa-edit"></i>编辑</el-button>
							</el-col>
							<el-col v-if="principal.level==3" :span="12">
								<el-button style="width: 100%;" type="danger" size="small" @click="removeFeedback(scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="feedbackList.length>0" @size-change="pageSizeChange" @current-change="pageChange"
					 :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]" :page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>

		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="意见反馈信息" :visible.sync="dialogVisible" width="800px">
			<div class="wf">
				<!-- -->
				<el-form class="wf" ref="feedbackForm" :model="feedbackForm" label-width="100px" :rules="rules">

					<el-row :gutter="10">
						<el-col :span="24">
							<el-form-item label="意见/问题" prop="opinion">
								<el-input type="textarea" :rows="4" v-model="feedbackForm.opinion" placeholder="请填写意见反馈"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="回复" prop="reply">
								<el-input type="textarea" :rows="4" v-model="feedbackForm.reply" placeholder="请填写意见反馈"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="标签" prop="tag">
								<el-input v-model="feedbackForm.tag" placeholder="请填写标签"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="是否推荐" prop="recommend">
								<el-select v-model="feedbackForm.recommend" placeholder="请填写标签" style="width: 100%;">
									<el-option :value="0" label="不推荐"></el-option>
									<el-option :value="1" label="推荐"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>

				</el-form>
				<!-- -->
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishFeedback">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		data() {
			return {
				imgUploadUrl: this.$http.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				feedbackForm: {},
				feedbackList: [],
				rules: {
					opinion: [{
							required: true,
							message: '请填写意见/问题',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 100,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					tag: [{
						required: true,
						message: '请填写意见/问题标签',
						trigger: 'blur'
					}]
				},
			}
		},
		computed: {
			...mapGetters([
				'principal'
			])
		},
		methods: {
			doSearch() {
				this.pageChange(1);
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getFeedbacks();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getFeedbacks();
			},
			newFeedback() {
				this.feedbackForm = {
					logo: ""
				};
				this.dialogVisible = true;
			},
			getFeedbacks() {
				this.$http.request(this, this.$apis.tools.feedbackList, this.searchForm, false, res => {
					this.searchForm.total = res.count;
					this.feedbackList = res.data;
				});
			},
			publishFeedback() {

				this.$refs['feedbackForm'].validate((valid) => {
					if (valid) {
						var feedbackForm = Object.assign({}, this.feedbackForm);
						feedbackForm.updateTime = "";

						this.$http.request(this, this.$apis.tools.feedbackPublish, feedbackForm, true, res => {
							if (res.data != null) {
								this.feedbackList.unshift(res.data);
							}

							this.dialogVisible = false;
						});
					}
				});
			},
			showFeedback(index) {
				var feedbackForm = this.feedbackList[index];
				this.feedbackForm = feedbackForm;
				this.dialogVisible = true;
			},
			removeFeedback(index) {
				var feedback = this.feedbackList[index];
				this.$confirm('此操作将永久删除[' + feedback.opinion + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.tools.feedbackRemove.replace("{serial}", feedback.serial);
					this.$http.request(this, url, {}, true, data => {
						this.feedbackList.splice(index, 1);
					});
				});
			}
		},
		mounted() {

		},
		created() {
			this.getFeedbacks();
		}
	}
</script>
