<template>
  <div class="fragementLog">
    <div class="pdding20 bgffffff radius5 font12">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item>客户管理</el-breadcrumb-item>
        <el-breadcrumb-item>网点管理</el-breadcrumb-item>
        <el-breadcrumb-item>操作日志</el-breadcrumb-item>
      </el-breadcrumb>
    </div>



    <div class="pdding20 bgffffff overflow mgtop10">
      <div class="wf lineh40">
        <div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
        <span class="fl bold mgl10 font14">筛选查询</span>
      </div>

      <div class="wf mgtop10">
        <el-form :inline="true">
          <el-form-item label="操作日期">
            <el-date-picker type="daterange" size="small" v-model="searchForm.daterange" value-format="yyyy-MM-dd"
              start-placeholder="开始日期" end-placeholder="结束日期" @change="dateRangeChange"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="success" size="small" @click="doSearch"><i class="fa fa-search"></i>查询</el-button>
            <el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>


    <div class="pdding20 bgffffff radius5 overflow mgtop10">

      <div class="wf lineh40">
        <div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
        <span class="fl bold mgl10 font14">数据展示</span>
      </div>

      <el-table :data="logList" :stripe="true" border class="mgtop10">
        <el-table-column prop="subTime" label="发布日期" width="160"></el-table-column>
        <el-table-column prop="message" label="操作信息"></el-table-column>
        <el-table-column prop="optionerName" label="操作者"></el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="wf mgtop10">
        <div class="pdding20">
          <el-pagination v-show="logList.length>0" @size-change="pageSizeChange" @current-change="pageChange"
            :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]" :page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="searchForm.total" background class="fr"></el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'fragementLog',
    data() {
      return {
        searchForm: {
          page: 1,
          pageSize: 20,
          total: 0
        },
        logList: [],
      }
    },
    methods: {
      resetSearch() {
        this.searchForm = {
          page: 1,
          pageSize: 20,
          total: 0
        }
      },
      dateRangeChange(e) {
        if (e == null) {
          this.searchForm.starTime = "";
          this.searchForm.endTime = "";
        } else {
          this.searchForm.starTime = e[0];
          this.searchForm.endTime = e[1];
        }
      },
      doSearch() {
        this.pageChange(1);
      },
      pageChange(page) {
        this.searchForm.page = page;
        this.getLogs();
      },
      pageSizeChange(pageSize) {
        this.searchForm.pageSize = pageSize;
        this.getLogs();
      },
      getLogs() {
        this.searchForm.identity = this.$route.params.orgId;
        this.$http.request(this, this.$apis.log.logList, this.searchForm, false, (res) => {
          this.searchForm.total = res.count;
          this.logList = res.data;

        });
      }
    },
    mounted() {

    },
    created() {
      this.getLogs();
    }
  }
</script>

<style>
</style>
