<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>卡券服务</el-breadcrumb-item>
				<el-breadcrumb-item>销售订单</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="手机号">
						<el-input v-model="searchForm.telphone" placeholder="请填写会员手机号" />
					</el-form-item>
					<el-form-item label="订单号">
						<el-input v-model="searchForm.bookCode" placeholder="请填写订单号" />
					</el-form-item>
					<el-form-item label="卡券名称">
						<el-input v-model="searchForm.good" placeholder="请填写卡券名称" />
					</el-form-item>
					<el-form-item label="订单状态">
						<el-select v-model="searchForm.status" clearable placeholder="请选择订单状态">
							<el-option :value="1" label="未付款"></el-option>
							<el-option :value="2" label="已付款"></el-option>
							<el-option :value="7" label="待退款"></el-option>
							<el-option :value="8" label="已退款"></el-option>
							<el-option :value="6" label="已完成"></el-option>
							<el-option :value="11" label="已关闭"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="核销状态">
						<el-select v-model="searchForm.consume" clearable placeholder="请选择核销状态">
							<el-option :value="1" label="已核销"></el-option>
							<el-option :value="0" label="未核销"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="付款日期">
						<el-date-picker v-model="searchForm.daterange" type="daterange" @change="dateChange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
						<el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i>重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">

			<div class="wf lineh40">
				<el-button type="success" size="small" @click="doExport"><i class="fa fa-download"></i> 批量导出</el-button>
			</div>

			<el-table :data="orderList" :stripe="true" border @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-table :data="props.row.svcInfos" :stripe="true" border>
							<el-table-column prop="type" label="账户信息" width="200">
								<template slot-scope="scope">
									<img height="70px" width="70px" :src="scope.row.goodImg" />
								</template>
							</el-table-column>
							<el-table-column prop="goodName" label="卡券名称"></el-table-column>
							<el-table-column prop="format" label="规格"></el-table-column>
							<el-table-column prop="buyCount" label="购买数量"></el-table-column>
						</el-table>
						<div class="pdding5 overflow">备注：{{props.row.remark}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="type" label="账户信息" width="240">
					<template slot-scope="scope">
						<div class="wf lineh20">昵称：{{scope.row.contacts}}</div>
						<div class="wf lineh20">电话：{{scope.row.telphone}}</div>
						<div class="wf lineh20">地址：{{scope.row.delivery}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="goods" label="订单信息">
					<template slot-scope="scope">
						<div class="wf lineh20">卡券名称：{{scope.row.goods}}</div>
						<div class="wf lineh20">订单编号：{{scope.row.bookCode}}</div>
						<div class="wf lineh20">创建时间：{{scope.row.subTime}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="valid" label="订单状态">
					<template slot-scope="scope">
						<el-tag size="small" v-if="scope.row.status==1" type="danger">未付款</el-tag>
						<el-tag size="small" v-if="scope.row.status==11" type="info">已关闭</el-tag>
						<el-tag size="small" v-if="scope.row.status==6" type="success">已完成</el-tag>
						<el-tag size="small" v-if="scope.row.status==4" type="info">已收货</el-tag>
						<el-tag size="small" v-if="scope.row.status>=2 && scope.row.status!=11" type="success">已付款
						</el-tag>
						<el-tag size="small" v-if="scope.row.status==3" type="success">已发货</el-tag>
						<el-tag size="small" v-if="scope.row.status==8 || scope.row.refundState>1" type="waring">已退款
						</el-tag>
						<el-tag size="small" v-if="scope.row.status==7" type="warning">申请退款</el-tag>
						<div class="wf" v-if="scope.row.status>=2 && scope.row.status!=11">付款时间:{{scope.row.payTime}}
						</div>
						<div class="wf" v-if="scope.row.refundState>1">退款时间:{{scope.row.refundTime}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="payType" label="支付方式" width="100" align="center">
					<template slot-scope="scope">
						<el-tag size="small" v-if="scope.row.payType==2 && scope.row.payTime" type="primary">支付宝支付
						</el-tag>
						<el-tag size="small" v-if="scope.row.payType==22 && scope.row.payTime" type="success">微信支付
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="cost" label="支付金额" align="right" width="120">
					<template slot-scope="scope">
						<el-tag type="danger" size="small">¥{{parseFloat(scope.row.actualCost * 0.01).toFixed(2)}}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="valid" label="核销状态" align="center" width="120">
					<template slot-scope="scope">
						<el-tag size="small" v-if="scope.row.consume==1" type="danger">已核销</el-tag>
						<el-tag size="small" v-if="scope.row.consume==0" type="info">未核销</el-tag>
						<div class="wf" v-if="scope.row.consume==1">
							核销时间:{{scope.row.consumeTime}}
						</div>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="orderList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>

		<!-- 订单详情及发货 -->
		<el-dialog :close-on-click-modal="false" title="订单详情" :visible.sync="dialogVisible" width="800px">
			<el-form class="wf" ref="orderForm" :model="orderForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="24">
						<el-table :data="orderForm.svcInfos" :stripe="true" border>
							<el-table-column prop="type" label="账户信息" width="200">
								<template slot-scope="scope">
									<img height="70px" width="70px" :src="scope.row.goodImg" />
								</template>
							</el-table-column>
							<el-table-column prop="goodName" label="卡券名称"></el-table-column>
							<el-table-column prop="format" label="规格"></el-table-column>
							<el-table-column prop="buyCount" label="购买数量"></el-table-column>
						</el-table>
						<div class="mgtop20 mgbtm30" style="border-top:1px dashed #DEDEDE;"></div>
					</el-col>
					<el-col :span="12">
						<el-form-item label="收货人" prop="contacts">
							<el-input v-model="orderForm.contacts" placeholder="请填写收货人" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="联系电话" prop="telphone">
							<el-input v-model="orderForm.telphone" placeholder="请填写联系电话" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="收货地址" prop="delivery" style="height: 40px;">
							<el-input v-model="orderForm.delivery" placeholder="请填写收货地址"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="订单备注" prop="remark">
							<el-input type="textarea" v-model="orderForm.remark" rows="5" placeholder="请填写订单备注">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="快递单号" prop="waybill">
							<div class="wf lineh40">
								<el-button type="success" size="small" @click="newExpress"><i class="fa fa-plus"></i>
									新增快递</el-button>
							</div>

							<el-table class="wf mgtop10" :data="orderForm.waybill" :stripe="true" border>
								<el-table-column type="index" width="55"></el-table-column>
								<el-table-column prop="name" label="快递名称">
									<template slot-scope="scope">
										<el-input placeholder="请填写快递名称" v-model="scope.row.name"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="number" label="运单号">
									<template slot-scope="scope">
										<el-input placeholder="请填写运单号" v-model="scope.row.number"></el-input>
									</template>
								</el-table-column>
								<el-table-column align="center" label="操作" width="100">
									<template slot-scope="scope">
										<el-row :gutter="10">
											<el-col :span="24">
												<el-button style="width:100%" type="danger" size="small"
													@click="orderForm.waybill.splice(scope.$index,1)"><i
														class="fa fa-remove"></i>删除</el-button>
											</el-col>
										</el-row>
									</template>
								</el-table-column>
							</el-table>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<span slot="footer" class="dialog-footer" align="center">
				<el-button type="success" size="small" @click="sendOrder"><i class="fa fa-edit"></i>确认发货</el-button>
				<el-button @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
		<!-- 订单详情及发货 end -->

		<!-- 退款详情及退款 -->
		<el-dialog :close-on-click-modal="false" title="退款详情" :visible.sync="refundVisible" width="800px">
			<el-form class="wf" ref="orderForm" :model="orderForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="24">
						<el-table :data="orderForm.svcInfos" :stripe="true" border>
							<el-table-column prop="type" label="账户信息" width="200">
								<template slot-scope="scope">
									<img height="70px" width="70px" :src="scope.row.goodImg" />
								</template>
							</el-table-column>
							<el-table-column prop="goodName" label="卡券名称"></el-table-column>
							<el-table-column prop="format" label="规格"></el-table-column>
							<el-table-column prop="buyCount" label="购买数量"></el-table-column>
						</el-table>
						<div class="mgtop20 mgbtm30" style="border-top:1px dashed #DEDEDE;"></div>
					</el-col>
					<el-col :span="12">
						<el-form-item label="收货人" prop="contacts">
							<el-input v-model="orderForm.contacts" placeholder="请填写收货人" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="联系电话" prop="telphone">
							<el-input v-model="orderForm.telphone" placeholder="请填写联系电话" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="收货地址" prop="delivery" style="height: 40px;">
							<el-input v-model="orderForm.delivery" placeholder="请填写收货地址"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="订单备注" prop="remark">
							<el-input type="textarea" v-model="orderForm.remark" rows="5" placeholder="请填写订单备注">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="退款理由" prop="refundReason">{{orderForm.refundReason}}</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="退款金额" prop="refundCost">
							<el-input v-model="orderForm.refundCost" placeholder="请填写退款金额">
								<span slot="append">元</span>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="退款回复" prop="refundReply">
							<el-input type="textarea" v-model="orderForm.refundReply" rows="5" placeholder="请填写退款回复">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<span slot="footer" class="dialog-footer" align="center">
				<el-button type="success" @click="toRefund"><i class="fa fa-edit"></i>确认退款</el-button>
				<el-button @click="refundVisible = false">取 消</el-button>
			</span>
		</el-dialog>
		<!-- 退款详情及退款 end -->

	</div>
</template>

<script>
	export default {
		name: 'fragementAddress',
		data() {
			return {
				dialogVisible: false,
				refundVisible: false,
				searchForm: {
					type: "goods",
					status: "",
					page: 1,
					pageSize: 20,
					total: 0
				},
				accountForm: {},
				orderData: {
					total: 0,
					new: 0,
					sumcost: 0
				},
				orderForm: {},
				orderList: [],
				multipleSelection: [],
				orgs: [],
				systems: [],
				loading: false,
				rules: {
					name: [{
						required: true,
						message: '请输入订单名称',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入订单代码',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择订单类型',
						trigger: 'blur'
					}],
					state: [{
						required: true,
						message: '请选择订单状态',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			dateChange(e) {
				if (e == null) {
					this.searchForm.startTime = "";
					this.searchForm.endTime = "";
				} else {
					this.searchForm.startTime = e[0];
					this.searchForm.endTime = e[1];
				}
			},
			resetSearch() {
				this.searchForm = {
					type: "goods",
					status: "",
					page: 1,
					pageSize: 20,
					total: 0
				}
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getOrders();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getOrders();
			},
			getOrders() {
				this.$http.request(this, this.$apis.pay.orderList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					res.data.forEach(item => {
						item.waybill = JSON.parse(item.waybill);
						item.refundCost = parseFloat(item.refundCost * 0.01).toFixed(2);
					})
					this.orderList = res.data;
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			doExport() {
				this.$http.download(this, this.$apis.pay.orderExport, this.searchForm, false, res => {
					var blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					})
					var url = window.URL.createObjectURL(blob);
					var aLink = document.createElement("a");
					aLink.style.display = "none";
					aLink.href = url;
					aLink.setAttribute("download", "运营端集市订单导出数据.xls");
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); //下载完成移除元素
					window.URL.revokeObjectURL(url); //释放掉blob对象
				});
			},
			showOrder(index) {
				var orderForm = this.orderList[index];
				this.orderForm = orderForm;
				this.dialogVisible = true;
			},
			refundOrder(index) {
				var orderForm = this.orderList[index];
				this.orderForm = orderForm;
				this.refundVisible = true;
			},
			toRefund() {
				var orderForm = this.orderForm;
				console.log("哈哈", orderForm);
				this.$confirm('此操作将发起退款, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.pay.orderRefund.replace("{serial}", orderForm.mserial);
					this.$http.request(this, url, {
						refundCost: parseFloat(orderForm.refundCost * 100).toFixed(0),
						refundReason: orderForm.refundReason
					}, true, res => {
						if (res.code == 0)
							orderForm.status = 8;
					});
				});
			},
			doneOrder(index) {
				var orderForm = this.orderList[index];
				this.$confirm('此操作将完成订单, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.pay.orderDone.replace("{serial}", orderForm.mserial);
					this.$http.request(this, url, {}, true, res => {
						if (res.code == 0)
							orderForm.status = 6;
					});
				});
			},
			getOrderData() {
				this.$http.request(this, this.$apis.pay.orderListData, this.searchForm, false, (res) => {
					let data = res.data;
					data.total.filter(item => {
						this.orderData.total += item.data;
						this.orderData.sumcost += item.cost;
					});

					data.new.filter(item => {
						this.orderData.new += item.data;
					});

				});
			},
			newExpress() {
				this.orderForm.waybill.push({
					name: "",
					number: ""
				})
			},
			sendOrder() {
				var orderForm = this.orderForm;
				this.$confirm('此操作将进行发货, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					console.log("什么操作", orderForm)
					let url = this.$apis.pay.orderSend.replace("{serial}", orderForm.mserial);
					this.$http.request(this, url, {
						waybill: JSON.stringify(orderForm.waybill)
					}, true, res => {
						if (res.code == 0) {
							orderForm.status = 3;
							this.dialogVisible = false;
						}
					});
				});
			}
		},
		created() {
			this.getOrders();
			this.getOrderData();
		}
	}
</script>

<style>
</style>
