<template>
	<div class="fragementBase">
		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">数据中心</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">会员优惠券</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form class="wf" ref="searchForm" :model="searchForm" :inline="true">
					<el-form-item label="优惠券名称">
						<el-input v-model="searchForm.name" placeholder="请填写优惠券名称" />
					</el-form-item>
					<el-form-item label="优惠券标签">
						<el-input v-model="searchForm.tag" placeholder="请填写优惠券标签" />
					</el-form-item>
					<el-form-item label="使用状态">
						<el-select placeholder="请选择使用状态" v-model="searchForm.status">
							<el-option :value="1" label="待使用"></el-option>
							<el-option :value="9" label="使用中"></el-option>
							<el-option :value="2" label="已使用"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="优惠券类型">
						<el-select placeholder="请选择类型" v-model="searchForm.type">
							<el-option :value="0" label="消费券"></el-option>
							<el-option :value="1" label="余额券"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="使用时间">
						<el-date-picker v-model="searchForm.daterange" type="daterange" @change="dateChange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="doSearch"><i class="fa fa-search"></i> 查询
						</el-button>
						<el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i>重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>




		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">数据展示</span>
			</div>

			<el-table :data="couponList" :stripe="true" border class="mgtop10">
				<el-table-column prop="number" label="优惠券" align="left" width="320">
					<template slot-scope="scope">
						<div class="wf">名称：{{scope.row.name}}</div>
						<div class="wf">编号：{{scope.row.number}}</div>
						<div class="wf">标签：{{scope.row.tag}}</div>
						<div class="wf">金额：{{parseFloat(scope.row.discount * 0.01).toFixed(2)}}</div>
						<el-tag size="small" type="danger" v-if="scope.row.type==0">消费券</el-tag>
						<el-tag size="small" type="success" v-if="scope.row.type==1">余额券</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="member" label="用户信息" width="300">
					<template slot-scope="scope">
						<div class="wf">昵称：{{scope.row.nickname}}</div>
						<div class="wf">手机号：{{scope.row.phone}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="subTime" label="获得时间" align="center"></el-table-column>
				<el-table-column prop="version" label="使用状态" align="center">
					<template slot-scope="scope">
						<el-tag type="success" size="small" v-if="scope.row.status==2">已使用</el-tag>
						<el-tag type="warning" size="small" v-if="scope.row.status==9">使用中</el-tag>
						<el-tag type="info" size="small" v-if="scope.row.status==1">未使用</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="useTime" label="使用时间" align="center"></el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="couponList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				couponForm: {},
				couponList: [],
				vipList: [],
				rules: {
					name: [{
							required: true,
							message: '请填写优惠券名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					discount: [{
						required: true,
						message: '请填写折扣',
						trigger: 'blur'
					}],
					duration: [{
						required: true,
						message: '请填写时长',
						trigger: 'blur'
					}],
					give: [{
						required: true,
						message: '请选择是否赠送',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择优惠券类型',
						trigger: 'blur'
					}],
					vip: [{
						required: true,
						message: '请选择会员套餐',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			resetSearch() {
				this.searchForm = {
					page: 1,
					pageSize: 20,
					total: 0
				}
				this.pageChange(1);
			},
			dateChange(e) {
				this.searchForm.startTime = e[0];
				this.searchForm.endTime = e[1];
			},
			doSearch() {
				this.pageChange(1);
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getCoupons();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getCoupons();
			},
			newCoupon() {
				this.couponForm = {};
				this.dialogVisible = true;
			},
			getCoupons() {
				this.$http.request(this, this.$apis.user.memberCouponList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					this.couponList = res.data;
				});
			}
		},
		created() {
			this.getCoupons();
		}
	}
</script>
