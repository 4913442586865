<template>
	<div class="wf">
		<el-upload :disabled="disabled" class="upload-demo" name="mdFile" list-type="picture-card"
			:action="imgUploadUrl" :headers="uploadHeaders" :on-change="picChange" :on-remove="picChange" multiple
			:on-preview="handlePictureCardPreview" :file-list="picList" :before-upload="beforePicUpload">
			<i class="el-icon-plus"></i>
			<div slot="tip" class="el-upload__tip">只能上传JPG、PNG、JPEG文件，且不超过1M</div>
		</el-upload>
		<el-dialog :close-on-click-modal="false" :visible.sync="previewVisible" append-to-body>
			<img width="100%" :src="previewImageUrl" alt="">
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				previewImageUrl: '',
				previewVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				picList: [],
			}
		},
		props: ["record", "pics", "label", "disabled"],
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLtM = file.size / 1024 / 1024 < 20;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLtM) {
					this.$message.error('上传图片大小不能超过 20MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLtM;
			},
			handlePictureCardPreview(file) {
				this.previewImageUrl = file.url;
				this.previewVisible = true;
			},
			picChange(file, fileList) {
				console.log("文件上传", fileList)
				this.picList = fileList;
			},
		},
		created() {
			if (this.pics != null && this.pics != undefined) {
				this.picList = [];
				this.pics.forEach(pic => {
					let file = {
						status: "success",
						url: pic.url,
						response: {
							files: [{
								orianName: pic.name,
								uploadUrl: pic.url,
								time: pic.time
							}]
						}
					};
					this.picList.push(file);
				});
			}
		},
		watch: {
			record(newValue, oldValue) {
				console.log("新的记录", this.record, this.pics);
				if (this.pics != null && this.pics != undefined) {
					this.picList = [];
					this.pics.forEach(pic => {
						let file = {
							status: "success",
							url: pic.url,
							response: {
								files: [{
									orianName: pic.name,
									uploadUrl: pic.url,
									time: pic.time
								}]
							}
						};
						this.picList.push(file);
					});
				} else {
					this.picList = [];
				}
				console.log("图片数据", this.picList)
			},
			picList(newValue, oldValue) {
				let pics = [];
				this.picList.forEach(item => {
					console.log("item", item)
					if (item.status == "success") {
						let pic = item.response.files[0];
						pics.push({
							name: pic.orianName,
							url: pic.uploadUrl,
							time: pic.time
						});
					}
				});
				this.$emit("updatePicData", this.label, pics)
				console.log("文件发生了变化", pics)
			}
		},
	}
</script>

<style>
</style>
