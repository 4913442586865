import Vue from 'vue'

import CryptoJS from 'crypto-js'

export const aesKey = "kesGB 239W83 JWE834";

export default {
	/**
	
	* @param {*需要加密的字符串 注：对象转化为json字符串再加密} word
	
	* @param {*aes加密需要的key值，这个key值后端同学会告诉你} keyStr
	
	*/
	encryptLogin(word, keyStr) { // 加密
	  // keyStr = aesKey;
	  var iv = CryptoJS.enc.Utf8.parse(keyStr);
	  keyStr = CryptoJS.enc.Utf8.parse(keyStr);
	  word = CryptoJS.enc.Utf8.parse(word);
	
	  var ciphertext = CryptoJS.AES.encrypt(word, keyStr, {
	    iv: iv,
	    mode: CryptoJS.mode.CBC
	  }).toString();
	  console.log(ciphertext)
	  return ciphertext;
	},
	
	encrypt (word, keyStr) { // 加密
		keyStr = aesKey;
		//var key = CryptoJS.enc.Utf8.parse(keyStr)

		var encrypted = CryptoJS.AES.encrypt(word, keyStr).toString() // 加密模式为ECB，补码方式为PKCS5Padding（也就是PKCS7）

		var encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted));

		return encData

	},

	decrypt (word, keyStr) { // 解密
	
		keyStr = aesKey;
		
		//var key = CryptoJS.enc.Utf8.parse(keyStr)

		let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
		
		var decrypt = CryptoJS.AES.decrypt(decData, keyStr).toString(CryptoJS.enc.Utf8);

		return decrypt

	}

}
