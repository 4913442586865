<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import WebHeader from '@/components/include/WebHeader'
	import WebFooter from '@/components/include/WebFooter'

	export default {
		name: 'App',
		components: {
			WebHeader,
			WebFooter
		},
		data() {
			return {

			}
		},
		mounted() {
			document.title = "首页-" + this.$http.webConfig.simple;
		}
	}
</script>
