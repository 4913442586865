<template>
  <div class="showSystem">

    <div class="pdding20 bgffffff radius5 font12">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item>早教管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-tabs type="border-card" class="mgtop10">
      <el-tab-pane label="订单信息">

        <el-row class="wf lineh40 font14">
          <el-col :span="12">订单代码：{{order.number}}</el-col>
          <el-col :span="12">服务项目：{{order.serviceName}}</el-col>
          <el-col :span="12">所属会员：{{member.name}}</el-col>
          <el-col :span="12">会员手机号：{{member.phone}}</el-col>
          <el-col :span="12">创建时间：{{order.subTime}}</el-col>
          <el-col :span="12">订单描述：{{order.remark}}</el-col>
        </el-row>

        <!-- 服务记录 -->
        <div class="wf bgffffff overflow mgtop10">
          <div class="wf lineh40">
            <div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
            <span class="fl bold mgl10 font14">筛选查询</span>
          </div>

          <div class="wf mgtop10">
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="服务时间">
                <el-date-picker v-model="searchForm.daterange" type="daterange" @change="dateChange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="pageChange(1)"><i class="fa fa-search"></i>查询</el-button>
                <el-button type="success" @click="newRecord"><i class="fa fa-plus"></i>新增服务记录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <!-- 最新激活记录 -->
        <div class="wf bgffffff overflow radius5 mgtop10">
          <div class="wf lineh40">
            <div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
            <span class="fl bold mgl10 font14">数据展示</span>
          </div>

          <el-table :data="recordList" :stripe="true" border class="mgtop10">
            <el-table-column prop="memberName" label="会员信息"></el-table-column>
            <el-table-column prop="itemName" label="项目名称"></el-table-column>
            <el-table-column prop="method" label="服务方式"></el-table-column>
            <el-table-column prop="application" label="服务时长" width="200" align="center">
              <template slot-scope="scope">
                <el-tag type="success" class="w80">{{scope.row.time}} h</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="subTime" label="发布日期" width="120">
              <template slot-scope="scope">
                <div class="wf lineh20 center">{{scope.row.subTime.substring(0,10)}}</div>
                <div class="wf lineh20 center">{{scope.row.subTime.substring(11,19)}}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="240">
              <template slot-scope="scope">
                <el-button style="width:100%" class="w100" type="primary" size="small" @click="showRecord(0,scope.$index)"><i
                    class="fa fa-edit"></i>查看</el-button>
              </template>
            </el-table-column>
          </el-table>


          <!-- 分页 -->
          <div class="wf mgtop10">
            <div class="pdding20">
              <el-pagination v-show="recordList.length>0" background layout="prev, pager, next" @current-change="pageChange"
                :page-size="searchForm.pageSize" :total="searchForm.total" class="fr"> </el-pagination>
            </div>
          </div>

        </div>


        <!-- 弹出层 -->
        <el-dialog :close-on-click-modal="false"  title="项目服务记录" :visible.sync="dialogVisible" width="1000px">
          <el-form class="wf" ref="recordForm" :model="recordForm" label-width="100px" :rules="rules">

            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="服务方式" prop="method">
                  <el-select v-model="recordForm.method" style="width: 100%;">
                    <el-option value="体验"></el-option>
                    <el-option value="正式"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="服务时长" prop="time">
                  <el-input v-model="recordForm.time">
                    <span slot="append">小时</span>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="服务描述" prop="content">
                  <el-input type="textarea" v-model="recordForm.content" rows="5"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">关 闭</el-button>
            <el-button type="primary" @click="publishRecord">保存</el-button>
          </span>
        </el-dialog>
        <!-- 弹出层 end-->



      </el-tab-pane>
    </el-tabs>


  </div>
</template>

<script>
  export default {
    name: 'showSystem',
    data() {
      return {
        dialogVisible: false,
        searchForm: {
          page: 1,
          pageSize: 20,
          total: 0
        },
        recordForm: {},
        recordList: [],
        order: {},
        member: {}
      }
    },
    methods: {
      getOrder() {
        let url = this.$apis.vaccine.serviceReservationShow.replace("{serial}", this.$route.params.orderId)
        this.$http.request(this, url, {}, false, res => {
          this.order = res.data;
          this.showStoreMember(this.order.member);
        });
      },
      pageChange(page) {
        this.searchForm.page = page;
        this.getRecords();
      },
      newRecord() {
        this.recordForm = {};
        this.dialogVisible = true;
      },
      getRecords() {
        this.searchForm.order = this.$route.params.orderId;
        this.$http.request(this, this.$apis.vaccine.serviceRecordList, this.searchForm, false, (res) => {
          this.searchForm.total = res.count;
          this.recordList = res.data;
        });
      },
      publishRecord() {
        this.$refs['recordForm'].validate((valid) => {
          if (valid) {
            var recordForm = Object.assign({}, this.recordForm);
            recordForm.orderNumber = this.order.serial;
            recordForm.member = this.member.serial;
            recordForm.memberName = this.member.name;
            recordForm.updateTime = "";

            for (var key in recordForm) {
              if (recordForm[key] == null || recordForm[key] == "null" || recordForm[key] == undefined ||
                recordForm[key] ==
                "undefined") {
                recordForm[key] = "";
              }
            }

            this.$http.request(this, this.$apis.vaccine.serviceRecordPublish, recordForm, true, res => {
              let data = res.data;
              if (data != null) {
                this.recordList.unshift(data);
              }
              this.dialogVisible = false;
            });
          }
        });
      },
      showRecord(index) {
        var recordForm = this.recordList[index];
        this.recordForm = recordForm;
        this.dialogVisible = true;
      },
      removeRecord(type, index) {
        var record = this.recordList[index];
        this.$confirm('此操作将永久删除服务记录[' + record.memberName + ' ' + record.itemName + '], 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let url = this.$apis.vaccine.serviceRecordRemove.replace("{serial}", record.serial);
          this.$http.request(this, url, {}, true, data => {
            this.recordList.splice(index, 1);
          });
        });
      },
      showStoreMember(serial) {
        let url = this.$apis.user.storeMemberShow.replace("{serial}", serial);
        this.$http.request(this, url, {}, false, res => {
          this.member = res.data;
        });
      }
    },
    created() {
      this.getOrder();
      this.getRecords();
    }
  }
</script>

<style>
  .el-table__expanded-cell {
    background: #F7F7F7;
  }
</style>
