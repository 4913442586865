<template>
   <div class="fragementRole">
      <div class="pdding20 bgffffff radius5 font12">
       <el-breadcrumb separator="/">
         <el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
         <el-breadcrumb-item>设置</el-breadcrumb-item>
         <el-breadcrumb-item>角色管理</el-breadcrumb-item>
       </el-breadcrumb>
     </div>

     <div class="pdding20 bgffffff radius5 overflow mgtop10">

       <div class="wf mgtop10">
        <el-button type="success" size="small" @click="newRole"><i class="fa fa-plus"></i>添加角色</el-button>

       </div>


       <!-- 最新激活记录 -->
       <div class="wf mgtop10">

        <el-table :data="roleList" :stripe="true" border class="mgtop10">
          <el-table-column prop="subTime" label="发布日期" width="100">
            <template slot-scope="scope">
               {{scope.row.subTime.substring(0,10)}}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="角色名称" width="300"></el-table-column>
          <el-table-column align="center" label="操作" width="260">
            <template slot-scope="scope">
               <el-button type="primary" size="small" @click="showRole(scope.$index)"><i class="fa fa-edit"></i>编辑</el-button>
               <el-button type="danger" size="small" @click="removeRole(scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="wf mgtop10" >
          <div class="pdding20">
            <el-pagination v-show="roleList.length>0" background layout="prev, pager, next" @current-change="pageChange" :page-size="searchForm.pageSize" :total="searchForm.total" class="fr"> </el-pagination>
          </div>
        </div>

       </div>
    </div>


       <!-- 弹出层 -->
    <el-dialog :close-on-click-modal="false"  title="角色信息" :visible.sync="dialogVisible" width="800px">
     <div class="wf">
      <!-- -->
      <el-form class="wf" ref="roleForm"  :model="roleForm" label-width="100px" :rules="rules">

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="角色名称" prop="name" >
              <el-input v-model="roleForm.name" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

       </el-form>
      <!-- -->
     </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="publishRole">保存</el-button>
      </span>
    </el-dialog>
     <!-- 弹出层 end-->

   </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'fragementRole',
  data () {
    return {
      dialogVisible: false,
      searchForm:{
        page:1,
        pageSize:15,
        total:0
      },
      roleForm:{},
      roleList:[],

      rules:{
        name: [
          { required: true, message: '请填写基地名称', trigger: 'blur' },
          { min: 2, max: 100, message: '长度在 2 到 100个字符', trigger: 'blur' }
        ],
        reports: [
          { required: true, message: '请上传图片', trigger: 'blur' }
        ],
        pubTime: [
          { required: true, message: '请选择证书获取时间', trigger: 'blur' }
        ],
        area:[
          { pattern: /^\d+$|^\d+[.]?\d+$/, message: '面积一般为数字' }
        ]
      },
    }
  },
  computed: {
  	...mapGetters([
  	  'canAppend'
  	])
  },
  methods:{

    pageChange(page){
      this.searchForm.page=page;
      this.getRoles();
    },
    newRole(){
      this.roleForm = {};
      this.dialogVisible=true;
    },
    getRoles(){
    	this.$http.user_loadCompanyRole(this.searchForm,(res)=>{
        this.searchForm.total = res.count;
    		this.roleList = res.data;

    	});
    },
    publishRole(){
      this.$refs['roleForm'].validate((valid) => {
      	if (valid) {
          var roleForm = Object.assign({}, this.roleForm);
          roleForm.updateTime ="";

          this.$http.user_publishCompanyRole(this,roleForm,(data)=>{
              if(data!=null){
                this.roleList.unshift(data);
              }

              this.dialogVisible=false;
          });
      	}
      });
    },
    showRole(index){
      var roleForm = this.roleList[index];
      this.roleForm = roleForm;
      this.dialogVisible=true;

    },
    removeRole(index){
      var that = this;
      var report = this.roleList[index];
      this.$confirm('此操作将永久删除['+report.name+'], 是否继续?', '提示', {
      	confirmButtonText: '确定',
      	cancelButtonText: '取消',
      	type: 'warning'
      }).then(() => {
      	that.$http.user_removeCompanyRole(that,report.serial,data=>{
          that.roleList.splice(index,1);
      	});
      });
    }
  },
  mounted() {

  },
  created() {
    this.getRoles();
  }
}
</script>

<style>
</style>
