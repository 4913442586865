<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>卡券服务</el-breadcrumb-item>
				<el-breadcrumb-item>卡券管理</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">

			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="卡券名称">
						<el-input v-model="searchForm.name" placeholder="请填写卡券名称" />
					</el-form-item>
					<el-form-item label="卡券分类">
						<el-select v-model="searchForm.group" placeholder="请选择卡券类型">
							<el-option v-for="(item,index) in productTypes" :key="index" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
						<el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i>重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>


		<div class="pdding20 bgffffff overflow mgtop10">


			<div class="wf lineh40">
				<el-button v-if="principal.level==3" type="danger" size="small" @click="batchRemove"><i
						class="fa fa-trash"></i> 批量删除</el-button>
				<el-button type="success" size="small" @click="newProduct"><i class="fa fa-plus"></i> 新增卡券</el-button>
				<el-button type="success" size="small" @click="doExport"><i class="fa fa-download"></i> 批量导出</el-button>
			</div>

			<el-table class="mgtop10" :data="productList" :stripe="true" border
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="name" label="卡券名称">
					<template slot-scope="scope">
						<div class="wf lineh20">{{scope.row.name}}</div>
						<div class="wf mgtop10">
							<el-tag size="small" type="success">{{scope.row.digest}}</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="clazzName" label="卡券分类" align="center"></el-table-column>
				<el-table-column prop="price" label="价格" align="center">
					<template slot-scope="scope">
						<div class="wf lineh30">
							<el-tag size="small" type="danger">价格：{{scope.row.price}} 元</el-tag>
						</div>
						<div class="wf lineh30">
							<el-tag size="small" type="danger">原价：{{scope.row.marketPrice}} 元</el-tag>
						</div>
						<div class="wf lineh30">
							<el-tag size="small" type="danger">会员价：{{scope.row.memberPrice}} 元</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="库存/销量" align="center">
					<template slot-scope="scope">
						<div class="wf lineh20">
							<el-tag size="small" type="success">总库存：{{scope.row.stock}} 件</el-tag>
						</div>
						<div class="wf lineh20">
							<el-tag size="small" type="success">初始销量：{{scope.row.monthSales}} 件</el-tag>
						</div>
						<div class="wf lineh20">
							<el-tag size="small" type="success">实际销量：{{scope.row.sales}} 件</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="updateTime" label="更新时间" width="120">
					<template slot-scope="scope">
						<div class="wf lineh20 center">{{scope.row.updateTime.substring(0,10)}}</div>
						<div class="wf lineh20 center">{{scope.row.updateTime.substring(11,19)}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="200">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="12">
								<el-button style="width:100%" type="warning" size="small"
									@click="showStandard(scope.$index)"><i class="fa fa-edit"></i>规格</el-button>
							</el-col>
							<el-col :span="12">
								<el-button style="width:100%" type="primary" size="small"
									@click="showProduct(scope.$index)"><i class="fa fa-edit"></i>编辑</el-button>
							</el-col>
							<el-col class="mgtop10" :span="12">
								<el-button style="width:100%" :type="scope.row.onshelf==0?'info':'success'" size="small"
									@click="stopProduct(scope.$index)"><i class="fa fa-stop"></i>
									<span v-if="scope.row.onshelf==1">下架</span>
									<span v-else>上架</span>
								</el-button>
							</el-col>
							<el-col v-if="principal.level==3" class="mgtop10" :span="12">
								<el-button style="width:100%" type="danger" size="small"
									@click="removeProduct(scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="productList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr">
					</el-pagination>
				</div>
			</div>

		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="卡券信息" :visible.sync="dialogVisible" width="800px">
			<el-form class="wf" ref="productForm" :model="productForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="12">
						<el-form-item label="卡券名称" prop="name">
							<div class="wf h40">
								<el-input v-model="productForm.name" placeholder="请填写包装盒上卡券名称" />
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="卡券编号" prop="number">
							<div class="wf h40">
								<el-input v-model="productForm.number" placeholder="请填写卡券编号" />
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="卡券分类" prop="clazzName">
							<div class="wf h40">
								<el-select v-model="productForm.clazzName" placeholder="请选择卡券类型" style="width:100%">
									<el-option v-for="(item,index) in productTypes" :key="index" :value="item.name">
									</el-option>
								</el-select>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="退货设置" prop="returned">
							<div class="wf h40">
								<el-select v-model="productForm.returned" placeholder="请选择卡券退货设置" style="width:100%">
									<el-option :value="true" label="允许退货"></el-option>
									<el-option :value="false" label="禁止退货"></el-option>
								</el-select>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="限时设置" prop="time">
							<div class="wf h40">
								<el-select v-model="productForm.time" placeholder="请选择卡券限时设置" style="width:100%">
									<el-option :value="1" label="限时"></el-option>
									<el-option :value="0" label="普通"></el-option>
								</el-select>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="核销设置" prop="consume">
							<div class="wf h40">
								<el-select v-model="productForm.consume" placeholder="请选择核销设置" style="width:100%">
									<el-option :value="0" label="不核销"></el-option>
									<el-option :value="1" label="核销"></el-option>
								</el-select>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="有效时长" prop="duration">
							<div class="wf h40">
								<el-input v-model="productForm.duration" placeholder="请填写有效时长">
									<template slot="append">天</template>
								</el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="卡券价格" prop="price" style="height: 40px;">
							<div class="wf h40">
								<el-input v-model="productForm.price" placeholder="请填写卡券价格">
									<span slot="append">元</span>
								</el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="卡券原价" prop="marketPrice" style="height: 40px;">
							<div class="wf h40">
								<el-input v-model="productForm.marketPrice" placeholder="请填写卡券原价">
									<span slot="append">元</span>
								</el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="会员价" prop="memberPrice" style="height: 40px;">
							<div class="wf h40">
								<el-input v-model="productForm.memberPrice" placeholder="请填写卡券原价">
									<span slot="append">元</span>
								</el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12" v-if="false">
						<el-form-item label="卡券运费" prop="freight" style="height: 40px;">
							<div class="wf h40">
								<el-input v-model="productForm.freight" placeholder="请填写卡券运费">
									<span slot="append">元</span>
								</el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12" v-if="false">
						<el-form-item label="卡券重量" prop="weight" style="height: 40px;">
							<div class="wf h40">
								<el-input v-model="productForm.weight" placeholder="请填写卡券重量">
									<span slot="append">kg</span>
								</el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="初始销量" prop="monthSales" style="height: 40px;">
							<div class="wf h40">
								<el-input v-model.number="productForm.monthSales" placeholder="请填写卡券初始销量">
									<span slot="append">件</span>
								</el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="简介" prop="digest">
							<el-input type="textarea" v-model="productForm.digest" rows="5" placeholder="请填写卡券作用">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="卡券图片">
							<el-upload class="upload-demo" name="mdFile" list-type="picture-card" :action="imgUploadUrl"
								:headers="uploadHeaders" :on-change="picChange" :on-remove="picChange"
								:on-preview="handlePictureCardPreview" :file-list="picList"
								:before-upload="beforePicUpload" :limit="5">
								<i class="el-icon-plus"></i>
								<div slot="tip" class="el-upload__tip">只能上传JPG、PNG、JPEG文件，且不超过1M</div>
							</el-upload>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<div class="pdding10" style="border-top: 1px dashed #DEDEDE;">
								<div class="wf lineh40 bold">卡券内容</div>
								<VueUEditor @ready="ueditorReady" style="min-height:600px;line-height: 20px;">
								</VueUEditor>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<div class="pdding10" style="border-top: 1px dashed #DEDEDE;">
								<div class="wf lineh40 bold">购买须知</div>
								<VueUEditor @ready="ueditorPropReady" style="min-height:600px;line-height: 20px;">
								</VueUEditor>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishProduct">保存</el-button>
			</span>
		</el-dialog>

		<el-dialog :close-on-click-modal="false" :visible.sync="previewVisible">
			<img width="100%" :src="previewImageUrl" alt="">
		</el-dialog>
		<!-- 弹出层 end-->

		<!-- 规格 -->
		<el-dialog :close-on-click-modal="false" title="卡券规格信息" :visible.sync="standardVisible" width="1000px">
			<div class="wf lineh40">
				<el-button type="success" size="small" @click="newStandrad"><i class="fa fa-plus"></i> 新增规格</el-button>
			</div>

			<el-table class="wf mgtop10" :data="standardList" :stripe="true" border>
				<el-table-column type="index" width="55"></el-table-column>
				<el-table-column prop="combination" label="规格">
					<template slot-scope="scope">
						<el-input placeholder="请填写规格名称" v-model="scope.row.combination"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="sku" label="SKU编码">
					<template slot-scope="scope">
						<el-input placeholder="请填写SKU编码" v-model="scope.row.sku"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="price" label="价格">
					<template slot-scope="scope">
						<el-input placeholder="请填写价格" v-model="scope.row.price">
							<span slot="append">元</span>
						</el-input>
					</template>
				</el-table-column>
				<el-table-column prop="memberPrice" label="会员价">
					<template slot-scope="scope">
						<el-input placeholder="请填写会员价格" v-model="scope.row.memberPrice">
							<span slot="append">元</span>
						</el-input>
					</template>
				</el-table-column>
				<el-table-column prop="stock" label="库存">
					<template slot-scope="scope">
						<el-input placeholder="请填写库存数量" v-model.number="scope.row.stock">
							<span slot="append">件</span>
						</el-input>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="200">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="12">
								<el-button style="width:100%" type="primary" size="small"
									@click="publishStandard(scope.$index)"><i class="fa fa-edit"></i>保存</el-button>
							</el-col>
							<el-col v-if="principal.level==3" :span="12">
								<el-button style="width:100%" type="danger" size="small"
									@click="removeStandard(scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="standardVisible = false">关 闭</el-button>
			</span>
		</el-dialog>
		<!-- 规格 -->


	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		components: {
			VueUEditor
		},
		data() {
			return {
				ueditor: null,
				ueditorProp: null,
				previewImageUrl: '',
				previewVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				dialogVisible: false,
				standardVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				productTypes: [],
				productForm: {
					props: "",
					content: ""
				},
				productList: [],
				standardList: [],
				standardForm: {},
				picList: [],
				relationList: [],
				loading: false,
				multipleSelection: [],
				rules: {
					name: [{
						required: true,
						message: '请填写卡券包装全称',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择卡券类型',
						trigger: 'blur'
					}],
					price: [{
						required: true,
						message: '请填写价格',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '价格为数字，例如：12.00'
					}],
					marketPrice: [{
						required: true,
						message: '请填写价格',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '价格为数字，例如：12.00'
					}],
					memberPrice: [{
						required: true,
						message: '请填写价格',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '价格为数字，例如：12.00'
					}],
					freight: [{
						required: true,
						message: '请填写价格',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '价格为数字，例如：12.00'
					}],
					weight: [{
						required: true,
						message: '请填写重量',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '价格为数字，例如：12.00'
					}]
				},
			}
		},
		methods: {
			ueditorReady(ueditor) {
				let _this = this
				this.ueditor = ueditor;
				ueditor.addListener("contentChange", () => {
					_this.productForm.content = ueditor.getContent();
				});
				if (this.productForm.content != undefined)
					ueditor.setContent(this.productForm.content);
			},
			ueditorPropReady(ueditor) {
				let _this = this
				this.ueditorProp = ueditor;
				ueditor.addListener("contentChange", () => {
					_this.productForm.props = ueditor.getContent();
				});
				if (this.productForm.props != undefined)
					ueditor.setContent(this.productForm.props);
			},
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handlePictureCardPreview(file) {
				this.previewImageUrl = file.url;
				this.previewVisible = true;
			},
			picChange(file, fileList) {
				console.log("文件上传", fileList)
				this.picList = fileList;
			},
			picDone(res, file) {
				console.log("文件上传完成", file)
				if (file.response.files.length > 0)
					this.orgForm.pic = file.response.files[0].uploadUrl;
			},
			resetSearch() {
				this.searchForm = {
					page: 1,
					pageSize: 20,
					total: 0
				}
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getProducts();
			},
			newProduct() {
				this.productForm = {
					props: "",
					content: "",
					freight: 0,
					weight: 0
				};
				this.picList = [];
				if (this.ueditor != null)
					this.ueditor.setContent("");

				if (this.ueditorProp != null)
					this.ueditorProp.setContent("");

				this.dialogVisible = true;
			},
			newStandrad() {
				this.standardForm = {
					combination: "",
					price: "0.00",
					memberPrice: "0.00",
					stock: 0,
					sku: ""
				};
				this.standardList.push(this.standardForm);
			},
			getProducts() {
				this.$http.request(this, this.$apis.pay.shopProductList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					res.data.filter(item => {
						item.price = parseFloat(item.price * 0.01).toFixed(2);
						item.marketPrice = parseFloat(item.marketPrice * 0.01).toFixed(2);
						item.memberPrice = parseFloat(item.memberPrice * 0.01).toFixed(2);
						item.freight = parseFloat(item.freight).toFixed(2);
						item.weight = parseFloat(item.weight).toFixed(2);
					});
					this.productList = res.data;
				});
			},
			doExport() {
				this.$http.download(this, this.$apis.pay.shopProductExport, this.searchForm, false, res => {
					var blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					})
					var url = window.URL.createObjectURL(blob);
					var aLink = document.createElement("a");
					aLink.style.display = "none";
					aLink.href = url;
					aLink.setAttribute("download", "运营端卡券信息导出数据.xls");
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); //下载完成移除元素
					window.URL.revokeObjectURL(url); //释放掉blob对象
				});
			},
			publishProduct() {
				this.$refs['productForm'].validate((valid) => {
					if (valid) {
						var productForm = Object.assign({}, this.productForm);
						productForm.price = parseFloat(productForm.price * 100).toFixed(0);
						productForm.marketPrice = parseFloat(productForm.marketPrice * 100).toFixed(0);
						productForm.memberPrice = parseFloat(productForm.memberPrice * 100).toFixed(0);
						productForm.updateTime = "";
						var picList = [];
						this.picList.filter(file => {
							if (file.status == "success" && file.response.files.length > 0) {
								let doc = file.response.files[0];
								picList.push({
									uid: file.uid,
									name: doc.orianName,
									url: doc.uploadUrl
								})
							}
						});
						productForm.imageOriginal = JSON.stringify(picList);

						this.$http.request(this, this.$apis.pay.shopProductPublish, productForm, true, res => {
							let data = res.data;
							if (data != null) {
								data.price = parseFloat(data.price * 0.01).toFixed(2);
								data.marketPrice = parseFloat(data.marketPrice * 0.01).toFixed(2);
								data.memberPrice = parseFloat(data.memberPrice * 0.01).toFixed(2);
								this.productList.unshift(data);
							}

							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			showProduct(index) {
				var productForm = this.productList[index];
				this.relationMethod(productForm.shopName);
				this.picList = JSON.parse(productForm.imageOriginal);
				if (this.picList != null)
					this.picList.filter(pic => {
						pic.status = "success";
						pic.response = {
							files: [{
								orianName: pic.name,
								uploadUrl: pic.url
							}]
						}
					});
				this.productForm = productForm;
				this.dialogVisible = true;
			},
			showStandard(index) {
				var productForm = this.productList[index];
				this.productForm = productForm;
				this.standardVisible = true;
				this.getStandards();
			},
			removeProduct(index) {
				var product = this.productList[index];
				this.$confirm('此操作将删除[' + product.name + ']的厂家, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.pay.shopProductRemove.replace("{serial}", product.serial);
					this.$http.request(this, url, {}, true, res => {
						this.productList.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);
				this.$confirm('此操作将永久删除选中的卡券信息 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.pay.shopProductBatchRemove, {
						serials: serials
					}, true, res => {
						var products = Object.assign([], this.productList);
						products.filter((product, index) => {
							this.multipleSelection.filter(select => {
								if (select.serial == product.serial) {
									this.productList.splice(index, 1);
								}
							})
						});
					});
				});
			},
			stopProduct(index) {
				var product = this.productList[index];
				this.$confirm('此操作将下架[' + product.name + ']的厂家,如需删除，请点击删除。 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.pay.shopProductStop.replace("{serial}", product.serial);
					this.$http.request(this, url, {}, true, res => {
						if (res.code == 0)
							product.onshelf = res.data;
					});
				});
			},
			recommendProduct(index) {
				var product = this.productList[index];
				this.$confirm('此操作将推荐[' + product.name + ']的厂家,如需删除，请点击删除。 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.pay.shopProductRecommend.replace("{serial}", product.serial);
					this.$http.request(this, url, {}, true, res => {
						if (res.code == 0)
							product.state = res.data;
					});
				});
			},
			getTypes() {
				this.$http.request(this, this.$apis.pay.shopTypeList, {}, false, res => {
					this.productTypes = res.data;
				});
			},
			getStandards() {
				let url = this.$apis.pay.shopProductStandardList.replace("{serial}", this.productForm.serial);
				this.$http.request(this, url, {}, false, (res) => {
					this.searchForm.total = res.count;
					res.data.filter(item => {
						item.price = parseFloat(item.price * 0.01).toFixed(2);
						item.memberPrice = parseFloat(item.memberPrice * 0.01).toFixed(2);
					});
					this.standardList = res.data;
				});
			},
			publishStandard(index) {
				var standardForm = this.standardList[index];
				standardForm.price = parseFloat(standardForm.price * 100).toFixed(0);
				standardForm.memberPrice = parseFloat(standardForm.memberPrice * 100).toFixed(0);
				standardForm.product = this.productForm.serial;
				standardForm.updateTime = "";
				this.$http.request(this, this.$apis.pay.shopProductStandardPublish, standardForm, true, res => {
					standardForm.price = parseFloat(standardForm.price * 0.01).toFixed(2);
					standardForm.memberPrice = parseFloat(standardForm.memberPrice * 0.01).toFixed(2);
				});
			},
			removeStandard(index) {
				var standard = this.standardList[index];
				this.$confirm('此操作将删除[' + standard.combination + ']的规格, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.pay.shopProductStandardRemove.replace("{serial}", standard.serial);
					this.$http.request(this, url, {}, true, res => {
						this.standardList.splice(index, 1);
					});
				});
			},
			relationMethod(query) {
				if (query !== '') {
					this.loading = true;
					setTimeout(() => {
						this.loading = false;
						this.$http.request(this, this.$apis.user.orgList, {
							name: query,
							type: 2
						}, false, res => {
							this.relationList = res.data;
						});
					}, 200);
				} else {
					this.relationList = [];
				}
			},
			relationChange() {
				this.relationList.forEach(item => {
					if (item.serial == this.productForm.shop)
						this.productForm.shopName = item.name;
				})
			}
		},
		created() {
			this.getTypes()
			this.getProducts();
		}
	}
</script>

<style>
	.edui-default .edui-editor-iframeholder {
		height: 400px;
	}
</style>


<style>
</style>
