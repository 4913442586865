<template>
	<div class="RegSuccess bgffffff">
		<div class="wf" style="border-bottom: 1px solid #EDEDED;">
			<div class="w1200 h100 lineh100">
				<a href="/">
					<div class="logo fl" style="line-height: 50px;"><img src="../../assets/logo.png?v=1.0"
							style="height: 50px;margin-top: 25px;"></div>
					<div class="fl mgl10 font18 bold">{{$http.webConfig.simple}}</div>
				</a>
			</div>
		</div>

		<div class="wf" style="margin-top: 100px;">
			<div class="w400 center-block " style="min-height: 460px;">
				<div class="wf mgtop20" style="padding-left: 120px;position: relative;">
					<img class="fl mgr10" width="60" src="/static/images/reg_success.png"
						style="position: absolute;left: 0;" />
					<p class="lineh60 font20" style="color:#009966;">恭喜，{{nickname}}已注册成功！</p>
					<div class="wf mgtop10">
						<a href="/">
							<el-button type="default">立即登录</el-button>
						</a>
					</div>
					<p class="wf mgtop10">您的昵称：<label
							style="color:#ff9966">{{nickname}}</label>将会展示在页面顶部和业务中心等地方，如不希望，建议您修改昵称.</p>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'RegSuccess',
		data() {
			return {
				nickname: ""
			}
		},
		methods: {

		},
		mounted() {
			document.title = "用户注册-供深食品";
		},
		created() {
			this.nickname = window.sessionStorage.getItem("nickname");
		}
	}
</script>

<style>
	.Reg .el-input__inner {
		height: 50px !important;
		line-height: 50px !important;
	}
</style>
