<template>
	<div class="pdding20 bgffffff">
		<el-table class="mgtop20" :data="dataList" :stripe="true" border>
			<el-table-column label="序号" type="index" width="55"></el-table-column>
			<el-table-column label="名称" prop="name" />
			<el-table-column label="价格 " prop="price">
				<template slot-scope="scope">{{parseFloat(scope.row.price*0.01).toFixed(2)}}</template>
			</el-table-column>
			<el-table-column label="销量" prop="sales" />
			<el-table-column label="销售额" prop="income"><template
					slot-scope="scope">{{parseFloat(scope.row.income*0.01).toFixed(2)}}
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				dataList: []
			}
		},
		methods: {
			getProducts() {
				this.$http.request(this, this.$apis.pay.shopProductList, {}, false, res => {
					if (res.code == 0) {
						this.dataList = res.data;
					}
				});
			}
		},
		created() {
			this.getProducts();
		}
	}
</script>
