// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import App from './App'
import router from './router'
import store from './store'
import * as  $http from './requests'
import $apis from './requests/apis'
import VueCookies from 'vue-cookie'
import $utils from './utils.js'

Vue.config.productionTip = false
Vue.prototype.$store = store;
Vue.prototype.$http = $http
Vue.prototype.$utils = $utils
Vue.prototype.$apis = $apis

/* eslint-disable no-new */
Vue.use(VueCookies)

new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})
