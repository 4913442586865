<template>
	<div class="fragementAddress">

		<div class="wf bgffffff overflow ">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="网点名称">
						<el-input v-model="searchForm.name" placeholder="请填写网点名称" />
					</el-form-item>
					<el-form-item label="站点编码">
						<el-input v-model="searchForm.code" placeholder="请填写站点编码" />
					</el-form-item>
					<el-form-item label="站点类型">
						<el-select v-model="searchForm.nature">
							<el-option label="平台运营中心" value="0"></el-option>
							<el-option label="区域运营中心" value="1"></el-option>
							<el-option label="医疗机构/组织/服务中心" value="2"></el-option>
							<el-option label="眼管家" value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所在区域">
						<el-cascader v-model="searchForm.mregion" placeholder="试试搜索：广州" :options="gsonOptions"
							filterable style="width: 100%;"></el-cascader>
					</el-form-item>
					<el-form-item label="电话">
						<el-input v-model="searchForm.contactNumber" placeholder="请填写网点电话" />
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
						<el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i>重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<!-- 最新激活记录 -->
		<div class="wf bgffffff overflow radius5 mgtop10">
			<div class="wf lineh40">
				<el-button v-if="principal.level==3" type="danger" size="small" @click="batchRemove"><i
						class="fa fa-trash"></i> 批量删除</el-button>
				<el-button type="success" size="small" @click="newOrg"><i class="fa fa-plus"></i> 新增网点</el-button>
				<el-button type="success" size="small" @click="doExport"><i class="fa fa-download"></i> 批量导出</el-button>
			</div>

			<el-table :data="orgList" :stripe="true" border class="mgtop10" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="name" label="组织类型">
					<template slot-scope="scope">
						<div class="wf">{{scope.row.name}}
							<el-tag v-if="scope.row.nature==0" type="warning" size="small">平台运营中心</el-tag>
							<el-tag v-if="scope.row.nature==1" type="success" size="small">区域运营中心</el-tag>
							<el-tag v-if="scope.row.nature==2" type="primary" size="small">医疗机构/组织/服务中心</el-tag>
							<el-tag v-if="scope.row.nature==3" type="info" size="small">眼管家</el-tag>
						</div>
						<div class="wf">站点编码：{{scope.row.orgCode}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="contact" label="联系人">
					<template slot-scope="scope">
						<div class="wf">联系人：{{scope.row.contact}}</div>
						<div class="wf">联系电话：{{scope.row.contactNumber}}</div>
					</template>
				</el-table-column>

				<el-table-column prop="member" label="用户数量" width="100" align="center"></el-table-column>
				<el-table-column prop="enable" label="状态" width="160" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.mstate" @change="switchState" active-text="正常" inactive-text="封禁"
							:active-value="scope.$index+'-1'" :inactive-value="scope.$index+'-0'">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="240">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="24">
								<el-button style="width: 100%;" type="primary" size="small"
									@click="showOrg(0,scope.$index)"><i class="fa fa-edit"></i>编辑</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="orgList.length>0" @size-change="pageSizeChange" @current-change="pageChange"
						:current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]" :page-size="searchForm.pageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="searchForm.total" background
						class="fr"></el-pagination>
				</div>
			</div>

		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="网点信息" :visible.sync="dialogVisible" width="800px">
			<div class="wf">
				<!-- -->
				<el-form class="wf" ref="orgForm" :model="orgForm" label-width="100px" :rules="rules">

					<el-row :gutter="10">
						<el-col :span="12">
							<el-form-item label="网点名称" prop="name">
								<div class="wf h40">
									<el-input v-model="orgForm.name" placeholder="请填写网点 名称"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="所在区域" prop="region">
								<div class="wf h40">
									<el-cascader v-model="orgForm.region" placeholder="试试搜索：云南" :options="gsonOptions"
										filterable style="width:100%"></el-cascader>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="详细地址" prop="address">
								<div class="wf h40">
									<el-input v-model="orgForm.address" placeholder="请填写详细地址">
										<span class="pointer" slot="append" @click="showMap">选择位置</span>
									</el-input>
								</div>
							</el-form-item>
						</el-col>
						<div class="wf">
							<el-col :span="12">
								<el-form-item label="网点性质" prop="nature">
									<el-select v-model="orgForm.nature" @change="natureChange" style="width: 100%;">
										<el-option label="平台运营中心" value="0"></el-option>
										<el-option label="区域运营中心" value="1"></el-option>
										<el-option label="医疗机构/组织/服务中心" value="2"></el-option>
										<el-option label="眼管家" value="3"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12" v-if="orgForm.nature!='0'">
								<el-form-item label="所属单位" prop="parent">
									<div class="wf h40">
										<el-select v-model="orgForm.parent" filterable remote reserve-keyword
											placeholder="请输入关键词" :remote-method="getParent" :loading="loading"
											@change="parentChange" style="width: 100%;">
											<el-option v-for="(item,index) in parentList" :value="item.serial"
												:label="item.name" :key="item.serial"></el-option>
										</el-select>
									</div>
								</el-form-item>
							</el-col>
						</div>

						<el-col :span="12">
							<el-form-item label="联系人" prop="contact">
								<div class="wf h40">
									<el-input v-model="orgForm.contact" placeholder="请填写网点联系人"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="联系电话" prop="contactNumber">
								<div class="wf h40">
									<el-input v-model="orgForm.contactNumber" placeholder="请填写网点联系电话"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="网点账号" prop="act">
								<div class="wf h40">
									<el-input v-model="orgForm.act" placeholder="请填写网点账号"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="账号密码" prop="pwd">
								<div class="wf h40">
									<el-input v-model="orgForm.pwd" placeholder="请填写账号密码"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="角色" prop="roles">
								<el-select v-model="orgForm.roles" style="width:100%">
									<el-option v-for="(item,index) in roles" :key="index" :value="item.serial"
										:label="item.name"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="合作单位">
								<div class="wf h40">
									<el-input v-model="orgForm.vendor" placeholder="请填写合作单位" />
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="合作方式" prop="alliance">
								<div class="wf h40">
									<el-select v-model="orgForm.alliance" style="width: 100%;">
										<el-option label="合作" :value="0"></el-option>
										<el-option label="直营" :value="1"></el-option>
										<el-option label="自营" :value="2"></el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="站点编码" prop="orgCode">
								<div class="wf h40">
									<el-input v-model="orgForm.orgCode" placeholder="请填写站点编码"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="营业时间" prop="workTime">
								<div class="wf h40">
									<el-date-picker style="width: 100%;" v-model="orgForm.workTime" type="date"
										placeholder="选择日期" value-format="yyyy-MM-dd">
									</el-date-picker>
								</div>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="经度" prop="lng">
								<el-input placeholder="请填写网点坐标经度" v-model="orgForm.lng"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="纬度" prop="lat">
								<el-input placeholder="请填写网点坐标纬度" v-model="orgForm.lat"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="24">
							<el-form-item label="网点图片" prop="workTime">
								<el-upload class="upload-demo" name="mdFile" list-type="picture-card"
									:action="imgUploadUrl" :headers="uploadHeaders" :on-change="picChange"
									:on-remove="picChange" :on-preview="handlePictureCardPreview" :file-list="picList"
									:before-upload="beforePicUpload">
									<i class="el-icon-plus"></i>
									<div slot="tip" class="el-upload__tip">只能上传JPG、PNG、JPEG文件，且不超过1M</div>
								</el-upload>
								<el-dialog :close-on-click-modal="false" :visible.sync="previewVisible">
									<img width="100%" :src="dialogImageUrl" alt="">
								</el-dialog>
							</el-form-item>
						</el-col>


					</el-row>

				</el-form>
				<!-- -->
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishOrg">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

		<el-dialog :close-on-click-modal="false" :visible.sync="previewVisible" append-to-body>
			<img width="100%" :src="previewImageUrl" alt="">
		</el-dialog>


		<el-dialog :close-on-click-modal="false" title="位置选择" :visible.sync="mapVisible" width="800px">
			<div class="wf lineh40">
				<el-input @change="getLocation" class="fl" v-model="orgForm.address" placeholder="请填写具体位置"
					style="width:500px;" />
				<span class="fl mgl10">坐标：{{orgForm.lng}},{{orgForm.lat}}</span>
			</div>
			<div style="height: 400px;width: 100%;margin-top: 10px;" id="container"></div>
			<div class="wf mgtop20" style="line-height: 40px;overflow: hidden;padding-top:10px;">
				<el-button @click="confirmMap" style="float: right;" type="primary">确认</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	var gsonData = require('../../../gson/china.json');
	export default {
		name: 'fragementAddress',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				previewImageUrl: '',
				previewVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				picList: [],
				mapAddress: "",
				map: null,
				mapVisible: false,
				marker: null,
				settingVisible: false,
				dialogVisible: false,
				searchForm: {
					type: 0,
					page: 1,
					pageSize: 20,
					total: 0
				},
				gsonOptions: gsonData,
				paramForm: {},
				orgForm: {
					parent: ""
				},
				orgList: [],
				vendorList: [],
				multipleSelection: [],
				subOrgList: [],
				parentList: [],
				roles: [],
				loading: false,
				nature: "临时站点",
				rules: {
					name: [{
							required: true,
							message: '请填写网点名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 10 到 30个字符',
							trigger: 'blur'
						}
					],
					code: [{
							required: true,
							message: '请填写网点代码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 30,
							message: '长度在 10 到 30个字符',
							trigger: 'blur'
						}
					],
					region: [{
						required: true,
						message: '请选择所在区域',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请选填写网点地址',
						trigger: 'blur'
					}],
					effective: [{
						required: true,
						message: '请选择有效期',
						trigger: 'blur'
					}, ],
					contact: [{
						required: true,
						message: '请填写联系人姓名',
						trigger: 'blur'
					}, ],
					contactNumber: [{
						required: true,
						message: '请填写联系人电话',
						trigger: 'blur'
					}, ],
					contactMail: [{
						required: true,
						message: '请填写联系人邮箱地址',
						trigger: 'blur'
					}, ],
					roles: [{
						required: true,
						message: '请选择账号权限',
						trigger: 'blur'
					}],
					act: [{
						required: true,
						message: '请填写网点账号',
						trigger: 'blur'
					}],
					pwd: [{
						required: true,
						message: '请选择账号密码',
						trigger: 'blur'
					}],
					alliance: [{
						required: true,
						message: '请选择合作方式',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handlePictureCardPreview(file) {
				this.previewImageUrl = file.url;
				this.previewVisible = true;
			},
			picChange(file, fileList) {
				console.log("文件上传", fileList)
				this.picList = fileList;
			},
			resetSearch() {
				this.searchForm = {
					type: 0,
					page: 1,
					pageSize: 20,
					total: 0
				}
				this.pageChange(1)
			},
			getLocation(e) {
				console.log(e);
				this.orgForm.address = e;
				this.geoCodeGetLocation(this);
			},
			showMap() {
				this.mapVisible = true;
				this.$nextTick(() => {
					this.initMap();
				});
			},
			confirmMap() {
				this.mapVisible = false;
			},
			geoCodeGetLocation(that) {
				AMap.plugin('AMap.Geocoder', function() {
					var geocoder = new AMap.Geocoder({
						// city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
						city: that.orgForm.region[1]
					})

					if (that.orgForm.address == "")
						that.orgForm.address = that.orgForm.region[2];

					console.log("abcdef", that.orgForm.region[1], that.orgForm.address)

					geocoder.getLocation(that.orgForm.address, function(status, result) {
						console.log("location", status, result);
						if (status === 'complete' && result.info === 'OK') {
							// result中对应详细地理坐标信息
							var location = result.geocodes[0].location;
							that.orgForm.lat = location.lat;
							that.orgForm.lng = location.lng;
							console.log("什么意思", that.map)
							that.map.setCenter([location.lng, location.lat]);
							console.log("》》》》", that.map)
							if (that.marker != null) {
								that.map.remove(that.marker);
								that.marker = new AMap.Marker({
									icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
									position: [location.lng, location.lat]
								});
								that.map.add(that.marker);
							}
						}
					})
				});
			},
			geoCodeGetAddress(that) {
				AMap.plugin('AMap.Geocoder', function() {
					var geocoder = new AMap.Geocoder({
						// city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
						city: that.orgForm.region[1]
					})
					var lnglat = [parseFloat(that.orgForm.lng), parseFloat(that.orgForm.lat)]

					geocoder.getAddress(lnglat, function(status, result) {
						console.log("amk", status, result)
						if (status === 'complete' && result.info === 'OK') {
							// result为对应的地理位置详细信息
							that.orgForm.address = result.regeocode.formattedAddress;
						}
					});
				});
			},
			initMap() {
				// 构造点标记
				var that = this;
				let map = new AMap.Map('container', {
					zoom: 14
				});
				this.map = map;
				this.geoCodeGetLocation(this);
				//this.geoCodeGetAddress(this);

				map.on('moveend', function(ev) {
					var center = map.getCenter(); //获取当前地图中心位置
					if (that.map != null) {
						if (that.marker != null)
							map.remove(that.marker);

						that.marker = new AMap.Marker({
							icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
							position: [center.lng, center.lat]
						});
						map.add(that.marker);
						that.orgForm.lat = center.lat;
						that.orgForm.lng = center.lng;
						that.geoCodeGetAddress(that);
					}
					console.log("移动", center)
				});

			},
			toggleRowExpansion(row, expanded) {
				if (row.expand == undefined || row.expand == false) {
					row.expand = true;
					this.$http.org_getSubOrgs(row.serial, data => {
						this.subOrgList = data;
					});
				} else {
					row.expand = false;
				}
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getOrgs();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getOrgs();
			},
			newOrg() {
				this.orgForm = {
					isParent: true,
					lat: "",
					lng: "",
					address: ""
				};
				this.picList = [];
				this.dialogVisible = true;
			},
			getOrgs() {
				this.searchForm.region = JSON.stringify(this.searchForm.mregion);
				this.$http.request(this, this.$apis.user.orgList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					res.data.forEach((data, index) => {
						data.mstate = index + "-" + data.state;
						data.region = JSON.parse(data.region);
						data.orgYear = this.$http.getMyAge(data.workTime);
					});
					this.orgList = res.data;
				});
			},
			doExport() {
				this.$http.download(this, this.$apis.user.orgExport, this.searchForm, false, res => {
					var blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					})
					var url = window.URL.createObjectURL(blob);
					var aLink = document.createElement("a");
					aLink.style.display = "none";
					aLink.href = url;
					aLink.setAttribute("download", "运营端网点管理导出数据.xls");
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); //下载完成移除元素
					window.URL.revokeObjectURL(url); //释放掉blob对象
				});
			},
			publishOrg() {
				this.$refs['orgForm'].validate((valid) => {
					if (valid) {
						var orgForm = Object.assign({}, this.orgForm);
						orgForm.province = orgForm.region[0];
						orgForm.city = orgForm.region[1];
						orgForm.county = orgForm.region[2];
						orgForm.region = JSON.stringify(orgForm.region);
						orgForm.updateTime = "";
						var picList = [];
						this.picList.filter(file => {
							if (file.status == "success" && file.response.files.length > 0) {
								let doc = file.response.files[0];
								picList.push({
									uid: file.uid,
									name: doc.orianName,
									url: doc.uploadUrl
								})
							}
						});
						orgForm.cover = JSON.stringify(picList);

						this.$http.request(this, this.$apis.user.orgPublish, orgForm, true, res => {
							let data = res.data;
							if (data != null) {
								data.region = JSON.parse(data.region);
								this.orgList.unshift(data);
							}

							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			newSubOrg(index) { //新增子网点
				var parentOrgForm = this.orgList[index];
				this.orgForm = {
					isParent: false,
					parent: parentOrgForm.serial,
					parentName: parentOrgForm.name
				};
				this.dialogVisible = true;
			},
			showOrg(type, index) {
				var orgForm = type == 0 ? this.orgList[index] : this.subOrgList[index];
				orgForm.isParent = type == 0 ? true : false;
				this.picList = JSON.parse(orgForm.cover);
				if (this.picList != null)
					this.picList.filter(pic => {
						pic.status = "success";
						pic.response = {
							files: [{
								orianName: pic.name,
								uploadUrl: pic.url
							}]
						}
					});

				this.orgForm = orgForm;
				this.dialogVisible = true;
				this.natureChange();
			},
			showSetting(type, index) {
				var orgForm = type == 0 ? this.orgList[index] : this.subOrgList[index];
				orgForm.isParent = type == 0 ? true : false;
				this.orgForm = orgForm;
				this.showStationParam(orgForm.serial);
				this.settingVisible = true;
			},
			removeOrg(type, index) {
				var org = type == 0 ? this.orgList[index] : this.subOrgList[index];
				this.$confirm('此操作将永久删除网点[' + org.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.user.orgRemove.replace("{serial}", org.serial);
					this.$http.request(this, url, {}, true, res => {
						if (type == 0) {
							this.orgList.splice(index, 1);
						} else {
							this.subOrgList.splice(index, 1);
						}

					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的网点 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.user.orgBatchRemove, {
						serials: serials
					}, true, res => {
						this.orgList = this.orgList.filter(org => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == org.serial) {
									flag = false;
								}
							})
							return flag;
						});

					});
				});
			},
			getParent(query) {
				this.loading = true;
				this.$http.request(this, this.$apis.user.orgList, {
					name: query,
					nature: this.nature
				}, false, res => {
					this.loading = false;
					this.parentList = res.data;
					this.parentChange();
				});
			},
			switchState(e) {
				let index = e.split("-")[0];
				var org = this.orgList[index];
				let url = this.$apis.user.orgStop.replace("{serial}", org.serial);
				this.$http.request(this, url, {}, true, res => {
					if (res.code == 0)
						org.state = res.data;
				});
			},
			getVendors() {
				this.$http.request(this, this.$apis.user.orgList, {
					type: 1
				}, false, (res) => {
					this.vendorList = res.data;
				});
			},
			vendorChange() {
				this.vendorList.forEach(item => {
					if (this.orgForm.vendor == item.serial)
						this.orgForm.vendorName = item.name;
				});
			},
			natureChange() {
				if (this.orgForm.nature == "0") {
					this.orderForm.parent = "";
					this.orgForm.parentName = "";
					this.orgForm.parentids = "";
				} else if (this.orgForm.nature == "1") {
					this.nature = "0";
					this.orgForm.parentids = this.orgForm.parentids.replace("-" + this.orgForm.serial, "");
				} else {
					this.nature = "1";
					this.orgForm.parentids = this.orgForm.parentids.replace("-" + this.orgForm.serial, "");
				}
				console.log("获取", this.orgForm.parentids)
				this.getParent("");
			},
			parentChange() {
				this.parentList.forEach(item => {
					if (this.orgForm.parent == item.serial) {
						this.orgForm.parentName = item.name;
						this.orgForm.parentids = item.parentids;
					}
				});
			},
			getRoles() {
				this.$http.request(this, this.$apis.user.roleList, {
					pageSize: 50
				}, false, (res) => {
					this.roles = res.data;
				});
			}
		},
		created() {
			this.getRoles();
			this.getOrgs();
			this.getVendors();
		}
	}
</script>

<style>
</style>
