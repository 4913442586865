<template>
	<div class="frame_content relative">
		<div id="container" :style="'height: '+height+'px;width: 100%;'"></div>

		<div id="sdbd" style="position:  absolute;top:30px;left:30px;">
			<div class="wf bgffffff pdding10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item>
						<el-button @click="showTools" type="success" size="small"><i class="fa fa-plus"></i>绘制区域
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<!-- 导航栏 -->
		<tools ref="tools" :map="map" v-on:polygonChange="polygonChange" v-on:polygonRemove="polygonRemove"></tools>
		<!-- 导航栏 end -->

	</div>
</template>

<script>
	import Tools from '@/components/client/org/map/area/Tools'
	export default {
		data() {
			return {
				layer: null,
				map: null,
				height: 100,
				projectList: [],
				sceneList: [],
				polygonList: [],
				mapPolygonList: [],
				polyEditors: [],
				searchForm: {},
				overlayList: [],
				labelList: [],
				textStyle: {
					fontSize: 12,
					fontWeight: 'normal',
					fillColor: '#22886f',
					strokeColor: '#fff',
					strokeWidth: 2,
					fold: true,
					padding: '2, 5',
				}
			}
		},
		components: {
			Tools
		},
		methods: {
			installMap() {
				this.map = new AMap.Map('container', {
					resizeEnable: true, //是否监控地图容器尺寸变化
					zoom: 16, //初始化地图层级
					center: [98.588086, 24.43369] //初始化地图中心点
				});

				this.layer = new AMap.LabelsLayer({
					zooms: [3, 20],
					zIndex: 1000,
				});

				// 图层添加到地图
				this.map.add(this.layer);

				this.getOverlays();
			},
			showTools() {
				let center = this.map.getCenter();
				let path = [];
				let lat = center.lat;
				let lng = center.lng;
				for (let i = 1; i <= 3; i++) {
					path.push([parseFloat(lng) + (Math.random() * i * 0.001), parseFloat(lat) + (Math.random() * i *
						0.001)])
				}
				this.$refs["tools"].areaForm = {
					name: "未命名的区域",
					index: this.overlayList.length + 1,
					path: path,
					config: {
						strokeColor: "#FF33FF",
						strokeWeight: 6,
						strokeOpacity: 0.2,
						fillOpacity: 0.4,
						fillColor: '#1791fc',
						zIndex: 50
					},
					pics: []
				};
				this.$refs["tools"].showVisible = true;
			},
			getOverlays() {
				this.$http.request(this, this.$apis.user.overlayList, this.searchForm, false, (res) => {
					if (res.code == 0) {
						res.data.forEach((item, index) => {
							item.config = JSON.parse(item.config);
							item.path = JSON.parse(item.path);
							item.index = index;
							item.pics = JSON.parse(item.pics);
							this.packPolygon(item);
						});
						this.polygonList = res.data;
					}
				});
			},
			polygonChange(e) {
				console.log("覆盖物信息", e);
				this.packPolygon(e);
				this.polygonList.push(e);
			},
			polygonRemove(e) {
				console.log("删除覆盖物信息", e, this.polygonList);
				this.polygonList.forEach((item, index) => {
					console.log(item, index);
					if (item.serial == e) {
						let polygon = this.mapPolygonList[index];
						console.log("要删除", index, polygon)
						this.polyEditors[index].close();
						this.map.remove(polygon);
						this.polygonList.splice(index, 1);
					}
				})
			},
			packPolygon(item) {
				console.log("覆盖物", item)
				let _this = this;

				item.config["path"] = item.path;
				var polygon = new AMap.Polygon(item.config);
				this.map.add(polygon)
				this.mapPolygonList.push(polygon);
				// 缩放地图到合适的视野级别
				this.map.setFitView([polygon])

				var polyEditor = new AMap.PolyEditor(this.map, polygon)

				polyEditor.on('end', function(event) {
					console.log('触发事件： end', polygon.getPath());
					item.path = [];
					polygon.getPath().forEach((path, mindex) => {
						item.path.push([path.lng, path.lat]);
					});
					// event.target 即为编辑后的多边形对象
				})

				console.log("什么", polyEditor)

				this.polyEditors.push(polyEditor);
				polygon.on("click", function(event) {
					polyEditor.open();
					_this.$refs["tools"].areaForm = item;
					_this.$refs["tools"].showVisible = true;
				});

				polygon.on("dblclick", function(event) {
					polyEditor.close();
					console.log("结束了", item);
					_this.$refs["tools"].areaForm = item;
					_this.$refs["tools"].publish();
				});

				if (item.path.length > 0) {
					let path = item.path[0];
					var marker = new AMap.LabelMarker({
						name: item.name,
						position: [path.lng, path.lat],
						zooms: [10, 20],
						opacity: 1,
						zIndex: 3,
						text: {
							content: item.name,
							direction: 'right',
							offset: [-20, -5],
							style: this.textStyle
						}
					});
					console.log("有信息么？", this.textStyle);
					this.layer.add(marker);
				}
			}
		},
		mounted() {
			this.installMap();
		},
		created() {
			this.height = window.screen.height - 180;
		}
	}
</script>

<style>
	#sdbd .el-form-item {
		margin-bottom: 0 !important;
	}
</style>
