<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="名称">
						<el-input v-model="searchForm.name" placeholder="请填写角色名称" />
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<!-- 最新激活记录 -->
		<div class="wf bgffffff overflow radius5">
			<div class="wf lineh40">
				<el-button type="success" size="small" @click="newRole"><i class="fa fa-plus"></i>新增角色</el-button>
			</div>

			<el-table :data="roleList" :stripe="true" border class="mgtop10" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="name" label="角色名称"></el-table-column>
				<el-table-column prop="updateTime" label="更新时间" width="160" align="center"></el-table-column>
				<el-table-column align="center" label="操作" width="240">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="12">
								<el-button style="width: 100%;" type="primary" size="small"
									@click="showRole(0,scope.$index)"><i class="fa fa-edit"></i>编辑</el-button>
							</el-col>
							<el-col v-if="principal.level==3" :span="12">
								<el-button style="width: 100%;" type="danger" size="small"
									@click="removeRole(0, scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="roleList.length>0" @size-change="pageSizeChange" @current-change="pageChange"
						:current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]" :page-size="searchForm.pageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="searchForm.total" background
						class="fr"></el-pagination>
				</div>
			</div>
		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="角色信息" :visible.sync="dialogVisible" width="800px">
			<el-form class="wf" ref="roleForm" :model="roleForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="24">
						<el-form-item label="角色名称" prop="name">
							<el-input v-model="roleForm.name" placeholder="请填写角色名称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="角色权限" prop="authority">
							<el-tree ref="tree" :data="authoritys" show-checkbox node-key="serial"
								:props="defaultProps">
							</el-tree>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishRole">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0,
					platform: "5c2dc9d7934847f8b4ff9d2266afc093"
				},
				roleForm: {
					authority: []
				},
				roleList: [],
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				multipleSelection: [],
				authoritys: [],
				rules: {
					name: [{
							required: true,
							message: '请填写角色名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					]
				},
			}
		},
		methods: {
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getMembers()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getRoles();
			},
			newRole() {
				this.roleForm = {
					authority: []
				};
				this.dialogVisible = true;
			},
			getRoles() {
				this.$http.request(this, this.$apis.user.roleList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					res.data.forEach(item => {
						item.authority = JSON.parse(item.authority);
					})
					this.roleList = res.data;
				});
			},
			checkAuth(nodes, auth) {
				nodes.forEach(node => {
					if (auth.serial == node.serial)
						auth.checked = true;
				});
			},
			publishRole() {
				this.$refs['roleForm'].validate((valid) => {
					if (valid) {
						let nodes = this.nodeSelected();
						let auths = JSON.parse(JSON.stringify(this.authoritys));
						auths.forEach(auth => {
							this.checkAuth(nodes, auth)
							auth.children.forEach(child => {
								this.checkAuth(nodes, child);
							});
						});
						auths = auths.filter(auth => {
							auth.children = auth.children.filter(child => {
								return child.checked == true;
							});
							return auth.checked == true;
						});

						var roleForm = Object.assign({}, this.roleForm);
						roleForm.authority = JSON.stringify(auths);
						roleForm.platform = this.searchForm.platform;
						roleForm.updateTime = "";
						this.$http.request(this, this.$apis.user.rolePublish, roleForm, true, res => {
							let data = res.data;
							if (data != null) {
								this.roleList.unshift(data);
							}

							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			showRole(role, index) {
				let auths = [];
				this.roleForm = this.roleList[index];
				this.roleForm.authority.forEach(auth => {
					if (auth.children == 0)
						auths.push(auth);
					else
						auth.children.forEach(child => {
							auths.push(child);
						});
				})
				this.dialogVisible = true;
				let _this = this;
				console.log(this.roleForm.authority)
				setTimeout(() => {
					if (_this.$refs.tree != undefined)
						_this.$refs.tree.setCheckedNodes(auths);
				}, 1000);
			},
			removeRole(role, index) {
				var role = this.roleList[index];
				this.$confirm('此操作将永久删除角色[' + role.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					role: 'warning'
				}).then(() => {
					let url = this.$apis.user.roleRemove.replace("{serial}", role.serial);
					this.$http.request(this, url, {}, true, data => {
						this.roleList.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的服务类型 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					role: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.sso.roleBatchRemove, {
						serials: serials
					}, true, res => {

						this.roleList = this.roleList.filter(role => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == role.serial) {
									flag = false;
								}
							})
							return flag;
						});

					});
				});
			},
			authCheck(e) {
				var auth = e.split("_")[0];
				if (e.indexOf("1") >= 0) {
					this.authoritys.forEach(item => {
						if (item.serial.indexOf(auth) >= 0)
							this.roleForm.authority.push(item.serial);
						item.children.forEach(sitem => {
							if (sitem.serial.indexOf(auth + "_") >= 0)
								this.roleForm.authority.push(sitem.serial);
						});
					});
				} else {
					this.roleForm.authority = this.roleForm.authority.filter(item => {
						return item.indexOf(auth) < 0;
					});
				}
			},
			getMenus() {
				this.$http.request(this, this.$apis.user.menuList, this.searchForm, false, (res) => {
					console.log("菜单数据", res.data)
					if (res.code == 0)
						this.authoritys = res.data;
				});
			},
			nodeSelected() {
				console.log("选择的节点", this.$refs.tree.getCheckedNodes(false, true))
				return this.$refs.tree.getCheckedNodes(false, true);
			},
		},
		created() {
			this.getRoles();
			this.getMenus();
		}
	}
</script>

<style>
</style>
