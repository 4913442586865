<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="名称">
						<el-input v-model="searchForm.name" placeholder="请填写部门名称" />
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<!-- 最新激活记录 -->
		<div class="wf bgffffff overflow radius5">
			<div class="wf lineh40">
				<el-button type="success" size="small" @click="newDepart"><i class="fa fa-plus"></i>新增部门</el-button>
			</div>

			<el-table :data="departList" :stripe="true" border class="mgtop10"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="name" label="部门名称"></el-table-column>
				<el-table-column prop="updateTime" label="更新时间" width="160" align="center"></el-table-column>
				<el-table-column align="center" label="操作" width="240">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="12">
								<el-button style="width: 100%;" type="primary" size="small"
									@click="showDepart(0,scope.$index)"><i class="fa fa-edit"></i>编辑</el-button>
							</el-col>
							<el-col v-if="principal.level==3" :span="12">
								<el-button style="width: 100%;" type="danger" size="small"
									@click="removeDepart(0, scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="departList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="部门信息" :visible.sync="dialogVisible" width="600px">
			<el-form class="wf" ref="departForm" :model="departForm" label-width="100px" :rules="rules">
				<el-row :gutter="10">
					<el-col :span="24">
						<el-form-item label="部门名称" prop="name">
							<el-input v-model="departForm.name" placeholder="请填写部门名称"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishDepart">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				departForm: {},
				departList: [],
				multipleSelection: [],
				rules: {
					name: [{
							required: true,
							message: '请填写部门名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					]
				},
			}
		},
		methods: {
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getMembers()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getDeparts();
			},
			newDepart() {
				this.departForm = {};
				this.dialogVisible = true;
			},
			getDeparts() {
				this.$http.request(this, this.$apis.user.departmentList, this.searchForm, false, (res) => {
					this.searchForm.total = res.count;
					this.departList = res.data;
				});
			},
			publishDepart() {
				this.$refs['departForm'].validate((valid) => {
					if (valid) {
						var departForm = Object.assign({}, this.departForm);
						departForm.updateTime = "";

						this.$http.request(this, this.$apis.user.departmentPublish, departForm, true, res => {
							let data = res.data;
							if (data != null) {
								this.departList.unshift(data);
							}

							if (res.code == 0)
								this.dialogVisible = false;
						});
					}
				});
			},
			showDepart(depart, index) {
				var departForm = this.departList[index];
				this.departForm = departForm;
				this.dialogVisible = true;
			},
			removeDepart(depart, index) {
				var depart = this.departList[index];
				this.$confirm('此操作将永久删除部门[' + depart.name + '], 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					depart: 'warning'
				}).then(() => {
					let url = this.$apis.user.departmentRemove.replace("{serial}", depart.serial);
					this.$http.request(this, url, {}, true, data => {
						this.departList.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的服务类型 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					depart: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.sso.departmentBatchRemove, {
						serials: serials
					}, true, res => {

						this.departList = this.departList.filter(depart => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == depart.serial) {
									flag = false;
								}
							})
							return flag;
						});

					});
				});
			},
		},
		created() {
			this.getDeparts();
		}
	}
</script>

<style>
</style>
