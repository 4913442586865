<template>
	<div class="wf">
		<div :id="id" :style="'width: 100%;height:'+height+'px;'"></div>
	</div>
</template>

<script>
	export default {
		props: ["data", "height", "title", "legend", "unit", "id"],
		data() {
			return {
				sourceData: [],
				option: {
					title: {
						text: ''
					},
					tooltip: {
						trigger: 'item'
					},
					legend: {
						top: '5%',
						left: 'center'
					},
					series: [{
						name: '',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: true,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '40',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: []
					}]
				}
			};
		},
		methods: {
			installChart() {
				this.myChart = echarts.init(document.getElementById(this.id));
				this.packOption();
			},
			packOption() {
				let serialData = [];
				this.sourceData.forEach(item => {
					serialData.push({
						value: item.value,
						name: item.label
					});
				});
				this.option.series[0].data = serialData;
				this.option.series[0].name = this.legend;
				this.option.title.text = this.title;
				this.myChart.clear();
				this.myChart.setOption(this.option);
			}
		},
		mounted() {
			this.installChart();
		},
		watch: {
			data(newValue, oldValue) {
				this.sourceData = newValue;
				this.packOption();
			}
		}
	};
</script>
