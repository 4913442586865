<template>
  <div class="fragementBase">
    <!-- 整体数据 -->
    <div class="frame_content mgtop3">
      <el-breadcrumb>
        <el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link to="/">运营中心</router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link to="/">轮播广告</router-link>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 整体数据 end-->
    <div class="pdding20 bgffffff overflow mgtop10">
      <div class="wf lineh40">
        <div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
        <span class="fl bold mgl10 font14">筛选查询</span>
      </div>

      <div class="wf mgtop10">
        <el-form class="wf" ref="searchForm" :model="searchForm" :inline="true">
          <el-form-item label="名称">
            <el-input v-model="searchForm.name" size="small" clearable placeholder="请填写轮播图名称" />
          </el-form-item>
          <el-form-item>
            <el-button type="success" size="small" @click="doSearch"><i class="fa fa-search"></i>查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>


    <!-- 最新激活记录 -->
    <div class="pdding20 bgffffff overflow mgtop10">
      <div class="wf lineh40">
        <el-button type="success" size="small" @click="newBanner"><i class="fa fa-plus"></i>新增轮播图</el-button>
      </div>

      <el-table :data="bannerList" :stripe="true" border class="mgtop10">
        <el-table-column prop="name" label="名称" width="300"></el-table-column>
        <el-table-column prop="website" label="有效期">
          <template slot-scope="scope">{{scope.row.startTime}}至{{scope.row.endTime}}</template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新日期"> </el-table-column>
        <el-table-column align="center" label="操作" width="260">
          <template slot-scope="scope">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-button style="width: 100%;" type="primary" size="small" @click="showBanner(scope.$index)"><i class="fa fa-edit"></i>编辑</el-button>
              </el-col>
              <el-col :span="12">
                <el-button style="width: 100%;" type="danger" size="small" @click="removeBanner(scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
              </el-col>
            </el-row>

          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="wf mgtop10">
        <div class="pdding20">
          <el-pagination v-show="bannerList.length>0" @size-change="pageSizeChange" @current-change="pageChange"
            :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]" :page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="searchForm.total" background class="fr"></el-pagination>
        </div>
      </div>
    </div>


    <!-- 弹出层 -->
    <el-dialog :close-on-click-modal="false" title="轮播图信息" :visible.sync="dialogVisible" width="800px">
      <div class="wf">
        <!-- -->
        <el-form class="wf" ref="bannerForm" :model="bannerForm" label-width="100px" :rules="rules">

          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="名称" prop="name">
                <el-input v-model="bannerForm.name" placeholder="请填写轮播图名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="推广价" prop="mprice">
                <el-input v-model="bannerForm.mprice" placeholder="请填写推广价格">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开始日期" prop="startTime">
                <el-date-picker style="width:100%;" v-model="bannerForm.startTime" type="date" placeholder="选择日期"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结束日期" prop="endTime">
                <el-date-picker style="width:100%;" v-model="bannerForm.endTime" type="date" placeholder="选择日期"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型" prop="type">
                <el-select style="width:100%;" v-model="bannerForm.type" clearable placeholder="请选择">
                  <el-option value="news" label="文章"></el-option>
                  <el-option value="goods" label="商品"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="内容" prop="beSerial">
                <el-select style="width:100%;" v-model="bannerForm.beSerial" clearable filterable remote
                  reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="loading">
                  <el-option v-for="item in options" :key="item.serial" :label="item.name" :value="item.serial">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="封面" prop="cover">
                <el-upload class="avatar-uploader" name="mdFile" :action="imgUploadUrl" :headers="uploadHeaders"
                  :show-file-list="false" :on-success="handleCoverSuccess" :before-upload="beforePicUpload">
                  <img v-if="bannerForm.cover" :src="bannerForm.cover" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="wf lineh20"> <i class="el-icon-star-off"></i>建议使用800*800正方形图片，支持JPG、PNG格式，最大1MB</div>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
        <!-- -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="publishBanner">保存</el-button>
      </span>
    </el-dialog>
    <!-- 弹出层 end-->

  </div>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'
  export default {
    name: 'fragementBase',
    data() {
      return {
        imgUploadUrl: this.$apis.component.baseUploadUrl,
        uploadHeaders: {
          'Authorization': sessionStorage.getItem("token")
        },
        dialogVisible: false,
        searchForm: {
          page: 1,
          pageSize: 20,
          total: 0
        },
        bannerForm: {},
        bannerList: [],
        loading: false,
        options: [],
        rules: {
          name: [{
              required: true,
              message: '请填写轮播图名称',
              trigger: 'blur'
            },
            {
              min: 2,
              max: 30,
              message: '长度在 2 到 30个字符',
              trigger: 'blur'
            }
          ],
          startTime: [{
            required: true,
            message: '请选择开始日期',
            trigger: 'blur'
          }],
          endTime: [{
            required: true,
            message: '请选择结束日期',
            trigger: 'blur'
          }],
          type: [{
            required: true,
            message: '请选择轮播图类型',
            trigger: 'blur'
          }],
          beSerial: [{
            required: true,
            message: '请选择内容',
            trigger: 'blur'
          }],
          cover: [{
            required: true,
            message: '请选择轮播图封面',
            trigger: 'blur'
          }],
          mprice: [{
            required: true,
            message: '请填写价格',
            trigger: 'blur'
          }, {
            pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
            message: '价格为数字，例如：12.00'
          }],
        },
      }
    },
    computed: {
      ...mapGetters([
        'canAppend'
      ])
    },
    methods: {
      beforePicUpload(file) {

        console.log("图片类型", file.type)

        const isJPG = file.type === 'image/jpeg';
        const isJPEG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';

        const isLt1M = file.size / 1024 / 1024 < 1;

        if (!(isJPG || isJPEG || isPNG)) {
          this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
        }

        if (!isLt1M) {
          this.$message.error('上传图片大小不能超过 1MB!');
        }
        return (isJPG || isJPEG || isPNG) && isLt1M;
      },
      handleCoverSuccess(res, file) {
        console.log("文件上传", res)
        if (res.files.length > 0)
          this.bannerForm.cover = res.files[0].uploadUrl;
      },
      doSearch() {
        this.pageChange(1);
      },
      pageChange(page) {
        this.searchForm.page = page;
        this.getBanners();
      },
      pageSizeChange(pageSize) {
        this.searchForm.pageSize = pageSize;
        this.getBanners();
      },
      newBanner() {
        this.bannerForm = {
          cover: "",
          mprice: "0.00"
        };
        this.dialogVisible = true;
      },
      getBanners() {
        this.$http.request(this, this.$apis.tools.bannerList, this.searchForm, false, res => {
          this.searchForm.total = res.count;
          res.data.forEach(item => {
            item.mprice = parseFloat(item.price * 0.01).toFixed(2);
          });
          this.bannerList = res.data;
        });
      },
      publishBanner() {
        this.$refs['bannerForm'].validate((valid) => {
          if (valid) {
            var bannerForm = Object.assign({}, this.bannerForm);
            bannerForm.updateTime = "";
            bannerForm.price = parseFloat(bannerForm.mprice * 100).toFixed(0);
            let option = {};
            this.options.forEach(item => {
              if (item.serial == bannerForm.beSerial) {
                option = item;
              }
            });
            if (bannerForm.type == "news") {
              bannerForm.content = option.url;
            } else if (bannerForm.type == "goods") {
              bannerForm.content = option.serial;
            } else {
              bannerForm.content = JSON.stringify(option);
            }

            this.$http.request(this, this.$apis.tools.bannerPublish, bannerForm, true, res => {
              let data = res.data;
              if (data != null) {
                this.bannerList.unshift(data);
              }
              if (res.code == 0)
                this.dialogVisible = false;
            });
          }
        });
      },
      showBanner(index) {
        var bannerForm = this.bannerList[index];
        this.bannerForm = bannerForm;
        this.dialogVisible = true;
      },
      removeBanner(index) {
        var banner = this.bannerList[index];
        this.$confirm('此操作将永久删除[' + banner.name + '], 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let url = this.$apis.tools.bannerRemove.replace("{serial}", banner.serial);
          this.$http.request(this, url, {}, true, res => {
            this.bannerList.splice(index, 1);
          });
        });
      },
      remoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            if (this.bannerForm.type == 'news') {
              this.getNews(query);
            } else if (this.bannerForm.type == 'goods') {
              this.getGoods(query);
            }
          }, 200);
        } else {
          this.options = [];
        }
      },
      getNews(name) {
        this.$http.request(this, this.$apis.material.newsList, {
          name: name
        }, false, (res) => {
          res.data.forEach(item => {
            item.name = item.title;
          })
          this.options = res.data;
        });
      },
      getTopic(name) {
        this.$http.request(this, this.$apis.material.topicList, {
          name: name
        }, false, (res) => {
          this.options = res.data;
        });
      },
      getStore(name) {
        this.$http.request(this, this.$apis.user.orgList, {
          type: 1,
          name: name
        }, false, (res) => {
          this.options = res.data;
        });
      },
      getGoods(name) {
        this.$http.request(this, this.$apis.pay.shopProductList, {
          name: name
        }, false, (res) => {
          this.options = res.data;
        });
      }
    },
    mounted() {

    },
    created() {
      this.getBanners();
    }
  }
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }

  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }
</style>
