<template>
	<div class="fragementAddress">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>检查治疗</el-breadcrumb-item>
				<el-breadcrumb-item>AI自诊记录</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form :inline="true" :model="searchForm">
					<el-form-item label="手机号">
						<el-input v-model="searchForm.phone" placeholder="请填写用户手机号" />
					</el-form-item>
					<el-form-item label="预约编号">
						<el-input v-model="searchForm.number" placeholder="请填写预约编号" />
					</el-form-item>
					<el-form-item label="诊断日期">
						<el-date-picker v-model="searchForm.daterange" type="daterange" @change="dateChange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="pageChange(1)"><i class="fa fa-search"></i>查询
						</el-button>
						<el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i>重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>


		<div class="pdding20 bgffffff overflow mgtop10">

			<div v-if="principal.level==3" class="wf lineh40">
				<el-button type="success" size="small" @click="doExport"><i class="fa fa-download"></i> 批量导出
				</el-button>
			</div>

			<!-- 最新激活记录 -->
			<el-table class="mgtop10" :data="orderList" :stripe="true" border @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="userName" label="用户信息" width="180">
					<template slot-scope="scope">
						<div class="wf lineh30">{{scope.row.contact}}
							<el-tag v-if="scope.row.gender=='男'" type="primary" size="small">男</el-tag>
							<el-tag v-else type="success" size="small">女</el-tag>
						</div>
						<div class="wf lineh30">手机号：{{scope.row.phone}}</div>
						<div class="wf lineh30">出生日期：{{scope.row.birth}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="症状名称" align="center" width="100"></el-table-column>
				<el-table-column prop="tips" label="典型症状">
					<template slot-scope="scope">
						<div>{{scope.row.tips}}</div>
						<span v-for="(pic,index) in scope.row.pics" :key="index">
							<a target="_blank" :href="pic.url">
								<el-tag size="small" type="primary">{{pic.name}}</el-tag>
							</a>
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="replenish" label="就医意见" align="left"></el-table-column>
				<el-table-column prop="status" label="预约状态" width="100" align="center">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.status == 0" type="primary" size="small">进行中</el-tag>
						<el-tag v-if="scope.row.status == 1" type="warning" size="small">已匹配</el-tag>
						<el-tag v-if="scope.row.status == 2" type="success" size="small">已完成</el-tag>
						<el-tag v-if="scope.row.status == 11" type="info" size="small">已取消</el-tag>
						<el-tag v-if="scope.row.tag == 1" type="success" size="small">完成派单</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="subTime" label="诊断时间" width="160" align="center"></el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="orderList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				orderVisible: false,
				dialogVisible: false,
				recordVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0,
					sortServiceTime: 1
				},
				orderData: {
					total: 0,
					new: 0,
					future: 0,
					state: {
						1: 0,
						2: 0,
						3: 0,
						4: 0
					}
				},
				orderForm: {},
				accountForm: {},
				orderList: [],
				servingList: [],
				multipleSelection: [],
				orgs: [],
				systems: [],
				parentList: [],
				loading: false,
				serials: [""],
				rules: {
					provider: [{
						required: true,
						message: '请选择需要委派的单位',
						trigger: 'blur'
					}],
					tips: [{
						required: true,
						message: '请填写注意事项',
						trigger: 'blur'
					}],
					day: [{
						required: true,
						message: '请选择预约日期',
						trigger: 'blur'
					}],
					time: [{
						required: true,
						message: '请选择预约时间',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请填写需要诊断',
						trigger: 'blur'
					}],
					contact: [{
						required: true,
						message: '请填写预约人名称',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请填写预约人手机号',
						trigger: 'blur'
					}],
					serving: [{
						required: true,
						message: '请选择资源信息',
						trigger: 'blur'
					}],
					tag: [{
						required: true,
						message: '请选择需求类型',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			resetSearch() {
				this.searchForm = {
					page: 1,
					pageSize: 20,
					total: 0
				}
			},
			dateChange(e) {
				if (e == null) {
					this.searchForm.startTime = "";
					this.searchForm.endTime = "";
				} else {
					this.searchForm.startTime = e[0];
					this.searchForm.endTime = e[1];
				}
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getOrders();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getOrders();
			},
			getOrders() {
				var searchForm = Object.assign({}, this.searchForm);
				this.$http.request(this, this.$apis.tools.applyExamList, searchForm, false, res => {
					this.searchForm.total = res.count;
					res.data = this.$http.cleanDatas(res.data);
					res.data.forEach(item => {
						item.pics = JSON.parse(item.pics);
					})
					this.orderList = res.data;
				});
			},
			doExport() {
				var searchForm = Object.assign({}, this.searchForm);
				if (this.serials.length > 1)
					searchForm.serials = this.serials.join(",");
				this.$http.download(this, this.$apis.tools.applyExamExport, searchForm, false, res => {
					var blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					})
					var url = window.URL.createObjectURL(blob);
					var aLink = document.createElement("a");
					aLink.style.display = "none";
					aLink.href = url;
					aLink.setAttribute("download", "管理端诊断记录导出数据.xls");
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); //下载完成移除元素
					window.URL.revokeObjectURL(url); //释放掉blob对象
				});
			},
			showOrder(index) {
				var orderForm = this.orderList[index];
				this.orderForm = orderForm;
				this.dialogVisible = true;
			},
			dealOrder(index) {
				var orderForm = this.orderList[index];
				this.orderForm = orderForm;
				this.recordVisible = true;
			},
			sendOrder(index) {
				var orderForm = this.orderList[index];
				this.orderForm = orderForm;
				this.remoteMethod(orderForm.providerName);
				this.dialogVisible = true;
			},
			doneOrder(index) {
				var orderForm = this.orderList[index];
				this.orderForm = orderForm;
				this.$confirm('此操作将完成需求预约, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.tools.applyExamDone.replace("{serial}", orderForm.serial);
					this.$http.request(this, url, {}, true, res => {
						if (res.code == 0) {
							this.orderForm.status = 2;
						}
					});
				});
			},
			remoteMethod(query) {
				if (query !== '') {
					this.loading = true;
					setTimeout(() => {
						this.loading = false;
						this.$http.request(this, this.$apis.user.orgList, {
							type: 1,
							name: query
						}, false, res => {
							this.orgs = res.data;
						});
					}, 200);
				} else {
					this.orgs = [];
				}
			},
			remoteServing(query) {
				if (query !== '') {
					this.loading = true;
					setTimeout(() => {
						this.loading = false;
						this.$http.request(this, this.$apis.serving.servingList, {
							name: query
						}, false, res => {
							this.servingList = res.data;
						});
					}, 200);
				} else {
					this.orgs = [];
				}
			},
			servingChange(e) {
				this.servingList.forEach(item => {
					if (item.serial == this.orderForm.serving) {
						this.orderForm.name = item.name;
					}
				});
			},
			pubOrder() {
				this.orderForm = {
					tag: 0
				}
				this.orderVisible = true;
			},
			publishOrder() {
				this.$refs['orderForm'].validate((valid) => {
					if (valid) {
						this.orderForm.appointment = this.orderForm.day + " " + this.orderForm.time;
						this.$http.request(this, this.$apis.tools.applyExamPublish, this.orderForm, true,
							res => {
								if (res.code == 0) {
									this.orderList.unshift(res.data);
									this.orderVisible = false;
								}
							});
					}
				});
			},
			handleSelectionChange(val) {
				console.log("勾选的项", val)
				this.multipleSelection = val;
				this.serials = [""];
				this.multipleSelection.forEach(item => {
					this.serials.push(item.serial);
				});
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的接种记录 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.device.orderBatchRemove, {
						serials: serials
					}, true, res => {
						this.orderList = this.orderList.filter(order => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == order.serial) {
									flag = false;
								}
							})
							return flag;
						});
					});
				});
			}
		},
		created() {
			this.getOrders();
		}
	}
</script>

<style>
</style>
