<template>
	<div class="home">

		<div class="pdding20 bgffffff radius5 font12">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>数据中心</el-breadcrumb-item>
				<el-breadcrumb-item>数据分析</el-breadcrumb-item>
			</el-breadcrumb>
		</div>


		<div class="pdding10 bgffffff mgtop10">
			<el-button @click="hubIndex=0" type="success" size="small"><i class="fa fa-line-chart"></i> 用户来源</el-button>
			<el-button @click="hubIndex=1" type="info" size="small"><i class="fa fa-pie-chart"></i> 病种分类</el-button>
			<el-button @click="hubIndex=2" type="warning" size="small"><i class="fa fa-line-chart"></i> 影像所见</el-button>
			<el-button @click="hubIndex=3" type="danger" size="small"><i class="fa fa-pie-chart"></i> 消费券销售</el-button>
		</div>

		<member-hub v-if="hubIndex==0"></member-hub>
		<diseases-hub v-if="hubIndex==1"></diseases-hub>
		<image-hub v-if="hubIndex==2"></image-hub>
		<coupon-hub v-if="hubIndex==3"></coupon-hub>
	</div>
</template>

<script>
	import CouponHub from '@/components/hub/CouponHub'
	import DiseasesHub from '@/components/hub/DiseasesHub'
	import MemberHub from '@/components/hub/MemberHub'
	import ImageHub from '@/components/hub/ImageHub'

	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'Home',
		components: {
			CouponHub,
			DiseasesHub,
			MemberHub,
			ImageHub
		},
		computed: {
			...mapGetters([
				'principal', 'isLogin'
			])
		},
		data() {
			return {
				hubIndex: 0
			}
		},
		methods: {

		},
		created() {}
	}
</script>
